<template>
	<div class="o-synchro-calendar block block--notitle">
		<div class="equal-padding">
			<p data-icon_after="calendar" class="icon-flex" v-if="user">
				<span v-if="user.isLegalRepresentative">
					Retrouvez toutes les formations souscrites par vos collaborateurs sur Gmail, Calendar, Outlook, en synchronisant votre calendrier.
				</span>
				<span v-else>
					Retrouvez toutes vos formations souscrites sur Gmail, Calendar, Outlook, en synchronisant votre calendrier.
				</span>
			</p>
			<div class="bottom-right bottom-right--margin">
				<form @submit.prevent="download" id="download-ical">
					<submit text="Synchroniser le calendrier" :loading="loading" :error="error" :disabled="error" :text-error="error"></submit>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

	import formationCourseRepository from '@/repositories/formationCourseRepository';

	export default {
		name: "synchro-calendar",
		data(){
			return{
				loading: false,
				error: false
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		methods:{
			download(){
				this.loading = true;
        formationCourseRepository.downloadICal().then(response=>{
					this.loading = false;
				},response=>{

					if( response.status === 404 )
						this.error = "Aucune formation disponible à ajouter";

					this.loading = false;
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-synchro-calendar{

	}
</style>