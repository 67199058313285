<template>
	<dl class="accordion box" role="presentation">
		<accordion-item
				v-for="(item,index) in content"
				:multiple="multiple"
				:item="item"
				:itemId="index"
				:groupId="group"
				:key="index">
		</accordion-item>
	</dl>
</template>

<script>
export default {
	name: "accordion",
	props: {
		content: {
			type: Array
		},
		multiple: {
			type: Boolean,
			default: false
		},
		group: {
			type: String,
			default: false
		}
	}
};
</script>

<style lang="scss">
@import '../../environment';
.accordion {
	padding: 0;

	div:not(:last-child) {
		border-bottom: 1px solid $c-border;
	}

	div:last-child .accordion-item-details {
		border-radius: 5px;
	}

	dd {
		margin-left: 0;
	}
}

</style>