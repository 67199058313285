<template>
	<div class="m-user-register">

		<div v-if="registered" class="m-user-register__validated primary">
			<div class="title-big">Inscription enregistrée</div>
			<p><b>Merci, nous vous avons envoyé un email pour confirmer la validation de votre compte</b></p>
		</div>

		<div v-else>
			<div class="title-big">Formulaire d'adhésion</div>
			<form @submit.prevent="register" id="user-register">
				<vue-form-generator tag="div" :schema="form" :model="user" :options="formOptions" @validated="validateForm"></vue-form-generator>
				<div class="error m-user-register__error" v-if="warning">Attention : l’adresse email saisie ressemble à une adresse email professionnelle. Vous devez vous assurer de renseigner une adresse email personnelle à cette étape de l’inscription.</div>
				<div class="bottom-center">
					<submit :loading="loading" text="Valider l'inscription" :success="registered" text-success="Validé"></submit>
				</div>
			</form>
			<div class="m-user-register__google-connect">
              <span class="m-user-register__google-connect-separator">Ou</span>
              <div class="m-user-register__google-connect-actions">
                <span>Ou connectez-vous avec...</span>
                <button @click="handleClickOnGoogleConnect" class="button button--white button--google-connect">Google</button>
              </div>
            </div>
		</div>

	</div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator'
import userRepository from "@/repositories/userRepository";
import fieldPasswordVue from "../atom/fields/field-password-vue.vue";
import googleConnectRepository from "@/repositories/googleConnectRepository";

export default {
	name: "user-register",
  components: {
    fieldPasswordVue
  },
  data(){
		return{
			registered: false,
			error: false,
			loading: false,
			formIsValid: false,
			checkEmailOnce: false,
			warning: false,
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
			user:{
        email:'',
				password:'',
				confirmPassword:'',
				type: this.$store.getters.type()
			},
			form: {
				groups:[
					{
						legend: "Vos informations personnelles",
						styleClasses: "form-group-title"
					},
					{
						fields: [
							{
								type: "input",
								inputType: "email",
								model: "email",
								label: "Email personnel",
								maxlength: 60,
								required: true,
								placeholder: "jean@dupond.fr",
								styleClasses: "fields-label"
							},
							{
								type: "password-vue",
								inputType: "password",
								model: "password",
								label: "Mot de passe",
								minlength: 6,
								placeholder: "******",
								required: true,
								styleClasses: "fields-label",
								autocomplete : "new-password"
							},
						]
					}
				]

			}
		}
	},
	methods:{
		validateForm(isValid, errors) {
			this.formIsValid = isValid;
		},
		checkDomains(){
			const authorized = [
				'9online.fr',
				'aliceadsl.fr',
				'alicepro.fr',
				'aol.com',
				'aol.fr',
				'bbox.fr',
				'caramail.com',
				'cegetel.net',
				'club-internet.fr',
				'fnac.net',
				'free.fr',
				'gmail.com',
				'gmx.fr',
				'googlemail.com',
				'hotmail.com',
				'hotmail.fr',
				'icloud.com',
				'infonie.fr',
				'laposte.net',
				'libertysurf.fr',
				'live.com',
				'live.fr',
				'mac.com',
				'me.com',
				'msn.com',
				'msn.fr',
				'neuf.fr',
				'noos.fr',
				'numericable.fr',
				'online.fr',
				'orange.fr',
				'outlook.com',
				'outlook.fr',
				'sfr.fr',
				'voila.fr',
				'wanadoo.fr',
				'worldonline.fr',
				'yahoo.com',
				'yahoo.fr']
			let domain = this.user.email.split('@').pop();
			this.checkEmailOnce = true;
			this.warning = authorized.indexOf(domain) === -1;

			return authorized.indexOf(domain) !== -1
		},
		register(){
			if(!this.formIsValid)
				return;

			let verified;
			if(!this.checkEmailOnce)
				verified = this.checkDomains()
			else
				verified = true

			if(!verified)
				return;

			this.loading = true;
			let self = this;

			userRepository.create(this.user).then(response=>{
				this.registered = true;
				this.loading = false;
			},response=>{
				this.loading = false;
			})
		},
		async handleClickOnGoogleConnect(){
			googleConnectRepository.register().then(response=>{
				if(response.body.response.message==="User already exist"){
					this.$popin("google-connect-notice", {type: "already-exist"})
				}
				else{
					window.location.reload();
				}
			})
    	},
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-user-register{
	text-align: left; width: 50rem;
  @media #{$to-tablet}{ width: auto }

	.form-group + .form-group{ margin-top: $space-m }

	&__validated{ text-align: center; padding: $space $space-s }

	&__title{ margin-bottom: $space-m }

	&__error{ margin-top: $space-m }

	input[type=text],input[type=date],input[type=tel],input[type=email],input[type=password]{ background: transparent; border: solid 1px $c-border }

	&__google-connect{
    margin-top: $space;
    &-separator {
		color: $c-primary;
		font-size: $font-s;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		&::before, &::after {
			flex: 1;
			content: '';
			padding: 1px;
			background-color: $c-primary;
		}
		&::before{
			margin-right: 2rem;
		}
		&::after{
			margin-left: 2rem;
		}
    }

    &-actions{
      font-size: $font-xs;
      text-transform: uppercase;
      margin-top: $space;
      display: flex;
      gap: $space-s;
      justify-content: center;
      align-items: center;
    }

  }
}

</style>
