<template>
  <div class="o-clap-alert-ship__img-alert-new-system-container" :class="interface ? interface.visibility : ''">
    <img class="o-clap-alert-ship__img-alert-new-system-ship" src="/img/new-ship.svg" alt="SHIP"/>
    <div>
      <div><span class="o-clap-alert-ship__img-alert-new-system-new-red">NEW</span> <span style="font-size: 16px; font-weight: 600">Le 3 juillet !</span></div>
      <div class="o-clap-alert-ship__img-alert-new-system-paragraph-container">
        <span>Vos espaces documents et signatures évoluent vers un nouveau système.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'clap-alert-ship',
  props: ['interface'],
}
</script>

<style lang="scss">
@import '../../environment';
.o-clap-alert-ship{
  &__img-alert-new-system-container{
    background-color: $c-primary;
    border-radius: 4px 4px 0 0;
    height: 16rem;
    padding: $space-m $space $space;
    color: $c-background;
    margin: -20px -30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-alt;
      @media #{$to-tablet} {
        height: 25rem;
        margin: 0;
        margin-top: 3rem;
        border-radius: 4px;
    }
    .o-grid__aside &{
      border-radius: $border-radius;
      height: 288px;
      margin: 30px 0 0 0;
      padding: $space-m $space $space;
      color: $c-background;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      font-family: $font-alt;
    }
  }
  &__img-alert-new-system-ship{
    position: absolute;
    margin-top: 10%;
    .o-grid__aside &{
      margin: 0 0 16% 0;
    }
  }
  &__img-alert-new-system-new-red{
    background-color: var(--c-secondary);
    border-radius: 30px;
    padding: 2px 10px;
    margin-right: 4px;
    color: #FFFFFF;
  }
  &__img-alert-new-system-paragraph-container{
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }
}


</style>
