<template>
	<div class="m-contact-reports">
		<h2 class="title">{{ data.title }}</h2>
		<div class="center" v-if="loading"><loader static="1"/></div>
		<div v-else class="m-contact-reports__list">
			<p v-if="list.length === 0">Votre collaborateur ne dispose d'aucune formation</p>
			<contact-report v-for="(training, index) in list" :key="training.id" :data="training" class="m-contact-reports__item" @delete="deleteItem" />
		</div>
	</div>
</template>

<script>
	import formationCourseRepository from '@/repositories/formationCourseRepository';

	export default {
		name: "contact-reports",
		data(){
			return{
				loading: true,
				download: false,
				list:[]
			}
		},
		props:['data'],
		methods:{
			openPopin(id){
				this.$popin('training-external',id)
			},
			deleteItem(id){
				this.list = this.list.filter(item =>{
					return item.id !== id
				})
			},
			getContactReport() {
        formationCourseRepository.getContactReport(this.data.id).then(response=>{
					this.loading = false;
					this.list = response.list;

				}, response=>{
					this.loading = false;
					this.error = response.body.status_text;
				})
			},
			downloadAll(contact_id) {
				this.download = true;
				formationCourseRepository.downloadAll(contact_id).then(response=>{ this.download = false }, response=>{ this.download = false });
			}
		},
		mounted(){
			this.getContactReport();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.m-contact-reports{
		text-align: left;

		.title + &__list{ margin-top: $space-m }

		&__list &__item{ background: $c-background-lighter; padding: $space-s; margin-top: 4px!important; }

		&__item{
			& + &{ margin-top: $space }
			& + .bottom-between{ margin-top: $space-l }
		}

		&__infos{
			display: flex; align-items: center;
			& > * + *{ margin-left: $space-m }
		}
		&__date{ font-size: $font-xs }

		.bottom-between > * + * { margin-left: $space-s }
	}

</style>