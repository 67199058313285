<template>
	<div class="m-payment-summary">
			<div v-if="!data.payment.state && summary">
				<div class="title">Récapitulatif de l'achat</div>

				<div v-for="(detail,index) in summary.details" :key="index" class="primary">
					<div v-html="detail.formation.startAt !== detail.formation.endAt ? formatShortDate(detail.formation.startAt) + ' - ' + formatShortDate(detail.formation.endAt) : formatShortDate(detail.formation.startAt)"></div>
					<p class="title-big">{{detail.formation.title}}</p>
					<div class="m-payment-summary__icons">
						<span data-icon="pin" class="icon-flex">{{detail.formation.location.city}}</span>
						<span data-icon="timer" class="icon-flex">{{detail.formation.duration.hours}}h</span>
					</div>

					<p class="m-payment-summary__contacts"><b>Collaborateur(s) inscrit(s)</b></p>
					<ul>
						<li v-for="(contact,index) in user.staff" :key="'part'+index" v-if="detail.contacts.includes(contact.id)" class="m-payment-summary__line">
							{{contact.firstname + ' ' + contact.lastname}} <span>{{formatPrice(detail.price)}}</span>
						</li>
					</ul>
				</div>
				<div class="m-payment-summary__subtotal">
					<div class="m-payment-summary__line">Sous total <span>{{formatPrice(summary.totalAmount)}}</span></div>
					<div class="m-payment-summary__line">TVA ({{tax}}%) <span>{{formatPrice(summary.totalTax)}}</span></div>
				</div>
				<div class="title-big m-payment-summary__line">Total TTC <span>{{formatPrice(summary.totalAmount+summary.totalTax)}}</span></div>
			</div>
		<div v-else>
			Le paiement n'est pas finalisé. {{ error }}
			<div @click="goTo()">Retour au paiement</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "payment-summary",
		props:['data'],
		data() {
			return {
				summary: false,
				error: false
			}
		},
		computed:{
			user(){
				return this.$user()
			},
			tax(){
				// TODO calculate Tax
				return (this.summary.totalTax/this.summary.totalAmount).toFixed(2)*100
			}
		},
		methods:{
			goTo(){
				this.$store.commit('popin',false)
				this.$router.push({ name: 'order', params:{id: this.data.id}})
			}
		},
		mounted(){
			let _this = this;
			this.$http.get('order/'+_this.data.id).then(response => {
				_this.summary = response.body.response;
				resolve();
			})
				.catch(e => {
					_this.error = e.body.status_text

				})
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-payment-summary{
		text-align: left;

		.title + *{ margin-top: $space }

		&__contacts{ text-transform: uppercase}

		&__details-title{
			margin-top: $space*2;
			& + *{ margin-top: $space-m }
		}

		&__details{
			background: $c-background; padding: $space-m;

			& > * + *{ margin-top: $space-s }
		}

		&__icons{
			&> * + *{ margin-left: $space }
			& + *{ margin-top: $space }
		}

		&__subtotal{
			margin-top: $space; text-transform: uppercase;
			& + *{ margin-top: $space }
		}

		&__line{ display: flex; justify-content: space-between }
	}

</style>