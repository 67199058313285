<template>

	<div class="m-news" :class="{'m-news--featured': data.featured}">
    <div class="m-news__thumbnail" v-if="data.thumbnail">
      <img :src="data.thumbnail+(large?'?w=467&h=163\'':'?w=356&h=125')"/>
		</div>
    <div class="m-news__date" v-if="!data.featured">{{ formatDigitDate(data.modifiedAt) }}</div>
		<div class="m-news__content">
      <div class="m-news__featured" v-if="data.featured">A la une</div>
      <div class="m-news__sup" v-else-if="data.label">{{ data.label }}</div>
      <h2 class="m-news__title title" v-if="data.title">{{ data.title }}</h2>
      <div class="m-news__description" v-if="data.description" v-html="data.description"></div>
		</div>
    <a class="m-news__link" :href="data.link" target="_blank" rel="noopener" v-if="data.linkType === 'external'"></a>
    <a @click="preview(data.linkType, data.link)" class="m-news__link" v-else-if="data.linkType === 'document'"></a>
    <router-link :to="'/edito/'+data.link" class="m-news__link" v-else-if="data.linkType === 'page'"></router-link>
    <router-link :to="'/news/'+data.slug" class="m-news__link" v-else-if="data.linkType === 'article'"></router-link>
	</div>
</template>

<script>

	import documentRepository from '@/repositories/documentRepository';
	import userRepository from '@/repositories/userRepository';

	export default {
		name: 'news',
		props:['data','large'],
		methods:{
			preview(type, id){
        this.$popin('preview', {type:type, id:id});
			},
			getProgress(){
				return this.data.quota < this.data.completed ? 100 : (this.data.completed/this.data.quota)*100
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-news{
		$c: &;

		position: relative; padding: $space-m; border-radius: $border-radius; border: 1px solid rgba(0,0,0,0.1);

    & + &{
      margin-top: $space-m;
    }

    &__date{
      background: rgba(0,0,0,0.5); border-radius: $border-radius; font-size: $font-xs; display: inline-block; padding: 4px 10px; color: #fff; margin-bottom: $space-m;
    }

    &__description{ color: $c-primary; margin-top: $space-s }

		&__featured{ color: $c-secondary; font-size: $font-xs; font-weight: bold; margin-bottom: $space-xs; text-transform: uppercase }

		&__thumbnail{
			border-radius: $border-radius $border-radius 0 0; overflow: hidden; padding-top: 35%; position: relative; margin: -$space-m; margin-bottom: $space-m;
			img{ position: overlay(); object-fit: cover; height: 100%; width: 100% }
      a{ cursor: pointer }
			& + *{ margin-top: $space-s }
		}

    &__thumbnail + &__date{
      position: absolute; left: $space-m; top: $space-m; margin-top: 0
    }

		&__sup{
			color: $c-primary; font-size: $font-xs; font-weight: bold;
			& + *{ margin-top: $space-s }
		}

		&__link{ position: overlay(); cursor: pointer }

		.title{ color: $c-text }
		&__content{ position:relative }

		&__pin{ position: absolute; top : -2px; right: 0; color: $c-primary; z-index: 1 }
	}
</style>
