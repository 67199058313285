<template>
	<div class="a-autocomplete" :data-empty="empty" :class="{open:'a-autocomplete--open'}">
		<div class="a-autocomplete__container">
			<a class="a-autocomplete__geoloc" v-if="geoloc && has_geoloc" @click="geolocalise"></a>
			<div class="a-autocomplete__tags" v-if="seletedOptions.length">
				<span @click="removeOption(option)" v-for="option in seletedOptions">{{ option.label }}</span>
			</div>
			<input type="text" name="autocomplete" ref="autocomplete" class="a-autocomplete__field" @input="autoComplete" @focus="focused" @blur="hideOnClickOutside"/>
		</div>
		<input type="hidden" :name="schema.inputName" :value="hiddenValue"/>
		<transition name="slideDown">
			<ul class="a-autocomplete__options" v-if="open">
				<li class="a-select__option" @click="addOption(option)" v-for="option in schema.values">
					{{ option.label }}
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
	import { abstractField } from "vue-form-generator";

	export default {
		name: "field-autocomplete",
		mixins: [ abstractField ],
		data (){
			return{
				options: [],
				seletedOptions: [],
				open: false,
				sending: false,
				empty: 'true',
				hiddenValue: '',
				timeout: false,
				has_geoloc: navigator.geolocation,
				geoloc:false,
				ismultiple: false
			}
		},
		watch:{
			seletedOptions(items){
				if(items.length){
					this.$refs.autocomplete.removeAttribute('required');
					this.hiddenValue =  this.seletedOptions.map(function(elem){return elem.value}).join('|||');
				}
				else{
					this.hiddenValue = '';
				}

				this.empty = items.length > 0 ? 'false' : 'true';
			},
			isrequired(val){
				this.updateRequired(val);
			}
		},
		methods:{
			showSelect(){
				this.open = true;
			},
			geolocalise(){
				let self = this;
				navigator.geolocation.getCurrentPosition(function(position){
					self.$http.post("/api/postal_code.php", {lat:position.coords.latitude, lng:position.coords.longitude}).then(response => {
						self.seletedOptions = [{label:'Ma position', value:response.body}];
						self.$emit('input', self.seletedOptions);
					});

				}, function(e){
					if( e.code === e.PERMISSION_DENIED )
						alert('Vous devez autoriser la géolocalisation depuis les paramètres de votre navigateur.');
					else if( e.code === e.POSITION_UNAVAILABLE )
						alert('La position n’a pu être déterminée.');
				});
			},
			hideSelect(){
				this.open = false;
			},
			clearSelect(){
				this.open = false;
				if( 'autocomplete' in this.$refs && this.$refs.autocomplete)
					this.$refs.autocomplete.value = "";
			},
			hideOnClickOutside(e) {
				if ( !this.$el.contains(e.target) )
					this.clearSelect();
			},
			addOption(option){
				if( !this.seletedOptions.length || this.ismultiple )
					this.seletedOptions.push(option);

				this.$emit('input', this.seletedOptions);
				this.clearSelect();
			},
			removeOption(option){

				this.seletedOptions = this.seletedOptions.filter(function(el) { return el.value !== option.value });
				this.$emit('input', this.seletedOptions);
			},
			autoComplete() {

				clearTimeout(this.timeout);

				let value = this.$refs.autocomplete.value;

				if( value.length < 2 || this.sending )
					return;

				this.$http.post(this.api, {'value':value, 'rows':6}).then(response => {

					this.sending = false;
					this.options = [];

					if(response.data.length === 0){
						let noresult = {'label': this.noresult, 'value': 'false'};
						this.options.push(noresult);
						this.timeout = setTimeout(this.hideSelect, 5000);
					}
					else{
						this.options = response.data;
					}

					this.open = true;

				}, response => {

					this.sending = false;
				});
			},
			focused(){
				this.empty = 'false'
			}
		},
		mounted (){

			this.seletedOptions = this.value;

			document.addEventListener('click', this.hideOnClickOutside);
		}
	}
</script>

<style scoped>

</style>