<template>
	<div class="m-contact" :class="{'m-contact--inactive':!data.isActive}">
		<loader blur="1" v-if="loading"></loader>
		<div class="m-contact__heading">
			<div class="m-contact__thumbnail">
				<div>
					<img :src="data.avatar" v-if="data.avatar" />
					<span class="m-contact__initial" v-else><span v-if="data.firstname">{{ data.firstname.substr(0,1) }}</span>{{ data.lastname.substr(0,1) }}</span>
				</div>
			</div>
			<div class="m-contact__name">
				<div class="title-big"><span v-if="data.firstname">{{data.firstname}}</span> {{data.lastname}}</div>
				<div v-if="data.isLegalRepresentative">Répresentant légal</div>
				<div v-if="data.isCommercialAgent">Agent commercial</div>
			</div>
			<div class="toggle" @click="toggleActive" :class="{'toggle--active': data.isActive}" v-if="hasRole(user,'company')">
				<div class="toggle-item" title=""></div>
			</div>
		</div>
		<div class="m-contact__infos primary">
			<div class="m-contact__inner">

				<div class="m-contact__title">
					<div class="subtitle">Informations</div>
          <div class="m-contact__buttons">
            <template v-if="hasRole(user,'company') || hasRole(user, 'user_contact_write')">
              <a class="m-contact__modify disabled" data-icon="write" title="Ce collaborateur dispose de son propre espace adhérent. Toute modification doit être faite par lui dans son espace adhérent associé" v-if="data.isCommercialAgent"></a>
              <a class="m-contact__modify" data-icon="write" @click="modifyContact" v-else></a>
            </template>
          </div>
        </div>

        <div class="m-contact__col" v-if="data.addresses.work.phone">
					<div class="subtitle subtitle--light">Téléphone pro</div>
					{{data.addresses.work.phone}}
				</div>
				<div class="m-contact__col m-contact__col--right" v-if="data.birthday">
					<div class="subtitle subtitle--light">Anniversaire</div>
					<span v-html="formatBirthday(data.birthday)"></span>
				</div>
				<div>
					<div class="subtitle subtitle--light">Email Pro</div>
					<div v-if="data.addresses.work && data.addresses.work.email">{{data.addresses.work.email}}</div>
					<div v-else class="error">Email manquant !</div>
          <div class="error" v-if="!data.addresses.work.emailUnique">Cet  email est utilisé sur plusieurs compte</div>
        </div>
				<div v-if="data.addresses.home && data.addresses.home.street">
					<div class="subtitle subtitle--light">Adresse Perso</div>
					{{data.addresses.home.street}}<br/>
					{{data.addresses.home.zip}} {{data.addresses.home.city}}
				</div>
        <div v-if="data.isMember">
          <div class="subtitle subtitle--light">Email Perso</div>
          <div v-if="data.addresses.home && data.addresses.home.email">{{data.addresses.home.email}}</div>
          <div v-else class="error">Email manquant !</div>
          <div class="error" v-if="!data.addresses.home.emailUnique">Cet email est utilisé sur plusieurs compte</div>
        </div>

				<div class="m-contact__col error" v-if="data.isLegalRepresentative && !data.addresses.work.startedAt">
					La date d'entrée dans l'entreprise n'est pas renseignée
				</div>
				<div class="m-contact__col error" v-if="!data.isLegalRepresentative && !data.addresses.work.issuedAt">
					La date de délivrance de l'attestation d'habilitation n'est pas renseignée
				</div>
				<div v-else-if="!data.isLegalRepresentative && data.addresses.work.issuedAt">
					<div class="subtitle subtitle--light">Attestation d'habilitation :</div>
					<span v-if="data.addresses.work.expireAt">Du</span> {{ formatDigitDate(data.addresses.work.issuedAt) }}
					<span v-if="data.addresses.work.expireAt">au {{ formatDigitDate(data.addresses.work.expireAt) }}</span>
				</div>

				<div v-if="data.elearning && data.elearning.version === 2" class="m-contact__elearning">
					<div class="m-contact__title">
						<div class="subtitle">Compte e-learning</div>
					</div>
          <span>V2</span>
        </div>
        <div v-else-if="data.elearning" class="m-contact__elearning">
					<div class="m-contact__title">
						<div class="subtitle">Compte e-learning</div>
						<a class="link" @click="reinitElearning" v-if="hasRole(user,'company') && !data.isCommercialAgent">Réinitialiser le mot de passe</a>
					</div>
					<div class="subtitle subtitle--light">Email de connexion</div>
					<span>{{ data.elearning.email }}</span>
				</div>
			</div>
		</div>
		<div class="m-contact__infos" v-if="hasRole(user,'company') && (!data.isMember || data.isCommercialAgent) && user.canCreateAccount">
			<div class="m-contact__inner">
				<div class="subtitle">
					<span v-if="user.contact && data.id === user.contact.id">Mon compte</span>
					<span v-else>Compte {{ data.isLegalRepresentative ? 'représentant légal' : 'collaborateur' }}</span>
				</div>
				<div class="m-contact__inner--account">
					<p v-if="!data.addresses.work || !data.addresses.work.email">Ce {{ data.isLegalRepresentative ? 'représentant légal' : 'collaborateur' }} ne dispose pas de mail pro</p>
					<p v-else-if="data.hasAccount">
						<span v-if="user.contact && data.id === user.contact.id">Vous disposez déja d'un compte</span>
						<span v-else>Ce {{ data.isLegalRepresentative ? 'représentant légal' : 'collaborateur' }} dispose déja d'un compte</span>
					</p>
					<p v-else>Ce {{ data.isLegalRepresentative ? 'représentant légal' : 'collaborateur' }} ne dispose pas encore de compte</p>
					<a class="button" :class="{'button--disabled':(data.hasAccount && !data.changePassword) || !data.addresses.work || !data.addresses.work.email}" @click="invite">
						<template v-if="data.hasAccount">
							<span v-if="data.changePassword">Réinviter</span>
							<span v-else>Invité</span>
						</template>
						<span v-else>Inviter</span></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import contactRepository from "@/repositories/contactRepository";
import userRepository from "@/repositories/userRepository";

export default {
	name: "contact",
	props:['data'],
	data(){
		return{
			loading:false,
      permissions: parseInt(process.env.VUE_APP_PERMISSIONS)
		}
	},
	computed:{
		user(){
			return this.$user()
		}
	},
	methods:{
		reinitElearning(){

			contactRepository.requestElearningPassword(this.data.id).then(response => {
				this.$popin('change-password', { type: 'elearning', email: response.body.response.email, login: this.data.id});
			});
		},
		invite(){
			this.loading = true;
			contactRepository.update(this.data.id,{hasDashboard:true}).then(response => {
				this.$update();
				this.loading = false;
			});
		},
		toggleActive(){

			this.loading = true;

			let addresses = {};
			addresses[this.data.addresses.work.index] = {isActive: this.data.isActive?'0':'1'};

			contactRepository.update(this.data.id, {addresses:addresses}).then(response => {
				this.data.isActive = !this.data.isActive;
				this.loading = false;
			}, response=>{
				this.loading = false;
			});
		},
		modifyContact(){
			const permissionsCanBeEdited = this.hasRole(this.user,'company') && this.data.hasAccount && !this.data.isLegalRepresentative && this.permissions;
			this.$popin('contact-settings', {...this.data, permissionsCanBeEdited}, false)
		},
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-contact{
	position: relative; display: flex; flex-flow: column;
	&__initial{
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center;
		align-items: center; color: $c-primary; font-size: 3rem; opacity: 0.2; font-weight: bold; text-transform: uppercase;
	}
  &__infos{
    &.primary{ background: $c-background-lighter; height: 100% }
  }
	&__heading{
		padding: $space; display: flex; align-items: center; background: white; transition: allow(background);
		.m-contact--inactive &{ background: $c-background-lighter }
		& > * + *{ margin-left: $space-m }
	}
	&__thumbnail{
		width: 20%; padding-top: 20%; position: relative; border-radius: 50%; overflow:hidden;
		transition: allow(opacity);

		&:before{ position: overlay(); content: ''; background: $c-primary; z-index: 1;  opacity: 0.4 }

		.m-contact--inactive &{ opacity: 0.4 }
		img{ position:overlay(); object-fit: cover; height: 100%; width: 100% }
	}
	.title-big+div{ margin-top: 0 }
	&__name{
		flex: 1;
		.title-big{ font-size: 1.8rem; word-break: break-word }
		.m-contact--inactive &{ opacity: 0.5 }
	}
	&__inner{
		padding: $space; border-top: solid 1px $c-border; display: flex; flex-wrap: wrap;
		& > *{ width: 100%; margin-bottom: 1.5rem }
		.m-contact--inactive &{ opacity: 0.5 }
		&--account{
			display: flex; align-items: center; margin-bottom: 0; margin-top: -1rem;
			p{ font-size: 90% }
			.button{ margin-left: auto }
		}
	}
	&__title{
		display: flex; align-items: center;
		.link{ margin-left: auto; font-size: 85% }
	}
	&__col{
		width: 50%;
		&.error{ width: 100% }
		& + &--right{ text-align: right }
	}
	&__elearning{
		margin-top: 2rem;
		.m-contact__title{ margin-bottom: 1.5rem }
	}

	&__buttons{ margin-left: auto }
	&__modify{
		cursor: pointer; width: 30px; height: 30px; background: $c-primary; color: white; display: inline-flex; align-items: center; justify-content: center ; border-radius: 50%;
		font-size: 2rem; position: relative;
		& + &{ margin-left: $space-s }

		&--permission:after{ position: absolute; top: -2px; right: -6px; font-size: 1.2rem; width: 16px; height: 16px; border-radius: 50%; background: #FF8A00; align-items: center; justify-content: center; display: none  }
		&--hasRoles{
			&:after{ display: inline-flex }
		}
  }
}
</style>
