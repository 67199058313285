<template>
	<iframe width="100%" height="600px" :src="interface.url"></iframe>
</template>

<script>
export default {
	name: "iframe-component",
	props:['data', 'interface']
}
</script>

<style scoped lang="scss">
@import '../../environment';

iframe{
  height: calc(100vh - 29rem);
  @media #{$to-tablet}{ height: calc(100vh - 18rem) }
  .page--snpi_tv &{
    height: calc(100vh - 23rem);
    @media #{$to-tablet}{ height: calc(100vh - 8rem) }
  }
}
</style>