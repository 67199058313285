<template>
	<div class="custom-password field-input">
		<input
				class="form-control"
				v-model="value"
				:type="type"
				:disabled="disabled"
        :id="getFieldID(schema)"
        :name="getFieldName(schema)"
				:autocomplete="schema.autocomplete"
				:maxlength="schema.max"
				:placeholder="schema.placeholder" ref="input">
		<div class="custom-password__show" @mousedown="showPassword(true)"  @mouseup="showPassword(false)" data-icon="see"></div>
	</div>
</template>

<script>
	import { abstractField } from "vue-form-generator";

	export default {
		name: "field-password-custom",
		mixins: [ abstractField ],
		data (){
			return{
				type: "password"
			}
		},
		methods:{
			showPassword(show){
				let oldvalue = this.$refs.input.value;
				this.type = show ? "text" : 'password'
				this.$refs.input.value = oldvalue

			}
		}
	}
</script>

<style lang="scss">
	@import '../../../environment';
	.custom-password{
		position: relative;
		input::-ms-reveal, input::-ms-clear {
			display: none;
		}
		&__show{
			position: absolute; right: 0; cursor: pointer; top: 0; font-size: 22px; background: $c-background;
			padding: 0 1rem; bottom: 0; display: flex; align-items: center;
			&:before{ margin-top:  0 }

			.accessible-version &{ border: solid 1px $c-border; border-left: none; border-radius: $border-radius; border-top-left-radius: 0; border-bottom-left-radius: 0 }
		}
	}
</style>