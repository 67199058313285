<template>
	<div class="o-feature container">
		<div class="block">
			<h2 class="title">{{data.title}}</h2>

			<div class="o-feature__content">
				<div>
					<div v-if="data.content.uptitle" class="title title--small o-feature__uptitle">{{data.content.uptitle}}</div>
					<h3 class="title-big" v-if="data.content.title">{{data.content.title}}</h3>
					<div v-html="data.content.text"  v-if="data.content.text" class="o-feature__text"></div>

					<router-link :to="'/edito/'+data.content.cta.page" v-if="data.content.cta.type === 'edito'"  class="button button--white">{{ data.content.cta.title}}</router-link>
					<router-link :to="getPage(data.content.cta.url)" v-else-if="data.content.cta.type === 'internal'"  class="button button--white">{{ data.content.cta.title}}</router-link>
					<a class="button button--white" :href="data.content.cta.url" target="_blank" v-else>{{data.content.cta.title}}</a>
				</div>

				<div>
					<img :src="data.content.image" v-if="data.content.image" class="o-feature__image" />
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "feature",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-feature{

		&__uptitle{
			text-transform: uppercase; color: $c-secondary;

			&+*{ margin-top: $space-s }
		}

		&__content{


			@media #{$from-small}{
				display: flex; justify-content: space-between; align-items: center; padding: $space-ll!important;

				& > *{ width: calc(50% - #{$space}/3)}
			}

			@media #{$to-phone}{
				& > * +* { margin-top: $space-m }
			}


		}

		&__text{
			color: $c-primary;
			& +* { margin-top: $space }
		}

		&__image{ max-height: 33rem; margin: 0 auto; border-radius: 4px }
	}

</style>
