<template>
	<div class="o-elearning-tunnel">

		<div class="o-elearning-tunnel__infos">
			<div>
				<div class="o-elearning-tunnel__logo">
					<img src="/img/logo_snpi_campus.png" alt="SNPI Campus" class="vhsbs" />
				</div>

				<h1 class="o-elearning-tunnel__title title-big">
					Finaliser et valider ma formation
				</h1>

				<div class="subtitle" v-if="formationCourse">Nom de la formation</div>
				<h2 class="title-big o-elearning-tunnel__course">{{ formationCourse.title }}</h2>
				<p v-if="current_time < formationCourse.startAt">La formation commence le {{ formatDateTime(formationCourse.startAt) }}</p>
				<p v-else-if="current_time < formationCourse.endAt">La formation se termine le {{ formatDateTime(formationCourse.endAt) }}</p>
			</div>

			<div>
				<div class="o-elearning-tunnel__warning">
					<div data-icon="warning" class="o-elearning-tunnel__warning-icon"></div>
					Attention, si vous ne remplissez pas toutes les étapes du formulaire, nous ne pourrons pas vous délivrer votre attestation de formation !
				</div>
				<footer class="tablet">
					<div class="v-login__footer-container">
						<div class="v-login__footer-logo">
							<img src="/img/logo-snpi.svg" alt="SNPI" class="snpi" />
						</div>
						<div class="primary center">Pour toutes informations, contactez notre équipe</div>
						<div class="v-login__footer-infos">
							<a data-icon="phone" href="tel:01 53 64 91 91">01 53 64 91 91</a>
							<a data-icon="mail" href="mailto:contact@snpi.fr">contact@snpi.fr</a>
						</div>
					</div>
				</footer>
			</div>

		</div>

		<div class="o-elearning-tunnel__content" v-if="activeStep>=0&&!error">

			<div class="m-caci-register__steps o-elearning-tunnel__steps" :class="'m-caci-register__steps--'+(activeStep-1)">
				<div class="m-caci-register__step" v-for="(step,index) in steps" :key="index" :class="{'m-caci-register__step--full': index+1 <= activeStep }">
					<div class="m-caci-register__number"></div>
				</div>
			</div>
			<div class="o-elearning-tunnel__steps-title" v-if="!formationCourse.multiDays">
				<div class="subtitle">étape {{activeStep +'/' + steps.length}}</div>
				<div class="title-med">{{ steps[activeStep-1] }}</div>
			</div>

			<div class="o-elearning-tunnel__elements">
				<form @submit.prevent="getStatus" v-if="activeStep===1" class="o-elearning-tunnel__step1" id="e-learning-tunnel_start">
					<h3 class="title-big">Entrez votre email pour démarrer</h3>
					<vue-form-generator tag="div" :schema="form" :model="data"></vue-form-generator>
					<div class="bottom-right">
						<submit :loading="sending" text="étape suivante" :disabled="current_time < formationCourse.endAt"></submit>
					</div>
					<div v-if="current_time < formationCourse.endAt" class="o-elearning-tunnel__alert">La formation se termine le {{ formatDateTime(formationCourse.endAt) }}</div>
				</form>
				<poll v-else-if="activeStep===2" :formationParticipant="formationParticipant" @sent="formationParticipant.poll=true"></poll>
				<survey v-else-if="activeStep===3" :formationParticipant="formationParticipant" @sent="formationParticipant.survey=true"></survey>
				<formationSign v-else-if="activeStep===4" :formationParticipant="formationParticipant" @sent="formationParticipant.present=true"></formationSign>
				<div v-else-if="activeStep===5" class="o-elearning-tunnel__attestation">
					<h3 class="title-med" v-if="formationParticipant.type==='formationParticipant'">
            <span v-if="formationParticipant.ended">Votre formation est validée !</span>
            <span v-else>La feuille de présence a été signée</span>
          </h3>
					<h3 class="title-med" v-else>La feuille d'émargement a été signée</h3>
					<form @submit.prevent="download" id="e-learning-tunnel_download" v-if="formationParticipant.type==='formationParticipant'&&formationParticipant.ended">
						<submit :loading="downloading" text="Télécharger votre attestation"></submit>
					</form>
				</div>
			</div>
		</div>
		<div class="o-elearning-tunnel__content o-elearning-tunnel__content--error" v-else>
			<h3 class="title-med" v-if="error">{{ error }}</h3>
			<h3 class="title-med" v-else-if="activeStep===-1">Vous n'avez pas terminé la formation</h3>
			<h3 class="title-med" v-else-if="activeStep===-2"><loader static="1"></loader>Réception des éléments depuis Zoom.us, merci de revenir dans quelques instants...</h3>
		</div>

		<footer class="phone">
			<div class="v-login__footer-container">
				<div class="v-login__footer-logo">
					<img src="/img/logo-snpi.svg" alt="SNPI" class="snpi" />
					<span class="primary center">Pour toutes informations, contactez notre équipe</span>
				</div>
				<div class="v-login__footer-infos">
					<a data-icon="phone" href="tel:01 53 64 91 91">01 53 64 91 91</a>
					<a data-icon="mail" href="mailto:contact@snpi.fr">contact@snpi.fr</a>
				</div>
			</div>
		</footer>


	</div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";
import formationParticipantRepository from "@/repositories/formationParticipantRepository";

export default {
	name: "e-learning-tunnel",
	props:['id', 'type'],
	data(){
		return{
			data:{},
			error: false,
			formationCourse: false,
			formationParticipant: false,
			downloading: false,
			sending: false,
			loading: true,
			steps: [],
			stepsSingleDays: ['Identification','Quizz','Questionnaire de satisfaction','Feuille de présence','Attestation'],
			stepsMultiDays: ['Identification','Feuille de présence'],
			form:{
				groups:[
					{
						fields:[
							{
								type: "input",
								inputType: "email",
								maxlength: 60,
								model: "email",
								styleClasses: ["fields-label","margintop"],
								label: "Email",
								placeholder:'jean@dupond.fr',
								required: true
							}]
					}
				]
			}
		}
	},
	computed:{
    current_time(){ return this.$currentTime() },
		activeStep(){
			if( !this.formationCourse )
				return 0;
			else if( !this.formationCourse.processed )
				return -2;
			else if( !this.formationParticipant )
				return 1
			else if( this.formationCourse.completed && !this.formationParticipant.completed )
				return -1
			else if( !this.formationParticipant.poll )
				return 2
			else if( !this.formationParticipant.survey )
				return 3
			else if( !this.formationParticipant.present )
				return 4
			else
				return 5
		}
	},
	methods: {
		getStatus(){
			this.sending = true;
			formationCourseRepository.getStatus(this.formationCourse.id, this.data.email).then(formationParticipant=>{
				this.formationParticipant = formationParticipant;
				this.sending = false;
			}, response=>{
				this.sending = false;
			})
		},
		search(){

			this.$catchError(false);

			formationCourseRepository.search(this.type, this.id).then(formationCourse=>{

				this.formationCourse = formationCourse;

				this.steps = formationCourse.multiDays ? this.stepsMultiDays : this.stepsSingleDays;

				this.loading = false;

				if( this.data.email )
					this.getStatus();

			}, response=>{
				this.error = response.body.status_text;
				this.loading = false;
			})
    },
    download(){
      this.downloading = true;
      formationParticipantRepository.terminate(this.formationParticipant.id).then(response=>{
        formationParticipantRepository.download(this.formationParticipant.id).then(response=>{
          this.downloading = false;
        }, response=>{
          this.downloading = false;
        });
      }, response=>{
        this.downloading = false;
      });
		}
	},
	mounted() {

		const urlParams = new URLSearchParams(window.location.search);
		if( urlParams.has('email') )
			this.data.email = urlParams.get('email');

		this.search();
		let self = this;
	}
}
</script>

<style lang="scss">
@import '../../environment';

.o-elearning-tunnel{
	$c: &;

	@media #{$from-tablet}{
		display: flex; height: 100vh;
		.phone{ display: none }
	}

	@media #{$to-phone}{
		.tablet{ display: none }
		footer{ margin-top: $space; margin-bottom: $space-m }
	}

	@media #{$only-tablet}{
		.v-login__footer-infos{
			& > * { display: block }
			& > * + *{ margin-left: 0; margin-top: $space-s }
		}
	}

	&__infos{ padding: $space-m; text-align: left;
		@media #{$from-tablet}{ padding: $space-l; display: flex; justify-content: space-between; width: 33%; flex-direction: column;  }
	}

	&__alert{
		position: absolute; left: 0; width: 100%; top: 0; height: 100%; z-index: 1; background: rgba(255,255,255,0.8); display: flex; align-items: center; justify-content: center; color: $c-red;
	}

	&__step1{ position: relative }

	&__content{
		flex: 1; background: white; padding: $space-m; min-height: 100%; display: flex;
		flex-direction: column; align-items: center; overflow: hidden; overflow-y: scroll; -webkit-overflow-scrolling: touch;
		&--error{ justify-content: center }
		@media #{$from-tablet}{ padding: $space-l $space-xl }
	}

	&__logo{
		display: flex; align-items: center;
		& > * + *{ margin-left: $space-m }
		.snpi{ width: 5rem }
		.vhsbs{ width: 13rem }
		& + *{
			margin-top: $space-m;
			@media #{$from-tablet}{ margin-top: $space-l }
		}
	}

	.v-login__footer-infos{ text-align: center }

	&__title{
		font-size: 2.5rem;
		@media #{$from-tablet}{ font-size: 3rem }
		& + *{ margin-top: $space }
	}

	&__course{ color: $c-primary-light }

	&__steps{
		width: 100%;
		@media #{$from-tablet}{ width: 46rem }
		& + &-title{ margin-top: 1rem }
		&-title + *{ margin-top: $space }
	}
	.subtitle{ font-size: $font-xs }

	&__warning{
		background: white; border-radius: $border-radius; color: $c-secondary; padding: $space-s $space-m; text-align: left; font-size: $font-l;
		line-height: 1.4; margin-top: $space-m;
		@media #{$from-tablet}{ padding: $space-m }

		& + *{ margin-top: $space }

		&-icon{
			margin-bottom: $space-m;
			&:before{ font-size: $font-xl }
		}
	}

	&__elements{
		flex: 1; display: flex; justify-content: center; align-items: center; width: 90%;
		.safari &{ align-items: stretch }
	}

	&__attestation{
		.title-med + *{ margin-top: $space }
	}
	.m-caci-register__steps--2:after{ width: 51% }
	.m-caci-register__steps--3:after{ width: 71% }
	.snpi{ width: 5rem; margin-right: 1rem }
	.v-login__footer-logo{
		display: flex; justify-content: center;
		& + *{ margin-top: $space-s}
	}

	.radio-list-inputs{
		.fields-label label{ margin-bottom: $space-s }
		.radio-list,.listbox{
			display: block;
			& > * + *{ margin-left: 0 }
			& > *{ align-items: flex-start }
			input{ flex-shrink: 0 }
			label{ align-items: flex-start}
		}

		label{
			margin-bottom: 1rem; font-size: 1.3rem;
		}
	}

}

</style>
