<template>
	<form id="caci-register_companies">
		<h3 class="primary">
      <b v-if="type==='vhs'">Votre société</b>
      <b v-else>Agences par lesquelles vous êtes mandaté</b>
    </h3>
		<div class="caci-register_companies__item" v-for="(address,index) in companiesAddresses" :key="index">
			<caci-company  :address="address"></caci-company>
			<template v-if="index > 0 && !address.id">
				<a class="caci-register_companies__item-delete" data-icon_after="close" @click="deleteLast" aria-label="close"></a>
			</template>
		</div>
		<div class="bottom-right-inline">
			<button class="button button--white" :class="{'button--disabled':!canAddCompany}" @click.prevent="addCompany" v-if="type!=='vhs'">Ajouter une autre agence</button>
			<button class="button" :class="{'button--disabled':!hasWorkAddress || !canAddCompany}" @click.prevent="nextStep">Suivant</button>
		</div>
	</form>
</template>

<script>

	import contactRepository from "@/repositories/contactRepository";

	export default {
		name: "caci-register-agencies",
		data(){
			return{
				error: false
			}
		},
		methods: {
			deleteLast(){
				this.user.contact.addresses.pop()
			},
			addCompany() {
				this.user.contact.addresses.push({
					id: false,
					company: '',
					email: '',
					phone: '',
					isMain: false,
					isHome: false,
					isActive: true
				});
			},
			nextStep() {
				contactRepository.setContractAgencies();
				this.$emit('nextStep');
			}
		},
		computed:{
			user(){ return this.$user() },
      type(){ return this.$store.getters.type() },
      hasWorkAddress() {
				return this.user.contact.addresses.filter(address=>{
					return address.id && address.isActive && !address.isHome
				}).length
			},
			companiesAddresses(){
				return this.user.contact.addresses.filter(address => {
					return !address.isHome
				})
			},
			canAddCompany() {
				const addresses = this.user.contact.addresses;
				const lastAddress = addresses[addresses.length - 1];
					return lastAddress && lastAddress.company && lastAddress.id;
			}
		},
		mounted() {
			 if( !this.hasWorkAddress ){
				 this.addCompany();
				 this.$forceUpdate();
			 }
		}
	}
</script>

<style lang="scss">
.caci-register_companies__item{ position: relative }
.caci-register_companies__item-delete{ position: absolute; right: 10px; top: 10px; cursor: pointer }
.caci-register_companies__item-delete:after{ margin: 0 }
</style>
