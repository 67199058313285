<template>
  <div class="a-preview" :class="'a-preview--'+type" v-if="item">
    <img :src="item.thumbnail" v-if="item.thumbnail"/>
    <h2 class="title">{{item.title}}</h2>
    <div>Publié le {{ formatDate(item.createdAt) }}</div>
    <ul class="a-preview__assets">
      <li v-for="(file, index) in item.assets" :key="index">
        <form @submit.prevent="downloadAsset(index)" id="preview_download">
          <submit class="button--icon" data-icon="download" :text="(type==='document'&&item.assets.length>1)?file.title:'Télécharger'" :loading="loading===index"></submit>
        </form>
      </li>
    </ul>
  </div>
  <loader v-else static="1"></loader>
</template>

<script>
import documentRepository from '@/repositories/documentRepository';
import appendixRepository from '@/repositories/appendixRepository';
import newsRepository from '@/repositories/newsRepository';

export default {
  name: "preview",
  props:['data'],
  data(){
    return{
      item: false,
      type: '',
      loading: false
    }
  },
  methods:{
    downloadAsset(index){

      this.loading = index;

      if(this.data.type === 'document'){
        documentRepository.download(this.item.id, index).then(response=>{
          this.loading = false
        },response=>{
          this.loading = false
        });
      }
      else if(this.data.type === 'appendix'){
        appendixRepository.download(this.item.id).then(response=>{
          this.loading = false
        },response=>{
          this.loading = false
        });
      }
    }
  },
  mounted(){

    this.type = this.data.type

    if( typeof this.data.item != 'undefined' ){

      this.item = this.data.item
    }
    else{

      if(this.data.type === 'document'){

        documentRepository.get(this.data.id).then(response => {
          if( response && response.assets.length && response.assets[0].type === 'podcast'){
            this.$store.commit('podcast', response);
            this.$emit('close');
          }
          else
            this.item = response
        });
      }
      else if(this.data.type === 'appendix'){

        appendixRepository.get(this.data.id).then(response => {
          this.item = response
        });
      }
      else if(this.data.type === 'news'){

        newsRepository.get(this.data.id).then(response => {
          this.item = response
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../environment';
.a-preview{
  text-align: left; max-width: 50rem;

  & > * + *{ margin-top: $space-s }

  &__assets{
    margin: 0 -0.5rem; margin-top: $space-s;
    li{ display: inline-block; margin: 0.5rem }
    & > *{
      cursor: pointer;
      & + *{ margin-top: $space-s }
    }
  }
}
</style>