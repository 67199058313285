<template>
	<main class="v-register" v-if="user">
		<popin v-if="!user.type || user.isRegistering" type="modal" :content="{type:'caci-register', data:user}"></popin>
		<popin v-else type="default" :content="{type:'message', data:{title:'Inscription', text:'Votre inscription est terminée', link:'/', cta:'Continuer'}}"></popin>
	</main>
</template>

<script>

	export default {
		name: "Register",
		data(){
			return{
				step : 1
			}
		},
		computed:{
			user(){ return this.$user() }
		}
	}
</script>