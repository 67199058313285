import Vue from "vue";

export default {
  create(form) {
    return Vue.http.post("positioning", form);
  },

  createCold(form) {
    return Vue.http.post("appreciation-quiz", form);
  },
};
