<template>
	<div class="o-full-image container">
		<img :src="data.image+'?w=1440&h=489'" class="o-full-image__image" />
		<div class="o-full-image__content">
			<h1 class="title-big">{{data.title}}</h1>
		</div>

	</div>
</template>

<script>
	export default {
		name: "full-image",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-full-image{
		position: relative; margin: $space-l auto; border-radius: 4px; overflow: hidden;padding: 0;
		@media #{$to-tablet}{ margin: $space-m auto }
		&__image{
			width: 100%; height: auto; max-height: 60vh; object-fit: cover; filter: brightness(0.8);
		}
		&__content{
			display: flex; align-items: center; justify-content: center; z-index: 1; padding: $space-ll;

			@media #{$from-small}{
				position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba($c-primary,0.5); color: white;
				.title-big{ font-size: 3.4rem ; color: white; font-weight: normal; text-shadow: 0 0 20px rgba(0, 0, 0, 0.3) }
			}

			@media #{$to-tablet}{ padding: $space-m; background: white }
		}
	}
</style>
