import Vue from "vue";
import store from "@/services/store";

export default {

	get() {

		return new Promise((resolve, reject) => {
			Vue.http.get('config').then(response=>{
				store.commit('config', response.body.response);
				resolve()
			}, reject).catch(response=>{})
		});
	}
};
