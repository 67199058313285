<template>
	<div class="o-catalogue block" v-if="config">
		<div class="title">Notre catalogue</div>
		<div class="o-catalogue__content equal-padding">
			<a href="https://www.snpicampus.fr/formation" data-icon_after="book" class="icon-flex" target="_blank">Voir notre catalogue de formations</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "catalogue",
		computed:{
			config(){ return this.$config() }
		}
	}
</script>

<style lang="scss">
	.o-catalogue{
		&__content{
			line-height: 130%;
		}
	}

</style>