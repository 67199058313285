import { Loader } from '@googlemaps/js-api-loader';
import eventBus from "@/services/event-bus";
import configRepository from "@/repositories/configRepository";

export default {

	install (Vue, options) {

		let catchError = true;
		let heatbeat = false;

		Vue.prototype.$popin = function(type, data, closeable, priority, onClose){

			if( typeof closeable == 'undefined')
				closeable = true;

			if( typeof priority == 'undefined')
				priority = 2;

			this.$store.commit('popin', {
				type: type,
				data: data,
				closeable: closeable,
				priority: priority,
				onClose
			});
		};

		Vue.prototype.$catchError = function(status){

			catchError = status
		};

		Vue.prototype.$heartbeat = function(status){

			if( status && !heatbeat )
				heatbeat = setInterval(configRepository.get, 60000);
			else if( !status && heatbeat )
				clearInterval(heatbeat)
		};

		Vue.prototype.$mustCatchError = function(){

			return catchError
		};

		Vue.prototype.$error = function(data){

			this.$store.commit('error', {
				type: 'error',
				data: data
			});
		};

		Vue.prototype.$config = function(){

			return this.$store.getters.config();
		};

		Vue.prototype.$currentTime = function(){

			return this.$store.getters.currentTime();
		};

		Vue.prototype.$user = function(){

			return this.$store.getters.user();
		};

		Vue.prototype.$update = function(callback){

			if( typeof callback == 'undefined')
				eventBus.$emit('update');
			else
				eventBus.$on('update', callback);
		};

		Vue.prototype.$alert = function(message, closeable){

			closeable = typeof closeable == 'undefined' ? true : closeable;

			this.$store.commit('alert', {
				type: 'message',
				data: typeof message == 'string' ? {text:message} : message,
				closeable: closeable
			});
		};

		Vue.prototype.$broadcast = function(event, params){

			eventBus.$emit(event, params);
		};

		Vue.prototype.$scrollTop = function(callback){

			if( typeof callback == 'undefined')
				eventBus.$emit('scrollTop');
			else
				eventBus.$on('scrollTop', callback);
		};

		Vue.prototype.$receive = function(event, callback){

			eventBus.$on(event, callback);
		};

		Vue.prototype.$loadGoogleApi = function(libraries){

			let self = this;

			const loader = new Loader({apiKey: process.env.VUE_APP_GMAP, libraries: libraries});

			loader.load().then(() => {
				self.$store.commit('google', google)
			});
		};

		Vue.prototype.$resetTour = function(callback){
			if( typeof callback == 'undefined')
				eventBus.$emit('resetTour');
			else
				eventBus.$on('resetTour', callback);
		};
	}
}