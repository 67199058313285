import Vue from "vue";
import store from "@/services/store";
import cache from "@/services/cache";

export default {

	get(params) {

		return new Promise((resolve, reject) => {
			Vue.http.get('search', { params: params}).then(response => {
				let data = response.body.response;
				resolve(data);
			}, e => reject);
		})
	}
};
