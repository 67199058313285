<template>
	<div class="m-quiz">
		<div class="m-quiz__header" v-if="step === 2">
			<img src="/img/results-quiz.svg" width="300" height="236"/>
			<div class="m-quiz__result">
				<p class="title-big">{{ this.result.length }} <span v-if="this.result.length > 1">bonnes réponses</span><span v-else>bonne réponse</span> sur {{ this.data.quiz.length }}</p>
				<small>Bravo, vous avez obtenu {{ this.quizRating.toFixed(2) }}% de bonnes réponses !</small>
			</div>
		</div>

    <p class="m-quiz__details-title title-med" v-if="step === 2">Résultats détaillés</p>

    <form @submit.prevent="validate" ref="questions">

      <input type="hidden" name="quizId" :value="data.id">

      <question v-for="(question, index) in data.quiz" :data="question" :key="index" :number="index+1" :total="data.quiz.length" @question-answered="updateQuiz" :step="step"></question>

      <div class="bottom-right" v-if="step === 1">
        <submit class="button" :loading="sending" text="Valider les réponses"></submit>
      </div>
    </form>
  </div>
</template>

<script>
import formationParticipantRepository from "@/repositories/formationParticipantRepository";

export default {
	name: "quiz",
	props:['data','participant'],
	data(){
		return{
			result: [],
      sending: false,
			step: 1,
			quizRating: false
		}
	},
	methods:{
    updateQuiz(correct, index) {
      if (correct) {
        this.result.push(index);
      } else {
        this.result = this.result.filter((item) => item !== index);
      }
    },
		validate(){

		  if( this.sending )
		    return;

      this.sending = true;

      let formData = new FormData( this.$refs.questions );

      formationParticipantRepository.sendPoll(this.participant.id, formData).then(response=>{

        this.step++;
        this.sending = false;
				this.quizRating = response.quizRating;
				this.$emit("answer-checked", {editing: false})
        this.$emit('updated', 1)

      }, response=>{

        this.sending = false;
      })

		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
	.m-quiz{
		&__header{
			display: flex; flex-direction: column; align-items: center;
			img + *{ margin-top: $space }

			& + *{ margin-top: $space-l }

		}



		&__result{
			text-align: center;
			.title-big{ font-size: 3.5rem }
			.title-big + small{ margin-top: $space-s; font-size: $font-l }
		}
	}
</style>
