<template>
	<div class="o-faq block">
		<h2 class="title">{{interface.title}}</h2>
		<div class="equal-padding-l primary">
			<div class="title-big">Vous avez une question ?</div>
			<p>&laquo; Comment renouveler ma carte professionnelle ?&nbsp;&raquo;</p>
			<p>&laquo; J'ai changé d'adresse, que faire ?&nbsp;&raquo;</p>
			<p>&laquo; Comment déclarer un établissement secondaire&nbsp;?&nbsp;&raquo;</p>
			<p>&laquo; Comment paramétrer l'envoi de mes annonces ?&nbsp;&raquo;</p>
			<div class="bottom-right" v-if="config">
				<router-link :to="'/edito/'+config.option.faq_page.value" class="button">Consulter la FAQ</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "faq",
		props:['interface','data'],
		computed:{
			config(){ return this.$config() }
		}
	}
</script>

<style lang="scss">
	.o-faq{
		p + p{ margin-top: 0.5rem }
	}
</style>