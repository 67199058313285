<template>
	<div class="formation-sign primary">
		<h3>
			<b>
				Signer la feuille de présence
			</b>
		</h3>
		<div class="formation-sign__background">
			<p>
				<b>
					Après avoir cliqué sur le bouton suivant, vous recevrez un {{ formationParticipant.phone ? 'SMS': 'email' }} contenant votre code de sécurité au :
					<span class="secondary">{{ formationParticipant.phone ? formationParticipant.phone : formationParticipant.email }}</span>
				</b>
			</p>
			<div class="bottom-center">
				<form @submit.prevent="send" id="formation-sign_send-code">
					<submit :loading="loading==='send'" text="Envoyer le code" :success="sent" text-success="Code envoyé" :error="error" :text-error="error"></submit>
				</form>
			</div>
			<div v-if="step===2" class="center">
				<vue-form-generator class="center" tag="div" :schema="form" :model="model" :options="formOptions" ref="form"></vue-form-generator>
				<form @submit.prevent="check" class="margin" id="formation-sign_sign">
					<submit :loading="loading==='check'" text="Valider" :success="signed" text-success="Validé" :error="error" :text-error="error"></submit>
				</form>
				<div class="formation-sign__countdown" v-if="sent">Vous pourrez demander à recevoir un nouveau code de sécurité dans {{ countdown }}</div>
			</div>
		</div>
	</div>

</template>

<script>
import formationParticipantRepository from "@/repositories/formationParticipantRepository";

export default {
	name: "formation-sign",
	props:['formationParticipant'],
	data(){
		return{
			validated: false,
			loading: false,
			error: false,
			sent: false,
			signed: false,
			step: 1,
			timer: false,
			formOptions: {
				validateAfterLoad: false,
				validateAfterChanged: true
			},
			model:{},
			form:{
				fields:[
					{
						type: "input",
						inputType: "text",
						inputName: "code",
						model: "code",
						required: true,
						styleClasses:["fields-label","input-background-darker","field--half","field--center"],
						label: "Code de sécurité reçu"
					}
				]
			},
			tel: '',
			countdown: false
		}
	},
	methods:{
		check(){

			this.loading = 'check';

			formationParticipantRepository.checkCode(this.formationParticipant.id, this.model.code).then(response=>{

						this.loading = false;
						this.sent = false;
						clearInterval(this.timer);

						this.$emit('sent');
					},
					response=>{
						this.loading = false;
					})
		},
		send(){
			this.loading = 'send';

			formationParticipantRepository.getCode(this.formationParticipant.id).then(response=>{
						this.loading = false;
						this.startCountDown();
						this.step = 2;
					},
					response=>{
						this.loading = false;
					})
		},
		startCountDown(){

			let self = this;
			let date = new Date();
			let countDownDate= date.setMinutes( date.getMinutes() + 10 );

			this.sent = true;

			this.timer = setInterval(function() {
				let now = new Date();
				let distance = countDownDate - now;
				let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((distance % (1000 * 60)) / 1000);
				self.countdown = minutes + ' min et ' + seconds + ' sec';

				if (distance < 0) {
					clearInterval(self.timer);
					self.sent = false
				}
			}, 500);
		}
	},
	beforeDestroy(){
		clearInterval(this.timer);
	}
}
</script>