<template>
	<div class="o-personal-report" v-if="report">
		<h2 class="title">Rapport</h2>
		<report-progress :data="report"></report-progress>
	</div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";

//TODO: dynamic and condition
export default {
	name: "personal-report",
	data(){
		return{
			report:false
		}
	},
	methods:{
		getReport() {
			formationCourseRepository.getReport().then(response=>{
				this.loading = false;
				this.report = response.items[0];

			}, response=>{
				this.loading = false;
				this.error = response.body.status_text;
			})
		}
	},
	mounted() {
		this.getReport();
	}
}
</script>

<style scoped>

</style>