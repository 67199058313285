<template>
  <div class="o-gallery container">
    <div class="event-container-header">
      <h3 class="title-big" v-if="data.title">{{ data.title }}</h3>
      <div
        v-if="data.location && data.date"
        class="event-container-header-details"
      >
        <p v-if="data.location" data-icon="pin">{{ data.location }}</p>
        <p v-if="data.date" data-icon="calendar">{{ data.date }}</p>
      </div>
    </div>
    <p v-if="data.subtitle" class="o-gallery__subtitle">{{ data.subtitle }}</p>
    <a
      :href="data.file"
      v-if="data.file"
      target="_blank"
      class="o-gallery__file"
      >{{ data.file_label }}</a
    >

    <div class="o-gallery__gallery">
      <figure
        v-for="(image, index) in data.gallery"
        :key="index"
        class="o-gallery__image"
      >
        <img :src="image.url + '?h=300'" :alt="image.alt" />
        <figcaption v-if="image.caption" class="o-gallery__description">
          {{ image.caption }}
        </figcaption>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "gallery",
  props: ["data"],
};
</script>

<style lang="scss">
@import "../../environment";
.o-gallery {
  text-align: left;
  margin: $space-m auto $space * 2 auto;
  @media #{$to-tablet} {
    margin-right: $space-m;
    margin-left: $space-m;
  }

  &__gallery {
    margin-top: $space;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 2rem;
    -webkit-overflow-scrolling: touch;
  }
  .event-container-header {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    color: var(--c-primary);
    @media #{$to-tablet} {
      width: 100%;
    }
    .title-big {
      width: fit-content;
    }
    .event-container-header-details {
      background: white;
      border-radius: 5px;
      margin-top: 0;
      display: flex;
      align-items: center;
      padding: 1rem;
      white-space: nowrap;
      gap: 3rem;
      p {
        width: fit-content;
        &::before {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__subtitle {
    color: $c-primary;
    width: 40%;
    margin-bottom: 2rem;
    @media #{$to-tablet} {
      width: 100%;
    }
    & + * {
      margin-top: $space-s;
    }
  }
  &__file {
    display: block;
    text-decoration: underline;
    color: $c-primary;
  }

  &__image {
    padding: 0 $space-m;
    display: inline-block;
    vertical-align: top;
    img {
      width: auto;
      height: 100%;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  &__description {
    text-align: center;
    padding-top: $space-s;
    font-family: $font-main;
    font-style: italic;
  }
}

.page--international {
  .o-gallery {
    @media #{$to-tablet} {
      width: 95%;
    }
    .event-container-header {
      width: fit-content;
    }
    .o-gallery__gallery {
      background: #e2e4eb;
      padding-top: 2rem;
      padding-left: 4rem;
      img {
        border-radius: 5px;
      }
    }
  }
}
</style>
