<template>
	<div class="a-burger" v-click-outside="close">
		<a class="a-burger__link" v-on:click="toggleVisibility"></a>
		<span class="a-burger__icon"></span>
	</div>
</template>

<script>
	export default {
		name: "burger",
		methods:{
			toggleVisibility: function(){
				document.body.classList.toggle('burger-is-open')
			},
			close(){
				document.body.classList.remove('burger-is-open')
			}
		}
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';

	.a-burger {
		@media #{$from-small}{
			display: none
		}
		$c: &;
		$height: 3px;
		$width: 25px;
		$color: white;
		$offset: 250%;

		height: 100%; width: $width; position: absolute; top: 0; right: $space-m; z-index: 5; margin-top: 0;

		&__link { z-index: 10; position: overlay() }

		&__icon {
			position: absolute; width: 100%; height: $height; left: 0;
			top: calc(50% - #{math.div($height,2)}); background-color: $color;
      .app-vhs &{ background-color: $c-primary }

			&, &:before, &:after { transition: allow(transform opacity background) }

			&:after, &:before {
				position: absolute; content: ''; width: 100%; height: 100%;
				left: 0; background-color: $color;
        .app-vhs &{ background-color: $c-primary }
      }

			&:after { transform: translateY($offset); width: 50% }
			&:before { transform: translateY(-$offset) }

			.burger-is-open &{
				transform: rotate(135deg);

				&:after, &:before { transform: none!important }
				&:before { transform: rotate(90deg)!important }
				&:after { opacity: 0!important }
			}
		}
	}

</style>