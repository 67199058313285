<template>
  <div class="m-foad-video">
    <vimeo-player :videoId="data.video_url.replace('https://vimeo.com/','')" :startAt="participant.progress.media" @video-ended="ended" @video-updated="updated"/>
    <div class="wysiwyg" v-html="data.wysiwyg" v-if="data.wysiwyg"></div>
  </div>
</template>

<script>
export default {
  name: "foad-video",
  props:['data','participant'],
  methods:{
    ended(){
      this.$emit('updated', 1)
    },
    updated(value){
      this.$emit('updated', value)
    }
  },
  mounted() {
    this.updated(this.participant.progress.media)
  }
}
</script>

<style lang="scss">
@import '../../environment';
@import '../../services/plyr.css';
.m-foad-video{
  position: relative;
  &__overlay{
    position: overlay(); background: black; color: white; display: flex; align-items: center; justify-content: center; padding-top: 51.7%;
  }
  .player-wrapper+.wysiwyg{ margin-top: 7rem }
  iframe{ pointer-events: none }
}
</style>