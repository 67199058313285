<template>
	<div class="a-attestation">
		<span v-if="data.file" class="primary">délivrée le {{ data.file.date }}</span>
		<span v-else>Aucune attestation</span>

		<div class="a-attestation__action">
			<a :href="data.file.url" class="button button--small" target="_blank" v-if="data.file">Télécharger l'attestation</a>
			<span v-else class="link">
				<span @click="handleFile(data.id)">
					<span class="loading" v-if="loading">Loading</span>
					<span v-else>Téléverser une attestation</span>
				</span>
				<input type="file" :name="'file-'+data.id" :id="'file-'+data.id" style="display: none">
			</span>
		</div>
	</div>

</template>

<script>
	export default {
		name: "attestation",
		props:['data'],
		data(){
			return{
				loading: false
			}
		},
		methods:{
			handleFile(id){
				document.getElementById('file-'+id).click()
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-attestation{
		font-size: $font-xs; width: 100%; text-align: right;
		&__action{ width: 15rem; text-align: center; display: inline-block }
		& > * + *{ margin-left: $space-s }
		.button{ text-transform: none }
	}
</style>