<template>
	<div class="o-faq block">
		<h2 class="title">{{interface.title}}</h2>
		<div class="equal-padding primary">
			<p>Grâce à votre adhésion SNPI, vous pouvez disposer des services du médiateur Medimmoconso.<br/>
				Pour plus d'informations contactez : <a href="mailto:contact@medimmoconso.fr" class="link">contact@medimmoconso.fr</a></p>
			<div class="bottom-right" v-if="config">
				<a href="https://cms.snpi.pro/uploads/2021/04/flash_info_votre_nouveau_mediateur.pdf" class="button button--white" target="_blank">Créer mon espace Medimmoconso</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "meditateur",
		props:['interface','data'],
		computed:{
			config(){ return this.$config() }
		}
	}
</script>

<style lang="scss">
	.o-mediateur{
		p + p{ margin-top: 0.5rem }
	}
</style>