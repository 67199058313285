<template>
	<header class="o-header" :class="{'o-header--membership':renew,'o-header--banner': banner}">
		<banner :data="banner" v-if="banner" @closeBanner="closeBanner"></banner>
		<div class="grid">
			<div class="content">
				<div class="o-header__logo title" v-if="type !== 'vhs'">
					<router-link to="/"><img src="/img/logo-snpi-outline.svg" alt="SNPI" /></router-link>
					<span>
						<router-link to="/">Espace adhérent</router-link>
					</span>
				</div>
        <div v-else class="o-header__logo">
          <router-link to="/">
            <img src="/img/logo_snpi_campus.png" alt="SNPI Campus" />
            <a href="https://www.snpicampus.fr/" v-if="type ==='vhs'">Retour au site</a>
          </router-link>
        </div>
				<search data-col="7/12" class="o-header__search" v-if="$route.name !== 'order'"></search>
			</div>

			<div class="aside desktop" v-if="$route.name !== 'order'">
				<links data-col="3/10"></links>
			</div>

			<burger></burger>
		</div>
		<div class="o-header__membership" v-if="renew">
			{{ user.type === 'commercial_agent' ? config.option.renew_caci : config.option.renew_company }}
			<a class="button button--xsmall button--border-white" @click="showMembershipPopin">Payer</a>
		</div>
	</header>
</template>

<script>

	export default {
		name: 'header-component',
		data(){
			return{
				banner_closed: false
			}
		},
		computed:{
			membership(){ return this.$store.getters.membership() },
			user(){ return this.$user() },
			config(){ return this.$config() },
			renew(){ return this.membership && (this.membership.snpi.total_amount || this.membership.vhs.total_amount || this.membership.asseris.total_amount || this.membership.caci.total_amount) },
			type(){ return this.$store.getters.type() },
			banner(){ return this.config && this.config.maintenance.status && this.config.maintenance.enabled && !this.banner_closed ? this.config.maintenance.status : false  }
		},
		methods:{
			showMembershipPopin(){
				this.$popin('renew', this.membership);
			},
			closeBanner(){
				this.banner_closed = true
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-header{
		background: $c-primary; color: white; position: sticky; left: 0; width: 100%; top: 0; z-index: 9999;
    .app-vhs &{ background: #fff; color: $c-text; box-shadow: $box-shadow }

		@media #{$to-phone}{
			position:fixed;
		}
		.container{ display: flex }
		.content{
			display: flex; align-items: center;
			@media #{$only-tablet}{ width: 80% }
		}
		&__old{ text-transform: none; font-size: $font-xs; font-family: 'Open Sans', Arial, sans-serif; text-decoration: underline; display: block; letter-spacing: normal }
		&__membership{
			position: absolute; top: 100%; left: 0; width: 100%; background: $c-secondary; padding: 5px 0;
			.button{ margin-left: 1rem }
			.page--order &{ display: none }
		}

		&__logo{
			flex: 1; text-align: left; height: $header-h; display: flex; align-items: center;
			&.title{ color: white; font-size: $font-l }
			img{
				display: inline-block; margin-right: $half-space; width: 6.5rem;
				@media #{$to-phone}{ width: 5.5rem }
        .app-vhs &{ height: 4rem; width: auto }
				.ie &{ height: 6.5rem }
			}
			.app-vhs & a{ text-decoration: underline; font-size: $font-xs; color: $c-main }
		}

		.grid{ display: flex; justify-content: space-between; position:relative; padding: $space-s $space-m;  }

		.aside{ display: flex; align-items: center; justify-content: flex-end; margin-top: 0 }

		&__search{
			@media #{$to-tablet}{ flex-basis: auto; flex-grow: 1 }
			@media #{$to-phone}{ display: none }
		}
		&--membership~.o-navigation{ margin-top: 31px }
	}
</style>
