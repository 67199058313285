<template>
  <div class="o-international-presentation container">
    <div class="o-presentation-logo-container">
      <img src="img/logo-snpi-international.svg" />
    </div>

    <div class="o-presentation-container wysiwyg">
      <div class="o-presentation-logo">
        <img :src="this.content[0].data.image.url" />
      </div>
      <div class="o-presentation-txt">
        <h1 class="title-big">{{ this.content[0].data.title }}</h1>
        <p>
          {{ this.content[0].data.introduction }}
        </p>
        <div v-for="(bullet, index) in this.content[0].data.bullet_points">
          <div class="o-presentation-ul-container">
            <img :src="bullet.logo.url" />
            <div class="presentation-ul">
              <h2 class="title-big">{{ bullet.title }}</h2>
              <div class="o-bullet-points">{{ bullet.texte }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="o-presentation-container wysiwyg">
      <div class="o-presentation-logo">
        <img :src="this.content[1].data.image.url" />
      </div>
      <div class="o-presentation-txt">
        <h1 class="title-big">{{ this.content[1].data.title }}</h1>
        <p>
          {{ this.content[1].data.introduction }}
        </p>
        <div v-for="(bullet, index) in this.content[1].data.bullet_points">
          <div class="o-presentation-ul-container">
            <img :src="bullet.logo.url" />
            <div class="presentation-ul">
              <h2 class="title-big">{{ bullet.title }}</h2>
              <div class="o-bullet-points">{{ bullet.texte }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageRepository from "@/repositories/pageRepository";

export default {
  name: "international-presentation",
  props: ["data", "interface"],
  data() {
    return {
      content: false,
      loading: true,
    };
  },
  computed: {
    config() {
      return this.$config();
    },
  },
  mounted() {
    let page = this.config.option[this.interface.page];

    if (page) {
      pageRepository.get(page.value).then((response) => {
        this.content = response.layout;
        console.log(this.content);
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import "../../environment";

.v-page:has(.o-international-presentation) {
  background-image: url("/img/bg-international.svg") !important;
  background-repeat: no-repeat !important;
  background-position-y: 29rem !important;
}
.o-international-presentation {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: auto;
  flex-wrap: wrap;
  margin-bottom: -6rem;
  text-align: left;
  .o-presentation-logo-container {
    width: 25%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("/img/bg-logo-international.svg");
    background-repeat: no-repeat;
    background-position-y: 6rem;
    background-position-x: center;
    background-size: 35rem;
    @media #{$to-tablet} {
      width: 100%;
    }
    img {
      @media #{$to-tablet} {
        width: 50%;
      }
    }
  }
  .o-presentation-container {
    width: 35%;
    background: white;
    border-radius: 10px;
    margin: $space-m 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @media #{$to-tablet} {
      width: 90%;
      margin: $space-m auto;
    }
    .o-presentation-logo {
      height: 13rem;
      overflow: hidden;
    }
    .o-presentation-txt {
      padding: 0 $space $space $space;
      .title-big + p {
        margin-top: 0;
        margin-bottom: $space-s;
      }
      .title-big + ul {
        margin-top: 0;
      }
      .o-presentation-ul-container {
        display: flex;
        margin-top: 0;
        gap: $space-s;
        img {
          height: 9rem;
          padding-top: $space-m;
        }
        .presentation-ul {
          margin-left: $space-m;
          .o-bullet-points {
            white-space: pre-line;
          }
        }
      }
    }
  }
  .wysiwyg {
    h2 {
      margin: 0;
      margin-bottom: $space-s;
      line-height: 1;
    }
    li {
      margin-top: 0;
    }
  }
}
</style>
