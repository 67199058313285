<template>
	<div class="c-text">
		{{data}}
	</div>
</template>

<script>
	export default {
		name: "plain-text",
		props:['data']
	}
</script>

<style scoped>

</style>