var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-text-image container",class:{ 'o-text-image--background': _vm.data.background }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"o-text-image__content",class:'o-text-image__content--' +
        _vm.data.position +
        ' o-text-image__content--' +
        _vm.data.image_size},[(_vm.data.image)?_c('div',{staticClass:"o-text-image__col o-text-image__col--image"},[_c('img',{staticClass:"o-text-image__image",attrs:{"src":_vm.data.image +
            '?w=' +
            (_vm.data.image_size == 25
              ? '312'
              : _vm.data.image_size == 50
              ? 648
              : 972)}})]):_vm._e(),_c('div',{staticClass:"o-text-image__col o-text-image__col--text"},[(_vm.data.uptitle)?_c('div',{staticClass:"o-text-image__uptitle title title--small"},[_vm._v(" "+_vm._s(_vm.data.uptitle)+" ")]):_vm._e(),_c('h1',{staticClass:"title-big"},[_vm._v(_vm._s(_vm.data.title))]),_c('runtime-text',{staticClass:"o-text-image__wysiwyg",attrs:{"data":_vm.data.text}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }