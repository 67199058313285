<template>
	<div class="m-document m-document--appendix" :class="{ 'm-document--unread': unread}">
		<div class="m-document__icon" data-icon="file"></div>
		<div class="m-document__infos document-adaptative">
			<div class="m-document__type" v-if="type && !$route.params.section">{{ type.title }}</div>
			<h2 class="m-document__title" @click="downloadAsset()" v-if="data.assets.length === 1">{{ data.title }}</h2>
			<h2 class="m-document__title" v-else>{{ data.title }}</h2>
		</div>
		<div class="m-document__category document-sw" v-if="mod === 'preview'">
			<span v-if="category">{{category.title}}</span>
		</div>
		<div class="m-document__nature document-sw" v-if="mod === 'preview'">
			<span></span>
		</div>
		<time class="m-document__date" :class="{'document-sw':mod === 'preview', 'document-mw':mod !== 'preview'}">
			<span v-if="mod !== 'preview'">publié le </span>{{ formatDigitDate(data.createdAt) }}
		</time>
		<div class="m-document__actions document-actions">
			<a class="m-document__share m-document__share--inactive" data-icon="share"></a>
			<a class="favorite" :data-icon="favorite?'favorite-full':'favorite'" v-if="user.contact" @click.prevent="updateFavorite"></a>
			<a class="m-document__see m-document__see--inactive" data-icon="see"></a>
			<div class="m-document__download" data-icon="download" :class="{'m-document__download--inactive': !data.assets || !data.assets.length, 'm-document__download--downloading': downloading}">
				<loader v-if="downloading"></loader>
				<a @click="downloadAsset()" v-if="data.assets.length === 1"></a>
			</div>
		</div>
	</div>
</template>

<script>
	import appendixRepository from '@/repositories/appendixRepository';
	import userRepository from '@/repositories/userRepository';

	export default {
		name: "appendix",
		props:{
			data:{
				default: false
			},
			mod:{
				default: false
			}
		},
		data(){
			return {
				downloading: false
			}
		},
		computed:{
			type(){
				return this.data.categories.find(category => category.depth === 0 )
			},
			category(){
				return this.data.categories.find(category => category.depth === 1 )
			},
			user(){
				return this.$user();
			},
			unread(){
				return this.user && this.user.lastLogin < this.data.createdAt && !userRepository.getMetadata(this.data.entity, 'read', this.data.id);
			},
			favorite(){
				return userRepository.getMetadata(this.data.entity, 'favorite', this.data.id);
			}
		},
		methods:{
			downloadAsset(){
				this.downloading = true;
				appendixRepository.download(this.data.id).then(
					response=>{
						this.downloading = false;
					},
					response=>{
						this.downloading = false;
					});
			},
			preview(){
				if(this.mod === 'preview')
					this.$emit('preview')
				else
					this.$popin('preview', {type:'appendix', item:this.data});
			},
			updateFavorite(){
				userRepository.updateMetadata(this.data.entity, 'favorite', this.data.id);
			}

		}
	}
</script>