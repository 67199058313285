import Vue from "vue";
import cache from "@/services/cache";

export default {

	find(params) {
		params.target = 'extranet'
		return cache.list('news', params);
	},
	get(id){
		return new Promise((resolve, reject) => {
			Vue.http.get('news/'+id).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},
	ratingNews(id, params){

		return new Promise((resolve, reject) => {

			Vue.http.post('rate/news/' + id, params).then(response => {
				resolve(response.body.response);

			}, reject);
		});
	}
};
