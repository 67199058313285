<template>
	<div class="o-video container">
		<h3 class="title-big" v-if="data.title">{{data.title}}</h3>

		<div class="o-video__container" @click="playVideo()">
			<transition name="fade">
				<div class="o-video__poster" v-if="overlay">
					<img :src="data.poster+'?w=1080&h=608'" alt="video" v-if="data.poster" />
					<div class="play"></div>
				</div>
			</transition>

			<transition name="fade">
				<div class="o-video__iframe" v-if="data.video&&!overlay" >
          <iframe v-if="data.video.indexOf('vimeo') !== -1" :src="'https://player.vimeo.com/video/'+vimeoId(data.video)+'?byline=0&color=042b72&title=0&portrait=0&loop=0&autoplay=1&controls=1'" allow="autoplay; fullscreen"></iframe>
          <iframe v-else :src="'https://www.youtube.com/embed/'+youtubeId(data.video)+'?color=042b72&modestbranding=1&showinfo=0&loop=0&rel=0&autoplay=1&controls=1'" allow="autoplay; fullscreen"></iframe>
        </div>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		name: "video-component",
		props: ['data'],
		data: function(){
			return{
				overlay: true
			}
		},
		methods: {
			playVideo:function(){
				this.overlay = false;
			}
		}
	}
</script>

<style lang="scss">

@use "sass:math";
@import '../../environment';
	.o-video{

		margin-top:$space-xl; margin-bottom: $space-xl;
		&__container{ @include aspect-ratio(math.div(16,9)); width: 75%; margin: 0 auto }

		iframe{ position: overlay(); width: 100%; height: 100%; background: #000 }

		&__poster{
			width: 100%; height: 100%; border-radius: $border-radius; overflow: hidden; display: flex; position:absolute;
			align-items: center; justify-content: center; cursor: pointer;
			&:after{ content: ''; position:overlay(); background: rgba(0,0,0,0.4); z-index: 1 }
			img{ position:overlay(); object-fit: cover; height: 100%; width: 100% }
			.play{ position:relative; z-index: 2; border-color: transparent transparent transparent white; width: $space-m*2; height: $space-m*2;
				border-width: $space-m 0 $space-m $space-m*2; border-style: solid;
			}
		}
	}
</style>