<template>
  <div
    class="o-text-image container"
    :class="{ 'o-text-image--background': data.background }"
  >
    <div class="container">
      <div
        class="o-text-image__content"
        :class="
          'o-text-image__content--' +
          data.position +
          ' o-text-image__content--' +
          data.image_size
        "
      >
        <div
          class="o-text-image__col o-text-image__col--image"
          v-if="data.image"
        >
          <img
            :src="
              data.image +
              '?w=' +
              (data.image_size == 25
                ? '312'
                : data.image_size == 50
                ? 648
                : 972)
            "
            class="o-text-image__image"
          />
        </div>
        <div class="o-text-image__col o-text-image__col--text">
          <div
            class="o-text-image__uptitle title title--small"
            v-if="data.uptitle"
          >
            {{ data.uptitle }}
          </div>
          <h1 class="title-big">{{ data.title }}</h1>
          <runtime-text
            class="o-text-image__wysiwyg"
            :data="data.text"
          ></runtime-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "text-image",
  props: ["data"],
};
</script>

<style lang="scss">
@import "../../environment";
.o-text-image {
  $c: &;
  background: white;
  padding: $space-ll 0;
  border-radius: 3px;
  margin-top: $space-l;
  margin-bottom: $space-l;
  &--background {
    background-color: $c-background;
    margin-top: $space-ll;
    margin-bottom: $space-ll;
    padding: 0;
  }

  &__content {
    color: $c-primary;
    @media #{$from-small} {
      display: flex;
      align-items: center;
      &--right-image {
        flex-direction: row-reverse;
        .o-text-image__col--text {
          padding-right: 0;
        }
      }
      &--left-image {
        .o-text-image__col--text {
          padding-left: 0;
        }
      }
    }
    @media #{$from-small} {
      &--25 #{$c}__col--image {
        width: 30%;
      }
      &--25 #{$c}__col--text {
        width: 70%;
      }
      &--75 #{$c}__col--image {
        width: 70%;
      }
      &--75 #{$c}__col--text {
        width: 30%;
      }
    }
  }

  &__image {
    width: 100%;
    max-height: 40rem;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__uptitle {
    text-transform: uppercase;
    color: $c-primary;

    & + * {
      margin-top: $space-s;
    }
  }

  &__col {
    @media #{$from-small} {
      width: 50%;
      padding: 0 $space-ll;
    }

    &--text {
      text-align: left;
      @media #{$to-tablet} {
        margin-top: $space-m;
        margin-right: $space-m;
        margin-left: $space-m;
      }
    }
  }
}
</style>
