<template>
  <div class="m-form-header">
    <h1>
      <span>Question {{ currentQuestionPosition }} </span>/
      {{ questions.length }}
    </h1>
    <div class="m-progress-bar">
      <div
        v-for="(question, index) in questions"
        :key="index"
        class="m-progress-point"
        :class="{
          'point-done': index < currentQuestionPosition,
          'point-not-done': index > currentQuestionPosition,
          'point-active': index === currentQuestion + 1,
        }"
      >
        <div
          class="line-segment"
          :class="{
            'line-segment__done': index < currentQuestionPosition + 1,
          }"
          v-if="index > 0"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["questions", "currentQuestionPosition", "currentQuestion"],
  name: "questionnaireSubheader",
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
  },
};
</script>

<style lang="scss">
@import "../../environment";

.m-form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-m;
  .m-progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 230px;
    .m-progress-point {
      position: relative;
      width: 20px;
      height: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #eee;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        z-index: 2;
      }
      .line-segment {
        background-color: #eee;
        height: 2px;
        position: absolute;
        top: 9px;
        left: -16px;
        right: 0;
        width: 22px;
        z-index: 1;
        &__done {
          background: $c-primary;
        }
      }
    }
    .point-active {
      &::before {
        background-color: #eee;
      }
      .line-segment__done {
        background: linear-gradient(to right, $c-primary 60%, #eee 40%);
      }
    }

    .point-done::before {
      background-color: $c-primary;
    }
  }
}
</style>
