<template>
	<div class="m-forgotten-password">

		<h2 class="title-big">Mot de passe oublié</h2>
    <p>Pour recevoir un nouveau mot de passe,<br> veuillez renseigner votre email</p>

		<div class="bottom-center">
			<form @submit.prevent="forgotten" class="v-login__form" id="forgotten-password">
				<vue-form-generator tag="div" :schema="formLogin" :model="model" :options="formOptions"></vue-form-generator>
				<submit text="Envoyer" :loading="loading" text-success="Envoyé"></submit>
			</form>
		</div>

  </div>
</template>

<script>

	import userRepository from '@/repositories/userRepository';

	export default {
		name: "forgotten-password",
		data() {
			return {
				type: 'snpi',
				loading: false,
				error: false,
				formOptions: {
					validateAfterLoad: true,
					validateAfterChanged: true,
					validateAsync: true
				},
				model:{
					login: ''
				},
				formLogin: {}
			}
		},
		methods:{
			forgotten(){

				if( this.loading )
					return;

				this.loading = true;

				userRepository.requestPassword(this.model.login).then(response => {

					this.loading = false;

					this.$emit('close');
					this.$popin('change-password', { type:'user', email: response.body.response.email, login: this.model.login });

				}, response => {

					this.loading = false;
				});
			}
		},
		mounted(){

			this.type = this.$store.getters.type();

      this.formLogin= {
        fields: [
          {
            type: "input",
            inputType: "text",
            inputName: "login",
            model: "login",
            label: "Adresse email",
            placeholder: "jean@gmail.com",
            styleClasses: "fields-label",
            hint: "Si vous n'avez pas finalisé votre inscription, veuillez renseigner votre email."
          }
        ]
      }
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-forgotten-password{

		text-align: left; padding: $space;

		.button{ margin-top: $space-m }

		p + p{ margin-top: $space-m }

		label .additional{ text-transform: none; font-weight: normal }
		.hint{ font-size: $font-xs; margin-top: $space-xs }
	}
</style>