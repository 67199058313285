<template>
	<div class="o-contact-info block">
		<div class="title">{{interface.title}}</div>
		<div class="equal-padding-l">
			<h2 class="title-big">Syndicat National des Professionnels Immobiliers</h2>

			<p class="subtitle">Adresse</p>
			<p>26 av Victor Hugo - 75116 PARIS</p>

			<p class="subtitle">Téléphone</p>
			<p>01 53 64 91 91</p>

			<p class="subtitle">Email</p>
			<p><a href="mailto:contact@snpi.fr" target="_blank">contact@snpi.fr</a></p>

			<p class="subtitle">Horaires</p>
			<ul class="o-contact-info__hours">
				<li>Lundi, mardi et jeudi de 9h00 à 12h30 et de 13h45 à 17h30</li>
				<li>Mercredi de 9h00 à 12h30</li>
				<li>Vendredi de 9h00 à 12h30 et de 13h45 à 16h15</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "contact-info",
		props:['data','interface']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-contact-info{
		color: $c-text;

		p + .subtitle{ margin-top: $space-m }

		&__hours{
			font-size: $font-xs;
			& > *:before{ content: '•'; font-size: $font-s; margin-right: 0.5rem }
		}
	}

</style>