<template>
	
	<div class="m-report">
		
		<div class="m-report__name">
			{{ data.contact.firstname }} {{ data.contact.lastname }}
		</div>
		<div class="m-report__time">
			{{ data.completed }}h
			<span class="total" v-if="data.senority">/ {{ data.quota }}h</span>
		</div>
		<div class="m-report__progress">
			<div :style="{'width': getProgress() +'%'}"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'report',
		props:['data'],
		methods:{
			getProgress(){
				return this.data.quota < this.data.completed ? 100 : (this.data.completed/this.data.quota)*100
			}
		}
	}
</script>

<style lang="scss">

@use "sass:math";
@import '../../environment';

	.m-report{

		display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: $half-space;

		&__name{
			font-size: $font-xs;
			.user--commercial_agent &, .user--collaborator &{ visibility: hidden }
		}

		&__time{
			font-weight: bold; font-size: $font-xs;
			.total{ font-weight: normal }
		}
		&__progress{
			width: 100%; position: relative; background: $c-background-darker; height: 8px; border-radius: $border-radius;
			& > *{ position: absolute; left: 0; bottom: 0; top: 0; background: $c-primary; border-radius: $border-radius }
		}
	}
</style>
