	<template>
		<div class="custom-siren field-input">
			<span class="relative">
				<input :type="type" :placeholder="schema.placeholder" class="input-inline" v-model="siren" minlength="9" maxlength="9" @input="onlyNumbers" @paste="paste" ref="input"/>
				<span>Le Siren doit comporter 9 chiffres</span>
			</span>

			<button @click.prevent="searchSiren" class="button" :class="{'button--loading': loading, 'button--error': error}">
				<loader v-if="loading"></loader>
				<span v-if="error">{{ error }}</span>
				<span v-else>Rechercher</span>
			</button>
			<div v-if="list" class="custom-siren__list">
				<label>Agence</label>
				<div class="field-select">
					<div class="field-wrap">
						<select name="company" v-model="company" class="select" :disabled="!list" @change.prevent="updateInput">
							<option disabled :selected="list.length > 0" value="">Sélectionner une enseigne</option>
							<option v-for="(item, index) in list" :key="index" :value="item" :selected="index === 0">{{item.name}} - {{item.city}}</option>
						</select>
					</div>
				</div>
			</div>
			<form v-else-if="add" ref="company" @submit.prevent="addCompany" id="add-company">
				<vue-form-generator tag="div" :schema="form" :model="newCompany" :options="newCompanyOptions" ref="form"></vue-form-generator>
				<div class="custom-siren__submit">
					<submit text="AJouter" :loading="adding"></submit>
				</div>
			</form>
		</div>
	</template>

	<script>
		import { abstractField } from "vue-form-generator";
		import companyRepository from "@/repositories/companyRepository";

		export default {
			name: "field-siren-custom",
			mixins: [ abstractField ],
			data (){
				return{
					type: "text",
					siren:'',
					name:'',
					list: false,
					company: false,
					error: false,
					loading: false,
					add: false,
					adding: false,
					newCompany:{
						siren:'',
						position:[],
						kind:['Gestion'],
						cci:12437
					},
					newCompanyOptions: {
						validateAfterLoad: false,
						validateAfterChanged: true
					},
					form:{
						groups:[
							{
								legend: "Créer une agence",
								styleClasses: "form-group-title"
							},
							{
								styleClasses: ["form-group-background","form-group-columns"],
								fields:[
									{
										type: "input",
										inputType: "text",
										model: "name",
										inputName: "name",
										styleClasses: "fields-label",
										label: "Nom",
										required: true,
									},
									{
										type: "input",
										inputType: "text",
										model: "street",
										maxlength: 60,
										inputName: "street",
										styleClasses: "fields-label",
										label: "Adresse",
										required: true,
									},
									{
										type: "input",
										inputType: "text",
										model: "zip",
										inputName: "zip",
										styleClasses: ["fields-label","field--half"],
										label: "Code postal",
										required: true,
									},
									{
										type: "input",
										inputType: "text",
										model: "city",
										inputName: "city",
										maxlength: 50,
										styleClasses: ["fields-label","field--half"],
										label: "Ville",
										required: true,
									},
                  {
                    type: "input",
                    inputType: "hidden",
                    model: "siren",
                    inputName: "siren",
                    styleClasses: ["field--hidden"],
                  }
								]
							},
							{
								legend: "Représentant légal",
								styleClasses: "form-group-title"
							},
							{
								styleClasses: ["form-group-background","form-group-columns"],
								fields:[
									{
										type: "radios",
										inputName: "civility",
										model: "civility",
										styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--inline"],
										label: "Civilité",
										values: [
											"Monsieur",
											"Madame"
										]
									},
									{
										type: "input",
										inputType: "text",
										model: "lastname",
										inputName: "lastname",
										maxlength: 60,
										styleClasses: ["fields-label","field--half"],
										label: "Nom",
										required: true,
									},
									{
										type: "input",
										inputType: "text",
										model: "firstname",
										inputName: "firstname",
										styleClasses: ["fields-label","field--half"],
										label: "Prénom",
										maxlength: 60,
										required: true,
									},
									{
										type: "input",
										inputType: "email",
										maxlength: 60,
										model: "email",
										inputName: "addresses[0][email]",
										styleClasses: ["fields-label","field--half"],
										label: "Email professionnel",
										required: true,
									},
									{
										type: "multiselect-custom",
										model: "position",
										multiple: true,
										inputName: "addresses[0][positions]",
										returnType: 'string',
										styleClasses: ["fields-label","field--half"],
										label: "Fonction",
										required: true,
										placeholder: "Choisir une fonction",
										values(){
											return ['Gérant','Président','PDG','DG']
										}
									}
								]
							},
							{
								legend: "Carte professionnelle",
								styleClasses: "form-group-title"
							},
							{
								styleClasses: ["form-group-background","form-group-columns"],
								fields:[
									{
										type: "input",
										inputType: "text",
										model: "number",
										inputName: "number",
										styleClasses: ["fields-label","field--half"],
										label: "Numéro de la carte",
										required: true
									},
									{
										type: "calendar-custom",
										model: "issuedAt",
										inputName: "issuedAt",
										styleClasses: ["fields-label","field--half"],
										label: "Date de délivrance",
										required: true
									},
									{
										type: "select",
										model: "cci",
										inputName: "cci",
										styleClasses: ["fields-label","field--half"],
										label: "Délivrée par",
										required: true,
										values: [
											{id: 12394, name: "CCI DE L'AIN"},
											{id: 20846, name: "PREFECTURE DE L'AIN"},
											{id: 12395, name: "CCI DE L'AISNE"},
											{id: 21587, name: "PRÉFECTURE DE L'ALLIER"},
											{id: 22429, name: "CCI DE ALLIER"},
											{id: 12400, name: "CCI NICE-CÔTE D'AZUR"},
											{id: 21540, name: "PRÉFECTURE DE NICE"},
											{id: 12404, name: "CCI DE TROYES ET DE L'AUBE"},
											{id: 20814, name: "PREFECTURE DE L'AUDE"},
											{id: 12406, name: "CCI DE NARBONNE, LEZIGNAN-CORBIÈRES ET PORT-LA-NOUVELLE"},
											{id: 12405, name: "CCI DE CARCASSONNE - LIMOUX - CASTELNAUDARY"},
											{id: 21384, name: "CCI DE AUDE"},
											{id: 12407, name: "CCI DE L'AVEYRON"},
											{id: 12409, name: "CCI MARSEILLE-PROVENCE"},
											{id: 12410, name: "CCI.R PROVENCE - ALPES- CÔTE D'AZUR"},
											{id: 20909, name: "PRÉFECTURE BOUCHE-DU-RHÔNE"},
											{id: 12408, name: "CCI DU PAYS D'ARLES"},
											{id: 12413, name: "CCI DU PAYS D'AUGE"},
											{id: 12411, name: "CCI.R BASSE-NORMANDIE"},
											{id: 12412, name: "CCI DE CAEN"},
											{id: 12414, name: "CCI DU CANTAL"},
											{id: 12415, name: "PREFECTURE D'ANGOULÊME"},
											{id: 22432, name: "CCI DE CHARENTE"},
											{id: 12416, name: "CCI DE COGNAC"},
											{id: 21045, name: "PRÉFECTURE DE LA CHARENTE-MARITIME"},
											{id: 12417, name: "CCI DE LA ROCHELLE"},
											{id: 12418, name: "CCI DE ROCHEFORT-SUR-MER ET DE SAINTONGE"},
											{id: 12419, name: "CCI DE CHER"},
											{id: 12420, name: "CCI DE CORRÈZE"},
											{id: 12421, name: "CCI D'AJACCIO ET DE LA CORSE DU SUD"},
											{id: 12422, name: "CCI DE BASTIA ET DE LA HAUTE CORSE"},
											{id: 12423, name: "CCI.R CORSE"},
											{id: 21310, name: "PRÉFECTURE DE LA CÔTE-D'OR"},
											{id: 23025, name: "AGEFOS PME BFC"},
											{id: 12424, name: "CCI.R BOURGOGNE"},
											{id: 12425, name: "CCI DE CÔTE D'OR"},
											{id: 12426, name: "CCI DES CÔTES D'ARMOR"},
											{id: 12427, name: "CCI DE LA CREUSE"},
											{id: 12428, name: "CCI DE LA DORDOGNE"},
											{id: 12429, name: "CCI DU DOUBS"},
											{id: 12430, name: "CCI.R FRANCHE-COMTÉ"},
											{id: 12431, name: "CCI DE LA DRÔME"},
											{id: 21376, name: "PRÉFECTURE DE L'EURE"},
											{id: 21609, name: "CCI DE PORTE DE NORMANDIE"},
											{id: 12432, name: "CCI DE L'EURE"},
											{id: 12433, name: "CCI DE EURE-ET-LOIR"},
											{id: 22649, name: "PRÉFECTURE D'EURE-ET-LOIR"},
											{id: 21433, name: "PRÉFECTURE DU FINISTERE"},
											{id: 12434, name: "CCI DE BREST"},
											{id: 12435, name: "CCI DE QUIMPER CORNOUAILLE"},
											{id: 12436, name: "CCI DE MORLAIX"},
											{id: 12438, name: "CCI NÎMES-BAGNOLS UZÈS-LE VIGAN"},
											{id: 21225, name: "CCI DE GARD"},
											{id: 12437, name: "CCI ALÈS CÉVENNES"},
											{id: 12396, name: "CCI DE MOULINS-VICHY"},
											{id: 21006, name: "PREFECTURE DE TOULOUSE"},
											{id: 12439, name: "CCI DE TOULOUSE"},
											{id: 12397, name: "CCI DE MONTLUÇON - GANNAT PORTES D'AUVERGNE"},
											{id: 12440, name: "CCI.R MIDI-PYRÉNÉES"},
											{id: 22290, name: "PRÉFECTURE DU GERS"},
											{id: 12441, name: "CCI DE GERS"},
											{id: 21010, name: "PRÉFECTURE DE GIRONDE"},
											{id: 12442, name: "CCI.R AQUITAINE"},
											{id: 12443, name: "CCI DE BORDEAUX-GIRONDE"},
											{id: 12444, name: "CCI DE LIBOURNE"},
											{id: 12448, name: "CCI.R LANGUEDOC-ROUSSILLON"},
											{id: 20828, name: "PRÉFECTURE DE L'HÉRAULT"},
											{id: 12446, name: "CCI DE MONTPELLIER"},
											{id: 20933, name: "CCI DE HÉRAULT"},
											{id: 12447, name: "CCI DE SETE - FRONTIGNAN - MEZE"},
											{id: 12445, name: "CCI DE BÉZIERS - SAINT-PONS"},
											{id: 21847, name: "CCI DE ILLE-ET-VILAINE"},
											{id: 12450, name: "CCI DE RENNES"},
											{id: 12449, name: "CCI.R BRETAGNE OUEST"},
											{id: 12451, name: "CCI DE SAINT-MALO-FOUGÈRES"},
											{id: 12452, name: "CCI DE L'INDRE"},
											{id: 20703, name: "PRÉFECTURE DE TOURS"},
											{id: 21317, name: "PRÉFECTURE D'INDRE-ET-LOIRE"},
											{id: 12453, name: "CCI DE TOURAINE"},
											{id: 20883, name: "PRÉFECTURE DE L'ISERE"},
											{id: 12454, name: "CCI DE GRENOBLE"},
											{id: 12455, name: "CCI DE NORD-ISÈRE"},
											{id: 21099, name: "PRÉFECTURE DE LONS"},
											{id: 12456, name: "CCI DE JURA"},
											{id: 12398, name: "CCI DE ALPES DE HAUTE-PROVENCE"},
											{id: 12457, name: "CCI DE LANDES"},
											{id: 21242, name: "SOUS-PRÉFECTURE DE DAX"},
											{id: 12458, name: "CCI DE LOIR-ET-CHER"},
											{id: 12460, name: "CCI DE SAINT-ETIENNE MONTBRISON"},
											{id: 21369, name: "PRÉFECTURE DE SAINT-ETIENNE"},
											{id: 12459, name: "CCI DE ROANNE LOIRE NORD"},
											{id: 23567, name: "CCI DE ROANNAIS"},
											{id: 12461, name: "CCI DE LA HAUTE LOIRE"},
											{id: 21120, name: "PRÉFECTURE DE LOIRE ATLANTIQUE"},
											{id: 12462, name: "CCI DE NANTES-SAINT NAZAIRE"},
											{id: 12463, name: "CCI.R PAYS-DE-LA-LOIRE"},
											{id: 12464, name: "CCI.R CENTRE"},
											{id: 21340, name: "PRÉFECTURE DU LOIRET"},
											{id: 12465, name: "CCI DU LOIRET"},
											{id: 12466, name: "CCI DU LOT"},
											{id: 12467, name: "CCI DU LOT-ET-GARONNE"},
											{id: 20936, name: "PRÉFECTURE DE LOT ET GARONNE"},
											{id: 12468, name: "CCI DE LA LOZÈRE"},
											{id: 12469, name: "CCI DE MAINE-ET-LOIRE"},
											{id: 21372, name: "PRÉFECTURE DU MAINE-ET-LOIRE"},
											{id: 12399, name: "CCI DES HAUTES-ALPES"},
											{id: 21986, name: "CCI DE OUEST NORMANDIE"},
											{id: 12471, name: "CCI DE CHERBOURG-COTENTIN"},
											{id: 12470, name: "CCI CENTRE ET SUD MANCHE"},
											{id: 12473, name: "CCI DE CHALONS-EN-CHAMPAGNE, VITRY-LE-FRANÇOIS, STE MENEHOULD"},
											{id: 12472, name: "CCI.R CHAMPAGNE-ARDENNE"},
											{id: 21982, name: "CCI DE MARNE"},
											{id: 12474, name: "CCI DE REIMS ET D'EPERNAY"},
											{id: 12475, name: "CCI DE LA HAUTE-MARNE"},
											{id: 12476, name: "CCI DE LA MAYENNE"},
											{id: 21444, name: "PRÉFECTURE DU MORBIHAN"},
											{id: 22375, name: "PRÉFECTURE MEURTHE-ET-MOSELLE"},
											{id: 12477, name: "CCI.R LORRAINE"},
											{id: 12478, name: "CCI DE MEURTHE-ET-MOSELLE"},
											{id: 12479, name: "CCI DE LA MEUSE"},
											{id: 12480, name: "CCI DU MORBIHAN"},
											{id: 20707, name: "PREFECTURE DE MOSELLE"},
											{id: 12481, name: "CCI DE LA MOSELLE"},
											{id: 12482, name: "CCI DE LA NIÈVRE"},
											{id: 12484, name: "CCI GRAND LILLE"},
											{id: 12485, name: "CCI.R NORD DE FRANCE"},
											{id: 12483, name: "CCI GRAND HAINAUT"},
											{id: 12486, name: "CCI DE L'OISE"},
											{id: 12487, name: "CCI D'ALENÇON"},
											{id: 21822, name: "PRÉFECTURE DE L'ORNE"},
											{id: 12488, name: "CCI DE FLERS - ARGENTAN"},
											{id: 20865, name: "PRÉFECTURE DU PAS DE CALAIS"},
											{id: 12490, name: "CCI DE L'ARTOIS"},
											{id: 12489, name: "CCI DE LA CÔTE D'OPALE"},
											{id: 21985, name: "CCI DE LITTORAL HAUTS-DE-FRANCE"},
											{id: 20813, name: "PREFECTURE DU PUY DE DOME"},
											{id: 12493, name: "CCI DE PUY DE DÔME"},
											{id: 12492, name: "CCI.R AUVERGNE"},
											{id: 12495, name: "CCI DE PAU BEARN"},
											{id: 21013, name: "PRÉFECTURE DES PYRÉNÉES ATLANTIQUES"},
											{id: 12494, name: "CCI DE BAYONNE - PAYS BASQUE"},
											{id: 12496, name: "CCI DE TARBES ET DES HAUTES-PYRÉNÉES"},
											{id: 21563, name: "PRÉFECTURE DES PYRÉNÉES-ORIENTALES"},
											{id: 12497, name: "CCI DE PERPIGNAN ET DES PYRÉNÉES-ORIENTALES"},
											{id: 20898, name: "PREFECTURE DU BAS-RHIN"},
											{id: 12499, name: "CCI DE STRASBOURG ET DU BAS-RHIN"},
											{id: 12498, name: "CCI.R ALSACE"},
											{id: 21203, name: "PRÉFECTURE DU HAUT-RHIN"},
											{id: 12500, name: "CCI DE COLMAR ET DU CENTRE-ALSACE"},
											{id: 12501, name: "CCI DE SUD ALSACE MULHOUSE"},
											{id: 22539, name: "CCI DE ALSACE EUROMÉTROPOLE"},
											{id: 20710, name: "PRÉFECTURE DU RHONE"},
											{id: 12504, name: "CCI.R RHÔNE-ALPES"},
											{id: 12503, name: "CCI DE LYON METROPOLE SAINT-ETIENNE ROANNE"},
											{id: 23467, name: "CCI DE BEAUJOLAIS"},
											{id: 20709, name: "VIVRE EN BEAUJOLAIS"},
											{id: 12502, name: "CCI DE VILLEFRANCHE ET DU BEAUJOLAIS"},
											{id: 12505, name: "CCI DE HAUTE-SAÔNE"},
											{id: 12506, name: "CCI DE SAÔNE-ET-LOIRE"},
											{id: 20850, name: "CCI DE LA SARTHE"},
											{id: 12507, name: "CCI DU MANS ET DE LA SARTHE"},
											{id: 12401, name: "CCI DE L'ARDÈCHE"},
											{id: 34321, name: "GCR ORGANISATION"},
											{id: 21008, name: "PRÉFECTURE DE LA SAVOIE"},
											{id: 12508, name: "CCI DE LA SAVOIE"},
											{id: 21020, name: "PRÉFECTURE DE HAUTE-SAVOIE"},
											{id: 12509, name: "CCI DE LA HAUTE-SAVOIE"},
											{id: 21272, name: "ACTALIANS"},
											{id: 21448, name: "AGEFOS PME SIEGE NATIONAL"},
											{id: 18275, name: "DMP"},
											{id: 21258, name: "CCI SEINE MER NORMANDIE"},
											{id: 12518, name: "CCI DE ROUEN METROPOLE"},
											{id: 21252, name: "PRÉFECTURE DE SEINE MARITIME"},
											{id: 12514, name: "CCI.R HAUTE-NORMANDIE"},
											{id: 12519, name: "CCI DU HAVRE"},
											{id: 12516, name: "CCI DE DIEPPE"},
											{id: 12517, name: "CCI DE FECAMP-BOLBEC"},
											{id: 12515, name: "CCI D'ELBEUF"},
											{id: 20863, name: "CCI DE SEINE ESTUAIRE"},
											{id: 21153, name: "PRÉFECTURE DE SEINE ET MARNE"},
											{id: 12520, name: "CCI DE SEINE-ET-MARNE"},
											{id: 20752, name: "PREFECTURE DE VERSAILLES"},
											{id: 12522, name: "CCI VERSAILLES - YVELINES 78"},
											{id: 20974, name: "PRÉFECTURE DES DEUX SEVRES"},
											{id: 12523, name: "CCI DE DEUX-SÈVRES"},
											{id: 12524, name: "CCI.R PICARDIE"},
											{id: 12525, name: "CCI DE AMIENS-PICARDIE"},
											{id: 12526, name: "CCI LITTORAL NORMAND-PICARD"},
											{id: 21264, name: "PRÉFECTURE D'ALBI"},
											{id: 12402, name: "CCI DES ARDENNES"},
											{id: 12527, name: "CCI DE TARN"},
											{id: 12528, name: "CCI DE MONTAUBAN ET DE TARN-ET-GARONNE"},
											{id: 20852, name: "PRÉFECTURE DU VAR"},
											{id: 12529, name: "CCI DE VAR"},
											{id: 20754, name: "PREFECTURE DU VAUCLUSE"},
											{id: 12530, name: "CCI DE VAUCLUSE"},
											{id: 12531, name: "CCI DE LA VENDÉE"},
											{id: 12533, name: "CCI DE LA VIENNE"},
											{id: 12532, name: "CCI.R POITOU-CHARENTES"},
											{id: 21303, name: "PRÉFECTURE DE LA HAUTE-VIENNE"},
											{id: 12534, name: "CCI DE LIMOGES ET DE LA HAUTE-VIENNE"},
											{id: 12535, name: "CCI.R LIMOUSIN"},
											{id: 12536, name: "CCI DES VOSGES"},
											{id: 12537, name: "CCI DE YONNE"},
											{id: 12538, name: "CCI DU TERRITOIRE DE BELFORT"},
											{id: 12403, name: "CCI DE L'ARIÈGE"},
											{id: 12539, name: "CCI DE L'ESSONNE"},
											{id: 21202, name: "AGEFOS PME IDF"},
											{id: 20941, name: "PRÉFECTURE DE NANTERRE"},
											{id: 21345, name: "PRÉFECTURE DES HAUTS-DE-SEINE"},
											{id: 21525, name: "SOUS PRÉFECTURE D'ANTONY"},
											{id: 12541, name: "CCI HAUTS DE SEINE 92"},
											{id: 12543, name: "CCI SEINE SAINT DENIS 93"},
											{id: 20940, name: "PREFECTURE DE CRETEIL"},
											{id: 21409, name: "PRÉFECTURE DU VAL-DE-MARNE"},
											{id: 12545, name: "CCI VAL DE MARNE 94"},
											{id: 12547, name: "CCI VAL D'OISE 95"},
											{id: 21152, name: "PRÉFECTURE DU VAL D'OISE"},
											{id: 12511, name: "CCI.R PARIS ILE-DE-FRANCE"},
											{id: 21648, name: "CENTRE DE FORMALITES PROFESSIONS IMMOBILIERES"},
											{id: 20706, name: "PRÉFECTURE DE BORDEAUX"},
											{id: 20722, name: "PREFECTURE DE POLICE DE PARIS"},
											{id: 20749, name: "PREFECTURE DES ALPES MARITIMES"},
											{id: 20761, name: "PREFECTURE DE NANTES"},
											{id: 20763, name: "PREFECTURE DE HAUTE GARONNE"},
											{id: 20917, name: "PRÉFECTURE DE LA RÉUNION"},
											{id: 20978, name: "PRÉFECTURE DU GARD"},
											{id: 20991, name: "PREFECTURE DE POLICE DE CAEN"},
											{id: 21018, name: "PREFECTURE DU LOT"},
											{id: 21019, name: "PREFECTURE DU LOT"},
											{id: 21053, name: "PREFECTURE DE MEAUX"},
											{id: 21064, name: "PREFECTURE DU CALVADOS"},
											{id: 21083, name: "PREFECTURE DE FORT DE FRANCE"},
											{id: 21100, name: "PREFECTURE MAINE ET LOIRE"},
											{id: 21119, name: "PREFECTURE DE BOURGES"},
											{id: 21159, name: "PREFECTURE DE LA ROCHELLE"},
											{id: 21161, name: "PRÉFECTURE DE BORDEAUX"},
											{id: 21166, name: "PREFECTURE DE PARIS"},
											{id: 21174, name: "PRÉFÉCTURE DE LA ROCHE SUR YON"},
											{id: 21177, name: "PRÉFECTURE DE VENDÉE"},
											{id: 21193, name: "PREFECTURE NIMES"},
											{id: 21208, name: "PREFECTURE DE LYON"},
											{id: 21228, name: "PRÉFECTURE DES YVELINES"},
											{id: 21231, name: "PREFECTURE DU TERRITOIRE DE BELFORT"},
											{id: 21241, name: "PREFECTURE DE MELUN"},
											{id: 21251, name: "PREFECTURE DES HAUTS DE SEINE"},
											{id: 21256, name: "PREFECTURE DE MARTINIQUE"},
											{id: 21271, name: "PREFECTURE DE CAEN"},
											{id: 21275, name: "PREFECTURE DES BOUCHES DU RHONE"},
											{id: 21276, name: "PRÉFECTURE DE LA SOMME"},
											{id: 21277, name: "PRÉFECTURE DE PÉRIGUEUX"},
											{id: 21278, name: "PRÉFECTURE DE TROYES"},
											{id: 21279, name: "PRÉFECTURE D'ARRAS"},
											{id: 21282, name: "PREFECTURE DE ROANE"},
											{id: 21287, name: "PRÉFECTURE DE LA MARNE"},
											{id: 21288, name: "PRÉFECTURE DES HAUTES ALPES"},
											{id: 21292, name: "PRÉFECTURE DU DOUBS"},
											{id: 21322, name: "PREFECTURE DE SAINT DENIS"},
											{id: 21332, name: "PREFECTURE DES PYRENEES ORIENTALES"},
											{id: 21338, name: "PREFECTURE DE LA LOIRE"},
											{id: 21364, name: "PREFECTURE DE MAINE ET LOIRE"},
											{id: 21365, name: "PRÉFECTURE DE CERGY PONTOISE"},
											{id: 21371, name: "PRÉFECTURE DE L'AISNE"},
											{id: 21375, name: "PRÉFECTURE DU CHER"},
											{id: 21377, name: "PRÉFECTURE DU TARN"},
											{id: 21378, name: "PRÉFECTURE DE L'ARIEGE"},
											{id: 21383, name: "PREFECTURE DE LA REUNION"},
											{id: 21396, name: "PRÉFECTURE DE LA NIÈVRE"},
											{id: 21399, name: "PREFECTURE DE STRASBOURG"},
											{id: 21414, name: "PREFECTURE DES ARDENNES"},
											{id: 21417, name: "PRÉFECTURE DE DORDOGNE"},
											{id: 21435, name: "PRÉFECTURE L'ILLE ET VILAINE"},
											{id: 21439, name: "PREFECTURE DE BESANCON"},
											{id: 21458, name: "PRÉFECTURE DE NIORT"},
											{id: 21464, name: "PRÉFECTURE DE LA SARTHE"},
											{id: 21465, name: "PRÉFECTURE DES HAUTES-PYRENNEES"},
											{id: 21476, name: "PRÉFECTURE DE VANNES"},
											{id: 21483, name: "PREFECTURE DE L'ARDECHE"},
											{id: 21520, name: "PREFECTURE DE FOIX"},
											{id: 21521, name: "PREFECTURE DE L'ESSONNE"},
											{id: 21530, name: "PREFECTURE ALPES MARITIMES"},
											{id: 21543, name: "PRÉFECTURE DE LOT"},
											{id: 21560, name: "SOUS PREFECTURE DE ROANNE"},
											{id: 21578, name: "PREFECTURE DE SEINE SAINT DENIS"},
											{id: 21588, name: "PRÉFECTURE DU NORD"},
											{id: 21652, name: "PREFECTURE SEINE MARITIME"},
											{id: 21660, name: "PREFECTURE DU HAUT RHIN"},
											{id: 21679, name: "PREFECTURE DE LA DROME"},
											{id: 21697, name: "PREFECTURE DE LA REUNION"},
											{id: 21746, name: "PREFECTURE DE L'AUBE"},
											{id: 21751, name: "PREFECTURE DE L'OISE"},
											{id: 21796, name: "PREFECTURE DE L'AVEYRON"},
											{id: 21824, name: "PREFECTURE PARIS ILE DE FRANCE"},
											{id: 21826, name: "PREFECTURE DE NIMES"},
											{id: 21828, name: "PREFECTURE LAON"},
											{id: 21851, name: "PREFECTURE DE STRASBOURG"},
											{id: 21877, name: "PREFECTURE DE LILLE"},
											{id: 21908, name: "PREFECTURE SEINE ST DENIS"},
											{id: 21920, name: "PREFECTURE DE L'OISE"},
											{id: 21937, name: "PRÉFECTURE DU LOIR-ET-CHER"},
											{id: 21938, name: "PREFECTURE DE VIENNE"},
											{id: 21946, name: "PREFECTURE DE CORSE"},
											{id: 21959, name: "PREFECTURE DE LA DROME"},
											{id: 21974, name: "PRÉFECTURE DES LANDES"},
											{id: 21979, name: "PRÉFECTURES DES CÔTES D'ARMOR"},
											{id: 22060, name: "PREFECTURE DE MONTPELLIER"},
											{id: 22139, name: "PRÉFECTURE DU JURA"},
											{id: 22144, name: "SOUS PRÉFECTURE DE SAINT-MALO"},
											{id: 22145, name: "PRÉFECTURE D'ILLE ET VILAINE"},
											{id: 22153, name: "PREFECTURE DE LA MANCHE"},
											{id: 22159, name: "PREFECTURE DES ALPES DE HAUTE-PROVENCE"},
											{id: 22404, name: "PRÉFECTURE DE LA HAUTE-SAONE"},
											{id: 22412, name: "PRÉFECTURE DE SAÔNE-ET-LOIRE"},
											{id: 22434, name: "PREFECTURE DE BOBIGNY"},
											{id: 22570, name: "PREFECTURE DE LA HAUTE GARONNE"},
											{id: 22694, name: "ST BRIEUC"},
											{id: 22708, name: "PREFECTURE DE MARSEILLE"},
											{id: 22807, name: "PREFECTURE DE VALENCE"},
											{id: 20970, name: "CCI ILES DE GUADELOUPE"},
											{id: 12549, name: "CCI DE SAINT BARTHÉLÉMY"},
											{id: 12548, name: "CCI DE SAINT MARTIN"},
											{id: 12550, name: "CCI.R DES ILES DE GUADELOUPE"},
											{id: 12551, name: "CCI.R DE LA MARTINIQUE"},
											{id: 12552, name: "CCI.R DE LA GUYANE"},
											{id: 12553, name: "CCI.R ILE DE LA RÉUNION"},
											{id: 12554, name: "CCI DE SAINT-PIERRE ET MIQUELON"},
											{id: 12555, name: "CCI.R DE MAYOTTE"}
											//todo: append to list from crm, as an evol do a webservice
										]
									},
									{
										type: "multiselect-custom",
										model: "kind",
										multiple: true,
										inputName: "kind[]",
										returnType: "array",
										styleClasses: ["fields-label","field--half"],
										label: "Type",
										required: true,
										values() {
											return ['Gestion', 'Syndic', 'Transaction']
										}
									}
								]
							}
						]

					}
				}
			},
			watch:{
				list(){
					this.company = this.list[0]
					this.updateInput()
				}
			},
			methods:{
				onlyNumbers() {
					this.siren = this.siren.replace(/[^0-9]/g,'');
				},
				addCompany() {

					let formData = new FormData( this.$refs.company );
					this.adding = true;

					companyRepository.create(formData).then(response => {
							this.adding = false;
							this.add = false;

							this.searchSiren();
						},
						response => {
							this.adding = false;
						});
				},
				paste(e) {

					let pastedText = '';

					if (e.clipboardData && e.clipboardData.getData)
						pastedText = e.clipboardData.getData('text/plain');
					else if (window.clipboardData && window.clipboardData.getData)
						pastedText = window.clipboardData.getData('Text');

					this.siren = pastedText.replace(/\D/g, '');
				},
				searchSiren(){

					if( this.siren.length !== 9 )
						return;

					let self = this;

					this.loading = true

					this.$catchError(false);

					companyRepository.getSiren(this.siren).then(response => {

							this.company = ''
							this.list = response.items;
							this.loading = false
						},
						response => {

							this.error = response.body.status_text;
							this.add = true;
							this.company = false;
							this.list = false;
							this.loading = false;
							this.newCompany.siren = this.siren;

							this.updateInput();

							setTimeout(()=>{ self.error = false }, 2000);
						})
				},
				updateInput(){
					this.value = this.company
				}
			}
		}
	</script>

	<style lang="scss">
		@import '../../../environment';
		.custom-siren{
			&__submit{ text-align: right; margin-top: 2rem }
			.input-inline{ display: inline-block; width: 50%;}
			.button{ margin-left: 1rem; border-radius: 4px; }
			input:invalid+span{ display: block }
			input+span{ position: absolute; top: 1px; right: 2rem; color: $c-red; display: none }
			&__list{ margin-top: $space-m }
			.select{ margin-top: 0 }

			.form-group-title{
				legend { text-transform: none; font-size: $font-m }
			}
			.vue-form-generator{
				margin-top: $space;
			}
			.form-group-background + *{ margin-top: $space-l }
			.vue-form-generator .form-group-title + *{ margin-top: $space-s }

			.field-multiselect-custom{
				.field-wrap .field-wrap{ width: 100% }
				.multiselect__tags{ border-radius: 4px; border-color: $c-background-darker }
			}
		}
	</style>
