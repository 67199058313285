<template>
  <div class="m-document" :class="{ 'm-document--unread': unread}">
		<div class="m-document__icon" data-icon="file"></div>
		<div class="m-document__infos document-adaptative">
			<h2 class="m-document__title" @click="edit">
				<span>{{ data.title }}</span>
			</h2>
		</div>
		<div class="m-document__category document-sw" v-if="mod === 'preview'">
			<span v-for="(category, index) in categories" v-if="categories">{{ index?' - ':'' }} {{ category.title }}</span>
		</div>
		<div class="m-document__kind document-sw" v-if="mod === 'preview'">
			<span v-if="kind">{{ kind.title }}</span>
		</div>
		<time class="m-document__date" :class="{'document-sw':mod === 'preview', 'document-mw':mod !== 'preview'}">
			<span v-if="mod !== 'preview'">publié le </span>{{ formatDigitDate(data.createdAt) }}
		</time>
		<div class="m-document__actions document-actions">
      <a class="m-document__edit" data-icon="pencil" @click="edit" title="éditer"></a>
      <a class="m-document__share" data-icon="share" @click="share" title="partager"></a>
			<a class="favorite" :data-icon="favorite?'favorite-full':'favorite'" @click.prevent="updateFavorite" v-if="user.contact" title="aouter aux favoris"></a>
			<a class="m-document__see" data-icon="see" @click="preview" :class="{'m-document__see--inactive': !data.thumbnail}" title="prévisualiser"></a>
		</div>
	</div>
</template>

<script>

	import userRepository from '@/repositories/userRepository';
  import editableDocumentRepository from "@/repositories/editableDocumentRepository";

	export default {
		name: "editabledocument",
		props:{
			data:{
				default: false,
			},
			mod:{
				default: false
			}
		},
		data(){
      return {
				downloading: false
			}
		},
		computed:{
			type(){
				return this.data.categories.find(category => category.depth === 0 )
			},
			categories(){
				return this.data.categories.filter(category => category.depth === 1 )
			},
			kind(){
				return this.data.categories.find(category => category.depth === 2 )
			},
			user(){
				return this.$user();
			},
			unread(){
				return this.user && this.user.lastLogin < this.data.createdAt && !userRepository.getMetadata(this.data.entity, 'read', this.data.id);
			},
			favorite(){
				return userRepository.getMetadata(this.data.entity, 'favorite', this.data.id);
			},
      clap_token(){
        if (this.user.clap_token.length>0)
          return true;
        else {
          this.$popin('clap-popin')
        }
      }
		},
		methods:{
			downloadAsset(index){
				this.downloading = true;
				editableDocumentRepository.download(this.data.id, index).then(
					response=>{
						this.downloading = false;
					},
					response=>{
						this.downloading = false;
					});
			},
			preview(){
				if(this.mod === 'preview' && window.innerWidth > 1024)
					this.$emit('preview')
				else
					this.$popin('preview', {type:'editabledocument', item:this.data});
			},
      play(){
				this.$store.commit('podcast', this.data);
			},
			updateFavorite(){
				userRepository.updateMetadata(this.data.entity, 'favorite', this.data.id);
			},
			share(){
				this.$popin('share', {type:'editabledocument', item: this.data});
      },
      edit(){
        if(this.clap_token) {
          editableDocumentRepository.getToken().then(response => {
            if (response.token) {
              window.open(process.env.VUE_APP_CLAP_FRONT_DOMAIN + '/nouveau-document?id=' + this.data.del_id + '&token_otp=' + response.token, '_blank');
            }
          })
        }

      }
		},
		created() {
			if (this.data.pictures[0]) {
				this.data.thumbnail = process.env.VUE_APP_CLAP_DOMAIN + "/" + this.data.pictures[0].url;
			}

			this.$watch('data', (newValue, oldValue) => {
				if (newValue.pictures[0]) {
					newValue.thumbnail = process.env.VUE_APP_CLAP_DOMAIN + "/" + newValue.pictures[0].url;
				}
			}, { deep: true });
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-document{
		$c: &;

		display: flex; align-items: center; padding: $half-space $half-space;

		.accessible-version &{
			& + #{$c}{ border-top: solid 1px $c-border }
		}

		@media #{$to-tablet}{ flex-wrap: wrap; padding: $half-space $space-s; align-items: start }

		&--unread{
			#{$c}__title{ font-weight: bold }
		}

		&__icon{ font-size: 2rem; margin-right: 0.5rem; color: $c-text; margin-top: -2px }

		&__infos{
			line-height: 1;

			@media #{$to-tablet}{
				flex: 0 0 90%
			}
		}

		&__type{ font-size: $font-xs }

		&__title{ font-size:$font-s; color: $c-text; cursor: pointer; position: relative }

		&__date{
			font-size: $font-xs;
			@media #{$from-small}{
				&.document-mw{ text-align: right }
			}
			& + *{ margin-left: 5% }

			@media #{$to-tablet}{ display: none }
		}
		&__actions{
			text-align: right;
			& > *{ display: inline-block; color: $c-text; cursor: pointer }
			& > * + *{ margin-left: 1rem }
			[data-icon]:before{ font-size: 1.8rem }
			[data-icon="headphones"]:before{ font-size: 1.6rem }

			@media #{$to-tablet}{ margin-left: auto; margin-top: $space-s }
		}

		&__download{
			&--downloading:before{ opacity: 0 }
			position: relative;
			> a{ position: overlay(); z-index: 1 }
			.a-loader{ transform: scale(0.65) }
		}
		.a-dropdown{ padding: 0 }

		&__dropdown{
			li{
				text-align: left; transition: allow(background);
				&:hover{ background: $c-background-lighter }
				a{ padding: $space-s $space-m; display: block }
				& + li{ border-top: solid 1px $c-border }
			}
		}

		&__category, &__kind{
			font-size: $font-xs;

			@media #{$to-tablet}{
				width: auto;
			}
		}

		&__see--inactive, &__download--inactive, &__share--inactive{ pointer-events: none; color: $c-background-darker }

		&__see, &__share{
			@media #{$to-tablet}{ display: none }
		}

		.document-sw{
			@media #{$to-tablet}{
				margin-left: 2.5rem;
				&+.document-sw{ margin-left: $space-s }
			}
		}
	}
</style>
