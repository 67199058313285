<template>
	<div class="o-footer">
		<nav class="o-footer__nav" v-if="config && $route.name !== 'login' && $route.name !== 'order' && config.menu.footer">
			<ul class="container">
				<li v-for="(item,index) in config.menu.footer" :key="index">
					<a :href="item.link" target="_blank" rel="noopener" v-if="item.type === 'custom'">{{ item.title }}</a>
					<a @click="preview(item.type, item.link)" v-else-if="item.type === 'document'">{{ item.title }}</a>
					<router-link :to="'/edito/'+getPage(item.link)" v-else-if="item.type === 'edito'">{{ item.title }}</router-link>
				</li>
				<li>
					<router-link to="/request">Contact</router-link>
				</li>
			</ul>
		</nav>
		<div class="o-footer__infos" v-if="type !== 'vhs'">
			<div class="o-footer__logo">
				<img src="/img/logo-snpi.svg" alt="SNPI" />
				<div class="title">
					<span>Syndicat national</span>
					<span>des professionnels immobiliers</span>
				</div>
			</div>
			<div class="o-footer__socials" v-if="config">
        <div class="o-footer__apps">
          <a class="app-btn" href="https://apps.apple.com/fr/app/snpi-news/id1603269091" target="_blank">
            <i data-icon="apple"></i>
            <p>Télécharger dans  <br/> <span class="big-txt">l'App Store</span></p>
          </a>

          <a class="app-btn" href="https://play.google.com/store/apps/details?id=com.snpi.news&hl=fr&gl=US" target="_blank">
            <i data-icon="google-play"></i>
            <p>DISPONIBLE SUR <br/> <span class="big-txt">Google Play</span></p>
          </a>
        </div>
        <div class="o-footer__icons" v-if="config">
          <a :href="config.option.facebook" data-icon="facebook" title="Facebook" target="_blank" rel="noopener" v-if="config.option.facebook"></a>
          <a :href="config.option.twitter" data-icon="twitter" title="Twitter" target="_blank" rel="noopener" v-if="config.option.twitter"></a>
          <a :href="config.option.linkedin" data-icon="linkedin" title="LinkedIn" target="_blank" rel="noopener" v-if="config.option.linkedin"></a>
          <a :href="config.option.youtube" data-icon="youtube" title="Youtube" target="_blank" rel="noopener" v-if="config.option.youtube"></a>
          <a :href="config.option.instagram" data-icon="instagram" title="Instagram" target="_blank" rel="noopener" v-if="config.option.instagram"></a>
        </div>
      </div>
		</div>
	</div>
</template>

<script>

  export default {
		name: "footer-component",
		computed:{
			config(){ return this.$config() },
			type(){ return this.$store.getters.type() }
		},
    methods:{
      preview(type, id){
        this.$popin('preview', {type:type, id:id});
      }
		}
	}
</script>

<style lang="scss">
@import '../../environment';
.o-footer{
  margin-top: auto;
  &__nav{
    background: white; font-family: $font-main; color: $c-primary; text-transform: uppercase;
    font-size: $font-m; padding: $space;
    ul{
      @media #{$from-small}{
        display: flex; align-items: center; justify-content: space-evenly;
      }
      @media #{$to-tablet}{
        & > * + *{ margin-top: $space-s }
      }
    }
  }
  .title{
    color: white; font-size: $font-l; text-align: left;
    span{ display: block }
  }
  &__infos{
    padding: $space $space-m; background: $c-primary; color: white;
    @media #{$from-small}{display: flex;  align-items: center; }
  }
  &__socials{
    display: flex; align-items: center; margin-left: auto; font-size: 3rem; justify-content: flex-end; flex-basis: 67%;
    a{ display: flex }
    @media #{$to-tablet}{
      margin-top: $space-m; justify-content: center; flex-flow: column;
    }
  }
  &__apps{
    display: flex; grid-gap: 1rem; margin-right: 4rem;
    @media #{$to-tablet}{ flex-flow: column; align-items: center; margin-right: 0; width: 100% }
    .app-btn{
      width: 170px; color: $c-primary; background: #fff;
      @media #{$to-tablet}{ width: 100% }
    }
  }
  &__icons{
    display: flex; grid-gap: 2rem;
    @media #{$to-tablet}{ margin-top: 2rem; justify-content: space-evenly; width: 100% }
  }
  &__logo{
    display: flex; align-items: center; flex-basis: 33.3%;
    img{ width: 6.5rem; margin-right: $half-space }
  }
}
</style>