<template>
	<transition name="slide-down">
		<div class="a-banner" @click="closeBanner">
			<span v-if="data">{{data}}</span>
			<span v-else>Site en maintenance. Veuillez nous excuser pour la gêne occasionnée</span>
			<div data-icon="close" class="a-banner__close"></div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "banner",
		props:['data'],
		methods:{
			closeBanner(){
				this.$emit('closeBanner')
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-banner{
		$c: &;

		background: $c-secondary; color: white; padding-right: 5rem; font-size: $font-s; position: relative;
		display: flex; align-items: center; justify-content: center; height: $banner;

		&__close{ position: absolute; right: $space-s }

	}

</style>