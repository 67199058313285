import Vue from "vue";
import cache from "@/services/cache";

export default {

	create(data) {

		return Vue.http.post('formation/course/external', data)
	},

	delete(id) {
		return new Promise((resolve, reject) => {

			Vue.http.post('formation/course/external/' + id + '/delete').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	download(id) {

		let popup = false;

		if( document.documentElement.classList.contains('safari') )
			popup = window.open('/wait', '_blank');

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/course/external/'+ id + '/download').then(response => {

				if( popup )
					popup.location.href = response.body.response.url;
				else
					window.open(response.body.response.url)

				resolve();

			}, response=>{

				reject(response);
			});
		});
	}
};
