import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

let time = Date.now();

const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Za-z-]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {

    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName.split('/').pop().replace(/\.\w+$/, '')
        )
    )

    // Register component globally
    Vue.component(
        componentName, componentConfig.default || componentConfig
    )
})

console.info('Loading '+requireComponent.keys().length+' components in '+(Date.now()-time)+'ms');