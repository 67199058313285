<template>
	<main class="v-news">
    <template v-if="data">
      <template v-if="data.linkType==='article' && data.layout.length">
        <component :is="component.type" :data="component.data" v-for="(component, index) in data.layout" :key="index"/>
      </template>
      <div class="v-news__content container" v-else>
        <div class="v-news__thumbnail" v-if="data.thumbnail">
          <img :src="data.thumbnail+'?w=1440&h=489'"/>
        </div>
        <div class="v-news__info">
          <div class="v-news__featured" v-if="data.featured">A la une</div>
          <div class="v-news__date">{{ getElapsedTime(data.createdAt) }}</div>
        </div>
        <h2 class="v-news__title title" v-if="data.title">{{ data.title }}</h2>
        <div class="v-news__description" v-if="data.description" v-html="data.description"></div>
        <a class="v-news__link button" :href="data.link" target="_blank" rel="noopener" v-if="data.linkType === 'external'">Visiter le lien</a>
        <a @click="preview(data.linkType, data.link)" class="v-news__link button" v-else-if="data.linkType === 'document'">Voir les documents</a>
        <router-link :to="'/edito/'+data.link" class="v-news__link button" v-else-if="data.linkType === 'page'">Voir la page</router-link>
      </div>
	    <rating :data="data" ></rating>
    </template>
    <loader v-if="loading"></loader>
    <not-found v-if="error"></not-found>
  </main>
</template>

<script>
import newsRepository from '@/repositories/newsRepository';
import Vue from 'vue';

export default {
	data() {
		return {
			config: false,
			loading: true,
			error: false,
      data: false
		}
	},
	computed:{
		root(){
			return Vue.http.options.root
		}
	},
  methods:{
    preview(type, id){
      this.$popin('preview', {type:type, id:id});
    },
    get() {
      newsRepository.get(this.$route.params.slug).then(response => {
            if(response.type)
              this.data = response;
            else
              this.error = true;

            this.loading = false;
          },
          response=>{
            this.error = true;
            this.loading = false;
          })
    }
  },
	mounted(){
		this.get()
    this.$update(this.get)
	}
}
</script>

<style lang="scss">
@import '../environment';

.v-news{
	min-height: 40rem; position: relative; padding: 0 $space-m;
  &__content{
    background: #fff; margin: $space-l auto; padding: $space-l; text-align: left; border-radius: $border-radius;
    @media #{$to-tablet}{ margin: $space-m auto; padding: $space-m }
  }
  &__info{ display: flex; justify-content: space-between }
  &__title{ margin: $space-m 0 }
  &__link{ margin-top: $space-l }
  &__featured{ color: $c-red }
  &__thumbnail{
    img{ border-radius: $border-radius }
  }
  .o-wysiwyg{ margin-top: $space-l; margin-bottom: $space-l }
  &__thumbnail+&__info{ margin-top: $space-m }
}
</style>
