<template>
	<div class="o-trainings-agency">
		<div v-if="$parent.$options.name !== 'popin'" class="o-trainings-agency__cta">
			<button class="button" @click="openPopin">Voir les formations en agence</button>
		</div>
		<div>
			<div class="block">
				<div class="title">Formations en agence</div>
				<div class="o-trainings-agency__content">
					<p class="primary">SNPI Campus vous propose également sur devis le déplacement d’un formateur dans vos locaux, sur les thématiques que vous souhaitez aborder.</p>
					<div>
						<p class="subtitle">Tarifs</p>
						<div class="o-trainings-agency__prices primary">
							<p class="o-trainings-agency__price">
								<b>1937€ HT</b> soit 2324,40€ TTC
							</p>
							<p>
								<small>
									Jusqu’a 13 personnes dans votre agence.
									<b>Coûts supplémentaires : 149€ HT par personne</b> de 14 à 20 personnes maximum.
								</small>
							<p>
							<small>
								Les frais de locations de salle sont à votre charge.
							</small>
						</p>
              </p>
						</div>
					</div>

					<div v-if="config.option.cgu_vhsbs">
						<p class="subtitle">Conditions générales d'utilisation / vente</p>
						<a :href="config.option.cgu_vhsbs.value" target="_blank" data-icon_after="download" class="icon-flex">
							Téléchargez nos conditions générales de vente en PDF
						</a>
					</div>

					<div>
						<p class="subtitle">Contact</p>
						<p>
              Merci de contacter l'équipe SNPI Campus par mail à l'adresse : <a class="mailto" href="mailto:contact@snpicampus.fr" target="_blank" rel="noopener">contact@snpicampus.fr</a>
						</p>
					</div>
					<div class="bottom-right bottom-right--margin">
						<router-link to="/request" class="button" href="">Contacter le SNPI</router-link>
					</div>
				</div>
			</div>
		</div>

    <!--      BLOCK NEW FORMATION OFFER   -->
    <div v-if="$parent.$options.name !== 'popin'" class="o-trainings-agency__cta">
      <button class="button" @click="openPopin">Voir les formations en agence</button>
    </div>
    <div>
      <div class="block">
        <div class="title">Formation en agence visio</div>
        <div class="o-trainings-agency__content">
          <p class="primary">SNPI Campus vous propose également sur devis, une formation en webinaire uniquement pour votre agence.</p>
          <div>
            <p class="subtitle">Tarifs</p>
            <div class="o-trainings-agency__prices primary">
              <p class="o-trainings-agency__price">
                <b>1200€ HT</b> soit 1440€ TTC
              </p>
              <p>
                <small>
                  Jusqu'à 10 personnes.
                  <b>Coûts supplémentaires : 120€ HT par personne</b>
                </small>
                <p>
                  <small>
                  </small>
                </p>
              </p>
            </div>
          </div>

          <div v-if="config.option.cgu_vhsbs">
            <p class="subtitle">Conditions générales d'utilisation / vente</p>
            <a :href="config.option.cgu_vhsbs.value" target="_blank" data-icon_after="download" class="icon-flex">
              Téléchargez nos conditions générales de vente en PDF
            </a>
          </div>

          <div>
            <p class="subtitle">Contact</p>
            <p>
              Merci de contacter l'équipe SNPI Campus par mail à l'adresse : <a class="mailto" href="mailto:contact@snpicampus.fr" target="_blank" rel="noopener">contact@snpicampus.fr</a>
            </p>
          </div>
          <div class="bottom-right bottom-right--margin">
            <router-link to="/request" class="button" href="">Contacter le SNPI</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

	export default {
		name: "trainings-agency",
		computed: {
      config(){ return this.$config() },
      type(){ return this.$store.getters.type() },
    },
		methods:{
			openPopin(){
				this.$popin('trainings-agency')
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-trainings-agency{
		$c: &;

		@media #{$to-tablet}{ margin-right: $space-m; margin-left: $space-m }

		&__content{
			& > * + *{ margin-top: $space-m }
		}
		.subtitle{ margin-bottom: 0.5rem }

		.mailto{ font-weight: bold; color: $c-secondary }
		&__prices{
			background: $c-background; padding: $space-s; border-radius: $border-radius;
			p + p{ margin-top: $space-s }
		}
		&__price{
			font-weight: bold;
			b{ color: $c-secondary; font-size: $font-xxl }
		}


		&__cta{
			@media #{$from-small}{ display: none }
			@media #{$to-tablet}{
				margin-top: $space-m;
				.button{ width: 100% }
				& + *{ display: none }
			}
		}

		.o-popin__content &{
			.title{ margin-left: 0; margin-right: 0 }
			.title,   #{$c}__content{ padding-left: 0; padding-right: 0 }
		}
	}


</style>
