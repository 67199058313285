<template>
	<div class="o-legal-representatives container">
		<h2 class="o-user-infos__title title" v-if="interface.title">{{ interface.title }}</h2>
		<div class="o-legal-representatives__grid">
			<contact class="o-legal-representatives__item" v-for="(contact, index) in contacts" :key="contact.id" :data="contact"/>
		</div>
		<loader static="1" v-if="loading"></loader>
	</div>
</template>

<script>
	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "legal-representatives",
		props: ['data','interface'],
		data(){
			return{
				contacts: [],
				loading: false,
				permissions: parseInt(process.env.VUE_APP_PERMISSIONS),
				params:{
                    isLegalRepresentative: true
				},
			}
		},
		computed:{
			user(){
				return this.$user()
			},
		},
		methods:{
			search(){
				this.contacts = []
				this.query(0);
			},
			query(offset){

				if( this.loading )
					return;

				this.loading = true;

				companyRepository.getStaff(this.params).then(response=>{
					this.contacts = response.items;
					this.loading = false;
				})
			}
		},
		mounted() {
			this.query(0);
			this.$update(this.query);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-legal-representatives{
		$c: &;
		text-align: left;

		& > .title{
			margin: $space $space $space-m;
			@media #{$to-tablet}{ margin-left: $space-m; margin-right: $space-m }
		}

		&__filter{
			background: #fff; border-radius: 4px; padding: $space; margin-bottom: $space-m; box-shadow: $subtle-box-shadow; position: relative;
			form { position: relative }
			&--action{
				display: flex; margin-top: $half-space; justify-content: space-between
			}
      &--actives{
        label{ margin-right: 1rem }
        .field-wrap{ display: flex; align-items: center }
      }
		}

		&__actives{
			display: flex; justify-content: flex-end; margin-bottom: 0; margin-top: -$space-m;
			& + #{$c}__filter{ margin-top: $space-s }
			#{$c}__filter + &{ margin-top: $space-l }
		}

		&__grid{
      display: grid;
      grid-gap: 3rem;
      @media #{$only-tablet}{ grid-template-columns: repeat(2, 1fr) }
      @media #{$from-small}{ grid-template-columns: repeat(3, 1fr) }
		}

		&__item{
			 background: white; border-radius: $border-radius; overflow: hidden; width: 100%; box-shadow: $subtle-box-shadow;
		}
	}

</style>
