import userRepository from '@/repositories/userRepository';
import Vue from "vue";

export default {
    getGoogleCode(){
        return new Promise((resolve, reject)=>{
            window.google.accounts.oauth2.initCodeClient({
            client_id: process.env.VUE_APP_GOOGLE_CONNECT_CLIENT_ID,
            scope: "email profile openid",
            ux_mode: "popup",
            callback: (googleResponse) => {
               resolve(googleResponse.code)
            },
            error_callback: (error)=>{
                reject(error)
            }
            }).requestCode();
        })
    },

    login(){
        return new Promise((resolve, reject)=>{
            this.getGoogleCode().then((googleCode)=>{
                Vue.http.get(`google/login?code=${googleCode}`).then(response => {

                    let user = response.body.response;
    
                    let token = response.headers.get('X-Auth-Token');
                    let refresh_token = response.headers.get('X-Refresh-Token');
    
                    if( token )
                        localStorage.setItem('token', token);
    
                    if( refresh_token )
                        localStorage.setItem('refresh_token', refresh_token);
    
                    if( resolve )
                        resolve(user);
    
                }).catch(reject);
            })
        })
    },

    register(){
        return new Promise((resolve, reject)=>{
            this.getGoogleCode().then((googleCode)=>{
                Vue.http.get(`google/create?code=${googleCode}`).then(response => {
    
                    let token = response.headers.get('X-Auth-Token');
                    let refresh_token = response.headers.get('X-Refresh-Token');
    
                    if( token )
                        localStorage.setItem('token', token);
    
                    if( refresh_token )
                        localStorage.setItem('refresh_token', refresh_token);
    
                    if( resolve )
                        resolve(response);
    
                }).catch(reject);
            })
        })
    },
}