<template>
  <div class="o-questionnaire-container">
    <div v-if="step == 'questions'" class="o-question-container">
      <questionnaireSubheader
        :questions="questions"
        :currentQuestionPosition="currentQuestionPosition"
        :currentQuestion="currentQuestion"
      ></questionnaireSubheader>
      <h2>{{ question.label }}</h2>
      <p
        v-if="question.referent">
          Référent Handicap SNPI Campus : <a :href="'mailto:' + question.referent">{{ question.referent }}</a>
      </p>
      <p
        v-if="
          question.subtitle &&
          (!question.subtitle_condition_format ||
            question.subtitle_condition_format == formationFormat)
        "
        class="o-question-container__subtitle"
      >
        {{ question.subtitle }}
      </p>
      <form>
        <div class="o-question-container__answers" :class="question.class">
          <template v-if="question.type === 'text'">
            <input type="text" v-model="currentAnswer" />
          </template>
          <template v-else-if="question.type === 'checkbox'">
            <label
              v-for="(option, index) in question.options"
              :key="index"
              class="input--checkbox text answer-label"
            >
              <input
                type="checkbox"
                :value="option"
                v-model="selectedOptions"
              />
              <span class="checkmark" data-icon="check"></span>
              <span class="answer-span">{{ option }}</span>
            </label>
          </template>
          <template v-else-if="question.type === 'radio'">
            <label
              v-for="(option, index) in question.options"
              :key="index"
              class="input--checkbox text answer-label"
            >
              <input type="radio" :value="option" v-model="currentAnswer" />
              <span class="checkmark" data-icon="check"></span>
              <span class="answer-span">{{ option }}</span>
            </label>
          </template>
          <template v-else-if="question.type === 'boolean'">
            <label
              v-for="(option, index) in question.options"
              :key="index"
              class="input--checkbox text answer-label"
            >
              <input
                type="radio"
                :value="option === 'Oui' ? 1 : 0"
                v-model="currentAnswer"
              />
              <span class="checkmark" data-icon="check"></span>
              <span class="answer-span">{{ option }}</span>
            </label>
          </template>
          <div
            v-else-if="question.type === 'textarea'"
            class="o-question-container__answers-textarea"
          >
            <textarea
              :value="currentAnswer"
              @input="currentAnswer = $event.target.value"
              class="custom-textarea"
            ></textarea>
          </div>
        </div>
      </form>
      <div class="o-questionnaire-buttons">
        <button
          class="button button--border"
          @click="previousQuestion"
          :disabled="!currentQuestion"
        >
          Question précédente
        </button>
        <button
          class="button"
          @click="nextQuestion"
          :disabled="
            (currentQuestionPosition !== 6 && currentAnswer === '') ||
            (currentQuestionPosition === 6 && !selectedOptions.length)
          "
          :class="{
            'button--border':
              (currentQuestionPosition !== 6 && currentAnswer === '') ||
              (currentQuestionPosition === 6 && !selectedOptions.length),
          }"
          v-html="
            currentQuestionPosition === questions.length
              ? 'Valider'
              : 'Question suivante'
          "
        ></button>
      </div>
    </div>
    <div
      v-else-if="step == 'validation'"
      class="o-questionnaire-container__resume"
    >
      <div class="o-question-container">
        <p class="o-question-container__validate">
          En cliquant sur « Envoyer », vous acceptez que VHS traite vos données
          pour les finalités visées ci-dessus sur la base de la Politique de
          Confidentialité.
        </p>
        <div class="o-questionnaire-buttons">
          <button @click="step = 'questions'" class="button--comeback button">
            Revenir
          </button>
          <button
            :disabled="loading"
            :loading="loading"
            class="button"
            :class="[
              {
                'button--loading': loading,
              },
            ]"
            @click="submitAnswers"
          >
            <loader v-if="loading"></loader>
            <span>Envoyer</span>
          </button>
        </div>
        <div class="o-question-container__form-mentions">
          <p>
            Ces données sont recueillies dans le but d'adapter les modalités et
            ou organisation de la formation. En ce sens, elles ne sont partagées
            qu'à l'équipe pédagogique. VHS, en sa qualité de responsable de
            traitement, traite les données recueillies dans le but d'adapter les
            modalités et ou organisation de la formation. En ce sens, elles ne
            sont partagées qu'à l'equipe pédagogique.
          </p>
          <p>
            Conformément aux dispositions légales et réglementaires relatives à
            la protection des données personnelles, vous disposez d'un droit
            d'accès, de rectification, d'effacement de vos données ainsi que
            d'un droit d'opposition, d'un droit à la portabilité et d'un droit à
            la limitation du traitement de données vous concernant. Vous
            disposez également du droit de donner des directives relatives au
            sort de vos données apres votre décès.
          </p>
          <p>
            Pour en savoir plus sur la gestion de vos données personnelles et
            pour exercer vos droits, vous pouvez consulter notre Politique de
            Confidentialité, nous contacter à l'adresse
            <a href="mailto:mesdonnees-vhs-asseris@snpi.fr" target="_blank"
              >mesdonnees-vhs-asseris@snpi.fr</a
            >
            ou contacter le DPO de VHS à l'adresse
            <a href="mailto:dpo-vhs-asseris@snpi.fr" target="_blank"
              >dpo-vhs-asseris@snpi.fr</a
            >
          </p>
        </div>
      </div>
    </div>
    <div v-else class="o-question-container">
      <h2>
        Nous vous remercions pour vos réponses qui contribueront à
        l'amélioration de nos actions de formation.
      </h2>
    </div>
  </div>
</template>

<script>
import qualiopiRepository from "@/repositories/qualiopiRepository";
import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
  data() {
    return {
      currentQuestion: 0,
      step: "questions",
      questions: [
        {
          index: 0,
          key: "contract_status",
          label: "Quel est votre statut ?",
          type: "radio",
          options: [
            "Titulaire de la carte professionnelle",
            "Salarié",
            "Agent Commercial",
          ],
        },
        {
          index: 1,
          key: "school_level",
          label: "Quel est votre niveau d’études ?",
          type: "radio",
          class: "o-question-container__answers-grid",
          options: ["< Bac", "Bac", "Bac +2", "Bac +3/5"],
        },
        {
          index: 2,
          key: "domain",
          label: "Quel est votre principal domaine d’intervention ?",
          type: "radio",
          options: [
            "Transaction vente/location",
            "Gestion locative",
            "Syndic de copropriété",
          ],
        },
        {
          index: 3,
          key: "professionnal_experience",
          label: "Quel est votre niveau d’expérience professionnelle ?",
          type: "radio",
          options: ["Moins d'un an", "Entre 1 an et 5 ans", "Plus de 5 ans"],
        },
        {
          index: 4,
          key: "level_of_knowledge",
          label:
            "Quel est votre niveau de connaissances sur la formation choisie ?",
          type: "radio",
          options: ["Débutant", "Intermédiaire", "Confirmé"],
        },
        {
          index: 5,
          key: "expectation",
          label: "Qu’attendez-vous de cette formation ?",
          type: "checkbox",
          subtitle: "Plusieurs réponses possibles",
          class: "o-checkboxes-container",
          options: [
            "L'acquisition de nouvelles compétences",
            "Le développement de compétences existantes",
            "Un développement personnel",
            "Un échange avec d'autres professionnels",
          ],
        },
        {
          index: 6,
          key: "specific_needs",
          label:
            "Avez-vous des besoins spécifiques concernant cette formation nécessitant l’intervention de l’équipe pédagogique ?",
          type: "boolean",
          class: "o-question-container__answers-grid",
          subtitle:
            "Toute réponse « OUI » entraînera une indisponibilité temporaire de votre accès à la formation en l’attente de vos suggestions par l'équipe pédagogique de SNPI Campus",
          subtitle_condition_format: "e-learning",
          options: ["Oui", "Non"],
        },
        {
          index: 7,
          key: "specific_needs_details",
          previousExpectedAnswer: "Oui",
          label: "Pouvez-vous détailler ces besoins ?",
          type: "textarea",
          subtitle:
            "Attention : tout remplissage de ce champ entraînera une indisponibilité temporaire de votre accès à la formation en l’attente de l’analyse de vos suggestions par l’équipe pédagogique de SNPI Campus.",
          subtitle_condition_format: "e-learning",
          showCondition: {
            questionIndex: 6, // Index de la question précédente
            expectedAnswer: 1, // Réponse attendue à la question précédente pour afficher cette question
          },
        },
        {
          index: 8,
          key: "disability",
          label:
            "Souffrez-vous d’un handicap nécessitant une adaptation de l’accès à la formation ? ",
          referent: "pare@snpi.fr",
          type: "boolean",
          class: "o-question-container__answers-grid",
          subtitle:
            "Toute réponse « OUI » entraînera une indisponibilité temporaire de votre accès à la formation en l’attente d’une intervention de notre référent Handicap.",
          subtitle_condition_format: "e-learning",
          options: ["Oui", "Non"],
        },
      ],
      currentAnswer: "",
      answers: {},
      selectedOptions: [],
      formationFormat: "",
    };
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
    currentQuestionPosition() {
      return this.currentQuestion + 1;
    },
  },
  mounted() {
    this.loading = true;
    // get key from url
    if(localStorage.getItem('key')){
      this.answers.key = localStorage.getItem('key');
    }
    else {
      this.answers.key = this.$route.query.key;
    }

    // get formation type from key
    formationCourseRepository
      .positionningFormat(this.answers.key)
      .then((response) => {
        this.formationFormat = response;
        this.loading = false;
      })
      .catch((error) => {
        this.error = true;
        this.loading = false;
      });

    // delete key from local storage
    localStorage.removeItem('key');
  },
  methods: {
    nextQuestion() {
      if (this.saveAnswer()) {
        if (this.currentQuestion >= this.questions.length - 1) {
          // Toutes les questions ont été répondues
          this.step = "validation";
        } else {
          this.currentQuestion++;
          const currentQuestion = this.questions[this.currentQuestion];
          const showCondition = currentQuestion.showCondition;

          if (showCondition && !this.checkShowCondition(showCondition)) {
            this.nextQuestion();
          } else {
            this.loadAnswer();
          }
        }
      }
    },
    previousQuestion() {
      if (this.currentQuestion > 0) {
        this.currentQuestion--;

        const currentQuestion = this.questions[this.currentQuestion];
        const showCondition = currentQuestion.showCondition;
        if (showCondition && !this.checkShowCondition(showCondition)) {
          this.previousQuestion();
        }
        this.loadAnswer();
      }
    },
    checkShowCondition(showCondition) {
      const previousQuestion = this.questions[showCondition.questionIndex];
      const previousAnswer = this.answers[previousQuestion.key];

      return previousAnswer === showCondition.expectedAnswer;
    },
    loadAnswer() {
      const previousAnswer = this.answers[this.question.key];
      if (this.question.type === "checkbox") {
        this.selectedOptions = previousAnswer || [];
      } else {
        this.currentAnswer = previousAnswer || "";
      }
    },
    saveAnswer() {
      const currentQuestion = this.questions[this.currentQuestion];
      if (currentQuestion.type === "checkbox") {
        this.answers[currentQuestion.key] = [...this.selectedOptions];
      } else {
        this.answers[currentQuestion.key] = this.currentAnswer;
      }

      if (this.answers[currentQuestion.key].length === 0) return false; //empty answer

      return true;
    },
    submitAnswers() {
      let self = this;

      this.loading = true;

      qualiopiRepository.create(JSON.stringify(this.answers)).then(
        (response) => {
          this.step = "submited";
        },
        (response) => {
          this.loading = false;
          this.error = response.data.message;
          setTimeout(function () {
            self.error = false;
          }, 3500);
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../environment";

.o-questionnaire-container {
  width: 80%;
  height: 69vh;
  min-height: 40rem;
  background: white;
  margin: auto;
  padding: 7rem 0;
  margin-bottom: -6rem;
  color: $c-primary;
  @media #{$to-tablet} {
    height: fit-content;
    width: 90%;
    margin-bottom: -$space-m;
    padding: 4rem 0;
  }
  .o-question-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    margin: auto;
    margin-bottom: $space-m;
    gap: $space-m;
    border: 2px solid lightgrey;
    width: 60%;
    min-height: 280px;
    justify-content: space-evenly;
    font-size: $space-m;
    font-weight: bold;
    @media #{$to-tablet} {
      width: 80%;
    }
    h1 {
      font-weight: bold;
      span {
        color: var(--c-secondary);
      }
    }
    h2 {
      font-weight: bold;
      font-size: 2.5rem;
      @media #{$to-tablet} {
        font-size: 2rem;
      }
    }
    &__subtitle {
      font-size: 1.5rem;
      font-weight: 100;
    }
    form {
      display: flex;
      justify-content: center;
      width: 100%;
      .o-question-container__answers {
        display: flex;
        font-size: 1.2rem;
        overflow: hidden;
        width: 100%;
        justify-content: center;
        @media #{$to-tablet} {
          flex-direction: column;
          gap: 1rem;
          width: fit-content;
        }
        &-grid {
          @media #{$to-tablet} {
            display: flex;
            flex-wrap: wrap;
            width: 90%;
            flex-direction: row;
            .answer-label {
              width: 40%;
            }
          }
        }
        .answer-label {
          display: flex;
          margin-right: 1rem;
          align-items: center;
          cursor: pointer;
          @media #{$to-tablet} {
            margin-right: 0;
          }
          input[type="radio"]:checked,
          input[type="checkbox"]:checked {
            border: solid 2px white;
            min-width: 14.5px;
          }
          input[type="radio"] {
            border-radius: 2px;
            &:checked + .checkmark {
              opacity: 1;
            }
          }
          .answer-span {
            font-size: 2rem;
            @media #{$to-tablet} {
              font-size: 1.6rem;
              margin-left: 10px;
              text-align: left;
            }
          }
        }
        &-textarea {
          width: 80%;
          min-height: 15rem;
          @media #{$to-tablet} {
            width: 100%;
            min-width: 210px;
          }
          textarea {
            height: 100%;
          }
        }
      }
      .o-checkboxes-container {
        flex-direction: column;
        text-align: left;
        width: fit-content;
        @media #{$to-tablet} {
          width: 80%;
        }
      }
    }
  }
  .o-questionnaire-buttons {
    position: absolute;
    bottom: -6rem;

    display: flex;
    flex-wrap: wrap;
    // width: 90%;
    // margin: auto;
    gap: 2rem;
    justify-content: center;
    @media #{$to-tablet} {
      flex-direction: column-reverse;
      gap: 2rem;
      // width: 70%;
      position: initial;
      button {
        margin-left: 0;
      }
    }
    button {
      min-width: 150px;
      text-transform: initial;
    }
    .button--disabled {
      opacity: 1;
      background: #eee;
      border: none;
      color: $c-primary;
    }
  }
  &__resume {
    p {
      font-weight: 100;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .o-question-container {
      .o-questionnaire-buttons {
        position: initial;
        &__validate {
          font-weight: bold;
        }
        .button--comeback {
          color: $c-primary;
          background: #eee;
          border: none;
        }
      }
      &__form-mentions p:not(:first-child) {
        font-style: italic;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.o-popin__scroll {
  .o-questionnaire-container {
    width: 100%;
    min-width: 750px;
    min-height: 37rem;
    padding: 0;
    margin-bottom: 0;
    height: fit-content;
    padding-bottom: 50px;
    @media #{$to-tablet} {
      min-width: 0;
    }
    .o-question-container {
      width: 90%;
      h2 {
        font-size: 2.3rem;
      }
    }
  }
}
</style>
