import Vue from "vue";

export default {
  //get lien 
  getWalletLink(channel) {
    return new Promise((resolve, reject) => {
      Vue.http.get(`wallet/getlink/${channel}`).then(response => {
        resolve(response.body.response);
      }).catch(reject);
    });
  },
  getWalletInfos() {
    return new Promise((resolve, reject) => {
      Vue.http.get(`wallet/information`).then(response => {
        resolve(response.body.response);
      }).catch(reject);
    });
  }
};
