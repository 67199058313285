<template>
	<div class="m-alerte-remaining-places">
		<h1 class="title">Alerte place disponible</h1>
		<p>Si vous confirmez votre intérêt pour cette formation, un mail vous sera envoyé dès qu'une place se libère. </p>
		<div class="bottom-center">
			<form @submit.prevent="validate">
				<button class="button button--white" @click.prevent="close">Annuler</button>
				<submit text="Confirmer" :loading="loading" :success="success" text-success="Intérêt confirmé" ></submit>
			</form>
		</div>
	</div>
</template>

<script>

import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
	name: "alert-remaining-places",
	data(){
		return{
			loading: false,
			success: false
		}
	},
	props:['data'],
	methods:{
		close(){
			this.$emit('close')
		},
		validate(){

			this.loading= true;

			formationCourseRepository.alert(this.data.id).then(response=>{

				this.$broadcast('alerted');
				this.loading = false;
				this.success = true;
				setTimeout(this.close, 1500);
			})
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-alerte-remaining-places{
	color: $c-primary;
	p{ margin-top: $space }
}
</style>