import Vue from "vue";
import cache from "@/services/cache";

export default {

	getStock() {
		return new Promise((resolve, reject) => {

			Vue.http.get('collection/signature/stock').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	get(params) {
		return cache.list('collection/signature', params)
	},

	cancel(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('collection/signature/'+id+'/cancel').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	create(params) {

		return Vue.http.post('collection/signature', params);
	},

	refresh(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('collection/signature/'+id+'/refresh').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	download(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('collection/signature/'+id+'/download').then(response => {

				document.location.href = response.body.response.url+'?disposition=attachment';

				resolve();

			}, response=>{

				reject(response);
			});
		});
	},

	checkFile(file) {
		let form = new FormData();
		form.append('file_upload',file)
		return Vue.http.post('collection/signature/file/check', form);
	},

	sendLink(id) {

		return new Promise((resolve, reject) => {

			Vue.http.post('collection/signature/'+id+'/link').then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	getPack() {

		return new Promise((resolve, reject) => {

			Vue.http.get('collection/signature/pack').then(response => {

				resolve(response.body.response);

			},reject);
		});
	}
};
