<template>
	<div class="o-404 container">
		<h2 class="title-big o-404__title">Contenu introuvable</h2>
		<p class="o-404__text">Cette page n'existe pas ou n'existe plus.<br/>
			Nous vous prions de nous excuser pour la gêne occasionée.</p>
		<div class="bottom-center">
			<router-link to="/" class="button button--secondary">Retour à mon tableau de bord</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: "not-found"
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-404{
		padding: $space*2 0;

		& > * + *{ margin-top: $space }

		&__title{ text-transform: uppercase }

		&__text{ font-size: $font-l; color: $c-primary }
	}
</style>