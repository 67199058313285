<template>
  <div
    class="o-international-next-events container"
    :class="{ 'o-trainings-list--loading': loading }"
  >
    <div class="o-text-image__col--text o-international-text-header">
      <h1 class="o-text-image__uptitle title title--small">
        {{ interface.title }}
      </h1>
      <p class="wysiwyg">
        Profitez des avantages négociés par le SNPI pour participer aux
        différents évènements Internationaux.
      </p>
    </div>
    <div class="o-events-list" :class="{ 'o-events-list--loading': loading }">
      <div v-infinite-scroll.full="loadMore" class="o-events-list__events">
        <eventItem
          :data="formation"
          class="o-events-list__training"
          v-for="(formation, index) in formations"
          :key="formation.id"
        ></eventItem>
        <loader class="action" v-if="loading"></loader>
      </div>
    </div>
  </div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";
import urlParams from "params-url";

export default {
  name: "internatiobal-next-events",
  props: ["data", "interface"],
  data() {
    return {
      loading: false,
      formations: false,
      count: -1,
      sort: "startAt|asc",
      params: {
        sort: "startAt",
        order: "asc",
        limit: 6,
        offset: 0,
        format: [],
        _dateRange: {
          startAt: "",
          endAt: "",
        },
        international: 1,
      },
    };
  },
  methods: {
    searchEvents() {
      this.query(0);
    },
    loadMore() {
      if (this.formations.length >= this.count || !this.formations.length)
        return;

      this.query(this.params.offset + this.params.limit);
    },
    query(offset) {
      if (this.loading === true) return;

      this.params.offset = offset;

      this.loading = true;

      formationCourseRepository.list(this.params).then(
        (response) => {
          this.params.offset = response.offset;
          this.count = response.count;
          this.formations = response.items;
          this.loading = false;

          this.$store.commit("form", {
            type: "evenements",
            params: this.params,
          });
        },
        (e) => {
          this.loading = false;
        }
      );
    },
  },
  mounted() {
    this.params.format = [];
    this.searchEvents();
  },
  computed: {
    config() {
      return this.$config();
    },
    user() {
      return this.$user();
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import "../../environment";

.o-international-next-events {
  padding-top: $space-m;
  .o-events-list__events {
    display: flex;
    flex-wrap: wrap;
    margin-top: $space-m;
  }
}
</style>
