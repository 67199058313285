import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";

export default {

	list(params) {

		if( 'search' in params && params.search.length )
			return cache.list('resource', params, 0);
		else
			return cache.list('resource', params);
	}
};
