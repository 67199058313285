<template>
	<div class="o-fee block block--notitle">

		<div class="o-fee__content">
			<h3 class="title-big">Mettre à jour mon barème d'honoraires</h3>
			<form ref="form" v-if="edit||!user.company.feeUpdatedAt" id="fee_update">
				<submit class="button--white" text="Choisir un fichier" :loading="uploading"></submit>
				<input type="file" name="file" accept="application/pdf" @change="upload">
			</form>
			<div class="center" v-else>
				<a @click="download" class="o-fee__download">bareme-d-honoraires-{{formatYear(user.company.feeUpdatedAt)}}.pdf</a>
				<a data-icon="pencil" @click="edit=true" class="o-fee__edit"></a>
			</div>
			<p v-if="user.company.feeUpdatedAt" class="center">Mis à jour le {{formatDate(user.company.feeUpdatedAt)}}</p>
			<p v-else>Votre barème s'affichera automatiquement dans votre fiche agence du site snpi.fr</p>
		</div>
	</div>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "fee",
		data(){
			return{
				uploading: false,
				downloading: false,
				edit: false
			}
		},
		computed:{
			user(){
				return this.$user();
			}
		},
		methods:{
			upload(){
				this.uploading = true;
				let formData = new FormData( this.$refs.form );
				companyRepository.uploadFee(formData).then(response=>{
						this.user.company.feeUpdatedAt = new Date().getTime();
						this.uploading = false;
						this.edit = false;
				},
				response=>{
					this.uploading = false;
				})
			},
			download(){
				this.downloading = true;
				companyRepository.downloadFee(this.user.company.id).then(response=>{
						this.downloading = false;
				},
				response=>{
					this.downloading = false;
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.o-fee{
		&__edit{
			text-decoration: none; background: $c-background; border-radius: 50%; width: 30px; height: 30px; color: $c-primary;
			line-height: 26px; text-align: center; display: inline-block; vertical-align: middle; margin-left: 1rem; cursor: pointer;
		}
		&__download{ text-decoration: underline; color: $c-primary; cursor: pointer }
		&__content{
			& > * + *{ margin-top: $space }
			form+form{ margin-top: 1rem }
			form{ position: relative }
			.button{ width: 100% }
			.button+input{
				position: overlay(); opacity: 0; z-index: 1;
			}
		}
	}

</style>