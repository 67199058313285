import Vue from "vue";
import cache from "@/services/cache";


export default {
	download(id) {
		return new Promise((resolve, reject) => {

			Vue.http.get('formation/'+ id + '/download').then(response => {

				document.location.href = response.body.response.url+'?disposition=attachment';

				resolve();

			}, response=>{

				reject(response);
			});
		});
	}
}