<template>
	<v-runtime-template :template="parse(data)" class="wysiwyg"></v-runtime-template>
</template>

<script>
	import VRuntimeTemplate from "v-runtime-template";
	import documentRepository from '@/repositories/documentRepository';

	export default {
		components: {VRuntimeTemplate},
		name: "runtime-text",
		props:['data'],
		methods:{
			preview(type, id){
					this.$popin('preview', {type:type, id:id});
			},
			parse(content){

				content = '<div>'+content.replace(/<a[^\>]*href=".*\/edito\/([^\"]*)"[^\>]*>([^\<]*)<\/a>/g, '<router-link to="/edito/$1">$2</router-link>')+'</div>';
				content = '<div>'+content.replace(/<a[^\>]*href=".*\/document\/([^\"]*)"[^\>]*>([^\<]*)<\/a>/g, '<a @click="preview(\'document\',\'$1\')">$2</a>')+'</div>';

				if( this.user.isLegalRepresentative)
				{
					content = content.replace('[adomos_key]', this.user.company.adomosKey);
				}
				else{
					//todo: remove link
					content = content.replace('[adomos_key]', '');
				}

				return content;
			}
		},
		computed:{
			user(){ return this.$user() }
		}
	}
</script>