<template>
	<div class="m-foad-text">
		<div class="wysiwyg" v-html="data.wysiwyg" ref="html"></div>
	</div>
</template>

<script>
export default {
	name: "foad-text",
	props:['data', 'participant'],
  mounted() {
    this.$emit('updated', 1)
    this.$refs.html.querySelectorAll('a').forEach(item=>{
      item.setAttribute('target', '_blank')
    })
  }
}
</script>

<style lang="scss">
@import '../../environment';
	.m-foad-text{
    img{ max-width: none; width: 100%; border-radius: 4px }
    ul, ol{ padding-left: 1rem }
    table{ border: 1px solid $c-background-darker; background: $c-background-lighter; border-radius: 4px }
    table td{ padding: 1rem }
  }
</style>