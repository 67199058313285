<template>
	<div class="o-cms">
		<component :is="component.type" :data="component.data" v-for="(component, index) in content" :key="index" />
		<loader v-if="loading"></loader>
	</div>
</template>


<style lang="scss">

	.o-cms{
		min-height: 40rem; position: relative; margin-top: 0;
	}

</style>

<script>
	import pageRepository from '@/repositories/pageRepository';

	export default {
		name: "cms",
		props:['data', 'interface'],
		data() {
			return {
				content: false,
				loading: true
			}
		},
		computed:{
			config(){ return this.$config() }
		},
		mounted() {

      let page = this.config.option[this.interface.page]

      if( page ){
        pageRepository.get(page.value).then(response => {
          this.content = response.layout;
          this.loading = false;
        })
      }
		}
	}
</script>

<style scoped>

</style>