<template>
  <div class="m-training-reporting">

    <div class="m-training-reporting__heading" v-if="hasRole(user, 'company')">
      <span class="title-big">{{ data.contact.firstname }} {{data.contact.lastname}}</span>
    </div>

    <div class="m-training-reporting__block">
      <h3 class="title title--small" v-if="hasRole(user, 'company')">Rapport</h3>
      <report-progress :data="data"></report-progress>

      <div class="m-training-reporting__heading">
        <h3 class="title title--small"  v-if="data.count">Dernières attestations</h3>
        <a class="link" @click="showAll(data.contact.id,data.contact.firstname + '  ' +data.contact.lastname)" v-if="data.count>3">Tout afficher</a>
      </div>

      <div class="m-training-reporting__history"  v-if="data.list.length">
        <div v-for="(training, index) in data.list" :key="training.id" class="m-training-reporting__item">
          <div class="m-training-reporting__infos">
            <div class="primary" v-if="training.duration">
              <b>{{training.duration.hours}}h</b>
            </div>
            <div class="primary" v-else-if="training.formation.duration">
              <b>{{training.formation.duration.hours}}h</b>
            </div>
            <div>
              <div class="m-training-reporting__date">
                <span v-html="formatDate(training.startAt)" v-if="training.format !== 'e-learning'"></span>
                <span v-else>En ligne</span>
              </div>
              <b class="primary m-training-reporting__title" v-if="training.formation">{{training.formation.title}}</b>
              <b class="primary m-training-reporting__title" v-else-if="training.title">{{training.title}}</b>
            </div>
          </div>
          <div class="m-training-reporting__appendix" v-if="training.appendix">
            <div class="m-training-reporting__delivered">Délivrée le {{formatDigitDate(training.appendix.createdAt)}}</div>
            <form @submit.prevent="deleteFormation(training.id)" id="report_delete-external" v-if="training.external">
              <submit secondary="1" class="button--xsmall" :loading="deleting===training.id" text="Supprimer" text-success="Supprimé" :success="deleted===training.id"></submit>
            </form>
            <form @submit.prevent="downloadAsset(training.appendix)" v-if="checkPermission('ROLE_FORMATION_ATTESTATION',user)" id="formation-report_download-attestation">
              <submit class="button--xsmall" text="Télécharger" :loading="download===training.appendix.id"></submit>
            </form>
          </div>
        </div>

      </div>
    </div>

    <div class="m-training-reporting__block">
      <p v-if="!data.list.length" class="m-training-reporting__none">Aucune formation effectuée</p>
      <div class="bottom-right-inline m-training-reporting__actions">
        <button class="button button--small" @click="openPopin(data.contact.id)"  v-if="checkPermission('ROLE_FORMATION_ATTESTATION',user)">Renseigner une formation</button>
        <form @submit.prevent="downloadAll(data.contact.id)" v-if="data.list.length > 1 && checkPermission('ROLE_FORMATION_EXTERNAL',user)" class="inline" id="formation-report_download-all">
          <submit class="button button--border button--small" text="Télécharger les attestations" :loading="download==='all_'+data.contact.id"></submit>
        </form>
        <router-link to="/formations" class="button button--border button--small" v-else>Trouver une formation</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import formationCourseRepository from '@/repositories/formationCourseRepository';
import externalFormationRepository from '@/repositories/externalFormationRepository';
import appendixRepository from "@/repositories/appendixRepository";

export default {
  name: "training-reporting",
  props:['data'],
  data(){
    return{
      download: false,
      deleting: false,
      deleted: false
    }
  },
  methods:{
    openPopin(id){

      this.$popin('training-external',id)
    },
    showAll(id,title){

      this.$popin('contact-reports',{id,title})
    },
    deleteFormation(id){

      this.deleting = id;

      externalFormationRepository.delete(id).then(response=>{

        this.deleting = false;
        this.deleted = id;

        this.$update();

      }, response=>{

        this.deleting = false;
      })
    },
    downloadAsset(appendix) {

      this.download = appendix.id;

      if( appendix.entity === 'external-formation' )
        externalFormationRepository.download(appendix.id).then(response=>{ this.download = false }, response=>{ this.download = false });
      else
        appendixRepository.download(appendix.id).then(response=>{ this.download = false }, response=>{ this.download = false });
    },
    downloadAll(contact_id) {

      this.download = 'all_'+contact_id;
      formationCourseRepository.downloadAll(contact_id).then(response=>{ this.download = false }, response=>{ this.download = false });
    }
  },
  computed:{
    user(){ return this.$user() }
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-training-reporting{

  background: white; border-radius: $border-radius; padding: $space; box-shadow: $subtle-box-shadow;

  @media #{$from-small}{
    width: calc(50% - #{$half-space});
    &:nth-of-type(even){ margin-left: $space }
    &:nth-of-type(1n+3){ margin-top: $space }

	  .o-trainings-reporting__grid--1 &{
		  width: 100%;
	  }
  }

  @media #{$to-tablet}{
    padding: $space-m;
    & + *{ margin-top: $space-m }
  }

  &__delivered{ opacity: 0; transition: allow(opacity) }

  &__history{
    margin-top: $space-s;
    @media #{$from-tablet}{ background: $c-background; border-radius: $border-radius; padding: $space-m }
  }

  @media #{$to-tablet}{
    &__item{ background: $c-background; border-radius: $border-radius; padding: $space-m }
  }

  &__item{ display: flex }

  &__item + &__item{ margin-top: $space-m }

  &__infos{
    display: flex; align-items: center;
    & > * + *{ margin-left: $space-m }

	  & > div:first-of-type{ min-width: 25px }
  }

  &__appendix{
    margin-top: $space-s; display: flex; align-items: center; justify-content: flex-end; font-size:$font-xs; margin-left: auto; white-space: nowrap;
    form{ margin-left: $space-s }
    .button{ text-transform: none }
    &:hover .m-training-reporting__delivered{ opacity: 1 }
    @media #{$to-tablet}{
      flex-direction: column-reverse; align-items: flex-start;
      form{ margin-bottom: $space-s; margin-left: 0 }
    }
  }

  &__date{ font-size: $font-xs }

  &__heading{
    color: $c-primary; margin-bottom: $space-s;
    @media #{$from-tablet}{ display: flex; align-items: center }
    span{ margin-right: $space-s }
    .link{ font-weight: normal; margin-left: 0.5rem; font-size: $font-xs }
  }

  &__actions{
    margin-top:$space-m;

    @media #{$to-phone}{
      form{ margin-left: 0 }
      .button{ margin-top: $space-s; margin-left: 0; display: block; text-align: center; width: 100% }
    }
  }

  &__none{ margin-bottom:$space-l }
}
</style>
