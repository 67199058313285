<template>
	<div class="m-search" :class="{'m-search--has-results':results, 'm-search--loading':loading}" v-click-outside="close" v-if="user">
		<form class="m-search__input input--search" @submit.prevent="query" id="search">
			<input type="text" placeholder="Rechercher..." v-model="params.query" minlength="3"/>
			<input type="submit" value="search" />
			<loader v-if="loading"></loader>
		</form>
		<div class="m-search__result" v-if="results">
			<div v-for="(entity,type) in results" class="m-search__entity" :class="'m-search__entity--'+type" v-if="entity.count">
				<h2 class="title">{{entity.title}}</h2>
				<div class="m-search__items">
					<component :is="getType(type)" :data="item" v-for="(item, index) in entity.items" :key="type+item.id"></component>
				</div>
			</div>
			<div v-if="!Object.values(results).some(item => item.count > 0)">
				Aucun résultat
			</div>
		</div>
	</div>
</template>

<script>

	import searchRepository from "@/repositories/searchRepository";

	export default {
		name: 'search',
		data(){
			return{
				results : false,
				loading : false,
				params:{
					query : '',
					limit : 3
				}
			}
		},
    computed:{
      user(){
        return this.$user()
      }
    },
		methods:{
			close(){
				this.results = false;
				this.params.query = '';
			},
			getType(type){

				if( type === 'formation' )
					return 'trainingExcerpt';
				else if( type === 'page' )
					return 'pageExcerpt';

				return type;
			},
			query(){

				if( this.loading )
					return;

				this.loading = true;

				searchRepository.get(this.params).then(response=>{

					this.results = response
					this.loading = false

				},e =>{
					this.results = false;
					this.loading = false
				})
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-search{
		.a-loader{
			&__spinner{
				right: 5px; left: auto; transform: scale(0.7);
				div{ border-color: #fff transparent transparent transparent }
			}
		}
		.input--search{
			&:after{
        color: white; padding: 11.5px;
        .app-vhs &{ color: $c-text }
      }
			input[type=text]{
				background: rgba(white,0.3); color: white;
        .app-vhs &{
          background: $c-background; color: $c-text;
          &::placeholder{ color: $c-main }
        }
        &::placeholder{ color: white }

				.accessible-version &{ border-color: $c-primary-light }
			}
			input[type=submit]{
				position: absolute; right: 0; top: 0; height: 100%; opacity: 0; z-index: 1;
			}
		}
		&__result{
			position: absolute; top: 100%; width: 100%; background: #fff; padding: $half-space; box-shadow: 2px 0 5px rgba(0,0,0,0.15); border-radius: 0 0 4px 4px;
			text-align: left; max-height: 60vh; overflow: auto; color: $c-main;  -webkit-overflow-scrolling: touch;
		}
		&--has-results{
			input[type=text]{ border-radius: 4px 4px 0 0 }
		}
		&--loading{
			.input--search:after{ display: none }
		}
		&__entity{
      > .title{ margin-bottom: $space-s }
      &+&{ margin-top: $space }
      &--document, &--appendix, &--formation, &--news, &--page{
        .m-search__items{ border: 1px solid $c-border; border-radius: $border-radius }
        .m-document:nth-child(2n){ background: $c-background-lighter }
      }
			&--expert{
        .m-search__items{ padding: 0 1rem; display: flex; flex-wrap: wrap }
        .m-expert{
          width:100%; margin: 0.5rem;
          &__inner{ background: $c-background-lighter }
					&__info{ padding: 1rem }
					&__avatar{ width: 25% }
					&__initial{ font-size: 4rem }
					&__title{ margin-bottom: $space-s }
				}
			}
      &--document, &--appendix{
        .m-document{
          &__date{
            span{ display: none }
          }
          &__actions{
            .m-document__see{ display: none }
          }
        }
      }
      &--page{
        .m-page{
          padding: $half-space; margin: 0; flex-basis: 100%; border: 0;
          &:nth-child(2n){ background: $c-background-lighter }
        }
      }
			&--formation{
				.m-trainings-excerpt{
          padding: $half-space; margin-top: 0;
					&:nth-child(2n){ background: $c-background-lighter }
					&__price{ font-size: $font-l }
				}
			}
			&--news{
				.m-news{
          padding: $half-space; margin: 0; flex-basis: 100%; border: 0;
          &:nth-child(2n){ background: $c-background-lighter }
          &__thumbnail{ display: none }
          &__date{
            background: none; color: inherit; padding: 0; margin-bottom: $space-xs; position: static;
          }
        }
			}
		}
	}
</style>
