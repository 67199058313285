<template>
	<div class="o-push-page container">
		<h3 class="title-big">{{ data.title }}</h3>

		<div class="o-push-page__grid">
			<div class="o-push-page__item" v-for="(item,index) in data.items" :key="index" v-if="item.url || item.page">
				<div class="o-push-page__thumbnail" v-if="item.thumbnail">
					<div>
						<img :src="item.thumbnail+'?w=233&h=169'" :alt="item.title" />
					</div>
				</div>

				<div class="o-push-page__content">
					<h4 class="title-med">{{item.title}}</h4>
					<span class="o-push-page__label">{{item.label}}</span>
				</div>

				<router-link :to="item.page" v-if="item.type === 'edito'"  class="o-push-page__link"></router-link>
				<router-link :to="getPage(item.url)" v-else-if="item.type === 'internal'"  class="o-push-page__link"></router-link>
				<a class="o-push-page__link" :href="item.url" target="_blank" v-else></a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "push-page",
		props: ['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-push-page{
		margin: $space-xl auto;

		&__grid{
			@media #{$from-small}{ display: flex; flex-wrap: wrap; }
		}
		&__item{
			background: white; border-radius: $border-radius; display: flex; overflow: hidden; box-shadow: $subtle-box-shadow;
			text-align: left; position: relative;

			@media #{$from-small}{
				width: calc(50% - #{$half-space});
				&:nth-of-type(even){ margin-left: $space }
				&:nth-of-type(n+3){ margin-top: $space }
			}

			@media #{$to-tablet}{
				& + *{ margin-top: $space }
			}

		}

		&__thumbnail{
			width: 33%; flex-shrink: 0;

			& > *{ padding-top: 72.6%; position: relative;  }
			img{ position: overlay(); width: 100%; height: 100%; object-fit: cover }
		}

		&__content{padding: $space-m; flex: 1; position: relative }

		&__label{ position: absolute; bottom: $space-m; right: $space-m; color: $c-primary }

		&__link{ position: overlay(); z-index: 1 }
	}
</style>