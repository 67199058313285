import Vue from "vue";
import store from "@/services/store";
import cache from "@/services/cache";

export default {

	send(form) {

		return Vue.http.post('request', form)
	}
};
