<template>
	<div class="field-input field-input-tag" :class="{'field-input-tag--active':this.value}">
		<div v-if="platform !== 'desktop'">
			<vue-form-generator tag="div" :schema="form" :model="tagModel" v-if="form.fields && tagModel" @model-updated="updateParentModel"></vue-form-generator>
		</div>
		<div v-else>
			<button @click.prevent class="tag">{{ schema.placeholder ? schema.placeholder : schema.label }}</button>
			<dropdown class="field-input-tag__popin">
				<vue-form-generator tag="div" :schema="form" :model="tagModel" v-if="form.fields && tagModel"></vue-form-generator>
				<div class="bottom-right">
					<div class="link" @click.prevent="resetFilter">Effacer</div>
					<button class="button button--small" @click.prevent="updateParentModel">Sélectionner</button>
				</div>
			</dropdown>
		</div>

	</div>
</template>

<script>
import { abstractField, fieldComponents } from "vue-form-generator";

export default {
	name: "field-tag-custom",
	components: fieldComponents,
	mixins: [ abstractField ],
	data(){
		return{
			tagModel:false,
			originalModel:false,
			form: {
				fields: false
			},
			show: false,
			platform: window.platform
		}
	},
	methods:{
		updateParentModel(){
				this.value = this.tagModel;
		},
		resetFilter(){
			this.tagModel = JSON.parse(this.originalModel)
			this.value = '';
		}
	},
	mounted(){
		this.tagModel = this.value ? this.value : this.schema.init;
		this.originalModel = JSON.stringify(this.schema.init);
		this.form.fields = this.schema.fields;
	}
}
</script>

<style lang="scss">
@import '../../../environment';
.field-input-tag{
	.tag{ background: white }
	&--active .tag{ background: $c-primary; color: #fff }
	&__popin.a-dropdown{
		position: absolute; top: 100%; background: white; padding: $space-m; box-shadow: $large-box-shadow; border-radius: $border-radius; z-index: 1; margin-top: 1rem;
		left: 0; right: auto;
		&:before{ display: none }

		.form-group + .input--range{ margin-top: $space-m }

		.link{
			font-weight: normal; font-size: $font-xs;
			& + *{ margin-left: $space-s }
		}
	}

	.input--range{
		color: $c-primary;
		.helper{
			position: absolute; top: -8px; right: 0; display: block; font-weight: bold; font-size: 90%;
		}
		&.input--seat{
			.helper:before{ content: 'Minimum : '}
		}
		label{ font-weight: bold}
	}

}

</style>