import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";
import eventBus from "../services/event-bus";

export default {

	get(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('appendix/' + id).then(response => {

				resolve(response.body.response);

			},reject);
		});
	},

	download(id) {

		let popup = false;

		if( document.documentElement.classList.contains('safari') )
			popup = window.open('/wait', '_blank');

		return new Promise((resolve, reject) => {

			let params = {};
			let url = 'appendix/'+ id + '/download';

			if( typeof id === 'object'){
				url = 'appendix/download';
				params = {params:{ids:id}};
			}

			Vue.http.get(url, params).then(response => {

				if( popup )
					popup.location.href = response.body.response.url;
				else
					window.open(response.body.response.url)

				resolve();

			}, response=>{

				reject(response);
			});
		});
	},

	list(params) {

		if( 'search' in params && params.search.length )
			return cache.list('appendix', params, 0);
		else
			return cache.list('appendix', params);
	},

	sync() {

		return new Promise((resolve, reject) => {

			store.commit('backgroundTask', {type:'appendix', state:true});

			Vue.http.post('appendix').then(response=>{

				store.commit('backgroundTask', {type:'appendix', state:false});

				if( response.body.response.count )
					eventBus.$emit('update');

				resolve();

			},response=>{

				store.commit('backgroundTask', {type:'appendix', state:false});
				reject();
			})
		});
	},

	regenerateCertificates(user, force) {

		return new Promise((resolve, reject) => {

			if( typeof force == 'undefined' )
				force = false;

			if( !user || !user.isCommercialAgent || ( user.isRegistering && !force ) ){

				resolve()
				return;
			}

			store.commit('backgroundTask', {type:'certificates', state:true});

			Vue.http.post('appendix/certificates').then(response=>{

				if( response.body.response.count )
					eventBus.$emit('update');

				store.commit('backgroundTask', {type:'certificates', state:false});
				resolve();

			},response=>{

				store.commit('backgroundTask', {type:'certificates', state:false});
				reject();
			})
		});
	},
};
