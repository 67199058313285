<template>
<div class="m-quizz-resume">
  <h1 class="title-big">Encore un petit effort !</h1>
  <p>Vous n'avez pas obtenu la moyenne nécessaire (50%) pour valider votre formation.</p>
  <p class="m-quizz-resume__global-result">Votre moyenne est de {{this.data.globalNote}}%</p>
  <p v-if="data.quizzResults">Vous devez recommencer un ou plusieurs quiz pour améliorer votre moyenne et enfin valider votre formation.</p>
  <p v-else="data.quizzResults">Vous devez recommencer le quiz pour améliorer votre moyenne et enfin valider votre formation.</p>

    <ul v-if="data.quizzResults">
      <li v-for="(result, index) in quizResults" :key="index" :class="quizClass(result)">
        Quiz {{ index + 1 }} : {{ result.note }}%
      </li>
    </ul>

  <p>Courage, vous y êtes presque !</p>

  <a @click="close" class="button o-infos__cta-archives">{{ !!data.quizResults ? "Retourner à la formation" : "Retourner au quiz"}}</a>

</div>
</template>

<script>

	export default {
		name: "quizz-popin",
    props:['data'],
    methods: {
      close() {
        if(this.data.onClose)
          this.data.onClose();
        this.$emit('close');
      },
      quizClass(result) {
        return {
          'is-success': result.note > 50,
          'is-fail': result.note <= 50
        };
      },
    },
    computed: {
      quizResults() {
        return Object.values(this.data.quizzResults);
      },
    }
	}
</script>

<style lang="scss">
	@import '../../environment';
  .m-quizz-resume {
    width: 50rem;

    @media #{$to-tablet} {
    width: 100%;
  }

    p + p{ margin-top: $space-m }

    &__global-result {
      color: var(--c-primary);
      font-weight: bold;
    }

    ul { padding-top: $space-m; padding-bottom: $space-m; text-align: left; width: fit-content; margin: auto; }

    .is-success {
      color: green;
    }

    .is-fail {
      color: red;
    }
  }
</style>
