<template>
  <div
    class="o-cart block"
    :class="{ 'block--notitle': !data.title }"
    v-if="
      data &&
      data.status === 'confirmed' &&
      (current_time < data.startAt || data.format === 'e-learning')
    "
  >
    <h2 class="title" v-if="data.title">{{ data.title }}</h2>
    <div class="equal-padding">
      <div class="o-cart__price" v-if="data.formation.price">
        <div class="subtitle">Prix par personne</div>
        <p>
          <b>{{ formatPrice(data.formation.price) }} HT</b>
        </p>
      </div>
      <div>
        <div v-if="data.format !== 'e-learning' && data.registerUntil">
          <div class="subtitle">Date limite de souscription</div>
          <div class="primary">{{ formatDate(data.registerUntil) }}</div>
        </div>

        <div
          v-if="
            user.isLegalRepresentative ||
            user.isCommercialAgent ||
            user.isStudent ||
            checkPermission('ROLE_FORMATION_SUBSCRIBE_WRITE', user, false)
          "
        >
          <div class="o-cart__participants">
            <div
              v-if="data.remainingPlaces > 0 || data.format === 'e-learning'"
            >
              <div v-if="user.isLegalRepresentative">
                <div
                  class="o-cart__item"
                  v-for="(contact, index) in cart.contacts"
                  :key="index"
                >
                  <div class="field-select">
                    <div class="field-wrap">
                      <select v-model="cart.contacts[index]">
                        <option value="" disabled selected>
                          Sélectionner un collaborateur
                        </option>
                        <option
                          value="all"
                          v-if="
                            (cart.contacts.length <= data.remainingPlaces &&
                              user.staff.length <= data.remainingPlaces) ||
                            data.format === 'e-learning'
                          "
                        >
                          ➜ Ajouter tous les collaborateurs
                        </option>
                        <option
                          v-if="
                            cart.contacts.length <= data.remainingPlaces ||
                            data.format === 'e-learning'
                          "
                          disabled
                        ></option>
                        <option
                          v-for="employee in user.staff"
                          :value="employee.id"
                          v-if="
                            cart.contacts.indexOf(employee.id) === -1 ||
                            cart.contacts[index] === employee.id
                          "
                          :disabled="isDisabled(employee)"
                          :title="getTitle(employee)"
                        >
                          {{
                            (data.participants.indexOf(employee.id) > -1
                              ? "✔ "
                              : isDisabled(employee)
                              ? "⚠ "
                              : "") +
                            employee.firstname +
                            " " +
                            employee.lastname
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    @click="deleteParticipant(contact)"
                    class="o-cart__remove icon-flex"
                    data-icon="close"
                    v-if="cart.contacts.length > 1"
                  ></div>
                </div>
                <div
                  @click="addParticipant()"
                  class="o-cart__add"
                  v-if="
                    (cart.contacts.length <= data.remainingPlaces ||
                      data.format === 'e-learning') &&
                    this.cart.contacts.length < validEmployees().length
                  "
                >
                  + ajouter un autre collaborateur
                </div>
              </div>
              <div
                v-else-if="data.participants.indexOf(user.contact.id) > -1"
                class="error"
              >
                Vous avez déja participé à cette formation
              </div>
            </div>
            <div
              v-else-if="
                checkPermission('ROLE_FORMATION_ALERT_AVAILABLE', user)
              "
            >
              <p class="error">
                Nous sommes désolés, il ne reste aucune place disponible pour
                cette formation.
              </p>
              <div class="bottom-right">
                <button
                  class="button button--success"
                  v-if="data.alert || alerted"
                >
                  Alerté si une place se libère
                </button>
                <button
                  class="button button--secondary"
                  @click="popinAlert"
                  v-else-if="user.contact"
                >
                  Etre alerté si une place se libère
                </button>
              </div>
            </div>
          </div>

          <div class="o-cart__line" v-if="subtotal">
            <span>Sous total HT</span>
            <span>{{ formatPrice(subtotal) }}</span>
          </div>
          <div class="o-cart__line" v-if="tva && total">
            <span>TVA ({{ data.taxRate * 100 }}%)</span>
            <span>{{ formatPrice(tva) }}</span>
          </div>
          <div class="o-cart__line o-cart__line--total" v-if="total !== false">
            <span>TOTAL TTC</span>
            <span v-if="total">{{ formatPrice(total) }}</span>
            <span v-else>Gratuit</span>
          </div>

          <div
            class="o-cart__cgu"
            v-if="
              config.option.cgu_vhsbs &&
              (data.remainingPlaces > 0 || data.format === 'e-learning')
            "
          >
            <label class="input--checkbox text">
              <input type="checkbox" name="cgu" v-model="cgu" />
              <span class="checkmark" data-icon="check"></span>
              <span class="o-cart__cgu-label">
                Avant d'accéder au paiement, je reconnais prendre connaissance
                et enregistrer sur un support durable les
                <a :href="config.option.cgu_vhsbs.value" target="_blank"
                  >Conditions Générales d'Utilisation</a
                >, et accepte que le présent évènement n'est pas remboursable en
                cas d'annulation.
              </span>
            </label>
          </div>

          <div
            class="bottom-right"
            v-if="data.remainingPlaces > 0 || data.format === 'e-learning'"
          >
            <form @submit.prevent="subscribe" id="formation_subscribe">
              <submit
                :disabled="cannotOrder || total === false || !cgu"
                text="Souscrire"
                :loading="loading"
              ></submit>
            </form>
          </div>
        </div>
        <div
          v-else-if="checkPermission('ROLE_FORMATION_ALERT_INTEREST', user)"
          class="o-cart__interest"
        >
          <p class="error">
            En tant que collaborateur, vous ne pouvez pas souscrire à une
            formation. <br />
            Toutefois, nous vous invitons à signaler votre intérêt pour cette
            formation à votre représentant légal !
          </p>
          <div class="bottom-right">
            <button class="button button--success" v-if="data.alert || alerted">
              Votre représentant légal a été alerté
            </button>
            <button
              class="button button--secondary"
              @click="popinInterest"
              v-else-if="user.contact"
            >
              Cette formation m'intéresse
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderRepository from "@/repositories/orderRepository";

export default {
  name: "cart",
  props: {
    data: {
      default: false,
    },
  },
  data() {
    return {
      cart: {
        orderId: false,
        type: "formation",
        contacts: [""],
        productId: false,
      },
      loading: false,
      alerted: false,
      cgu: false,
    };
  },
  methods: {
    isDisabled(employee) {
      return (
        !employee.isValid ||
        this.data.participants.indexOf(employee.id) > -1 ||
        (!employee.hasAccount && this.user.canCreateAccount)
      );
    },
    getTitle(employee) {
      if (this.data.participants.indexOf(employee.id) > -1)
        return "Ce collaborateur est deja inscrit à cette formation";
      else if (!employee.hasAccount && this.user.canCreateAccount)
        return "Ce collaborateur n'a pas activé son compte, rendez-vous dans votre compte rubrique collaborateurs pour l'inviter";
      else if (!employee.isValid)
        return "Rendez-vous dans votre profil pour voir les erreurs liées à ce collaborateur";
      return "";
    },
    addParticipant() {
      if (
        (this.cart.contacts.length < this.data.remainingPlaces ||
          this.data.format === "e-learning") &&
        this.cart.contacts.length < this.user.staff.length
      )
        this.cart.contacts.push("");
    },
    validEmployees() {
      let self = this;
      if (this.user.isLegalRepresentative)
        return this.user.staff.filter(function (contact) {
          return (
            contact.isValid && self.data.participants.indexOf(contact.id) === -1
          );
        });
      else return [this.user.contact];
    },
    deleteParticipant(id) {
      this.cart.contacts.splice(this.cart.contacts.indexOf(id), 1);
    },
    subscribe() {
      this.loading = true;

      orderRepository.create(this.cart).then(
        (id) => {
          this.cart.orderId = id;
          this.$store.commit("cart", this.cart);
          this.$router.push({ name: "order", params: { id: id } });
        },
        (response) => {
          this.loading = false;
        }
      );
    },
    getStoredCart() {
      let cart = orderRepository.getCart(this.cart);

      if (cart) this.cart = cart;
    },
    popinAlert() {
      this.$popin("alert-remaining-places", { id: this.data.id });
    },
    popinInterest() {
      this.$popin("interest", { id: this.data.id });
    },
    alert() {
      this.alerted = true;
    },
  },
  watch: {
    data(newVal) {
      this.cart.productId = newVal.id;
      this.getStoredCart();
    },
    "cart.contacts"(newVal) {
      if (newVal.indexOf("all") > -1) {
        let contacts = [];
        let validEmployees = this.validEmployees();
        validEmployees.forEach(function (employee) {
          contacts.push(employee.id);
        });
        this.cart.contacts = contacts;
      }
    },
  },
  computed: {
    current_time() {
      return this.$currentTime();
    },
    subtotal() {
      return this.cart.contacts.length >= 1 && this.cart.contacts[0] !== ""
        ? this.data.formation.price * this.cart.contacts.length
        : false;
    },
    tva() {
      return this.subtotal === false
        ? false
        : this.subtotal * this.data.taxRate;
    },
    total() {
      return this.subtotal === false ? false : this.subtotal + this.tva;
    },
    user() {
      return this.$user();
    },
    config() {
      return this.$config();
    },
    cannotOrder() {
      return (
        !this.total ||
        !this.cgu ||
        ((this.user.isCommercialAgent ||
          this.user.isStudent ||
          this.user.isCollaborator) &&
          this.data.participants.indexOf(this.user.contact.id) > -1)
      );
    },
  },
  mounted() {
    if (
      this.user.isCommercialAgent ||
      this.user.isStudent ||
      this.user.isCollaborator
    )
      this.cart.contacts = [this.user.contact.id];

    if (this.data) {
      this.cart.productId = this.data.id;
      this.getStoredCart();
    }

    this.$receive("alerted", this.alert);

    if (
      "action" in this.$route.params &&
      this.$route.params.action == "register"
    ) {
      this.cgu = true;
      this.subscribe();
    }
  },
};
</script>

<style lang="scss">
@import "../../environment";

.o-cart {
  &__participants {
    margin-top: $space;
    select {
      width: 100%;
      padding: 0.3rem $space-m 0.4rem $space-s;
    }
    & + * {
      margin-top: $space;
    }

    .error + * {
      margin-top: $space;
    }
  }
  &__item {
    display: flex;
    .field-select {
      flex: 1;
    }
    & + & {
      margin-top: 0.5rem;
    }
  }
  &__remove {
    justify-content: center;
    color: $c-primary;
    margin-left: $space-m;
    cursor: pointer;
  }
  &__add {
    margin-top: 0.5rem;
    text-align: right;
    font-size: $font-s;
    color: $c-primary;
    cursor: pointer;
  }
  .subtitle + * {
    margin-top: 1rem;
  }
  &__price {
    b {
      font-size: 2.4rem;
      color: $c-primary;
    }
    & + * {
      margin-top: $space;
    }
  }
  &__line {
    display: flex;
    text-transform: uppercase;
    justify-content: space-between;
    & + & {
      margin-top: $space-s;
    }

    &--total {
      color: $c-primary;
      font-size: $font-xxl;
      font-weight: bold;
      & + * {
        margin-top: $space;
      }
    }
  }
  &__cgu {
    font-size: $font-xs;
    .input--checkbox {
      display: flex;
    }
    &-label {
      flex: 1;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__interest {
    margin-top: $space-m;
    & > * + * {
      margin-top: $space-m;
    }
  }
}
</style>
