<template>

	<div class="m-page">
		<div class="m-page__content">
      <router-link :to="'/edito/'+data.link" class="m-page__link">
        <h2 class="m-page__title title" v-if="data.title">{{ data.title }}</h2>
      </router-link>
      <div class="m-page__description" v-if="data.description" v-html="data.description"></div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'page-excerpt',
    props:['data']
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-page{
		$c: &;

		position: relative;

    & + &{
      margin-top: $space-m;
    }

    &__description{ color: $c-primary; margin-top: $space-s }

		&__featured{ color: $c-secondary; font-size: $font-xs; font-weight: bold; margin-bottom: $space-xs;}

		&__thumbnail{
			border-radius: $border-radius $border-radius 0 0; overflow: hidden; padding-top: 35%; position: relative; margin: -$space-m; margin-bottom: $space-m;
			img{ position: overlay(); object-fit: cover; height: 100%; width: 100% }
      a{ cursor: pointer }
			& + *{ margin-top: $space-s }
		}
		&__sup{
			color: $c-primary; font-size: $font-xs; font-weight: bold;
			& + *{ margin-top: $space-s }
		}

		&__link{cursor: pointer }

		.title{ color: $c-text; text-transform: unset }
		&__content{ position:relative }

		&__pin{ position: absolute; top : -2px; right: 0; color: $c-primary; z-index: 1 }
	}
</style>
