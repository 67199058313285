<template>
	<div class="m-modify-software">
		<h1 class="title">Modifier mon logiciel</h1>
		<p>Je change de logiciel et utiliserait désormais : </p>
		<form @submit.prevent="updateSoftware"  id="change-password">
			<vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions" class="m-contact-add__form" v-if="loaded"></vue-form-generator>
			<p class="error" v-if="required">Veuillez remplir un des deux champs</p>
			<div class="bottom-right">
				<submit text="Mettre à jour le logiciel" :loading="loading" :success="success" text-success="Logiciel modifié"></submit>
			</div>
		</form>
	</div>
</template>

<script>

import companyRepository from "@/repositories/companyRepository";

export default {
	name: "modify-software",
	data(){
		return{
			loading: false,
			success: false,
			loaded: false,
			required: false,
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
			model:{
				software: this.data.software,
				name: ''
			},
			form: {}
		}
	},
	props:['data'],
	methods:{
		close(){
			this.$emit('close')
		},
		updateSoftware(){
			this.required = false;
			let valid = this.model.name !== '' || (this.model.software !== '' && this.model.software)

			if(!valid){
				this.required = true;
				return false;
			}

			this.loading= true;

			let params = this.model.name.length > 0 ? { customSoftware: this.model.name } : { software: this.model.software }

			companyRepository.updateSoftware(params).then(response=>{
				this.loading = false;
				this.success = true;
				this.$update();
				setTimeout(this.close, 1500);
			})
		}
	},
	mounted(){
		companyRepository.getSoftware().then(response => {
			let items = Object.values(response);
			this.form.fields= [
				{
					type: "select",
					model: "software",
					styleClasses:"fields-label",
					label: "Logiciel",
					selectOptions:{
						noneSelectedText: "---"
					},
					values: items,
					disabled(model) {
						return model && model.display_custom === true;
					}
				},
				{
					type: "checkbox-custom",
					label: "Mon logiciel n'est pas listé",
					model: "display_custom",
					default: false,
					styleClasses: "fields-label"
				},
				{
					type: "input",
					inputType: "text",
					model: "name",
					label: "Nom du logiciel",
					placeholder: "Nom",
					styleClasses: "fields-label",
					visible(model) {
						return model && model.display_custom === true;
					}
				}
			]
			this.loaded = true
		})

	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-alerte-remaining-places{
	color: $c-primary;
	p{ margin-top: $space }
}
</style>
