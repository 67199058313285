<template>
	<div class="m-interest">
		<h1 class="title">Cette formation m'intéresse</h1>
		<p>En confirmant votre intérêt pour cette formation, un mail automatique sera envoyé à votre représentant légal pour l'en avertir. </p>

		<div class="bottom-center">
			<form @submit.prevent="validate">
				<button class="button button--white" @click.prevent="close">Annuler</button>
				<submit text="Confirmer" :loading="loading" :success="success" text-success="Intérêt confirmé" ></submit>
			</form>
		</div>
	</div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
	name: "interest",
	data(){
		return{
			loading: false,
			success: false
		}
	},
	props:['data'],
	methods:{
		close(){
			this.$emit('close')
		},
		validate(){

			this.loading= true;

			formationCourseRepository.alert(this.data.id).then(response=> {

				this.$broadcast('alerted');
				this.loading = false;
				this.success = true;
				setTimeout(this.close, 1500);
			})
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-interest{
	color: $c-primary;
	p{ margin-top: $space }
}
</style>