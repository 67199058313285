<template>
  <clap-connect class="o-clap-popin"></clap-connect>
</template>

<script>

	export default {
		name: "clap-popin",
    		data(){
			return{
        hasToConnect: true,
			}
		},
	}
</script>

<style lang="scss">
	@import '../../environment';
  .o-clap-popin {
    width: 100%;
    max-width: 45rem;
    .o-signatures-clap__title {
      display: none;
    }
    img {
      background: var(--c-background);
    }
    .clap-icons {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      align-items: center;
        img {
          width: 10%;
          background: var(--c-primary);;
          padding: 0.6rem 0.5rem;
          border-radius: 4px;
        }
        span {
          color: var(--c-primary);
          font-weight: bold;
        }
    }
    .o-signatures-clap__div_container_clap-connected {
      width: 100%;
    }
}
</style>
