<template>
	<div class="a-progress-bar"></div>
</template>

<script>
	export default {
		name: 'progress-bar',
		props: ['tasks']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-progress-bar {
		position: fixed; top:0; width: 100%; background-color: $c-secondary; display: flex; z-index: 9999;
		&:before {
			height: 3px; width: 100%; margin: 0; background-color: $c-primary; content: '';
			animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      .app-vhs &{ background: #fff }
		}
	}
	@keyframes running-progress {
		0% { margin-left: 0; margin-right: 100%; }
		50% { margin-left: 25%; margin-right: 0; }
		100% { margin-left: 100%; margin-right: 0; }
	}
</style>