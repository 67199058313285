<template>
	<div>
		<input
				class="form-control"
				type="checkbox"
				v-model="value"
				:disabled="schema.disabled"
				:autocomplete="schema.autocomplete"
				:id="getFieldID(schema)"
				:name="getFieldName(schema)"
				:class="schema.fieldClasses"
				:required="schema.required"
		/>
		<span class="checkbox-checkmark" data-icon="check"></span>
	</div>

</template>

<script>
	import { abstractField } from "vue-form-generator";

	export default {
		name: "field-checkbox-custom",
		mixins: [ abstractField ]
	};
</script>

