<template>
  <div class="o-international-partners container">
    <div class="o-text-image__col--text o-international-text-header">
      <h1 class="o-text-image__uptitle title title--small">
        {{ data.title }}
      </h1>
      <p class="wysiwyg">
        {{ data.description }}
      </p>
    </div>
    <div class="o-partners-carousel">
      <div class="o-logo-container" v-for="logo in data.logos">
        <div class="o-logo-container__logo">
        <img :src="logo.image" />
        </div>
        <p>{{ logo.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "logos",
  props: ["data"],
};
</script>

<style lang="scss">
@use "sass:math";
@import "../../environment";

.o-international-text-header {
  width: 50%;
  @media #{$to-tablet} {
    width: 90%;
  }
}

.o-international-partners {
  padding-top: $space-m;
  .o-partners-carousel {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-items: center;
    @media #{$to-tablet} {
      grid-template-columns: repeat(1, 1fr);
    }
    .o-logo-container {
      display: flex;
      background: white;
      border-radius: 10px;
      @media #{$to-tablet} {
        width: 90%;
        display: flex;
        flex-direction: column;
      }
      &__logo {
        width: 40%;
        padding: $space-m;
        align-self: center;
        display: flex;
        justify-content: center;
        @media #{$to-tablet} {
          width: 50%;
        }
        img {
          aspect-ratio: 1/1;
          object-fit: contain;
        }
      }
      p {
        display: flex;
        align-items: center;
        width: 100%;
        padding: $space-m $space-m $space-m 0;
        @media #{$to-tablet} {
        padding: 0 $space-m $space-m $space-m;
      }
      }
    }
  }
}
</style>
