<template>
	<div class="m-training-receipt">
		<h2 class="title">Récapitulatif d'achat(s)</h2>

		<loader v-if="loading" static="1"></loader>

		<div v-if="formationCourse">
			<div class="primary">
				<span v-if="formationCourse.format === 'e-learning'" class="m-training-receipt__dates">E-learning</span>
				<div v-else class="m-training-receipt__dates">
					<span v-html="formatShortDate(formationCourse.startAt)"></span>
					<span v-html="formatShortDate(formationCourse.endAt)" v-if="formationCourse.endAt && formationCourse.endAt !== formationCourse.startAt"></span>
				</div>
			</div>

			<h3 class="title-big">{{formationCourse.formation.title}}</h3>

			<div v-if="formationCourse.formation.objective" class="m-training-receipt__details">
				<p class="subtitle">Objectifs</p>
				<div class="wysiwyg" v-html="ntobr(formationCourse.formation.objective)"></div>
			</div>

			<div class="m-training-receipt__details" v-if="formationCourse.condition">
				<p class="subtitle">Conditions</p>
				<div class="secondary">{{formationCourse.condition}}</div>
			</div>

			<div class="m-training-receipt__details m-training-receipt__icons primary ">
        <span data-icon="timer" class="icon-flex">{{formationCourse.formation.duration.hours}}h</span>
				<span data-icon="pin" v-if="formationCourse.location && formationCourse.location.city" class="icon-flex">{{formationCourse.location.city}}</span>
				<form @submit.prevent="downloadProgram" v-if="formationCourse.formation.program" class="m-training-receipt__button">
					<submit class="button button--small" text="Télécharger le programme" data-icon_after="download" :loading="download"></submit>
				</form>
			</div>

			<div class="m-training-receipt__details m-training-receipt__date m-training-receipt__line" v-if="formationCourse.invoices && formationCourse.invoices.length > 1">
				<span class="subtitle">Dates d'achat</span>
				<select v-model="selected">
					<option v-for="(invoice,index) in formationCourse.invoices" :value="index" :key="index">{{invoice.date}}</option>
				</select>
			</div>

			<div class="m-training-receipt__details">
				<invoice :id="order.id" v-for="(invoice,index) in formationCourse.invoices" :key="index" v-if="formationCourse.invoices && (formationCourse.invoices.length===1 || selected===index)" :data="invoice"></invoice>
      </div>

			<training-participants :data="formationCourse.participants"></training-participants>
		</div>

	</div>

</template>

<script>

	import formationCourseRepository from '@/repositories/formationCourseRepository';
	import formationRepository from "@/repositories/formationRepository";
  import appendixRepository from "@/repositories/appendixRepository";

	export default {
		name: "training-receipt",
		data(){
			return{
				selected: 0,
				order: false,
				error: false,
				visible: 0,
				formationCourse: false,
				loading: true,
				download: false,
        downloading: false
			}
		},
		props:['data'],
    computed:{
      invoices(){
        return this.formationCourse ? this.formationCourse.invoices.filter(invoice => invoice.appendix).map(invoice => invoice.appendix) : [];
      }
    },
		mounted() {
      formationCourseRepository.getOrder(this.data).then(formation => {
				this.formationCourse = formation;
				this.loading = false
			})
		},
		methods:{
      downloadInvoices(){
        this.downloading = true;
        appendixRepository.download(this.invoices).then(
            response=>{
              this.downloading = false;
            },
            response=>{
              this.downloading = false;
            });
      },
			downloadProgram(){
				this.download = true;
				formationRepository.download(this.formationCourse.formation.id).then(response=>{ this.download = false }, response=>{ this.download = false });
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-training-receipt{

		width: 50rem; max-width: 100%; text-align: left;

		.title{ margin-bottom: 2rem }
		.title-big{ margin-top: $space-s }

		&__details{
			margin-top: $space-m;
			.wysiwyg{ margin-top: 1rem; line-height: 100% }
		}

		&__infos{
			color: $c-primary;

			& > * + * { margin-left: $space-m }
		}

		&__name, &__line{
			display: flex; justify-content: space-between;

			& + * { margin-top: 0.5rem }
		}

		&__date{
			align-items: baseline;
			select{ width: auto; padding-top: 3px; padding-bottom: 3px; padding-left: 5px; padding-right: 0 }
		}

		&__dates{
			span{ margin-right: 0.5rem; display: inline-block }
		}

		&__line{
			text-transform: uppercase;

			&--total{ font-size: $font-xl; color: $c-primary; font-weight: bold; margin-top: $space-m }
		}

		&__icons{
			display: flex; flex-wrap: wrap;
			& > *{
				margin-bottom: $space-s;
			 &	+ *{ margin-left: $space-m }
			}
		}

		&__icon{ font-size: $font-xxl}

		.m-training-participants{ margin-top: $space*1.5 }

		&__button{ margin-left: auto }
	}
</style>