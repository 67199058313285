<template>
	<div class="o-user-card block">
		<h2 class="title" v-if="interface.title">{{ interface.title }}</h2>
		<div class="primary o-user-card__content equal-padding" v-if="company && user">
			<h3 class="title-big o-user-card__title">Informations générales</h3>

			<div v-if="company.businessCard.kind">
				<h4 class="subtitle">Type de carte pro</h4>
				<p>{{ company.businessCard.kind.join(' - ') }}</p>
			</div>

			<div>
				<h4 class="subtitle">N° de carte pro</h4>
				<p>{{ company.businessCard.number }}</p>
			</div>

			<div>
				<h4 class="subtitle">Délivrée par</h4>
				<p>{{ company.businessCard.cci }}</p>
			</div>

			<div>
				<h4 class="subtitle">Période de validité</h4>
				<p>{{ formatDate(company.businessCard.issuedAt) }} - {{ formatDate(company.businessCard.expireAt) }}</p>
			</div>

			<div>
				<h3 class="title-big">Titulaire de la carte professionnelle</h3>
				<div class="o-user-card__holder">
					<p v-for="(legalRepresentative, index) in company.legalRepresentatives">
						{{legalRepresentative.firstname + " " + legalRepresentative.lastname}}
					</p>
				</div>
			</div>

			<p class="secondary" v-if="user.isLegalRepresentative">Si les informations ci-dessus sont incorrectes, merci d'envoyer le scan de votre carte professionnelle
			RECTO/VERSO à <a href="mailto:contact@snpi.fr" target="_blank">contact@snpi.fr</a></p>
		</div>
	</div>
</template>

<script>
	
	import companyRepository from "@/repositories/companyRepository";
	
	export default {
		name: "user-card",
		props:['data','interface'],
		data(){
			return{
				company: false
			}
		},
		computed:{
			user(){
				return this.$user()
			}
		},
		mounted() {
			companyRepository.get().then(company=>{
				this.company = company
			});
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-user-card{
		$c: &;
		&__content{
			& > * + *{ margin-top: $space}
		}
		&__holder{ margin-top: $space-s }
	}

</style>