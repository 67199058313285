<template>
	<div class="m-document-preview no-padding">
		<div class="m-document-preview__img">
			<img :src="data.thumbnail" :alt="data.title" />
		</div>
		<div class="m-document-preview__infos">
			<div><b class="primary">{{data.title}}</b></div>
			<div class="m-document-preview__date">Document publié le {{ formatDate(data.createdAt) }}</div>
			<div class="bottom-right">
				<a class="m-document-preview__download button" data-icon_after="download" @click="downloadAsset(0)" v-if="data.assets && data.assets.length === 1"> Télécharger</a>
				<div class="m-document-preview__download button" data-icon_after="download" v-else-if="data.assets && data.assets.length > 1"> Télécharger
					<dropdown>
						<ul class="m-document__dropdown">
							<li v-for="(file, index) in data.assets" :key="index">
								<div @click="downloadAsset(index)">{{ file.title }}</div>
							</li>
						</ul>
					</dropdown>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import documentRepository from '@/repositories/documentRepository';

	export default {
		name: "document-preview",
		props:['data'],
		methods:{
			downloadAsset(index){

				documentRepository.download(this.data.id, index);
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';

	.m-document-preview{
		$c: &;
		max-height: calc(90vh - 80px); overflow: hidden; overflow-y: auto;
		&__img{ background: $c-background-darker; border-top-left-radius: $border-radius; border-top-right-radius: $border-radius; padding: $space }
		&__infos{ padding: $space }
		&__date{ font-size: $font-xs; margin-top: $space-s }
	}
</style>