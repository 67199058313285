<template>
	<div class="a-contact-report" v-if="!hidden">
		<div class="m-contact-reports__infos">
			<div class="primary">
				<b>{{data.formation.duration.hours}}h</b>
			</div>
			<div>
				<div class="m-contact-reports__date">
					<span v-html="formatDate(data.startAt)" v-if="data.format !== 'e-learning'"></span>
					<span v-else>En ligne</span>
				</div>
				<b class="primary">{{data.formation.title}}</b>
			</div>
		</div>
		<div class="m-contact-reports__actions">
			<form @submit.prevent="downloadAsset(data.appendix)" id="report_download">
				<submit text="Télécharger" class="button--xsmall" :loading="download===data.appendix.id"></submit>
			</form>
			<form @submit.prevent="deleteFormation" id="report_delete-external" v-if="data.external">
				<submit secondary="1" class="button--xsmall" :loading="deleting" text="Supprimer" text-success="Supprimé" :success="deleted"></submit>
			</form>
		</div>
	</div>
</template>

<script>

import externalFormationRepository from "@/repositories/externalFormationRepository";
import appendixRepository from "@/repositories/appendixRepository";

export default {
	name: "contact-report",
	data(){
		return{
			deleting: false,
			deleted: false,
			hidden: false,
			download: false
		}
	},
	props:['data'],
	methods:{
		hide(){
			this.$emit('delete',this.data.id)
		},
		deleteFormation(){

			this.deleting = true;

			externalFormationRepository.delete(this.data.id).then(response=>{

				this.deleting = false;
				this.deleted = true;

				this.$update();
				setTimeout(this.hide, 600);

			}, response=>{

				this.deleting = false;
			})
		},
		downloadAsset(appendix) {

			this.download = appendix.id;

			if( appendix.entity === 'external-formation' )
				externalFormationRepository.download(appendix.id).then(response=>{ this.download = false }, response=>{ this.download = false });
			else
				appendixRepository.download(appendix.id).then(response=>{ this.download = false }, response=>{ this.download = false });
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.a-contact-report{
	display: flex; position: relative; align-items: center;
	.bottom-right{ margin-top: 0; z-index: 1 }
	.m-contact-reports__infos{ width: 100% }
	.m-contact-reports__actions{
		white-space: nowrap; margin-left: $space-s;
		form{ display: inline-block; margin-left: 0.5rem }
	}
}
</style>