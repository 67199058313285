<template>
	<div class="o-agencies">
		<h2 class="o-agencies__title title" v-if="interface.title">{{ interface.title }}</h2>
    <template v-for="(address, index) in companiesAddresses">
      <caci-company :address="address" :key="index" @delete="handleDelete"></caci-company>
    </template>
		<div class="bottom-right-inline">
			<button class="button" :class="{'button--disabled':!canAddCompany}" @click.prevent="addCompany">Ajouter une autre agence</button>
		</div>
	</div>
</template>

<script>
	import CaciCompany from "../molecule/caci-company";
	import contactRepository from "../../repositories/contactRepository";

	export default {
		name: "agencies",
		props:['data','interface'],
		components: {CaciCompany},
		data(){
			return{
				error: false,
        addresses: false
			}
		},
		methods: {
			addCompany() {
				this.user.contact.addresses.push({
					id: false,
					company:'',
					email:'',
					phone:'',
					isMain:'',
					isActive:true
				});
			},
      getCompaniesAddresses(){
        return this.user.contact.addresses.filter(address => {
          return !address.isHome
        }).sort((a, b) => b.isActive - a.isActive)
      },
      reload(){
        this.$forceUpdate()
      },
	  handleDelete(id){
      contactRepository.deleteAddress(id).then(()=>{
        const addressIndex = this.user.contact.addresses.findIndex((address)=>address.id==id)
        this.user.contact.addresses.splice(addressIndex, 1)
      })
    },
		},
		computed:{
			user(){ return this.$user() },
			companiesAddresses(){
				return this.user.contact.addresses.filter(address => {
					return !address.isHome
				})
			},
			canAddCompany() {
				const addresses = this.user.contact.addresses;
				const lastAddress = addresses[addresses.length - 1];
				return lastAddress && lastAddress.company && lastAddress.id;
			}

		},
    mounted() {
      this.addresses = this.getCompaniesAddresses();
    }
  }
</script>

<style lang="scss">
	@import '../../environment';
	.o-agencies{
		&__company, .a-caci-company{
			background: white; padding: $space!important; border-radius: 4px; position: relative;
			&+&{ margin-top: $space-m }

			&--create.inactive{
         opacity: 0.6
      }
		}
		.a-caci-company__background, .vue-form-generator .form-group-background{ padding: 0; background: none }
	}

</style>
