var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded && _vm.$route.name !== 'wait')?_c('div',{class:[
    'app-' +
      _vm.type +
      ' page page--' +
      (_vm.$route.name ? _vm.$route.name : _vm.$route.params.page) +
      (_vm.user ? ' user--' + _vm.user.type : ''),
    {
      'accessible-version':
        _vm.user && _vm.user.dashboard && _vm.user.dashboard.isAccessible,
      'has-podcast': _vm.podcast,
    } ],attrs:{"id":"app"}},[(_vm.requireUser)?_c('header-component'):_vm._e(),(
      _vm.requireUser &&
      _vm.$route.name !== 'buy' &&
      _vm.$route.name !== 'foad' &&
      _vm.$route.name !== 'positionnement' &&
      _vm.$route.name !== 'sondage'
    )?_c('navigation'):_vm._e(),_c('div',{staticClass:"view-container"},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),(_vm.requireUser && _vm.$route.name !== 'foad')?_c('footer-component'):_vm._e(),(_vm.alert)?_c('popin',{attrs:{"content":_vm.alert,"type":"alert"}}):_vm._e(),_vm._l((_vm.popins),function(popin){return (popin)?_c('popin',{attrs:{"content":popin,"type":"popin"}}):_vm._e()}),(_vm.error)?_c('popin',{attrs:{"content":_vm.error,"type":"error"}}):_vm._e(),(_vm.backgroundTask.length && _vm.$route.name !== 'login')?_c('progress-bar',{attrs:{"tasks":_vm.backgroundTask}}):_vm._e(),(_vm.podcast)?_c('player',{key:_vm.podcast.id,attrs:{"data":_vm.podcast}}):_vm._e(),(_vm.user)?_c('tour'):_vm._e()],2):_c('div',{attrs:{"id":"app"}},[(_vm.$route.name === 'maintenance')?_c('maintenance',{staticClass:"maintenance"},[_vm._v("Maintenance")]):_c('loader',{attrs:{"text":_vm.$route.name === 'wait' ? 'Merci de patienter' : 'Chargement en cours...'}}),(_vm.error)?_c('popin',{attrs:{"content":_vm.error,"type":"error"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }