<template>
	<div class="a-quizz-validation">
		<p class="primary">
      Vous devez valider votre quiz avant de pouvoir continuer votre formation.
		</p>

		<div class="bottom-between">
			<button class="button" @click="resume">Reprendre le quiz</button>
		</div>

	</div>
</template>

<script>

export default {
name: "quiz-validation",
	methods:{
		resume(){
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss">
@import '../../environment';

.a-quizz-validation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>