<template>
	<div class="a-report-progress">
		<div v-if="!data.valid" class="error">
			<router-link to="/user/collaborateurs">
				<span v-if="data.contact.isLegalRepresentative"><u>Cliquez ici</u> pour renseigner la date d'entrée dans l'entreprise</span>
				<span v-else><u>Cliquez ici</u> pour renseigner la date de délivrance de l'attestation d'habilitation</span>
			</router-link>
		</div>

		<div v-else-if="data.senority" class="a-report-progress__grid">
			<div>
				<div v-if="data.senority">Heures de formation obligatoires <span class="a-report-progress__link" data-icon="share" @click="filteredFormations()"></span> </div>
				<div v-else>Heures de formation</div>

				<div class="a-report-progress__quota">
					<div class="a-report-progress__completed" :style="{'width': ((data.quota < data.completed) ? 100 : (data.completed/data.quota)*100) +'%'}">
						<span class="a-report-progress__ratio" v-if="data.completed/data.quota > 0.1 || !data.senority">{{ data.completed }}h</span>
					</div>

				</div>
				<div v-if="data.senority" class="a-report-progress__left error">Restant: {{ (data.quota >= data.completed) ? data.quota - data.completed : 0 }}h</div>
			</div>

			<div>
				<span>Déontologie <span class="a-report-progress__link" data-icon="share" @click="filteredFormations('ethics')"></span></span>
				<div class="a-report-progress__quota">
					<div class="a-report-progress__completed" :style="{'width': (data.completedEthics < 2 ? ((data.completedEthics/2)*100) : 100) +'%'}">
						<span class="a-report-progress__ratio">{{data.completedEthics }}h</span>
					</div>
				</div>
				<div v-if="data.senority" class="a-report-progress__left error">Restant: {{ data.completedEthics < 2 ? 2 - data.completedEthics : 0 }}h</div>
			</div>

			<div>
				<span>Discriminations <span class="a-report-progress__link" data-icon="share" @click="filteredFormations('discrimination')"></span></span>
				<div class="a-report-progress__quota">
					<div class="a-report-progress__completed" :style="{'width': (data.completedDiscrimination < 2 ? ((data.completedDiscrimination/2)*100) : 100) +'%'}">
						<span class="a-report-progress__ratio">{{data.completedDiscrimination }}h</span>
					</div>
				</div>
				<div v-if="data.senority" class="a-report-progress__left error">Restant: {{ data.completedDiscrimination < 2 ? 2 - data.completedDiscrimination : 0 }}h</div>
			</div>
		</div>
	</div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
	name: "report-progress",
	props:['data'],
	methods:{
		filteredFormations(type){
			let params = {
				sort: 'startAt',
				seat: '',
				order: 'asc',
				limit : 20,
				offset: 0,
				search: '',
				format: [],
				location: '',
				distance: '',
				startAt: '',
				endAt: '',
				duration: ''
			};

			if(type){
				params.ethics = type === 'ethics' ? 1 : 0;
				params.discrimination = type === 'discrimination' ? 1 : 0;
			}

			this.$store.commit('form', {type:'formations', params: params});
			this.$router.push('formations')
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.a-report-progress{
	$quota-radius: 5px;
	$quota-height: 1.8rem;

	font-size: $font-xs; color: $c-primary; margin-top: $space-s;

	&__grid{
		@media #{$from-small}{
			display: grid; grid-gap:1px; align-items: end;
			grid-template-columns: 1fr 1fr;
			& > div:first-of-type{ grid-column: span 2 }
		}
		@media screen and (min-width: 1280px) {
			grid-template-columns: 2.5fr 1fr 1fr;
			& > div:first-of-type{ grid-column: auto }
		}
		&:hover .a-report-progress__link{ opacity: 1 }
	}

	b{ font-size: $font-l }

	& + *{ margin-top: $space }
	&__quota{ margin-top: 0.5rem; position: relative; background: $c-background; border-radius: $quota-radius; height: $quota-height }
	&__completed{ position:absolute; left: 0; top: 0; bottom: 0; border-radius: $quota-radius; background: $c-primary; overflow: hidden; display: flex; align-items: center; justify-content: center }

	&__left{ margin-top: 0.5rem }

	&__ratio{ color: white; opacity: 0.8 }

	&__link{ font-size: 1.6rem; margin-left: 0.5rem; cursor: pointer; opacity: 0; transition: allow(opacity) }
}

</style>