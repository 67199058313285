<template>
	<div class="o-passerelle">
		<h2 class="title">{{interface.title}}</h2>
		<div class="equal-padding" v-if="company">
			<div v-if="!company.software" class="o-passerelle__empty">
				<p class="title-big icon-flex" data-icon="warning">Vous n'utilisez aucun logiciel de transaction.</p>
				<p>Pour gérer vos annonces immobilières, vous pouvez utiliser gratuitement SNPI tech (logiciel inclus avec votre cotisation syndicale).</p>
				<div class="bottom-center">
					<router-link to="activate" class="button button--secondary" v-if="hasRole(user,'company')">Activer mon logiciel de transaction</router-link>
					<a @click="popinSoftware" v-if="user.isLegalRepresentative" class="button button--border">Ajouter mon logiciel</a>
				</div>
      </div>

      <div v-else class="primary">

        <h3 class="title-big o-passerelle__title">
          <span v-if="company.software === 'SNPI ACCESS'">Vous utilisez un logiciel non listé</span>
          <span v-else>
            Vous utilisez le logiciel &laquo; {{company.software}} &raquo;
          </span>
        </h3>
        <div class="o-passerelle__actions" v-if="company.software || company.realEstate.url">
          <div>
            <h4 class="title-med">Paramétrez vos annonces</h4>
          <a :href="company.realEstate.url_apimo" target="_blank" v-if="['apimo / snpi tech', 'apimo'].includes(company.software.toLowerCase()) && company.realEstate.url_apimo && company.realEstate.url_apimo.length > 8" class="button button--secondary">Gérer mes annonces</a>
            <a @click="popinSoftware" v-if="company.software && user.isLegalRepresentative" class="button button--border">Modifier mon logiciel</a>

          </div>
          <div>
            <h4 class="title-med">&nbsp;</h4>
            <a v-if="apimoUrl" :href="apimoUrl" target="_blank" class="button">Voir ma fiche agence</a>
          </div>
        </div>
			</div>
		</div>
		<loader :static="true" v-if="loading"></loader>
	</div>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";

	export default {
		name: "passerelle",
		props:['data','interface'],
		data(){
			return{
				company:false,
				loading: true
			}
		},
		computed:{
			user(){
				return this.$user()
			},
      apimoUrl(){
        if(this.company.apimoId)
          return "https://snpi.immo/fr/agence/" + this.company.apimoId;
        else
          return false
      }
		},
		methods:{
			popinSoftware(){
				this.$popin('modify-software', {software:this.company.software})
			},
			get(){
				companyRepository.get({realEstate:true}).then(company=>{
					this.company = company
					this.loading = false
				});
			}
		},
		mounted() {
			this.get();
			this.$update(this.get);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';

	.o-passerelle{

		.a-loader{ min-height: 15rem; background: #fff!important; }
    .equal-padding{ min-height: 15rem}
    .title-med { margin-bottom: $space-s}

		&__empty{
			text-align: center;
			.bottom-center { margin-top: 4rem }
			[data-icon]:before{ color: $c-secondary }
		}

		&__title{
			& + p{ margin-top: $space-s }
		}


		p + &__title{ margin-top: $space }

    &__actions{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: $space;
			@media #{$to-phone}{ margin-bottom: $space-m }
		}
	}

</style>
