<template>
	<div class="m-pending-orders">
		<div  v-if="step===1">
			<div class="title error">Attention</div>
			<p class="primary">Des commandes n’ont pas été enregistrées correctement sur votre compte, même si les paiements ont bien été validés. Pour y remédier, merci de relancer l’enregistrement de ces commandes. </p>

			<div class="bottom-center">
				<form @submit.prevent="updatePending">
					<submit :loading="loading" text="Relancer" :success="validated" text-success="Validée" :error="error" :text-error="error" :progress="progress" :disabled="disabled"></submit>
				</form>
			</div>
		</div>

		<div class="m-pending-orders__result" v-else>
				<div class="title"  v-if="validated">Merci</div>
				<div class="title error"  v-else>Attention</div>
				<p class="primary" v-if="validated">Vos dernières commandes ont bien été enregistrées</p>
				<p class="primary" v-else-if="error">{{ error }}</p>
				<p class="primary" v-else>Une erreur est survenue. Veuillez nous excuser pour le désagrement.</p>
			<div class="bottom-center">
				<button class="button" @click.prevent="close">Fermer</button>
			</div>
		</div>


	</div>
</template>

<script>
import orderRepository from "@/repositories/orderRepository";

export default {
	name: "pending-orders",
	data(){
		return{
			loading: false,
			validated: false,
			error: false,
			errors:[],
			progress: 0,
			disabled: false,
			executed: 0,
			step: 1
		}
	},
	computed:{
		user(){
			return this.$user()
		}
	},
	methods:{
		close(){
			this.$emit('close')
		},
		execute(pending){

			if(pending.length > 0){

				this.$catchError(false);

				orderRepository.execute(pending[0]).then(response=> {
							this.continue(pending)
						},
						response=>{
							this.errors.push(pending[0])
							this.continue(pending)
						})

			} else {

				this.loading = false;
				this.validated = this.errors.length === 0 ? 'Commandes enregistées' : false
				this.error = this.errors.length > 0 ? 'Une erreur est survenue sur une des commandes : #' + this.errors.join(', #') : false
				this.disabled = this.errors.length > 0
				this.step = 2;
			}
		},
		continue(pending){
			let next = pending.filter(item => item !== pending[0]);
			this.executed++;
			this.progress = this.executed/this.user.pendingOrders.length
			this.execute(next)
		},
		updatePending(){
			this.loading = true;
			this.execute(this.user.pendingOrders);
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-pending-orders{
	@media #{$from-tablet}{ min-width: 50rem; }
	.title{ margin-bottom: 3rem }

	&__result > p{ margin: $space }
}
</style>