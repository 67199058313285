import Vue from "vue";

export default {

	getSurvey(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/participant/'+id+'/survey').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	sendSurvey(id, data) {

		return new Promise((resolve, reject) => {

			Vue.http.post('formation/participant/'+id+'/survey', data).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	terminate(id) {

		return new Promise((resolve, reject) => {

			Vue.http.post('formation/participant/'+id+'/terminate').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	getPoll(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/participant/'+id+'/poll').then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	sendPoll(id, data) {

		return new Promise((resolve, reject) => {

			Vue.http.post('formation/participant/'+id+'/poll', data).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	getCode(id, data) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/participant/'+id+'/code', data).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	checkCode(id, otp) {

		return new Promise((resolve, reject) => {

			Vue.http.post('formation/participant/'+id+'/code', {otp:otp}).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	},

	download(id) {

		return new Promise((resolve, reject) => {

			Vue.http.get('formation/participant/'+id+'/download').then(response => {

				document.location.href = response.body.response.url+'?disposition=attachment';

				resolve();

			}, response=>{

				reject(response);
			});
		});
	}
};
