<template>
	<div class="a-idle">
		<img src="/img/paused-foad.svg" width="298" height="266" alt="Formation en pause" />
		<p class="title-med">Formation en pause</p>
		<p class="primary">
			Cet onglet est inactif depuis <span v-if="elapsed_time < 1">moins d'1 minute</span><span v-else>{{ elapsed_time }}</span> <span v-if="elapsed_time === 1">minute</span><span v-else-if="elapsed_time > 1">minutes</span>
		</p>

		<div class="bottom-between">
			<button class="button button--white" @click="save">Finir plus tard</button>
			<button class="button" @click="resume">Reprendre ma formation</button>
		</div>

	</div>
</template>

<script>

export default {
name: "idle",
	data(){
		return{
			elapsed_time: false
		}
	},
	methods:{
		resume(){
			this.$store.commit('pausedFoad', false)
			this.$emit('close')
		},
		save(){
		  this.resume()
      this.$router.push('/formations/formations-souscrites');
		}
	},
	mounted(){
		let started_at = Date.now();
		this.timer = setInterval(() => {
			this.elapsed_time = new Date(Date.now() - started_at).getMinutes()
		}, 500);
	}
}
</script>

<style lang="scss">
@import '../../environment';
	.a-idle{
		text-align: center;

		img{ margin-left: auto; margin-right: auto }
		img  + *{ margin-top: $space }

		.bottom-between{ margin-top: $space }
		p + p{ margin-top: $space-m  }

		button{ flex: 1 }
	}
</style>