<template>
	<div :class="schema.styleClasses">
		<div v-if="platform==='mobile' || platform==='tablet'">
			<select  v-model="selected" multiple v-if="options">
				<option v-for="(option, index) in options">{{ option.name ? option.name : option }}</option>
			</select>
		</div>
		<div v-else>
			<multiselect v-if="options" v-model="selected" :options="options" :multiple="schema.multiple" :placeholder="schema.placeholder" :close-on-select="false" :clear-on-select="false" :preserve-search="true" label="name" track-by="name" :searchable="true" :showLabels="false" :taggable="true" tag-placeholder="Ajouter une adresse email" @tag="addTag" @input="updateSelected">
				<template slot="option" slot-scope="props">
					{{ props.option.name ? props.option.name : props.option.label }}
					<span class="multiselect__check" data-icon="check"></span>
				</template>
			</multiselect>
		</div>
	</div>

</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
	name: "field-multiselect-emails",
	mixins: [ abstractField ],
	data () {
		return {
			selected: [],
			selectedSingle: '',
			platform: window.platform
		}
	},
	methods:{
		addTag (newTag) {
			const tag = {
				name: newTag,
				id: newTag
			}
			this.options.push(tag)
			this.value.push(tag)
		},
		updateSelected(value) {
			if(typeof value === 'object')
				this.value = value.map(item => item.id)
			else
				this.value = value
		},
		formatString(value){
			if(value.length > 15){
				value = value.substr(0,15) + '...'
			}
			return value
		}
	},
	computed: {
		options() {
			let values = this.schema.values;
			if (typeof values == "function") {
				return values.apply(this, [this.model, this.schema]);
			} else {
				return values;
			}
		}
	}
};
</script>


<style lang="scss">
@import '../../../environment';
.field-multiselect-emails{
	.multiselect{
		$c: &;
		color: $c-primary; min-height: auto;

		&__tags{
			font-size: $font-m; border: solid 1px $c-background-darker; background: $c-background-darker; border-radius: $border-radius; display: block; min-height: auto;
			cursor: pointer; padding: 0;

			#{$c}--active &{ border-radius: #{$border-radius*2} !important;  }
		}
		&__select{ display: none }
		&__single,&__placeholder{ margin: 0; padding: $space-s 1.5rem; height: 100%; line-height: 1; min-height: auto; font-size: $font-m  }
		&__single{ color: $c-primary; background: $c-background-darker }
		&__placeholder{
			color: $c-primary;
			#{$c}--active &{ display: block }
		}

		&__content-wrapper{ width: 100%; border: solid 1px $c-background-darker; margin-top: $space-s; border-radius: $border-radius }
		&__option{
			padding-right: $space-l; font-size: $font-m;
			&--highlight,&--selected#{$c}__option--highlight{ background: $c-background; color: $c-primary }
			#{$c}__check{ visibility: hidden; position: absolute; right: $space-s }
			&--selected{
				background: none; color: $c-primary; font-weight: normal;
				#{$c}__check{ visibility: visible }
			}

			&:after{ background: $c-background; left: 0; right: 0 }

			&--highlight{
				&:after{ color: $c-primary }
			}
		}

		&__tag{
			background-color: transparent; border: solid 1px $c-primary; color: $c-primary; margin-top: 5px;
			&-icon:hover{ background: $c-primary }
		}

		&__tags-wrap{ display: inline-flex; padding: 0.1rem 0.5rem }
	}

	@media #{$to-tablet}{
		select{ height: auto }
	}
}
</style>