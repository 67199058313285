<template>
	<div class="primary">

		<h3>
			<b>
				Signer mon bulletin
				<span v-if="subStep<3">d'assurance Asseris<span v-if="!registered"> (1/2)</span></span>
				<span v-else>d'adhésion SNPI<span v-if="!registered"> (2/2)</span></span>
			</b>
		</h3>
		<div class="m-caci-register__background">
			<p class="m-caci-register__instruction">
				<b>
					Après avoir cliqué sur le bouton suivant, vous recevrez un SMS contenant votre code de sécurité au :
					<span class="secondary" v-if="user">{{ user.contact.phone }}</span>
				</b>
			</p>
			<div class="bottom-center">
				<button @click="updatePhone" class="button button--white" v-if="subStep===1||subStep===2">Modifier le numéro de téléphone</button>
				<form class="inline-block" @submit.prevent="send" id="caci-register_send-code">
					<submit :loading="loading==='send'" text="Envoyer le code" :success="sent" text-success="Code envoyé" :error="error" :text-error="error"></submit>
				</form>
			</div>
			<div v-if="subStep===2||subStep===4">
				<div class="m-caci-register__countdown center" v-if="sent">Vous pourrez demander à recevoir un nouveau code de sécurité dans {{ countdown }}</div>
				<vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions" ref="form"></vue-form-generator>
			</div>
		</div>
		<div class="bottom-right">
			<form @submit.prevent="check" :id="'caci-register_sign'+subStep<3 ? 'Asseris' : 'SNPI'">
				<submit :loading="loading==='check'" :text="registered?'Signer': ('Signer ' + (subStep<3 ? '1' : '2') +  '/2')" :success="signed" text-success="Validé" :error="error" :text-error="error" :disabled="subStep===1||subStep===3"></submit>
			</form>
		</div>
	</div>

</template>

<script>
	import contactRepository from "@/repositories/contactRepository";

	export default {
		name: "caci-register-sign",
		props:{
			subStep:{
				default: 1
			}
		},
		data(){
			return{
				validated: false,
				loading: false,
				error: false,
				sent: false,
				signed: false,
				timer: false,
				formOptions: {
					validateAfterLoad: false,
					validateAfterChanged: true
				},
				model:{},
				form:{
					fields:[
						{
							type: "input",
							inputType: "text",
							inputName: "code",
							model: "code",
							required: true,
							styleClasses:["fields-label","input-background-darker","field--half","field--center"],
							label: "Code de sécurité reçu par sms"
						}
					]
				},
				tel: '',
				countdown: false
			}
		},
		methods:{
			updatePhone(){
				this.$popin('update-phone', this.user, true, 3)
			},
			check(){
				this.loading = 'check';
				if( this.subStep === 2 ){
					contactRepository.checkAsserisCode(this.model.code).then(response=>{
							this.loading = false;
							this.sent = false;
							this.model.code = '';
							clearInterval(this.timer);

							if( this.registered )
								this.nextStep();
							else
								this.nextSubStep();
						},
						response=>{
							this.loading = false;
						})
				}
				else if( this.subStep === 4 ){
					contactRepository.checkCaciCode(this.model.code).then(response=>{
							this.loading = false;
							this.sent = false;
							clearInterval(this.timer);
							this.nextStep();
						},
						response=>{
							this.loading = false;
						})
				}
			},
			send(){
				this.loading = 'send';
				if( this.subStep < 3 ){
					contactRepository.sendAsserisCode().then(response=>{
							this.loading = false;
							this.startCountDown();
							this.setSubStep(2);
						},
						response=>{
							this.loading = false;
						})
				}
				else{
					contactRepository.sendCaciCode().then(response=>{
							this.loading = false;
							this.startCountDown();
							this.setSubStep(4);
						},
						response=>{
							this.loading = false;
						})
				}
			},
			startCountDown(){

				let self = this;
				let date = new Date();
				let countDownDate= date.setMinutes( date.getMinutes() + 10 );

				this.sent = true;

				this.timer = setInterval(function() {
					let now = new Date();
					let distance = countDownDate - now;
					let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					let seconds = Math.floor((distance % (1000 * 60)) / 1000);
					self.countdown = minutes + ' min et ' + seconds + ' sec';

					if (distance < 0) {
						clearInterval(self.timer);
						self.sent = false
					}
				}, 500);
			},
			nextStep(){
				this.$emit('nextStep')
			},
			prevSubStep(){
				this.$emit('updateSubStep', this.subStep - 1);
			},
			nextSubStep(){
				this.$emit('updateSubStep', this.subStep + 1);
			},
			setSubStep(step){
				this.$emit('updateSubStep', step);
			}
		},
		beforeDestroy(){
			clearInterval(this.timer);
		},
		computed:{
			user(){ return this.$user() },
			registered(){ return this.user && !this.user.isRegistering && window.location.search.indexOf('continue') >=0 }
		}
	}
</script>