<template>
	<div class="o-experts container">
		<div class="block">
			<h2 class="title o-experts__title" v-if="data.title">
				{{ data.title }}
			</h2>
		</div>
		<div class="o-experts__filter">
      <vue-google-autocomplete id="map" :country="['fr','gp', 'mq', 'gf', 're', 'pm', 'yt', 'nc', 'pf', 'mf', 'tf']" types="(cities)" :enable-geolocation="true" placeholder="Saisissez une ville" v-on:placechanged="onPlaceChanged"></vue-google-autocomplete>
		</div>
		<div class="o-experts__list" v-infinite-scroll.full="loadMore">
			<expert v-for="(expert, index) in experts" :key="expert.id" :data="expert"></expert>
    </div>
    <loader v-if="loading" static="true"></loader>
  </div>
</template>

<script>
	import contactRepository from '@/repositories/contactRepository';
  import VueGoogleAutocomplete from "vue-google-autocomplete";

	export default {
		name: "experts",
		props:['data'],
    components:{VueGoogleAutocomplete},
		data(){
			return{
				experts: false,
				count: -1,
				loading: false,
				params: {
					offset: 0,
					limit: 21,
					filter: '',
					location: '',
					city: '',
					sort: 'lastname',
					order: 'asc'
				}
			}
		},
		methods:{
      onPlaceChanged(value){
        this.params.location = value.latitude+','+value.longitude;
        this.searchExperts();
      },
			searchExperts(){
        this.experts = [];
				this.query(0);
			},
			loadMore(){
				if( this.experts.length >= this.count || !this.experts.length )
					return;

				this.query(this.params.offset + this.params.limit)
			},
			query(offset){

				if( this.loading === true )
					return;

				this.params.offset = offset;

				this.loading = true;

				contactRepository.findExperts(this.params).then(response=>{
					this.loading = false;
					this.experts = response.items;
					this.count = response.count;
				}, e => {
					self.loading = false;
				});
			}
		},
		mounted(){
			this.searchExperts();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-experts{
		&__list{
			position:relative; min-height: 10rem;

			@media #{$from-small}{
				display: flex; flex-wrap: wrap; margin-left: -0.5rem; margin-right: -0.5rem;
			}
			.m-expert{ flex-basis: 33.3%; padding: 0.5rem }

		}
		&__filter{
			background: #fff; border-radius: 4px; padding: $space; margin-bottom: $space-m; margin-top: $space-l; box-shadow: $subtle-box-shadow;
		}
	}

</style>
