<template>
	<div class="m-update-profile">
		<div class="title-big">Attention !</div>
		<p>Votre profil est incomplet. Vous risquez de ne pas pouvoir accéder à toutes les fonctionnalités de l’espace adhérent si vous ne renseignez pas vos informations personnelles.
		</p>
		<div class="bottom-center">
			<router-link to="/user/profil" class="button">Compléter mon profil</router-link>
		</div>
	</div>
</template>

<script>
export default {
name: "update-profile"
}
</script>

<style scoped>

</style>