<template>
  <div class="m-foad-pdf">
    <a class="button" @click="open" v-if="data.file">Lire le document</a>
    <div class="wysiwyg" v-html="data.wysiwyg" ref="html" v-if="data.wysiwyg"></div>
    <div class="m-foad-pdf__popin" v-if="is_open">
      <div class="m-foad-pdf__popin-inner">
        <a class="m-foad-pdf__popin-close" data-icon="close" @click="close"></a>
        <object :data="data.file+'?disposition=inline'" type="application/pdf" width="100%" height="100%" ref="pdf" tabindex="0">
          <p>This browser does not support PDFs. Please download Chrome or Firefox to continue</p>
        </object>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foad-pdf",
  props:['data','participant'],
  data(){
    return{
      is_open : false
    }
  },
  methods:{
    open(){
      this.$emit('handleFocus', true)
      this.is_open = true
    },
    close(){
      this.$emit('handleFocus', false)
      this.is_open = false;
      this.$emit('updated', 1)
    }
  },
  mounted() {
    this.$refs.html.querySelectorAll('a').forEach(item=>{
      item.setAttribute('target', '_blank')
    })
  }
}
</script>

<style lang="scss">
@import '../../environment';
.m-foad-pdf{
  &__popin{
    position: fixed; left: 0; top:0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 9999; padding: 6rem;
    &-close{ box-shadow: $box-shadow; position: absolute; left: 100%; bottom: 100%; width: 34px; text-align: center; color: $c-primary; padding: 5px; font-size: 2rem; cursor: pointer; background: #fff; border-radius: 50%; }
    &-inner{ background: #fff; border-radius: 5px; box-shadow: $box-shadow; position: relative; max-width: 1600px; margin: auto; height: 100% }
    object{ display: block }
  }
}
</style>