<template>
  <div ref="videoWrapper" class="player-wrapper">
    <div :ref="'videoPlayer' + id" :id="'videoPlayer' + id" class="video-player">
      <button v-if="isPaused && !buffering" id="big-play" class="big-play-btn" ref="bigPlayBtn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
        </svg>
      </button>
      <div v-if="buffering" ref="bufferingMsg" class="buffering-msg">
        <span>Chargement...</span>
      </div>
      <div ref="mask" class="mask" @click="handlePlayNPause"></div>
      <div ref="controlBar" id="ctrls" class="controls">
        <div ref="sliderContainer" class="plyr__progress slider-container">
            <input ref="progressInput" class="slider" @click="handleSeekerBar" type="range" min="0" max="100" v-model="seekerProgress">
            <progress ref="bufferProgressRef" class="plyr__progress__buffer" min="0" max="100" :value="getBufferProgress" ></progress>
            <span ref="playerLock" class="player-lock">
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
                <path d="M10 16c0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723v2.277h-2v-2.277c-.596-.347-1-.985-1-1.723zm11-6v14h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-13 0h8v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4zm11 2h-14v10h14v-10z"/>
              </svg>
            </span>
        </div>
        <div class="control-btns-wrapper">
          <div class="playback-controls">
            <button @click="handleReset" id="reset" class="ctrl-btn" >
              <svg class="reset-svg" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24">
                <path class="a" d="M6.758,8.758,5.344,7.344A8.048,8.048,0,0,0,3.5,10.2l1.873.7A6.048,6.048,0,0,1,6.758,8.758ZM19,13a7.99,7.99,0,0,0-7-7.93V2L7,6l5,4V7.089A5.991,5.991,0,0,1,17,13v0a6,6,0,0,1-.086.977c-.007.043-.011.088-.019.131a6.175,6.175,0,0,1-1.654,3.133,6.172,6.172,0,0,1-3,1.628c-.142.03-.285.05-.43.069-.062.009-.122.021-.184.027a6.1,6.1,0,0,1-1.9-.1L9.3,20.819a8.087,8.087,0,0,0,2.534.136c.069-.007.138-.021.207-.03.2-.026.409-.056.61-.1l.053-.009v0a7.877,7.877,0,0,0,2.136-.795h0L14.868,20a7.906,7.906,0,0,0,1.01-.67,7.343,7.343,0,0,0,1.458-1.46c.021-.028.049-.053.07-.081l-.006,0a7.9,7.9,0,0,0,1.093-2l.008,0c.029-.078.05-.158.076-.237.037-.11.075-.221.107-.333.04-.14.073-.281.1-.423.022-.1.048-.195.066-.3.032-.171.056-.344.076-.516.01-.076.023-.15.03-.227.023-.249.037-.5.037-.753,0,0,0,0,0-.008ZM6.2,16.6,4.6,17.8a8.045,8.045,0,0,0,2.569,2.225l.961-1.754A6.018,6.018,0,0,1,6.2,16.6ZM5,13c0-.145.005-.287.015-.429l-1.994-.143A7.977,7.977,0,0,0,3.5,15.8l1.873-.7A5.975,5.975,0,0,1,5,13Z"/>
              </svg>
            </button>
            <button v-if="isPaused" @click="handlePlay" id="play" class="ctrl-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
              </svg>
            </button>
            <button v-if="!isPaused" @click="handlePause" id="pause" class="ctrl-btn">
              <!-- credit bqlqn -->
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 511.448 511.448" height="18px" viewBox="0 0 511.448 511.448" width="18px">
                <path d="m436.508 74.94c-99.913-99.913-261.64-99.928-361.567 0-99.913 99.913-99.928 261.64 0 361.567 99.913 99.913 261.64 99.928 361.567 0 99.912-99.912 99.927-261.639 0-361.567zm-180.784 394.45c-117.816 0-213.667-95.851-213.667-213.667s95.851-213.666 213.667-213.666 213.666 95.851 213.666 213.667-95.85 213.666-213.666 213.666z"/>
                <path d="m298.39 160.057c-11.598 0-21 9.402-21 21v149.333c0 11.598 9.402 21 21 21s21-9.402 21-21v-149.333c0-11.598-9.401-21-21-21z"/>
                <path d="m213.057 160.057c-11.598 0-21 9.402-21 21v149.333c0 11.598 9.402 21 21 21s21-9.402 21-21v-149.333c0-11.598-9.401-21-21-21z"/>
              </svg>
            </button>
            <span class="current-time">{{currentTime+ ' / ' + durationTag}}</span>
          </div>
          <div class="volume-fullscreen">
            <div class="volume-btn-wrapper">
              <button ref="volumeBtnRef" v-show="!isMuted" id="volume" @click="handleMute" class="ctrl-btn volume-btn">
                <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5999996,3.3 C15.1999996,2.9 14.5999996,2.9 14.1999996,3.3 C13.7999996,3.7 13.7999996,4.3 14.1999996,4.7 C15.3999996,5.9 15.9999996,7.4 15.9999996,9 C15.9999996,10.6 15.3999996,12.1 14.1999996,13.3 C13.7999996,13.7 13.7999996,14.3 14.1999996,14.7 C14.3999996,14.9 14.6999996,15 14.8999996,15 C15.1999996,15 15.3999996,14.9 15.5999996,14.7 C17.0999996,13.2 17.9999996,11.2 17.9999996,9 C17.9999996,6.8 17.0999996,4.8 15.5999996,3.3 L15.5999996,3.3 Z"></path>
                  <path d="M11.2819745,5.28197449 C10.9060085,5.65794047 10.9060085,6.22188944 11.2819745,6.59785542 C12.0171538,7.33303477 12.2772954,8.05605449 12.2772954,9.00000021 C12.2772954,9.93588462 11.851678,10.9172014 11.2819745,11.4869049 C10.9060085,11.8628709 10.9060085,12.4268199 11.2819745,12.8027859 C11.4271642,12.9479755 11.9176724,13.0649528 12.2998149,12.9592565 C12.4124479,12.9281035 12.5156669,12.8776063 12.5978555,12.8027859 C13.773371,11.732654 14.1311161,10.1597914 14.1312523,9.00000021 C14.1312723,8.8299555 14.1286311,8.66015647 14.119665,8.4897429 C14.0674781,7.49784946 13.8010171,6.48513613 12.5978554,5.28197449 C12.2218894,4.9060085 11.6579405,4.9060085 11.2819745,5.28197449 Z"></path>
                  <path d="M3.78571429,6.00820648 L0.714285714,6.00820648 C0.285714286,6.00820648 0,6.30901277 0,6.76022222 L0,11.2723167 C0,11.7235261 0.285714286,12.0243324 0.714285714,12.0243324 L3.78571429,12.0243324 L7.85714286,15.8819922 C8.35714286,16.1827985 9,15.8819922 9,15.2803796 L9,2.75215925 C9,2.15054666 8.35714286,1.77453879 7.85714286,2.15054666 L3.78571429,6.00820648 Z"></path>
                </svg>
              </button>
              <div class="volume-slider-wrapper">
                <input ref="volumeInput" @input="handleVolume" @change="handleVolume" class="slider volume-slider" type="range" min="0" max="100" v-model="volumeLevel">
              </div>
            </div>

            <button ref="mutedBtnRef" v-show="isMuted" @click="handleMuted" id="volume" class="ctrl-btn">
              <svg width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <polygon points="12.4 12.5 14.5 10.4 16.6 12.5 18 11.1 15.9 9 18 6.9 16.6 5.5 14.5 7.6 12.4 5.5 11 6.9 13.1 9 11 11.1"></polygon>
                <path d="M3.78571429,6.00820648 L0.714285714,6.00820648 C0.285714286,6.00820648 0,6.30901277 0,6.76022222 L0,11.2723167 C0,11.7235261 0.285714286,12.0243324 0.714285714,12.0243324 L3.78571429,12.0243324 L7.85714286,15.8819922 C8.35714286,16.1827985 9,15.8819922 9,15.2803796 L9,2.75215925 C9,2.15054666 8.35714286,1.77453879 7.85714286,2.15054666 L3.78571429,6.00820648 Z"></path>
              </svg>
            </button>
            <button v-if="!isFullscreen" @click="handleFullScreen" id="fullscreen" class="ctrl-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
                <path d="M24 9h-2v-7h-7v-2h9v9zm-9 15v-2h7v-7h2v9h-9zm-15-9h2v7h7v2h-9v-9zm9-15v2h-7v7h-2v-9h9z"/>
              </svg>
            </button>
            <button v-if="isFullscreen" @click="handleExitFullScreen" id="exitFullscreen" class="ctrl-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
                <path d="M18 3h2v4h4v2h-6v-6zm6 12v2h-4v4h-2v-6h6zm-18 6h-2v-4h-4v-2h6v6zm-6-12v-2h4v-4h2v6h-6z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Player from "@vimeo/player"

export default {
  name: 'vimeo-player',
  props: ['videoId', 'startAt', 'width', 'id'],
  data() {
    return {
      options: {
        muted: false,
        controls: false,
        pip: false,
        texttrack: null,
        title: false,
        speed: false,
        sidedock: false,
        responsive: true,
        playsinline: true
      },

      //for fullscreen
      iframeHeight: 0,
      iframeWidth: 0,
      iFrameInitialStyleProp: null,
      playerInitialStyleProp: null,

      player: null,
      step: 5,
      buffering: false,
      isPaused: true,
      timeWatched: null,
      currentTime: '0:00',
      timeLeftTag: '--:--',
      durationTag: '--:--',
      duration: 0,
      seekerProgress: 0,
      progress: 0,
      bufferedProgress: 0,
      isMuted: false,
      currentTimePingIntervalId: null,
      volumeLevel: 100,
      isFullscreen: false,
      iFrame: null,
      timerCount: 0,
      valueHover: 0,
      timestampTag: '--:--',
      resumeAt: this.startAt,
      isIos: false,
      isPortrait: null
    }
  },
  mounted() {
    // Initialisations
    let self = this
    //MacIntel
    this.isIos = /(iPad|iPhone|iPod)/gi.test(navigator.platform)
    this.isPortrait = window.matchMedia("(orientation: portrait)")
    this.$refs.videoWrapper.style.width = this.width + 'px'
    let videoPlayerId = 'videoPlayer' + this.id
    this.player = new Player(videoPlayerId, {...self.options, width: this.width, id: this.videoId});

    this.player.setVolume(this.volumeLevel/100)
    this.$refs.volumeInput.style.background = 'linear-gradient(90deg, rgb(7, 45, 112) ' + this.volumeLevel + '%, rgba(211, 211, 211, 0.4) ' + this.volumeLevel + '%)'

    this.player.disableTextTrack()

    //transparent mask to play and pause on click
    this.$refs.mask.style.width = this.width + 'px'

    this.player.ready().then(function() {
      let videoPlayerRef = 'videoPlayer' + self.id
      self.iFrame = self.$refs[videoPlayerRef].getElementsByTagName('iframe')[0]

      self.$refs.mask.style.width = self.iFrame.clientWidth + 'px'
      self.$refs.mask.style.height = self.iFrame.clientHeight + 'px'
    });

    // Buffering message
    this.player.on('bufferstart', function() {
      self.buffering = true
    })
    this.player.on('bufferend', function() {
      self.buffering = false
    })

    // play and pause logic
    this.player.on('play', function(data) {
      if(self.resumeAt) {
        // resume video from a starting point
        self.player.setCurrentTime(self.startAt*data.duration)
        self.timeWatched = self.startAt*data.duration
        self.resumeAt = null
      }
      let minutes = Math.floor(data.duration / 60)
      let seconds = parseInt(data.duration - minutes * 60).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})
      self.durationTag =  minutes + ':' + seconds
      self.timeLeftTag =  minutes + ':' + seconds
      self.duration = data.duration
      self.isPaused = false
      self.$refs.bufferProgressRef.style.display = 'block'

    });
    this.player.on('pause', function() {
        self.isPaused = true
        self.setCurrentTime()
    });

    // Disable Seeking
    this.player.on("timeupdate", function(data) {
      if(data.seconds > self.timeWatched + 2) {
        self.player.setCurrentTime(self.timeWatched)
        self.seekerProgress = self.progress * 100
      }
      if (data.seconds - 1 < self.timeWatched && data.seconds > self.timeWatched) {
        self.timeWatched = data.seconds
        self.bufferedProgress = data.percent * 100
      }

      self.progress = data.percent
      self.seekerProgress = data.percent * 100
      self.$refs.progressInput.style.background = 'linear-gradient(90deg, rgb(7, 45, 112) ' + parseInt(self.seekerProgress + 1) + '%, rgba(211, 211, 211, 0.4) ' + parseInt(self.seekerProgress + 1) + '%)'

      // update timestamps
      let minutes = Math.floor(data.seconds / 60)
      let seconds = parseInt(data.seconds - minutes * 60).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})
      self.currentTime =  minutes + ':' + seconds

      let timeLeft = (data.duration - data.seconds)
      minutes = Math.floor( timeLeft / 60)
      seconds = parseInt(timeLeft - minutes * 60).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})
      self.timeLeftTag =  minutes + ':' + seconds
      self.updatedVideo(data.percent);
    });

    // End Of Playback
    this.player.on("ended", function(data) {
      self.endedVideo()
    })

    // the every 20 seconds while played function call
    this.currentTimePingIntervalId = setInterval( () => {
      if(!self.isPaused) {
        self.timerCount++
      }
      if(self.timerCount > 200) {
        self.setCurrentTime()
        self.timerCount = 0
      }
    }, 100)

    // On blur on focusing tab/window
    window.addEventListener('blur', () => {
      self.handlePause()
    });

    document.addEventListener("fullscreenchange", function (event) {
      if (!document.fullscreenElement) {
        self.handleExitFullScreen()
      }
    }, false);
    document.addEventListener("mozfullscreenchange", function (e) {
      if (!document.fullscreenElement) {
        self.handleExitFullScreen()
      }
    }, false);
    document.addEventListener("webkitfullscreenchange", function (e) {
      if (!document.fullscreenElement) {
        self.handleExitFullScreen()
      }
    }, false);
    this.$refs.progressInput.addEventListener('mousemove', function(e) {
      self.valueHover = self.calcSliderPos(e).toFixed(2);
      self.$refs.playerLock.style.display = 'block'
        self.$refs.playerLock.style.left = (self.valueHover )+ '%'
      if(self.valueHover > (self.timeWatched / self.duration) * 100) {
        self.$refs.playerLock.style.display = 'block'
      } else {
        self.$refs.playerLock.style.display = 'none'
      }
      let hoverTime = (self.valueHover/100) * self.duration
      if(hoverTime && hoverTime > 0) {
        let minutes = Math.floor( hoverTime / 60)
        let seconds = parseInt(hoverTime - minutes * 60).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})
        self.timestampTag = minutes + ':' + seconds
      }
    })

    // detect click outside
    window.addEventListener('click', self.clickOutside);

    // Supported Shortcuts
    window.addEventListener('keydown', self.shortcuts)

    // Screen orientation event
    window.addEventListener("resize",  self.resize);

    this.$refs.progressInput.addEventListener('mouseout', function(e) {
      self.$refs.playerLock.style.display = 'none'
    })
  },
  methods: {
  	resize(){
  		let self = this;
		  if(self.isIos) {
			  self.isPortrait = window.matchMedia("(orientation: portrait)");
			  if(self.isFullscreen && !self.isPortrait.matches){
				  // Landscape orientation
				  self.iFrame.style.position =  "absolute"
				  self.iFrame.style.left = "0"
				  self.iFrame.style.height = "100%"
				  if(self.$refs.mask) {
					  self.$refs.mask.style.height = "100%"
					  self.$refs.mask.style.width = "100%"
				  }
				  if(/(iPad)/gi.test(navigator.platform)) {
					  // for ipad
					  self.iFrame.style.top = "6rem"
					  self.iFrame.style.width = "100%"
					  self.iFrame.style.transform =  "translate(0, 0)"
					  self.$refs.controlBar.style.bottom = "35px"
				  } else {
					  self.iFrame.style.top = "-10%"
					  self.iFrame.style.width = "80%"
					  self.iFrame.style.transform =  "translate(13%, 0)"
					  self.$refs.controlBar.style.bottom = "0"
				  }
			  }

			  if(self.isFullscreen && self.isPortrait.matches){
				  // Portrait orientation
				  self.$refs.controlBar.style.bottom = '55px'
				  self.iFrame.style.position =  "absolute"

				  self.iFrame.style.left = "0"
				  self.iFrame.style.width = "100%"
				  self.iFrame.style.height = "100%"
				  if(self.$refs.mask) {
					  self.$refs.mask.style.height = "100%"
					  self.$refs.mask.style.width = "100%"
				  }
				  self.iFrame.style.transform =  "unset"
				  if(/(iPad)/gi.test(navigator.platform)) {
					  // for ipad
					  self.iFrame.style.top = "20rem"
				  } else {
					  self.iFrame.style.top = "100%"
				  }
			  }
		  }
	  },
  	shortcuts(e){
		  switch(e.key) {
			  case ' ': this.handlePlayNPause()
				  break;

			  case 'f':
			  case 'F':
				  if(this.isFullscreen) {
					  this.handleExitFullScreen()
				  } else {
					  this.handleFullScreen()
				  }
				  break;

			  case 'r':
			  case 'R':
				  this.handleReset()
				  break;

			  default:
		  }
	  },
	  clickOutside(e) {
		  if (document.getElementById('videoPlayer' + this.id) && !document.getElementById('videoPlayer' + this.id).contains(e.target)) {
			  this.handlePause()
		  }
	  },
    setCurrentTime () {
      this.player.getCurrentTime().then(function(seconds) {
        //API call to post the timestamp
      })
    },
    endedVideo() {
	    this.$emit('video-ended')

      // enable if you want to force avoid related videos, on your own discretion.
      // this.player.setCurrentTime(this.duration - 0.1)
      // this.player.pause()
    },
    updatedVideo(value) {
	    this.$emit('video-updated', value)

      // enable if you want to force avoid related videos, on your own discretion.
      // this.player.setCurrentTime(this.duration - 0.1)
      // this.player.pause()
    },
    handlePlay() {
      this.player.play()
    },
    handlePause() {
      this.player.pause()
    },
    handlePlayNPause() {
      if(this.isPaused) {
        this.handlePlay()
      } else {
        this.handlePause()
      }
    },
    handleForward() {
      let travel = (this.progress * this.duration) + this.step
      if(travel <= this.timeWatched) {
        this.player.setCurrentTime(travel)
      } else {
        this.player.setCurrentTime(this.timeWatched)
      }
    },
    handleRewind() {
      let travel = (this.progress * this.duration) - this.step
      if(travel > 0) {
        this.player.setCurrentTime(travel)
      } else {
        this.player.setCurrentTime(0)
      }
    },
    handleReset() {
      this.player.setCurrentTime(0);
    },
    handleVolume(e) {
      this.player.setVolume(this.volumeLevel/100)
      this.$refs.volumeInput.style.background = 'linear-gradient(90deg, rgb(7, 45, 112) ' + this.volumeLevel + '%, rgba(211, 211, 211, 0.4) ' + this.volumeLevel + '%)'
      if(this.volumeLevel === 0) {
        this.isMuted = true
      }
    },
    handleMute(e) {
      if(this.isMuted) {
        this.player.setVolume(0.2)
        this.isMuted = false
      } else {
        this.player.setVolume(0)
        this.isMuted = true
      }
    },
    handleMuted() {
      if(this.isMuted) {
        this.player.setVolume(this.volumeLevel/100)
        this.isMuted = false
      } else {
        this.player.setVolume(0)
        this.isMuted = true
      }
    },
    handleFullScreen() {
      // this.player.requestFullscreen()
      let videoPlayerRef = 'videoPlayer' + this.id
      let container = this.$refs[videoPlayerRef]
      this.iframeWidth = this.iFrame.style.width
      this.iframeHeight = this.iFrame.style.height
      if (this.isIos) {
        // if iPhone or ipad
        this.$refs[videoPlayerRef].classList.add("player-fullscreen")
        this.isFullscreen = true
        window.dispatchEvent(new Event('resize'));
      } else {
        this.iFrame.style.width = "100%"
        this.iFrame.style.height = "100%"
        this.$refs.mask.style.width = "100%"
        this.$refs.mask.style.height = "100%"
        this.$refs.controlBar.style.bottom = '0'
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        }

        if(window.innerWidth < 767) {
          // Screen orientation lock works with fullscreen only ... so wont work on ios
          let screenOrientation = window.screen.orientation
          screenOrientation.lock('landscape')
        }
      }
      this.isFullscreen = true
    },
    handleExitFullScreen() {
      let videoPlayerRef = 'videoPlayer' + this.id
      let container = this.$refs[videoPlayerRef]

      if (this.isIos) {
        // if iPhone or ipad
        this.$refs[videoPlayerRef].classList.remove("player-fullscreen")
        this.iFrame.style.top = "0"
        this.$refs.controlBar.style.bottom = '-50px'
        this.iFrame.style.width = '100%'
        this.iFrame.style.height = '100%'
        this.iFrame.style.transform =  "translate(0, 0)"
        this.$refs.mask.style.width = '100%'
        this.$refs.mask.style.height = '100%'
      } else {
        if(this.$refs.mask) {
          this.$refs.mask.style.width = this.iframeWidth
          this.$refs.mask.style.height = this.iframeHeight
        }
        this.iFrame.style.width = this.iframeWidth
        this.iFrame.style.height = this.iframeHeight
        this.$refs.controlBar.style.bottom = '-50px'

        if (document.exitFullscreen) {
          if(document.fullscreenElement) {
            document.exitFullscreen();
          }
        } else if (document.mozCancelFullScreen) {
          if(document.fullscreenElement) {
            document.mozCancelFullScreen();
          }
        } else if (document.webkitExitFullscreen) {
          if(document.fullscreenElement) {
            document.webkitExitFullscreen();
          }
        } else if (document.msExitFullscreen) {
          if(document.fullscreenElement) {
            document.msExitFullscreen();
          }
        }
        if(window.innerWidth < 767) {
          let screenOrientation = window.screen.orientation
          screenOrientation.unlock
        }
      }
      this.isFullscreen = false
    },
    handleSeekerBar() {
      if ((this.seekerProgress/100) * this.duration <= this.timeWatched) {
        this.player.setCurrentTime(parseInt((this.seekerProgress/100) * this.duration))
      }
    },
    calcSliderPos(e) {
      return (e.offsetX / e.target.clientWidth) *  parseInt(e.target.getAttribute('max'),10);
    },
  },
	watch:{
		paused(){
			this.handlePause()
		},
	},
  computed: {
      getBufferProgress: function() {
        return this.bufferedProgress
      },
		  paused(){
			  return this.$store.getters.pausedFoad()
		  }
  },
  destroyed() {
    clearInterval(this.currentTimePingIntervalId)
	  window.removeEventListener('click', this.clickOutside);
	  window.removeEventListener('keydown', this.shortcuts)
	  window.removeEventListener("resize",  this.resize);
  }
}
</script>

<style scoped lang="scss">
.video-player{
  position: relative;
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
}

.big-play-btn {
  display: inline;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: transparent;
  border-radius: 50%;
  fill: white;
  border: 0;
  z-index: 2;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.buffering-msg {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 60px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  color: white;
  border: 1px solid rgba(255,255,255, 0.5);
  display: flex;
  align-items: center;
  z-index: 2;
  span {
    margin: auto;
  }
}
.mask {
  position: absolute;
  background: transparent;
  z-index: 3;
  cursor: pointer;
}
.controls {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  justify-content: space-around;
  width: 100%;
  height: 50px;
  background: black;
  color: rgb(93, 118, 161);
  margin : auto;
  bottom: -50px;
  z-index: 4;
  .plyr__progress__buffer {
    display: none;
  }
  &:focus {
    outline: none;
  }
  .control-btns-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    .playback-controls, .volume-fullscreen {
      display: flex;
      width: fit-content;
      padding: 0 5px;
    }
  }
  span {
    color: white;
  }
  .ctrl-btn {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0;
    fill: white;
    color: white;
    &:focus {
      outline: none;
    }
  }
  .current-time {
    margin-left: 10px;
    letter-spacing: 1px;
    .reset-svg {
      transform: translate(-3 -2);
    }
  }
  .volume-btn-wrapper {
    position: relative;
    .volume-slider-wrapper, .mobile-volume-slider-wrapper {
      display: none;
      position: absolute;
      background: rgba(0,0,0,0.5);
      border-radius: 8px;
      height: 20px;
      width: 100px;
      left: -35px;
      top: -55px;
      z-index: 99;
      transform: rotate(-90deg);
      .volume-slider {
        width: 100px;
        margin: auto;
        background: rgba(211, 211, 211, 0.4);
      }
      .volume-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        background: white;
        opacity: 1;
        border-radius: 50%;
        cursor: pointer;
      }
      .volume-slider::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        background: white;
        opacity: 1;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .volume-btn-wrapper {
    &:hover {
      .volume-slider-wrapper {
        @media (min-width: 767px) {
          display: flex;
        }
      }
    }
  }
  .timestamp {
    display: none;
    position: absolute;
    width: 45px;
    height: 20px;
    background: rgba(0,0,0,0.5);
    bottom: 25px;
    transform: translate(-60%);
    border-radius: 8px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: rgba(0,0,0,0.5);
      border-bottom: 0;
      margin-left: -5px;
      margin-bottom: -5px;
    }
  }
  .player-lock {
    display: none;
    position: absolute;
    border-radius: .4em;
    width: 18px;
    height: 10px;
    transform: translate(-60%);
    bottom: 25px;
    left: 0;
    fill: white;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 2px;
      height: 10px;
      border-bottom: 0;
      margin-left: 0;
      margin-bottom: -21px;
      background: white;
    }
  }
  .plyr__progress {
    width: 100%;
    display: flex;
    align-items: center;
    top: -5px;
    z-index: 9;
    input[type=range] {
      z-index: 9;
    }
    &:focus {
      outline: none;
    }
  }
  span {
    padding-top: 5px;
  }
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  background: rgba(211, 211, 211, 0.4);
  height: 5px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.slider::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 5px;
    height: 5px;
    background: transparent;
    opacity: 1;
    cursor: pointer;
    &:focus {
      outline: none;
    }
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 5px;
  height: 5px;
  background: transparent;
  opacity: 1;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.player-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: black;
  width: 100vw;
  height: 100vh;
}
</style>
