<template>
	<div class="m-documents-filters" v-if="terms">

		<div class="m-documents-filters__filters-type" v-if="!this.$route.params.section">
			<b class="m-documents-filters__filters-title">Type</b>
			<div class="m-documents-filters__columns">
				<label v-for="(term, index) in terms" :key="term.id" class="m-documents-filters__filters-input input--radio" v-if="term.taxonomy==='category'">
					<input type="radio" name="type" :value="term.slug" v-model="model.type" @change="updateFilters(true, true)">
					<span v-html="term.title"></span>
				</label>
			</div>
		</div>

		<div class="m-documents-filters__filters-type" v-if="categories && categories.length">
			<b class="m-documents-filters__filters-title">Catégorie</b>
			<div class="m-documents-filters__columns">
				<label v-for="(term, index) in categories" :key="term.id" class="m-documents-filters__filters-input input--checkbox">
					<input type="checkbox" name="category" :value="term.slug" v-model="model.category" @change="updateFilters(false, true)">
					<span class="checkmark" data-icon="check"></span>
					<span v-html="term.title"></span>
				</label>
			</div>
		</div>

		<div class="m-documents-filters__filters-type m-documents-filters__filters-type--double" v-if="natures.length && this.$route.params.section">
			<b class="m-documents-filters__filters-title">Nature</b>
			<div class="m-documents-filters__columns">
				<label v-for="(term, index) in sort(natures, 'title')" :key="term.id"  class="m-documents-filters__filters-input input--checkbox">
					<input type="checkbox" name="nature" :value="term.slug" v-model="model.nature" @change="updateFilters(false, false)">
					<span class="checkmark" data-icon="check"></span>
					<span v-html="term.title"></span>
				</label>
			</div>
		</div>

		<div class="m-documents-filters__date" v-if="mod !== 'dashboard'">
			<b class="m-documents-filters__filters-title">Date</b>
				<vue-form-generator :model="model" @model-updated="updateFilters(false, false)" v-if="config && config.documents" :schema="formYear">
				</vue-form-generator>
		</div>

		<div class="filters-reset link" @click="reset">Réinitialiser tous les filtres</div>
	</div>
</template>

<script>

  import urlParams from "params-url";

  export default {
		name: "documents-filters",
		props:['mod', 'taxonomy'],
		data(){

			let self = this;

			return{
				formYear:{
					fields:[
						{
							type: "multiselect-custom",
							inputName: "year",
							model: "year",
							placeholder: "Année",
							closeOnSelect: true,
							styleClasses: "select-document",
							values(){
								return self.config.documents.years
							}
						}
					]
				},
				model:{
					type: false,
					category: [],
					nature: [],
					year: ''
				}
			}
		},
    watch:{
      model:{
        deep: true,
        handler(){
          this.updateUrl()
        }
      }
    },
		methods:{
			reset(){

				if( !this.$route.params.section )
					this.model.type = false;

				this.model.year = '';

				this.$emit('resetSearch');

				this.updateFilters(true, true);
			},
      updateUrl(){
        let removeEmpty = function(obj) {
          return Object.fromEntries(
              Object.entries(obj)
                  .filter(([_, v]) => v != null && v !== 0  && v !== '' && _ !== 'limit' && _ !== 'type' && _.substr(0,1) !== '_' && (typeof v === 'object' ? Object.keys(v).length : true) )
                  .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
          );
        }

        let model = removeEmpty(this.model);

        if( model.category )
          model.category = Object.values(model.category);

        if( model.nature ){
          let natures = [];
          Object.values(model.nature).forEach(nature=>{
            natures.push(Object.values(nature).join(','));
          })
          model.nature = natures
        }

        let url = urlParams.generate(window.location.pathname, model);

        window.history.replaceState({}, "", decodeURIComponent(url));
      },
			dedup(items){

				let dupes = {};
				items = JSON.parse(JSON.stringify(items));
				for(let i=0; i<items.length;i++){

					if( typeof dupes[items[i].title] == 'undefined'){

						dupes[items[i].title] = items[i];
						dupes[items[i].title].slug = [dupes[items[i].title].slug];
					}
					else
						dupes[items[i].title].slug.push(items[i].slug)
				}

				return Object.values(dupes);
			},
			updateFilters(resetCategory, resetNature){

				if( resetCategory )
					this.model.category = [];

				if( resetNature )
					this.model.nature = [];

				let terms = false;

				if(this.model.nature.length > 0){

					terms = this.model.nature;
					let mergedTerms = [];

					for(let i=0; i<terms.length;i++){

						if( typeof terms[i] === 'object')
							mergedTerms = mergedTerms.concat(terms[i]);
						else
							mergedTerms.push(terms[i])
					}
					terms = mergedTerms;
				}
				else if(this.model.category.length > 0 )
					terms = this.model.category;
				else if(this.model.type)
					terms = [this.model.type];

				this.$emit('changeFilters', terms, this.model)
			},
			sort(value, key){
				return value.sort(function(a, b) {
					if(a[key] < b[key]) { return -1; }
					if(a[key] > b[key]) { return 1; }
					return 0;
				})
			}
		},
		computed:{
			categories(){

				if(this.terms && this.model.type) {
					let categories = this.terms.find(term => term.slug === this.model.type);
					return categories ? categories.children : false;
				}

				return false
			},
			config(){ return this.$config()},
			terms(){
				return this.$store.getters.terms();
			},
			natures(){
				if(this.categories && this.terms && this.model.type){

					if(this.model.category && this.model.category.length > 0){

						let natures = [];
						for(let i=0; i<this.model.category.length;i++){
							let category = this.categories.find(term => term.slug === this.model.category[i]);
							if( category && typeof category.children != 'undefined')
								natures = natures.concat(category.children);
						}
						return this.dedup(natures);

					} else{
						let natures = [];
						for(let i=0; i<this.categories.length;i++){
							if( 'children' in this.categories[i] )
								natures = natures.concat(this.categories[i].children);
						}
						return this.dedup(natures);
					}
				}
				return false;
			}
		},
		mounted(){

      this.model.type = this.$route.params.section;

      let params = urlParams.parse(document.location.href )
      if( params ){
        for (const [key, value] of Object.entries(params)) {

          if( typeof this.model[key] == 'object' && typeof value !='object'){
            if(key === 'nature'){
              this.model[key].push(value.split(','));
            }
            else {
              this.model[key][0] = (isNaN(value) ? value : Number(value));
            }
          }
          else {
            if(key === 'nature'){
              let index=0;
              Object.values(value).forEach(nature=>{
                this.model[key][index] = nature.split(',');
                index++;
              })
            }
            else{
              this.model[key] = (isNaN(value) ? value : Number(value));
            }

          }
        }

        this.updateFilters();
      }

		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-documents-filters{
		$c: &;

		color: $c-primary; background: $c-background-lighter; border-top: solid 1px $c-background-darker; font-size: $font-xs;padding: $space;

    .o-documents-list--podcasts &{ border-bottom: 1px solid rgba(0,0,0,0.05) }

		@media #{$from-small}{ display: flex; flex-wrap: wrap; }

		&__filters-title{ text-transform: uppercase; margin-bottom: $space-s; display: block }

		&__filters-type{
			@media #{$from-small}{
				& + & { margin-left: 9rem }
				& + #{$c}__date{ margin-left: $space }
			}

			@media #{$to-tablet}{
				& + *{ margin-top: $space-m }

			}
			& > *{ display: block }
		}
		&__filters-input{
			display: flex;
			& + *{ margin-top: $space-s }
		}

		&__date select{ border: solid 1px $c-primary }

		.filters-reset{
			margin-left: auto; white-space: nowrap; margin-top: $space-m;

			@media #{$from-small}{ width: 100%; text-align: right }

		}

		&__filters-type--double{
			flex: 1;
			#{$c}__columns{
				display: flex; flex-wrap: wrap;
				& > *{
					width: 50%;
					& + *{ margin-top: 0 }
					&:nth-of-type(1n+3){ margin-top: $space-s }
				}
			}
		}

		@media #{$to-tablet}{
			&__columns{
				display: flex; flex-wrap: wrap;
				& > *{
					width: 50%;
					& + *{ margin-top: 0 }
					&:nth-of-type(1n+3){ margin-top: $space-s }
				}
			}
		}
	}
</style>
