<template>
	<div class="o-trainings-dashboard block">

		<h2 class="title">{{ interface.title }}</h2>

		<div v-if="!config.formations.enabled">Zone en maintenance</div>
		<div v-else>
			<div class="o-trainings-dashboard__team">

				<h3 class="o-trainings-dashboard__team-title" v-if="user">
					<span v-if="user.isLegalRepresentative">Votre équipe</span>
					<span v-else>Suivi de mes heures</span>
				</h3>

				<div class="o-trainings-dashboard__members">
					<loader v-if="reports.loading"></loader>
					<div class="error" v-if="reports.error">{{reports.error}}</div>
					<report v-for="(report, index) in reports.items" :key="index" :data="report"></report>
				</div>

				<router-link to="formations/suivi" class="o-trainings-dashboard__see-more" v-if="reports.count>3">
					Voir tous les collaborateurs
				</router-link>

				<div class="bottom-right">
					<router-link to="/formations" class="button button--icon" data-icon_after="search">Réserver des formations</router-link>
				</div>
			</div>

			<div class="o-trainings-dashboard__filters">

				<div class="o-trainings-dashboard__header">

					<h3 class="o-trainings-dashboard__filters-title">
						Les formations
					</h3>

					<div class="o-trainings-dashboard__filters-content">
						<div class="field-select" v-if="config">
							<div class="field-wrap">
								<select v-model="formations.params.format" @change="searchFormations">
									<option value="" disabled>Trier par</option>
									<option value=""></option>
									<option value="instructor-led" v-if="config.formations.filters.formats.indexOf('instructor-led') >= 0 ">Présentiel</option>
									<option value="e-learning" v-if="config.formations.filters.formats.indexOf('e-learning') >=0 ">En ligne</option>
									<option value="webinar" v-if="config.formations.filters.formats.indexOf('webinar')>=0">Webinaire</option>
								</select>
							</div>
						</div>
						<div class="field-select" v-if="formations.params.format === 'instructor-led'">
							<div class="field-wrap">
								<select v-model="formations.params.sort" @change="searchFormations">
                  <option value="duration">Durée</option>
                  <option value="distance">Le + proche</option>
									<option value="startAt">Le + tôt</option>
                  <option value="ethics">Avec déontologie</option>
                </select>
							</div>
						</div>
						<div class="field-select" v-else>
							<div class="field-wrap">
								<select v-model="formations.params.sort" @change="searchFormations">
									<option value="duration">Durée</option>
									<option value="ethics">Avec déontologie</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<div class="o-trainings-dashboard__overflow" v-infinite-scroll="loadMoreFormations">
					<training-excerpt v-for="(formation,index) in formations.items" :key="formation.id" :data="formation"></training-excerpt>
					<loader v-if="formations.loading"></loader>
          <div v-else-if="!formations.count" class="o-trainings-dashboard__error">Aucune formation disponible avec ces filtres</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import formationCourseRepository from '@/repositories/formationCourseRepository';

	export default {
		name: 'trainings-dashboard',
		props:['data','interface'],
		data(){
			return{
				formations: {
					params:{
						format: '',
						sort: 'duration',
						limit : 10,
						offset: 0,
					},
					count: -1,
					loading: false,
					items:[]
				},
				reports: {
					count: -1,
					loading: true,
					error: false,
					items:[]
				}
			}
		},
		computed:{
			config(){ return this.$config() },
			user(){ return this.$user() }
		},
		methods:{
			loadMoreFormations(){

				if( this.formations.items.length >= this.formations.count )
					return false;

				this.queryFormations(this.formations.params.offset + this.formations.params.limit)
			},
			searchFormations(){
				this.queryFormations(0)
			},
			queryFormations(offset){

				if( this.formations.loading === true )
					return;

				this.formations.params.offset = offset;

				this.formations.loading = true;

				let params = JSON.parse(JSON.stringify(this.formations.params));

				if( params.format )
					params['format[]'] = params.format;

				delete params.format;

        formationCourseRepository.list(params).then(response => {

					this.formations.params.offset = response.offset;
					this.formations.count = response.count;
					this.formations.items = response.items;
					this.formations.loading = false;
				}) .catch(e => {
					this.formations.loading = false;
				});
			}
		},
		mounted(){

      formationCourseRepository.getReport({limit:3}).then(response=>{

				this.reports.items = response.items;
				this.reports.count = response.count;
				this.reports.loading = false;

			}, response=>{
				this.reports.loading = false;
				this.reports.error = response.body.status_text;
			});

			this.searchFormations();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-trainings-dashboard{

		&__team{
			border-radius: $border-radius; background: $c-background; margin-top: $half-space; padding: $half-space; color: $c-text;
			@media #{$to-tablet}{ margin-top: 0 }
      .app-vhs &{ padding: 0; background: none }
		}

		&__team-title{ margin-bottom: 0.5rem; font-weight: bold; color: $c-primary; }

		&__members{ position: relative; min-height: 3rem }

		&__filters{
			margin-top: $half-space; position: relative;
			&-title{
				color: $c-primary; padding-top: 2px;
				option{ font-weight: bold }
			}
			&-content{
				margin-left: auto;
				@media #{$to-tablet}{ display: none }
			}
			.field-select{
				display: inline-block;
				& + *{ margin-left: $space-s }
				select{ font-size: $font-xs; background: none; text-align-last: right; height: 30px; padding-left: 0; padding-top: 0; padding-bottom: 0 }
				option{ direction: rtl }
			}
		}

		&__header{
			@media #{$from-small}{ display: flex; align-items: baseline; }
		}

		&__error{
			margin-top: $space-m;
		}

		.button{
			&:after{ font-size: $font-s }
		}

		&__see-more{
			font-size: $font-s; text-decoration: underline; margin-top: $space-s; cursor: pointer; display: inline-block;
			&:hover{ text-decoration: none; }
		}

		&__overflow{
			max-height: 20.5rem; overflow-y: auto; -webkit-overflow-scrolling: touch; padding-bottom: 2px;
			border-top: solid 1px rgba(0,0,0,0.1); margin-top: 0.5rem;
		}
	}
</style>
