<template>
	<vue-tel-input v-model="value" v-bind="bindProps" class="vue-tel-input-custom" @input="onInput"></vue-tel-input>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
	name: "field-tel-custom",
	mixins: [abstractField],
	data() {
		return {
			isValid: false,
			bindProps: {
				mode: "international",
				defaultCountry: "FR",
        disabled: (this.schema.disabled?this.schema.disabled:false),
        preferredCountries: (this.schema.onlyCountries?[]:["FR","BE","GB"]),
        onlyCountries: (this.schema.onlyCountries?this.schema.onlyCountries:[]),
				ignoredCountries: [],
				dropdownOptions: {
					showFlags: true
				},
				inputOptions: {
					showDialCode: false,
          maxLen: 25,
          name: this.schema.inputName,
          required: (this.schema.required?this.schema.required:false),
          autocomplete: "off",
        },
				validCharactersOnly: true
			}
		};
	},
	methods: {
		onInput(number, phoneObject) {
			this.isValid = phoneObject.valid;
			this.value = number
		},
		validate(calledParent) {

			if( this.disabled || !this.value.length )
			  return true;

			this.clearValidationErrors();

			if(!this.isValid)
				this.errors.push(this.schema.errorText || 'Numéro invalide');

			if (typeof this.schema.onValidated  === 'function')
				this.schema.onValidated.call(this, this.model, this.errors, this.schema);

			if (!calledParent)
				this.$emit("validated", this.isValid, this.errors, this);

			return this.errors;
		}
	}
};
</script>

<style lang="scss">
.vue-tel-input-custom.vue-tel-input{ border: none }
.vue-tel-input:focus-within{ box-shadow: none!important }
</style>