<template>
  <div class="a-share">
    <h2 class="title">Partager avec un collaborateur ou un adhérent</h2>
    <p class="a-share__warning secondary">
      Attention:
      <span v-if="data.type === 'document'">ce document</span>
      <span v-else>cet élement</span>
      n'est accessible que depuis l'espace adhérent SNPI.<br/>
      Si vous le partagez avec une personne qui n'est pas adhérente SNPI, celle-ci ne pourra pas la consulter.
    </p>
    <form @submit.prevent="send" :id="'share_'+data.type" v-if="contacts && contacts.length">
      <vue-form-generator tag="div" :schema="form" :model="share" v-if="contacts"></vue-form-generator>

      <div class="bottom-center">
        <submit :loading="loading" text="Envoyer" :success="sent" text-success="Envoyé" :error="error" :text-error="error"></submit>
      </div>
    </form>
    <div class="a-share__link">
      <input type="text" :value="link" ref="link" />
      <div class="a-share__copy" @click="copy">Copier</div>
      <transition name="fade">
        <div class="a-share__copy-confirm" v-if="copied">Copié vers le presse-papier</div>
      </transition>
    </div>
  </div>
</template>

<script>
import shareRepository from "@/repositories/shareRepository";

export default {
  name: "share",
  props:['data'],
  data(){
    const self = this;
    return{
      sent: false,
      loading: false,
      error: false,
      copied: false,
      contacts: false,
      link: false,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true
      },
      form:{
        groups:[
          {
            styleClasses: ["form-group-background","form-group-columns"],
            fields:[
              {
                type: "multiselect-emails",
                model: "emails",
                styleClasses: ["fields-label","select-classic"],
                label: "à",
                placeholder:"Sélectionner un collaborateur",
                required: true,
                multiple: true,
                values() {
                  return self.contacts
                }
              },
              {
                type: "input",
                inputType: "text",
                model: "subject",
                label: "Objet",
                required: true,
                styleClasses: ["fields-label"]
              },
              {
                type: "textArea",
                model: "message",
                label: "Message",
                required: true,
                styleClasses: ["fields-label"],
                rows: 8,
                hint: "<span class='primary'>Le lien vers " + (self.data.type === 'document' ? "le document" : "l'élement") + " sera automatiquement intégré au message</span>"
              }
            ]
          }
        ]

      },
      share:{
        subject: 'Un adhérent souhaite partager ' + (self.data.type === 'document' ? 'un document' : 'une actualité') + ' avec vous',
        message: 'Bonjour,\n\nJe partage avec vous ' + (self.data.type === 'document' ? 'ce document' : 'cette actualité') + ' du SNPI qui pourrait vous intéresser : « ' + self.data.item.title + ' ».\n\nA bientôt !\n\n',
        type: self.data.type,
        id: self.data.item.id
      }
    }
  },
  computed:{
    user(){ return this.$user() }
  },
  methods:{
    copy(e){

      this.$refs.link.select();
      document.execCommand("copy");

      const self= this;
      self.copied = true;

      setTimeout(function(){
        self.copied = false;
      },1500)
    },
    send(){

      let self = this;

      this.loading = true;

      shareRepository.share(this.share).then(response=>{

        this.loading = false
        this.sent = true

        setTimeout(function () {
          self.sent = false;
          self.share.emails = '';
          self.$emit('close')
        }, 3500);

      }, response=>{

        this.loading = false;
        this.error = response.body.status_text;
        setTimeout(function () {
          self.error = false;
        }, 3500);
      })
    }
  },
  mounted(){

    this.contacts = this.user.staff.filter(contact => (contact.isValid)).map(function(item){
      return { id: item.email, name: item.firstname + ' ' + item.lastname }
    });

    this.link = window.location.origin+this.$router.resolve({ name: this.data.type, params: { id: this.data.item.slug?this.data.item.slug:this.data.item.id } }).href
  }
}
</script>

<style lang="scss">
@import '../../environment';
.a-share{
  @media #{$from-small}{ width: 60rem; }

  .title + *,&__warning + *,&__message + *{ margin-top: $space }
  &__warning,&__message{ font-size: $font-xs }
  &__copy{ position: overlay(); z-index: 1; cursor: pointer; text-transform: uppercase; color: #042b72;
    font-weight: bold; font-size: 1.2rem; display: flex; align-items: center; justify-content: flex-end; padding: $space-s;
    &-confirm{ display: inline-block; padding: 0.5rem; position: absolute; bottom: 100%; left: 0; color: $c-primary; font-size: 1.2rem }

    @media #{$to-phone}{ top: -6rem }
  }
  &__link{
    position: relative; margin-top: $space-l;
    input{ padding-right: 80px }
  }
  .hint{ font-size: $font-xs }

  @media #{$to-tablet}{
    .form-group-columns{ flex-direction: column }
  }
}

</style>
