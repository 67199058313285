<template>
	<button :disabled="loading||success||error||disabled" :type="type" :class="[{'button--secondary': secondary, 'button--success': success, 'button--error': error, 'button--loading': loading, 'button--disabled': disabled},mod]">
		<loader v-if="loading"></loader>
		<span v-if="success">{{ textSuccess }}</span>
		<span v-else-if="error">{{ textError }}</span>
		<span v-else>{{ text }}</span>
		<span class="button__progress" :style="'width:'+(progress*100)+'%'" v-if="progress"></span>
	</button>
</template>

<script>

	export default {
		name: "submit",
		props: {
			mod:{
				default: 'button'
			},
			type:{
				default: 'submit'
			},
			text: {
				default: 'Connexion'
			},
			secondary: {
				default: false
			},
			progress: {
				default: 0
			},
			textSuccess: {
				default: 'Connecté'
			},
			textError: {
				default: 'Erreur'
			},
			loading: {
				default: false
			},
			success: {
				default: false
			},
			error: {
				default: false
			},
			disabled: {
				default: false
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.button{
		.a-loader{
			transform: scale(0.7);
			div{ border-color: #fff transparent transparent transparent }
			&+span{ opacity: 0 }
		}
		&--small .a-loader{
			transform: scale(0.5);
		}
		&--xsmall .a-loader{
			transform: scale(0.4);
		}
		&.button--light, &.button--white, &.button--border{
      .a-loader div { border-color:$c-primary transparent transparent transparent }
		}

		&--loading{
			&:before,&:after{ opacity: 0 }
		}
		&__progress{ position: absolute; left: 0; width: 0; top: 0; height: 100%; background: white; opacity: 0.2 }
	}

	.submit--clear{
		padding: 0 1.5rem 0 0; background: none; color: $c-primary; border: none; outline: none; font-family: $font-alt;
		cursor: pointer; position:relative; transition: allow(padding);
		.a-loader{
			right: auto; transform: scale(0.5);
		}

		&.secondary{ color: $c-secondary }

		&.button--loading{ padding-right: 0; padding-left: 1.5rem; }
	}
</style>
