<template>
	<div class="o-trainings-reporting container">

		<h2 class="title" v-if="interface.title">{{ interface.title }}</h2>

		<div class="o-user-contacts__filter" v-if="hasRole(user,'company')">
			<form @submit.prevent="search" id="contacts_search">
				<button type="submit" class="button--search"></button>
				<input type="text" placeholder="Rechercher par nom/prénom" name="search" v-model="params.search"/>
			</form>
		</div>

		<div class="o-trainings-reporting__grid" :class="'o-trainings-reporting__grid--'+count" v-infinite-scroll.full="loadMore" ref="container">

      <training-reporting v-for="(report, index) in items" :key="report.contact.id" :data="report"></training-reporting>
      <loader v-if="loading" class="o-trainings-reporting__loader"></loader>
		</div>

    <div class="o-trainings-reporting__block error" v-if="error">
      <p>{{ error }}</p>
    </div>
	</div>
</template>

<script>
	import formationCourseRepository from '@/repositories/formationCourseRepository';
	import externalFormationRepository from '@/repositories/externalFormationRepository';
	import appendixRepository from "@/repositories/appendixRepository";

	export default {
		name: "trainings-reporting",
		props:['data','interface'],
		data(){
			return{
				count:-1,
				items:[],
				loading: false,
				error: false,
        params: {
          limit : 10,
          offset : 0,
          search :''
        }
			}
		},
		methods:{
      search(){
				this.query(0)
			},
      loadMore(){

        if( this.items.length >= this.count || !this.items.length )
          return false;

        this.query(this.params.offset + this.params.limit)
      },
      query(offset){

        if( this.loading === true )
          return;

        if( !offset ){

          this.signatures = [];
          this.count = -1;
        }

        this.params.offset = offset;
        this.loading = true;

        formationCourseRepository.getReport(this.params).then(response=>{
          this.loading = false;
          this.count = response.count;
          this.items = response.items;

        }, response=>{

          this.loading = false;
          this.error = response.body.status_text;
        });
      },
      reset(){
        this.params.search = '';
        this.query(0);
      }
		},
		computed:{
			user(){ return this.$user() }
		},
		mounted(){

      this.$update(this.reset);

      this.reset();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-trainings-reporting{
		text-align: left;

		& > .title{
			margin: $space $space $space-m;

			@media #{$to-tablet}{ margin-left: $space-m; margin-right: $space-m }
		}

		&__grid{
			padding: 0!important; background: none!important;
      &--1{
        .m-training-reporting{ width: 100% }
      }
			@media #{$from-small}{ display: flex; flex-wrap: wrap }
		}
	}
</style>
