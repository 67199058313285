<template>
  <div class="o-international-partners container">
    <div class="o-text-image__col--text o-international-text-header">
      <h1 class="o-text-image__uptitle title title--small">
        {{ interface.title }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "international-gallery",
  props: ["interface"],
};
</script>
