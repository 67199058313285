<template>
  <div class="o-offer">
    <h2 class="title" v-if="interface.title">{{ interface.title }}</h2>
    <div class="o-offer__content">
      <div class="o-offer__text">
        <h3 class="title title--small">Devenir adhérent</h3>
        <p>
          - Informations juridiques<br/>
          - Réduction sur les assurances<br/>
          - Signature électroniques<br/>
          - Réduction sur les prix des formations
        </p>
        <a class="button button--border" href="https://www.snpi.fr/formulaire-adhesion" target="_blank">J'adhère au SNPI</a>
      </div>
      <div class="o-offer__logo">
        <img src="/img/logo-snpi.svg"/>
        <div class="o-offer__logo-baseline">
          Premier syndicat français<br/> de l'immobilier<br/><small>depuis 1963</small>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "offer",
  props:['data','interface'],
}
</script>

<style lang="scss">
@import '../../environment';

.o-offer{
  &__content{
    display: flex; justify-content: space-between;
    @media #{$to-phone}{ flex-direction: column }
  }
  &__text{
    padding-top: 4rem;
    @media #{$to-phone}{ padding-top: 0; margin-bottom: 4rem }
    .title, p{ margin-bottom: 2rem }
    p{ line-height: 1.5 }
  }
  &__logo{
    padding: 4rem 6rem; text-align: center;
    font-family: 'Optima', Helvetica, serif; text-transform: uppercase; white-space: nowrap;
    color: #333; font-size: $font-l;
    img{ width: 10rem; margin: auto; margin-bottom: 2rem }
  }
}
</style>