<template>
  <router-link
    tag="div"
    :to="{ name: 'evenement', params: { id: data.id } }"
    :class="'m-event-item'"
  >
    <div class="m-event-picture">
      <img :src="this.image_url" alt="E Learning" v-if="this.image_url" />
    </div>
    <div class="m-event-header-details primary">
      <p data-icon="pin">{{ data.city }}</p>
      <p data-icon="calendar">{{ formatShortDateEvent(data.startAt) }}</p>
    </div>
    <div class="m-event-text wysiwyg">
      <h1 class="title-big">{{ data.formation.title }}</h1>
      <p v-html="data.formation.objective"></p>
    </div>
  </router-link>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";
import appendixRepository from "@/repositories/appendixRepository";
import cache from "@/services/cache";

export default {
  name: "training-item",
  props: ["data", "type"],
  data() {
    return {
      documents: {
        loaded: false,
        invoices: [],
        certificates: [],
      },
      downloading: false,
      showParticipants: false,
      image_url: false
    };
  },
  methods: {
    download(item) {
      this.downloading = true;
      appendixRepository.download(this.documents[item]).then(
        (response) => {
          this.downloading = false;
        },
        (response) => {
          this.downloading = false;
        }
      );
    },
    openPopin() {
      this.$popin("training-receipt", this.data.id);
    },
    loadDocuments() {
      if (!this.documents.loaded) {
        this.downloading = true;
        formationCourseRepository.getDocuments(this.data.id).then(
          (documents) => {
            this.documents = documents;
            this.documents.loaded = true;
            this.downloading = false;
          },
          (response) => {
            this.downloading = false;
          }
        );
      }
    },
    cancel() {
      if (
        window.confirm(
          "Voulez vous annuler la formation pour tous les participants ?"
        )
      ) {
        this.downloading = true;
        formationCourseRepository.cancel(this.data.id).then((response) => {
          appendixRepository.sync().then((response) => {
            window.location.reload();
          });
        });
      }
    },
    picture(){
      // search image from cms
      formationCourseRepository.getPicture(this.data.formation.id).then((response) => {
        this.image_url = response.image_url;
      });
    }
  },
  computed: {
    user() {
      return this.$user();
    },
    elearning_v2() {
      return (
        this.user && this.user.contact && this.user.contact.elearning === 2
      );
    },
    current_time() {
      return this.$currentTime();
    },
    upcoming() {
      return this.current_time < this.data.startAt;
    },
    has_actions() {
      return (
        this.type === "subscribed" &&
        this.data.participants &&
        this.hasRole(this.user, "member")
      );
    }
  },
  mounted() {
    this.picture();
  }
};
</script>

<style lang="scss">
@use "sass:math";
@import "../../environment";
.m-event-item {
  width: 30%;
  background: white;
  border-radius: 10px;
  height: 30rem;
  overflow: hidden;
  margin: 1rem;
  flex-basis: calc(33.33% - 20px);
  align-content: space-between;
  padding-bottom: 3rem;
  cursor: pointer;
  @media #{$to-phone} {
    width: 100%;
    flex-basis: 100%;
  }
  .m-event-picture {
    height: 50%;
    background-color: white;
    overflow: hidden;
    background-size: cover;
  }
  .m-event-header-details {
    width: 80%;
    border-radius: 5px;
    margin: auto;
    margin-top: -2rem;
    display: flex;
    gap: 2rem;
    padding: 1rem;
    background: #f2f5f8;
    position: relative;
    p {
      &::before {
        margin-right: 0.5rem;
      }
    }
  }
  .m-event-text {
    padding: 0 2rem;
    h1 {
      text-align: initial;
      margin-top: 2rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p {
      margin-top: 0;
      text-align: initial;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
  }
}
</style>
