<template>
  <div class="o-user-wallet block" v-if="user.isCollaborator == false">
		<h2 class="title" v-if="interface.title">{{ interface.title }}</h2>
		<div class="primary o-user-card__content equal-padding" v-if="walletLink && user">

			<div v-if="walletInfos" class="o-user-wallet__card">
				<img src="/img/member_card_bg.png" alt="member card" class="o-user-wallet__card-bg">
				<div class="o-user-wallet__card-logos">
					<img src="/img/logo_snpi_2024.png" alt="SNPI" class="o-user-wallet__card-snpi-img" v-if="this.walletInfos.card_type == 'snpi'"> 
					<img src="/img/logo-membre-expert.png" alt="SNPI" class="" v-if="this.walletInfos.card_type == 'expert'">
					<img src="/img/logo-caci.svg" alt="CACI" class="o-user-wallet__card-caci-img" v-if="this.walletInfos.card_type == 'caci'">
					<p v-if="this.currentYearMembership">{{ this.currentYearMembership }}</p>
				</div> 
				<div class="o-user-wallet__card-infos">
					<h2>{{ this.walletInfos.firstname }} {{ this.walletInfos.lastname }}</h2>
					<p class="infosBold" v-if="this.walletInfos.company_name">{{ this.walletInfos.company_name }}</p>
					<div v-if="this.walletInfos.member_id">
					<p>Numéro d'adhérent :</p>
					<p>{{ this.walletInfos.member_id }}</p>
					</div>
					<p class="infosBold" v-if="this.walletInfos.member_since">Adhérent depuis <span>{{ this.membershipYear }}</span></p>
				</div>
			</div>
			<div class="bottom-center">
				<a :href="walletLink.link" target="_blank" class="button wallet-button">
					<img src="/img/icons/icon-wallet-2.svg" alt="wallet" class="wallet-button__img">
					Ajouter au wallet
					<img src="/img/icons/icon-wallet.svg" alt="wallet" class="wallet-button__img">
				</a>
			</div>

		</div>
	</div>
</template>

<script>
	
	import walletRepository from "@/repositories/walletRepository";
	
	export default {
		name: "user-wallet",
		props:['data','interface'],
		data(){
			return{
				walletLink: false,
				walletInfos: false,
				membershipYear: false,
				currentYearMembership: false
			}
		},
		computed:{
			user(){
				return this.$user()
			}
		},
		methods:{
			setMembershipYear(timestamp) {
				let date = new Date(timestamp * 1000);
				this.membershipYear = date.getFullYear();
			},
			setcurrentYearMembership(date) {
				let year = date.split("/")[2];
				this.currentYearMembership = year;
			}
		},
		mounted() {
			walletRepository.getWalletLink('espace-adherent').then(wallet=>{
				this.walletLink = wallet
			});
			walletRepository.getWalletInfos('espace-adherent').then(wallet=>{
				this.walletInfos = wallet
				if (this.walletInfos.member_since) {
    		this.setMembershipYear(this.walletInfos.member_since);
				this.setcurrentYearMembership(this.walletInfos.current_year_membership);
  		}
			});
		}
	}
</script>

<style lang="scss">
@import '../../environment';
.o-user-wallet.block--notitle > *, .o-user-wallet.block > .title + * {
    padding: 0;
		background: none;
		box-shadow: none;
}
.o-user-wallet__card-bg {
	position: absolute;
    z-index: -1;
    max-width: 110%;
    left: -10px;
    top: -10px;
		@media #{$to-tablet} { left: 3%; top: -5px; height: 115%; width: 100%; }
    @media #{$from-small}{ width: 110%;max-height: 26rem;}
}
.o-user-wallet {
	&__card {
		display: flex;
		justify-content: space-around;
		align-items: center;
		z-index: 1;
		padding: 2rem;
		height: auto;
		position: relative;
		font-family: $font-card;
		@media #{$to-tablet} { overflow: hidden; padding: 3rem; height: 100%; }
    @media #{$from-large} {padding: 3rem;}
		&-caci-img { height: 8.7rem; margin-bottom: 2rem; }
		&-snpi-img { height: auto; max-width: 80%; align-self: center; }
		&-snpi-inline-img { height: 3.2rem; }
		&-logos {
			border-right: 0.36px solid #003DA6;
			padding-right: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;
			height: 100%;
			gap: 1.2rem;
			p { font-weight: 250; font-size: 3.3rem; text-align: center; line-height: 33.553px;}
      @media #{$to-phone} {margin-left: 2rem;}
      @media #{$only-tablet} {margin-left: 6rem;}
		}
		&-infos {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 2rem;
      font-size: $font-xs;
			gap: 0.6rem;
			width: 50%;
			height: 100%;
			h2 { font-weight: 700; font-size: 1.8rem; color: #003DA6; line-height: 20.368px; }
			.infosBold { font-weight: 700; color: #003DA6; }
		}
	}
	.wallet-button { 
		background: black; width: 70%; margin: auto; text-transform: initial; font-weight: 400; font-size: 1.6rem;
		display: flex; gap: 1rem; align-items: center; justify-content: center;
	}
}
</style>
