import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";

export default {

	getStaff(params) {
		return cache.list('company/contact', params);
	},

	get(params) {
		return new Promise((resolve, reject) => {
			Vue.http.get('company', {params:params}).then(response=>{
				resolve(response.body.response);

				store.commit('permissions', response.body.response.roles);
			}, reject)
		});
	},

	getActiveStaff(params) {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/contact?hasAccount=1', {params:params}).then(response=>{
				resolve(response.body.response);
			}, reject)
		});
	},

	getCustomRoles(params) {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/custom-role', {params:params}).then(response=>{
				resolve(response.body.response);
			}, reject)
		});
	},

	update(id, data) {

		return Vue.http.post('company/'+id, data)
	},

	create(data) {

		return Vue.http.post('company', data)
	},

	uploadFee(data) {

		return Vue.http.post('company/fee', data)
	},

	downloadFee(id) {

		document.location.href = Vue.http.options.root+'/company/'+id+'/fee'
	},

	associate(id, data) {

		return Vue.http.post('company/contact/'+id, data)
	},

	getRepresentatives() {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/representatives').then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	getSiren(siren) {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/search',{params:{key: 'siren',value: siren}}).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},
	getSoftware() {
		return new Promise((resolve, reject) => {
			Vue.http.get('company/software').then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	updateSoftware(data) {

		return new Promise((resolve, reject) => {
			Vue.http.put('company/software', data).then(response => {
				resolve(response.body.response)
			}, reject)
		});
	},

	switchCompany(id) {
		return new Promise((resolve, reject) => {
			Vue.http.get('user/switch/'+id).then(response => {
				resolve(response.body.response)
				Vue.http.headers.common['X-Switch-User'] = String(response.body.response.id_to_impersonate);
				localStorage.setItem('current_company', String(response.body.response.id_to_impersonate));
			}, reject)
		});
	},

	getRelatedCompanies(){
		return new Promise((resolve, reject) => {
			Vue.http.get('switch/addresses').then(response => {
                store.commit("attachedAccounts", response.body.response)
                resolve();
			}, reject)
		});
	}

	// checkup() {
	// 	return new Promise((resolve, reject) => {
	// 		Vue.http.get('company/checkup').then(response => {
	// 			resolve(response.body.response)
	// 		}, reject)
	// 	});
	// }
};
