<template>
  <div class="o-training-details o-event-details" v-if="data">
    <!--		TODO back to same loaded trainings ?-->
    <a
      @click="$router.push('/international/calendrier')"
      data-icon="chevron-left"
      class="o-training-details__back"
      >retour</a
    >
    <h1 class="title">{{ interface.title }}</h1>
    <div class="o-training-details__content">
      <div class="o-training-details__heading">
        <div>
          <p class="primary">{{ formatShortDateEvent(data.startAt) }}</p>
          <div class="title-big">{{ data.formation.title }}</div>
          <p class="o-event-details__objectives" v-html="ntobr(data.formation.objective)"></p>
        </div>
      </div>

      <div class="o-training-details__grid">
        <div class="o-training-details__details">
          <span
            data-icon="pin"
            v-if="data.formation.duration.hours"
            class="icon-flex"
            >{{ data.city }}</span>
        </div>
      </div>

      <div class="o-training-details__content">
        <p class="subtitle">Programme</p>
        <div class="text" v-html="ntobr(data.formation.formation_program)"></div>
      </div>
      <div class="o-training-details__image" v-if="data.formation.picture_url">
        <img :src="data.formation.picture_url" alt="E Learning" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "event-details",
  props: ["interface", "data"],
  data() {
    return {
      formation: this.data,
    }
  },
  computed: {
    google() {
      return this.$store.getters.google();
    }
  }
};
</script>

<style lang="scss">
.o-event-details {
  .o-training-details__image {
    img {
      border-radius: 3px;
      max-height: 35rem;
      object-fit: cover;
    }
  }
  &__objectives {
    width: 70%;
  }
}
</style>
