<template>
  <div class="m-rating-news">
    <h1 class="title">Je donne mon avis</h1>
    <div>
      <p>Est-ce que cette information vous a plu ?</p>
      <div class="ratings" :class="{'ratings--rated' : model.rating != null}">
        <span v-for="(rate, index) in new Array(5)" :key="index" class="rating" :class="{'rating--rated' : model.rating > index}" @click="setRating(index + 1)">★</span>
      </div>
    </div>
    <form @submit.prevent="submitRating">
      <vue-form-generator tag="div" :schema="form" :model="model"></vue-form-generator>

      <div class="bottom-center">
        <submit :loading="loading" text="Envoyer" :success="success" text-success="Envoyé" :error="error" :text-error="error"></submit>
      </div>
    </form>
  </div>
</template>

<script>

import newsRepository from "@/repositories/newsRepository";
import userRepository from "../../repositories/userRepository";

export default {
  name: "rating-news",
  data(){
    return{
      loading: false,
      success: false,
      error: false,
      form:{
        fields:[
          {
            type: "textArea",
            model: "comment",
            label: "Commentaire",
            styleClasses: ["fields-label"],
            rows: 5,
            max: 5000
          }
        ]
      },
      model:{
        rating: 0
      }
    }
  },
  props:['data'],
  computed:{
    user(){ return this.$user() }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    setRating(value){
      this.model.rating = value
    },
    submitRating(){
      this.loading= true;

      newsRepository.ratingNews(this.data.id,{rating: this.model.rating, comment: this.model.comment }).then(response=>{

        this.loading = false;
        this.success = true;

        userRepository.get()
        setTimeout(this.close, 1500);
      })
    }
  },
  mounted(){
    if(this.data.rating)
      this.model.rating = this.data.rating
  }
}
</script>

<style lang="scss">
@import '../../environment';

.m-rating-news{
  & > * + *{ margin-top: $space-m }
  .ratings:hover{
    .rating{ color: $c-primary }
  }
  .rating{
    transition: allow(color); cursor: pointer;color: $c-border; font-size: 2.5rem;
    &:hover ~ .rating{ color: $c-border }
  }
}

</style>
