<template>
	<div class="o-faq-content container block">
		<h1 class="title">{{  data.title }}</h1>
		<div>
			<div class="o-faq-content__questions">
				<div v-for="(theme,index) in data.themes" :key="index">
					<h2 class="title title--small">{{ theme.theme }}</h2>
					<accordion :group="theme.title" :content="theme.questions" ></accordion>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "faq-content",
	props:['data']
}
</script>

<style lang="scss">
@import '../../environment';
.o-faq-content{
	&__questions{
		 margin-top: $space-m; margin-bottom: $space-m;
		@media #{$from-small}{
			display: grid; grid-template-columns: 1fr 1fr; grid-gap: $space-l;
		}
		@media #{$to-tablet}{
			& > * + *{ margin-top: $space }
		}
		.title + *{ margin-top: $space-s }
	}
}
.v-collapse-content{
	max-height: 0;
	transition: max-height 0.3s ease-out;
	overflow: hidden;
	padding: 0;
}

.v-collapse-content-end{
	transition: max-height 0.3s ease-in;
	max-height: 500px;
}
</style>