<template>
  <div class="m-player">
    <div class="m-player__info">
      <div class="m-player__thumbnail" v-if="data.thumbnail">
        <img :src="data.thumbnail+'?w=50&h=50'"/>
      </div>
      <h2 class="m-player__title">
        {{ data.title }}
        <span class="m-player__subtitle" v-if="data.assets[0].title!=='Manuel'">{{ data.assets[0].title }}</span>
      </h2>
    </div>
    <div class="m-player__controls">
      <a class="m-player__controls-backward" data-icon="fast-backward" @click="backward"></a>
      <a class="m-player__controls-play" :data-icon="isPlaying?'pause':'play'" @click="toggleAudio"></a>
      <a class="m-player__controls-forward" data-icon="fast-forward" @click="forward"></a>
    </div>
    <div class="m-player__progress">
      <div class="m-player__progress-current">{{ convertTime(playbackTime) }}</div>
      <div class="m-player__progress-bar" @mousemove="updateSelected"  @click="setTime" ref="progress">
        <span :style="'width:'+progress*100+'%'"></span>
        <span :style="'width:'+selected*100+'%'" class="selected"></span>
      </div>
      <div class="m-player__progress-total">{{ convertTime(totalTime) }}</div>
    </div>
    <a class="m-player__close" data-icon="close" @click="close"></a>
    <audio :src="file" ref="player" autoplay></audio>
  </div>
</template>

<script>
import documentRepository from '@/repositories/documentRepository';

export default {
  name: "player",
  props:['data'],
  data(){
    return{
      loading:true,
      totalTime:0,
      current:0,
      progress:0,
      selected:0,
      file:false,
      isPlaying:false,
      listenerActive:false,
      playbackTime:false
    }
  },
  methods:{
    convertTime(seconds){
      const format = val => `0${Math.floor(val)}`.slice(-2);
      let hours = seconds / 3600;
      let minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    playbackListener(e) {
      let audio = this.$refs.player;
      this.playbackTime = audio.currentTime;
      this.totalTime = audio.duration;
      this.progress = audio.currentTime/audio.duration
    },
    pauseListener() {
      this.isPlaying = false;
    },
    updateSelected(e) {

      this.selected = Math.max(0, Math.min(1, (e.clientX-this.$refs.progress.offsetLeft)/this.$refs.progress.clientWidth))
    },
    setTime() {
      let audio = this.$refs.player;
      audio.currentTime = audio.duration*this.selected
      this.selected = 0
      audio.play();
      this.isPlaying = true;
    },
    endListener() {
      this.isPlaying = false;
    },
    canplayListener() {
      let audio = this.$refs.player;
      audio.play().then(()=>{
        this.isPlaying = true;
      })
    },
    close() {
      this.$store.commit('podcast', false);
    },
    toggleAudio() {
      let audio = this.$refs.player;
      if (audio.paused) {
        if( audio.play() )
          this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    backward() {
      let audio = this.$refs.player;
      audio.currentTime -= audio.duration/10;
    },
    forward() {
      let audio = this.$refs.player;
      audio.currentTime += audio.duration/10;
    }
  },
  mounted() {
    let audio = this.$refs.player;

    documentRepository.getUrl(this.data.id, 0).then(
        response=>{
          this.file = response;
          this.loading = false;
          audio.addEventListener("canplay", this.canplayListener);
          audio.addEventListener("timeupdate",this.playbackListener);
          audio.addEventListener("ended", this.endListener);
          audio.addEventListener("pause", this.pauseListener);
        },
        response=>{

        });
  },
  beforeDestroy() {
    let audio = this.$refs.player;
    audio.removeEventListener("timeupdate", this.playbackListener);
    audio.removeEventListener("ended", this.endListener);
    audio.removeEventListener("pause", this.pauseListener);
    audio.removeEventListener("canplay", this.canplayListener);
  }
}
</script>

<style lang="scss">

@import '../../environment';

.m-player{
  position: fixed; z-index: 99999; left: 0; width: 100%; bottom: 0; background: #fff; padding: $space-s;
  box-shadow: $box-shadow; justify-content: space-between; display: flex; flex-flow: row;
  &__thumbnail{
    margin-right: 1rem; flex-shrink: 0;
    img{ width: 5rem; height: 5rem; object-fit: cover; border-radius: $border-radius }
  }
  &__info{
    display: flex; flex-flow: row; align-items: center; width: 40vw;
	  @media #{$to-phone}{ width: 33vw }
  }
  &__title{
    color: $c-primary; font-weight: bold; text-align: left; padding-right: 5rem; white-space: nowrap; width: 100%; text-overflow: ellipsis; overflow: hidden;
    span{ font-weight: normal; color: $c-main; display: block; font-size: 80% }
  }
  &__controls{
    font-size: 3rem; white-space: nowrap;
    &-play{
      background: $c-primary; width: 5rem; height: 5rem; border-radius: 50%; display: inline-block;
      color: #fff; text-align: center; line-height: 4.5rem; cursor: pointer;
      &[data-icon="play"]:before{ margin-left: 3px }
      &[data-icon="pause"]:before{ font-size: 80%; margin-top: -2px; display: inline-block }
	    @media #{$to-phone}{
		    width: 4rem; height: 4rem; line-height: 3.7rem; font-size: 2.8rem;
	    }

    }
    &-backward, &-forward{ color: $c-primary; cursor: pointer }
    &-backward{ margin-right: 1rem }
    &-forward{ margin-left: 1rem }
  }
  &__close{
    font-size: 2rem; color: $c-primary; display: flex; align-items: center; cursor: pointer;
  }
  &__progress{
    display: flex; align-items: center; padding: 0 5rem; font-size: 80%; width: 40vw;
    @media #{$to-phone}{ font-size: 90%; padding: 0 2rem 0 3rem }
    &-total{
      @media #{$to-phone}{
        white-space: nowrap;
        &:before{ content: '/ '}
      }
    }
    &-bar{
      margin: 0 1rem; position: relative; cursor: pointer; padding: 1rem 0; width: 100%;
      @media #{$to-phone}{ position: absolute; bottom: 100%; left: 0; padding: 0; margin: 0; background: #fff }
      &:after{ background: $c-background; content: ''; width: 100% }
      span{ background: $c-primary; z-index: 2 }
      span, &:after{ display: inline-block; height: 4px; border-radius: 5px; position: absolute; top: 50%; left: 0; margin-top: -2px }
      span.selected{ z-index: 3; opacity: 0.3 }
    }
  }
}

</style>
