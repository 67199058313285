<template>
	<main class="v-page">
		<foad-content></foad-content>
	</main>
</template>

<script>

	export default {
		name: "Foad"
	}
</script>