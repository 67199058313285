<template>
	<div class="o-rating container">
		<div>
			<p>Est-ce que cette information vous a plu ?</p>
			<div class="ratings ratings--parent" :class="{'ratings--rated' : data.rating != null, 'ratings--disabled': user.rates.resources.includes(this.data.id)}"  @click="popinRating">
				{{ Math.round(data.rating.average * 100) / 100 }}
				<span v-for="(rate, index) in new Array(5)" :key="index" class="rating" :class="{'rating--rated' : data.rating.average > index}">★</span>
				<span><small>{{ data.rating.count }} <template v-if="data.rating.count > 1">votes</template><template v-else>vote</template></small></span>
			</div>
		</div>

		<a class="button button--small o-rating__cta button--disabled" v-if="user.rates.resources.includes(this.data.id)">Vous avez déjà noté cet article</a>
		<a @click="popinRating" class="button button--small o-rating__cta" v-else>Donner mon avis</a>

		<a @click="share" class="button button--small o-rating__share" data-icon="share">Partager cet article</a>
	</div>
</template>

<script>
export default {
	name: "rating",
	props:['data'],
	computed:{
		user(){ return this.$user() }
	},
	methods:{
		popinRating(){
			this.$popin('rating-news', {id:this.data.id, data:this.data })
		},
		share(){
			this.$popin('share', {type:'news', item: this.data});
		}
	},
  data(){
    return{
      rates: [],
      loading: true
    }
  }
}
</script>

<style lang="scss">
	@import '../../environment';
	.o-rating{
		text-align: left; max-width: 96rem; padding-top: $space-l; padding-bottom:$space-l; display: flex; align-items: center;

		&__cta{ margin-left: auto }

		@media #{$to-tablet}{
			&__share{ margin-left: 0; margin-top: $space-s }
		}

		@media #{$to-tablet}{ display: block; padding-left: $space-m; padding-right: $space-m }

		.rating{ color: $c-border }
	}
	.ratings{
		font-size: 2rem;
		small{ font-size: $font-s; margin-left:$space-s }

		.rating--rated{ color: $c-primary }
		&--parent{ cursor: pointer }
		&--disabled{ pointer-events: none }
	}
</style>
