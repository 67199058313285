<template>
	<div class="m-turnover">
		<div class="title-big">Vos contrats d'assurance</div>
		<p class="primary"><strong>Renouvellement {{currentYear}} de vos contrats d’assurance</strong></p>
		<div class="m-turnover__content">
			<form @submit.prevent="updateTurnover()" id="turnover" ref="form">
				<vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions" class="m-contact-add__form center"></vue-form-generator>
				<submit class="button--secondary m-sales__submit" :loading="loading" :success="success" text-success="Validé" text="Déclarez votre CA" ></submit>
			</form>
		</div>
	</div>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";
	import userRepository from "@/repositories/userRepository";

	import VueFormGenerator from "vue-form-generator";

	export default {
		name: "turnover",
		props:['data'],
		data(){
			return{
				loading:false,
				success: false,
        currentYear: new Date().getFullYear(),
				formOptions: {
					validateAfterLoad: false,
					validateAfterChanged: true
				},
				model:{
          turnover:""
        },
				form:{
					fields:[
						{
              type: "radios",
              model: "turnover",
              styleClasses:["fields-label","radio-list-inputs","radio-list-inputs--column"],
              label: "Merci de cocher la case correspondant à votre chiffre d'affaires "+(new Date().getFullYear()-1),
              required: true,
              values:[
			  	{ name: "Inférieur à 1 000 000€", value: 'turnover1' },
                { name: "Entre à 1 000 001 et 2 000 000€", value: 'turnover2' },
                { name: "Entre à 2 000 001 et 2 499 999€", value: 'turnover3' },
                { name: "Entre à 2 500 000 et 4 999 999€", value: 'turnover4' },
                { name: "Supérieur à 5 000 000€", value: 'turnover5' },
              	]
						},
						{
							type: "checkbox-custom",
							model: "validate",
							inputName: "validate",
							required: true,
							label: "Je certifie sur l'honneur l'exactitude des informations communiquées"
						}
					]
				}
			}
		},
		methods:{
			close(){ this.$emit('close') },
      updateTurnover(){

				this.loading = true;
				let formData = new FormData( this.$refs.form );

				companyRepository.update(this.data,formData).then(response=>{

					this.loading = false;
					this.success = true;

					userRepository.get();

					setTimeout(this.close,1500);

				},response=>{

					this.loading = false;
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-turnover{
		&__content{
      margin-top: $space
    }

    .field-checkbox-custom{ font-size: $font-m }

		&__submit{ margin-top: $space }
	}
</style>