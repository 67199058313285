<template>
	<div class="a-radial" :class="'a-radial--'+mod+' '+(current===total?'a-radial--completed':'')">
		<span class="label" v-if="current < total && !mod">
			{{ current }}/{{ total }}
		</span>
		<span v-else-if="!mod" data-icon="check" class="a-radial__icon"></span>
		<div class="pie" :style="{'clip': percentage > 50 ? 'rect(auto, auto, auto, auto)' : ''}">
			<div class="left-side half-circle" :style="{'transform': 'rotate(' + percentage * 3.6 + 'deg)'}"></div>
			<div class="right-side half-circle" :style="{'transform': percentage > 50 ? 'rotate(180deg)' : ''}"></div>
		</div>
		<div class="shadow"></div>
	</div>
</template>

<script>
	export default {
		name: "radial",
		props:['current','total','mod'],
		computed:{
			percentage() {
				return (this.current / this.total) * 100;
			}
		}
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';

	$default-size: 3rem;
	$label-font-size: math.div($default-size, 4);
	$label-font-size-redo: $default-size * 4;

	// -- mixins
	@mixin size($width, $height) {
		height: $height;
		width: $width;
	}

	.a-radial {
		@include size($default-size, $default-size);
		position: relative;

		&:nth-child(3n + 1) {
			clear: both;
		}

		&__icon{ position: overlay(); display: flex; align-items: center; justify-content: center; }

		.pie {
			@include size(100%, 100%);
			clip: rect(0, $default-size, $default-size, math.div($default-size, 2));
			left: 0;
			position: absolute;
			top: 0;
			z-index: 1;

			.half-circle {
				@include size(100%, 100%);
				border: 2px solid $c-primary;
				border-radius: 50%;
				clip: rect(0, math.div($default-size, 2), $default-size, 0);
				left: 0;
				position: absolute;
				top: 0;
			}
		}

		.label {
			border-radius: 50%;
			color: $c-primary;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			position: overlay();
			font-size: 1.1rem;
			z-index: 2;
			.smaller {
				color: #bdc3c7;
				font-size: .45em;
				padding-bottom: 20px;
				vertical-align: super;
			}
		}

		&--completed{
			.pie{
				.half-circle { border-color: $c-green }
			}
			.label, .a-radial__icon { color: $c-green }
		}

		&--nolabel{
			.pie{
				.half-circle { border-color: $c-primary }
			}
		}

		.shadow {
			@include size(100%, 100%);
			border: math.div($default-size, 10) solid $c-background;
			border-radius: 50%;
		}
	}
</style>