<template>
	<div class="m-sales">
		<div class="title-big">Votre contrat d'assurance en responsabilité civile professionnelle</div>
		<p class="primary"><strong>Déclarez votre CA {{currentYear-2}} afin de vous assurer que l'option souscrite en {{currentYear-1}} est toujours valable pour l'année {{currentYear}}</strong></p>
		<div class="m-sales__content">
			<form @submit.prevent="updateSales()" id="sales-rcp" ref="form">
				<vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions" class="m-contact-add__form center"></vue-form-generator>
				<submit class="button--secondary m-sales__submit" :loading="loading" :success="success" text-success="Validé" text="Déclarez votre CA" ></submit>
			</form>
		</div>
	</div>
</template>

<script>

	import companyRepository from "@/repositories/companyRepository";
	import userRepository from "@/repositories/userRepository";

	import VueFormGenerator from "vue-form-generator";

	export default {
		name: "sales",
		props:['data'],
		data(){
			return{
				loading:false,
				success: false,
        currentYear: new Date().getFullYear(),
				formOptions: {
					validateAfterLoad: false,
					validateAfterChanged: true
				},
				model:{
          sales:""
        },
				form:{
					fields:[
						{
							type: "input",
							inputType: "number",
							model: "sales",
							inputName: "sales",
							placeholder: "0",
							required: true,
							validator: VueFormGenerator.validators.integer,
							hint: "€ de chiffres d'affaires en "+(new Date().getFullYear()-1),
							styleClasses: "input-flex"
						},
						{
							type: "checkbox-custom",
							model: "validate",
							inputName: "validate",
							required: true,
							label: "Je certifie sur l'honneur l'exactitude des informations communiquées"
						}
					]
				}
			}
		},
		methods:{
			close(){ this.$emit('close') },
			updateSales(){

				this.loading = true;
				let formData = new FormData( this.$refs.form );

				companyRepository.update(this.data,formData).then(response=>{

					this.loading = false;
					this.success = true;

					userRepository.get();

					setTimeout(this.close,1500);

				},response=>{

					this.loading = false;
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.m-sales{
		&__content{ margin-top: $space }

		.input-flex{
			display: flex; align-items: center; justify-content: center;
			.field-wrap + *{ margin-left: $space-m }
			.hint{ color: $c-primary }
		}

		.field-checkbox-custom{ font-size: $font-m }

		&__submit{ margin-top: $space }
	}
</style>