import { render, staticRenderFns } from "./legal-representatives.vue?vue&type=template&id=1105b8e2"
import script from "./legal-representatives.vue?vue&type=script&lang=js"
export * from "./legal-representatives.vue?vue&type=script&lang=js"
import style0 from "./legal-representatives.vue?vue&type=style&index=0&id=1105b8e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports