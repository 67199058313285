<template>
<div class="a-question" :class="{'a-question--result': step === 2}">
	<div v-if="step === 1">
		<div class="a-question__number">Question {{ number }}/{{total}}</div>
		<div v-if="data.name" class="a-question__title title-med">{{data.name}}</div>
	</div>


	<div class="a-question__header" v-else-if="step === 2" @click="active = !active" :class="{'a-question__header--active': active}">
		<span :data-icon="isCorrect ? 'check-circle' : 'x-circle'"></span>
		<div>
			<div class="a-question__number">Question {{ number }}</div>
			<div class="a-question__details" v-html="data.details" v-if="data.details"></div>
		</div>
		<span data-icon="chevron-bottom"></span>
	</div>

	<transition name="accordion-item" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
		<div class="a-question__answers" v-if="step === 1 || (step === 2 && active)">
			<div v-if="!isCorrect && step === 2" class="a-question__correction">
				<span v-if="correctAnswers.length > 1">Les bonnes réponses étaient : </span>
				<span v-else="correctAnswers.length > 1">La bonne réponse était </span>
				<span class="a-question__upper">{{ correctAnswers.join(', ') }}</span>
			</div>
			<answer v-for="(answer, index) in data.answers" :key="index" :data="answer" :name="data.name" :id="data.id" :number="number" :subnumber="index" :type="data.type" @selected-answer="countAnswers" @deselected-answer="removeAnswer" :class="{'a-answer--selected':radioChecked === index}" :radioChecked="radioChecked"></answer>
		</div>
	</transition>
</div>
</template>

<script>

export default {
name: "question",
	props:['data','number','total','step'],
	data(){
		return{
			isAnswered: false,
			isCorrect: false,
			count: 0,
			countCorrect: 0,
			radioChecked: false,
			totalCorrect: false,
			correctAnswers:[],
			active: false,
		}
	},
	methods:{
		startTransition(el) {
			el.style.height = el.scrollHeight + 'px'
		},
		endTransition(el) {
			el.style.height = ''
		},
		removeAnswer(value){

			this.count--;

			if(value)
				this.countCorrect--

			this.isCorrect = this.countCorrect === this.totalCorrect && this.totalCorrect === (this.data.type === 'single' ? 1 : this.count);

		},
		countAnswers(valid, answer){
			this.$parent.$emit("answer-checked", {editing: true})

			this.radioChecked = answer

			this.count++;

			if(valid)
				this.countCorrect++

			this.isCorrect = this.countCorrect === this.totalCorrect && this.totalCorrect === (this.data.type === 'single' ? 1 : this.count);
		}
	},
	watch:{
		isCorrect(){
			this.$emit('question-answered', this.isCorrect, this.number)
		}
	},
	mounted(){
		this.totalCorrect = this.data.answers.filter(item => item.correct).length

		let alphabet = "abcdefghijklmnopqrstuvwxyz";
		let self = this;
		this.data.answers.forEach(function(item,index){
			if(item.correct)
				self.correctAnswers.push(alphabet.charAt(index))
		})
	}
}
</script>

<style lang="scss">
	@import '../../environment';

	.a-question{
		$c: &;
		counter-reset: answer; overflow:  hidden;

		& + &{ margin-top: $space-l }

		&--result + &{ margin-top: $space }
		&__number{
			font-weight: bold;
			& + *{ margin-top: $space-s }
		}
		&__answers{
			* + &{ margin-top: $space-m }
		}

		&--result{
			border: solid 1px $c-border; border-top-left-radius: $border-radius; border-top-right-radius: $border-radius;
			#{$c}__header{
				border-top-left-radius: $border-radius; border-top-right-radius: $border-radius; padding:$space-m; background: $c-border; display: flex; align-items: center; cursor: pointer;
				& > * + *{ margin-left: $space-m }
				[data-icon]{ font-size: 2rem }
				[data-icon="check-circle"]{ color: $c-green }
				[data-icon="x-circle"]{ color: $c-red }
				[data-icon="chevron-bottom"]{ transition: allow(transform); font-size: 1.4rem }
				&--active [data-icon="chevron-bottom"]{ transform: rotate(180deg) }
			}
			#{$c}__answers{
        padding: $space-m; padding-top: $space-s;
				* + &{ margin-top:$space-s }
			}
		}

		&__upper{ text-transform: uppercase }

		&__correction{
			font-weight: bold; color: $c-red;

			& + *{ margin-top: $space-s }
		}
	}

</style>