<template>
	<div class="a-invoice">
		<div class="a-invoice__details">
			<div class="a-invoice__line">
				<span>Sous total</span>
				<span>{{ formatPrice(data.amount_et) }}</span>
			</div>
			<div class="a-invoice__line">
				<span>TVA (20%)</span>
				<span>{{ formatPrice(data.vat) }}</span>
			</div>
			<div class="a-invoice__line a-invoice__line--total">
				<span>TOTAL TTC</span>
				<span>{{ formatPrice(data.amount_ati) }}</span>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: "invoice",
		props:['data'],
		data(){
			return {
				downloading : false
			}
		}
	}
</script>

<style lang="scss">

	@import '../../environment';
	.a-invoice{

		&__details{
			margin-top: $space-m;
			form{ text-align: right; margin-top: 1rem }
		}


		&__line{
			display: flex; justify-content: space-between; text-transform: uppercase;

			& + * { margin-top: 0.5rem }

			&--total{ font-size: $font-xl; color: $c-primary; font-weight: bold; margin-top: $space-m }
		}
    &+form{ margin-top: 2rem; text-align: right }
	}
</style>