<template>
<div class="m-checkup">

	<div class="m-checkup__details" v-if="mod === 'details' && content[type].length > 0">
		<div class="title error">Attention</div>
		<p class="primary">Certaines informations de votre compte sont manquantes.</p>
		<p class="primary">Si vous ne les renseignez pas, vous risquez de ne pas pouvoir accéder à certaines fonctionnalités.</p>
		<p class="primary">Voici ce que vous devez compléter :</p>

		<div class="m-checkup__container">
			<accordion :group="'item'" :content="content[type]" v-if="type"></accordion>
		</div>

	</div>

	<div v-else-if="mod === 'notifs'" class="m-checkup__notifs">
			<ul>
				<li v-if="company && company.invalid.count">
					<router-link :to="{path:'/user/agence'}" class="m-checkup__profile">
						<div class="m-checkup__thumb">
							<img :src="company.logo" :alt="company.name" v-if="company.logo"/>
							<span v-else>{{company.name.substr(0,1)}}</span>
            </div>
            <div>
              <div>{{company.name}}</div>
              <small class="m-checkup__link">
                <span class="link">{{ company.invalid.count }} <span v-if="company.invalid.count > 1">erreurs</span><span v-else>erreur</span></span> pour cette société
              </small>
            </div>
          </router-link>
				</li>
				<li v-for="item in persons">
					<router-link :to="{path:'/user/collaborateurs'}" class="m-checkup__profile">
						<div class="m-checkup__thumb">
              <img :src="item.avatar" :alt="item.firstname + ' ' + item.lastname" v-if="item.avatar"/>
              <span v-else>{{item.firstname.substr(0,1)}}{{item.lastname.substr(0,1)}}</span>
            </div>
            <div>
              <div>{{item.firstname}} {{item.lastname}}</div>
              <small class="m-checkup__link">
                <span class="link">{{ item.invalid.count }} <span v-if="item.invalid.count > 1">erreurs</span><span v-else>erreur</span></span> pour ce collaborateur
              </small>
            </div>
          </router-link>
				</li>
			</ul>
	</div>


</div>
</template>

<script>
export default {
	name: "checkup",
	props:['data','mod','type'],
	data(){
		return{
			content:{
				persons:[],
				company:[]
			},
			persons: false,
			company: false
		}
	},
	mounted(){

		this.persons = this.data.filter(item => item.entity === 'legalRepresentative' || item.entity === 'collaborator');

		if(this.persons && this.persons.length > 0){

			for(let i=0;i<this.persons.length;i++ ){

				let person = this.persons[i];
				let errors = person.invalid.count;

				let answer = "<ul>";
				if(person.invalid.data.contact && person.invalid.data.contact.length > 0){
					for(let j=0;j<person.invalid.data.contact.length;j++ )
						answer += "<li>Contact : " + person.invalid.data.contact[j] + "</li>";
				}
				if(person.invalid.data.homeAddress && person.invalid.data.homeAddress.length > 0){
					for(let j=0;j<person.invalid.data.homeAddress.length;j++ )
						answer += "<li>Adresse personnelle : " + person.invalid.data.homeAddress[j] + "</li>";
				}
				if(person.invalid.data.workAddress && person.invalid.data.workAddress.length > 0){
					for(let j=0;j<person.invalid.data.workAddress.length;j++ )
						answer += "<li>Adresse professionnelle : " + person.invalid.data.workAddress[j] + "</li>";
				}

				answer += "</ul>"

				this.content.persons.push({
					question: this.persons[i].firstname + " " + this.persons[i].lastname + (errors > 0 ? (' (' + errors + (errors > 1 ? ' erreurs' : ' erreur') + ')') : ''),
					answer: answer
				})
			}

		}

		let companies = this.data.filter(item => item.entity === 'company');

		if( companies.length ){

			this.company = companies[0];

			if(this.company.invalid.count){

				let answer="<ul>";

				let errors = this.company.invalid.count;

				for(let i=0;i<this.company.invalid.data.length;i++ )
					answer += "<li>" + this.company.invalid.data[i] + "</li>";
				answer += "</ul>"

				this.content.company.push({
					question: 'Société' + (errors > 0 ? (' (' + errors + (errors > 1 ? ' erreurs' : ' erreur') + ')') : ''),
					answer: answer
				})
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-checkup{
	text-align: left;
	p{ white-space: normal }
	.title{ margin-bottom: 1rem }
	& > * + *, & + ul{ margin-top: 1.5rem }

	&__details{
		padding-bottom: $space-s; border-bottom: solid 1px $c-border; margin-bottom: $space;
		.a-accordion-item__title{ padding-left: 0 }
		ul{
			list-style-type: disc; padding-left: $space;

			& > li + li{ margin-top: $space-s }
		}
		ol{ list-style-type: decimal; padding-left: $space }
	}

	&__notifs{
		max-height: 50vh; overflow: hidden; overflow-y: auto; padding-right: 1rem;
		ul{
			& > li + li{ margin-top: 1.5rem }
		}
	}

	&__profile{ display: flex; align-items: center; }

	&__thumb{
		width: 3rem; height: 3rem; border-radius: 50%; background: $c-background-darker; color: white; display: flex; align-items: center; justify-content: center; text-transform: uppercase;
		overflow:hidden;

		img{ width: 100%!important; height: 100%!important; object-fit: cover; margin-bottom: 0!important }

		& + *{ margin-left: $space-s }
	}

	&__link{
    margin-left: auto;
    .link{ font-size: inherit }
  }

	&__container{ margin-top: $space-s; max-height: 18rem; overflow: hidden; overflow-y: auto }
}

</style>