<template>
  <div class="container o-news-list">
    <div class="block">
      <h2 class="o-news-list__title title">{{ interface.title }}</h2>
    </div>
    <div class="o-news-list__pushes" v-infinite-scroll.full="loadMore">
      <news v-for="(item, index) in news" :key="item.id" :data="item" :large="1"></news>
    </div>
  </div>
</template>

<script>

	import newsRepository from '@/repositories/newsRepository';

	export default {
		name: 'news-list',
		props:['data', 'interface'],
		data(){
			return{
				params:{
					limit : 15,
					offset: 0
				},
				count: -1,
				loading: false,
				news: []
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		methods:{
			loadMore(){

				if(this.news.length >= this.count || !this.news.length)
					return false;

				this.query(this.params.offset + this.params.limit)

			},
			query(offset){

        if( this.loading )
          return;

				this.loading = true;
				this.params.offset = offset;

				newsRepository.find(this.params).then(response => {
          this.count = response.count;
					this.news = response.items;
          this.loading = false;
        });
			}
		},
		mounted(){
			this.query(0);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-news-list{
		$c: &;

		&__pushes {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: $space-m;
      grid-row-gap: $space-m;
      @media #{$to-small}{ grid-template-columns: repeat(2, 1fr); }
      .m-news{
        @media #{$from-tablet}{ margin-top: 0 }
        background: #fff; box-shadow: $subtle-box-shadow; border: 0; text-align: left;
      }
			@media #{$to-phone}{
        display: block;
			}
		}
	}
</style>
