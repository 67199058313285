import Vue from "vue";
import cache from "@/services/cache";
import store from "@/services/store";

export default {

	find(type) {

		return cache.list('term', {taxonomy:type});
	},

	get() {

		return new Promise((resolve, reject) => {
			Vue.http.get('term').then(response=>{
				store.commit('terms', response.body.response.items);
			}, reject)
		});
	},
};
