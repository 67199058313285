<template>
    <div v-if="registered || data.registered" class="google-connect-notice__content">
        <h1 class="google-connect-notice__title">Inscription enregistrée</h1>
        <span class="google-connect-notice__prompt">Merci, nous vous avons envoyé un email pour confirmer la validation de votre compte</span>
    </div>
    <div v-else>
        <div v-if="data.type == 'doesnt-exist'" class="google-connect-notice">
            <loader static="1" v-if="loading"></loader>
            <div class="google-connect-notice__content" v-else-if="!registered">
                <h1 class="google-connect-notice__title">Aucun compte n’est associé à cette adresse e-mail.</h1>
                <span class="google-connect-notice__message">Vous essayez de vous connecter avec l’adresse e-mail suivante : {{ data.email }}.</span>
                <span class="google-connect-notice__prompt">Souhaitez-vous utiliser une autre adresse e-mail ou créer un nouveau compte avec celle-ci ?</span>
                <div class="google-connect-notice__actions">
                    <a @click="close">Me connecter</a>
                    <a @click="register">Créer un compte</a>
                </div>
            </div>
        </div>
        <div v-else-if="data.type == 'already-exist'" class="google-connect-notice">
            <div class="google-connect-notice__content">
                <h1 class="google-connect-notice__title">Un compte existe avec ce mail</h1>
                <span class="google-connect-notice__prompt">Souhaitez-vous vous connecter avec ce compte ?</span>
                <div class="google-connect-notice__actions">
                    <a @click="connect">Me connecter</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import googleConnectRepository from "../../repositories/googleConnectRepository";

export default{
    name: "google-connect-notice",
    props: ["data"],
    data() {
        return {
            loading: false,
            registered: false,
        }
    },
    methods: {
        close(){
            this.$emit("close");
        },
        register(){
			this.loading = true;

            googleConnectRepository.register().then(response=>{
                window.location.reload();
			})
		},
        connect(){
			this.loading = true;
            googleConnectRepository.login().then(()=>window.location.reload());
        }
    }
}
</script>

<style lang="scss">
@import '../../environment';
.google-connect-notice{
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $space-m;
        max-width: 80%;
        margin-inline: auto;
    }
    &__title{
        text-transform: uppercase;
        color: $c-primary;
        font-weight: 700;
        font-size: $font-m;
    }
    &__message{
        text-transform: uppercase;
        font-weight: 700;
        font-size: $font-s;
        color: $c-primary;
    }
    &__prompt{
        color: $c-primary;
        font-weight: 700;
        text-align: center
    }
    &__actions{
        display: flex;
        justify-content: space-evenly;
        a{
            color: $c-primary;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
        }
    }
}
</style>