<template>
	<transition name="fade">
		<div class="o-popin" :class="'o-popin--'+type+' o-popin--'+content.type" :style="'z-index:'+(99997+ (type === 'error' ? 999999 : content.priority ))">
			<div class="o-popin__content" ref="content">
				<button class="o-popin__close" @click="close" v-if="type==='error'||content.closeable">X</button>
				<div class="o-popin__scroll" ref="scrollable">
					<component :is="content.type" :data="content.data" @close="close"></component>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "popin",
		props:['content', 'type'],
		methods:{
			close(){
				if(this.content.onClose){
					this.content.onClose();
				}
				if(this.type === 'popin')
					this.$store.commit('popin',{ type: this.content.type, delete: true });
				else
					this.$store.commit(this.type,false);
			}
		},
		watch: {
			$route(to, from) {
				if( this.type !== 'error' && from.name !== 'auto-login' )
					this.close()
			}
		},
		mounted() {
			let self = this;
			this.$scrollTop(function () {
				if( self.$refs.scrollable )
					self.$refs.scrollable.scrollTop = 0
			});
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-popin{
		position: fixed; left: 0; right: 0; bottom: 0; top: 0; display: flex; align-items: center; justify-content: center; z-index: 99997;
		background: rgba(black, 0.8); backdrop-filter: blur(4px);
		&--alert{ z-index: 99999 }
		&--error{ z-index: 99998 }
    &--caci-register{
      .o-popin__scroll{
        .page--assurances &{ overflow: visible }
      }
    }
		&--modal{ backdrop-filter: blur(5px) }
		&__content{
			max-width: 90rem; min-width: 50rem; border-radius: $border-radius; background: white; padding-top: 3rem; min-height: 9.1rem;
			padding-right: 1rem; padding-left: 2rem; padding-bottom: 2rem; position: relative; box-shadow: $large-box-shadow;
			@media #{$to-tablet}{
				width: 90%; min-width: auto;
			}
		}
		&__scroll{
			max-height:calc(80vh - 20px); overflow-y: auto; -webkit-overflow-scrolling: touch; padding-right: 2rem; padding-bottom: 2px; padding-left: 1rem;
      @media #{$to-phone}{
        padding-right: 1rem; padding-left: 0; max-height: calc(100vh - 62px);
      }
		}
    .container{ width: 100% }
		.button-container{ text-align: center; margin-top: 3rem }
		.icon { text-align: center; font-size: 6rem; color: $c-primary; margin-bottom: 2rem }

		&__close{
			font-family: sans-serif; position: absolute; right: -1rem; top: -1rem;
			color: $c-primary; border: 0; cursor: pointer; background: #fff; border-radius: 50%;
			font-size: 10px; width: 28px; height: 28px; line-height: 28px;
		}
	}
</style>