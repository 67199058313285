<template>
  <div class="o-questionnaire-container">
    <div v-if="step == 'questions'" class="o-question-container o-cold-form">
      <questionnaireSubheader
        :questions="questions"
        :currentQuestionPosition="currentQuestionPosition"
        :currentQuestion="currentQuestion"
      ></questionnaireSubheader>
      <p v-if="question.subtitle" class="o-cold-form__subtheme">
        {{ question.subtitle ? question.subtitle : "" }}
      </p>
      <h2>{{ question.label }}</h2>
      <form @submit.prevent="submitAnswer">
        <div class="o-question-container__answers">
          <template>
            <label
              v-for="(option, index) in question.options"
              :key="index"
              class="input--star text answer-label"
            >
              <input
                type="radio"
                :value="option"
                v-model="currentAnswer"
                :name="`question-${currentQuestion}`"
              />
              <span class="checkmark" data-icon="star"></span>
              <span class="answer-span">{{ option }}</span>
            </label>
          </template>
        </div>
      </form>
      <div class="o-cold-form__values">
        <span v-if="question.min">
          {{ question.min }}
        </span>
        <span v-if="question.max">
          {{ question.max }}
        </span>
      </div>
      <div class="o-questionnaire-buttons">
        <button
          class="button button--border"
          @click="previousQuestion"
          :disabled="!currentQuestion"
        >
          Question précédente
        </button>
        <button
          class="button"
          @click="nextQuestion"
          :disabled="!currentAnswer"
          :class="{ 'button--border': !currentAnswer }"
          v-html="
            currentQuestionPosition === questions.length
              ? 'Valider'
              : 'Question suivante'
          "
        ></button>
      </div>
    </div>
    <div v-else class="o-question-container">
      <h2>
        Nous vous remercions pour vos réponses qui contribueront à
        l'amélioration de nos actions de formation.
      </h2>
    </div>
  </div>
</template>

<script>
import qualiopiRepository from "@/repositories/qualiopiRepository";
import questionnaireForm from "../molecule/questionnaire-subheader.vue";

export default {
  name: "questionnaire-froid",
  props: ["data", "interface"],

  data() {
    return {
      currentQuestion: 0,
      step: "questions",
      questions: [
        {
          index: 0,
          key: "putIntoPractice",
          label:
            "Je mets en pratique régulièrement les connaissances acquises au cours de la formation ou celles-ci facilitent l'appréhension de ma fonction",
          subtitle: "Au niveau de mes compétences individuelles :",
          min: "Pas du tout",
          max: "Quotidiennement",
          options: [1, 2, 3, 4, 5],
        },
        {
          index: 1,
          key: "objectivesAchieved",
          label:
            "Les objectifs pédagogiques de la formation ont été atteints :",
          subtitle: "Au niveau de mes compétences individuelles :",
          min: "Partiellement",
          max: "Absolument",
          options: [1, 2, 3, 4, 5],
        },
        {
          index: 2,
          key: "skillLevelIncreased",
          label: "Mon niveau de compétence a augmenté :",
          subtitle: "Au niveau de mes compétences individuelles :",
          min: "Partiellement",
          max: "Totalement",
          options: [1, 2, 3, 4, 5],
        },
        {
          index: 3,
          key: "teamWorkIncreased",
          label:
            "La formation a-t-elle contribué à l'amélioration de mon travail d'équipe ?",
          subtitle: "Au niveau de mon équipe :",
          min: "Partiellement",
          max: "Totalement",
          options: [1, 2, 3, 4, 5],
        },
        {
          index: 4,
          key: "teamSkillIncreased",
          label:
            "La formation a-t-elle favorisé le développement de compétences collectives au sein de mon équipe ?",
          subtitle: "Au niveau de mon équipe :",
          min: "Partiellement",
          max: "Totalement",
          options: [1, 2, 3, 4, 5],
        },
        {
          index: 5,
          key: "newFormationNeeded",
          label:
            "Pour aller plus loin dans le développement de mes compétences, je pense avoir besoin d'une nouvelle formation",
          subtitle: "Aller plus loin :",
          options: ["Oui", "Peut-être", "Non"],
        },
      ],
      currentAnswer: "",
      answers: {},
    };
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
    currentQuestionPosition() {
      return this.currentQuestion + 1;
    },
  },
  created() {
    // get key from url
    if(localStorage.getItem('key')){
      this.answers.key = localStorage.getItem('key');
    }
    else {
      this.answers.key = this.$route.query.key;
    }

    // delete key from local storage
    localStorage.removeItem('key');
    localStorage.removeItem('from');
  },
  methods: {
    nextQuestion() {
      if (this.saveAnswer()) {
        if (this.currentQuestion >= this.questions.length - 1) {
          // Toutes les questions ont été répondues
          this.submitAnswers();
        } else {
          this.currentQuestion++;
          this.loadAnswer();
        }
      }
    },
    previousQuestion() {
      if (this.currentQuestion > 0) {
        this.currentQuestion--;
        this.loadAnswer();
      }
    },
    loadAnswer() {
      const previousAnswer = this.answers[this.question.key];
      this.currentAnswer = previousAnswer || "";
    },
    saveAnswer() {
      const currentQuestion = this.questions[this.currentQuestion];
      this.answers[currentQuestion.key] = this.currentAnswer;

      if (this.answers[currentQuestion.key].length === 0) return false; //empty answer

      return true;
    },
    submitAnswers() {
      let self = this;

      this.loading = true;

      qualiopiRepository.createCold(JSON.stringify(this.answers)).then(
        (response) => {
          this.step = "submited";
          // setTimeout(function () {
          //   setTimeout(function () {
          //     window.location.href = "/";
          //   }, 1000);
          // }, 3500);
        },
        (response) => {
          this.loading = false;
          this.error = response.data.message;
          setTimeout(function () {
            self.error = false;
          }, 3500);
        }
      );
    },
    colorPreviousStars() {
      const questions = this.$el.querySelectorAll('input[name^="question-"]');

      questions.forEach((question, index) => {
        const inputValue = parseInt(question.value);
        const checkmark = question.nextElementSibling;

        if (!isNaN(inputValue)) {
          if (inputValue > this.currentAnswer) {
            checkmark.style.color = "";
          } else {
            checkmark.style.color = "#042b72";
          }
        }
      });
    },
  },
  watch: {
    currentAnswer() {
      this.colorPreviousStars();
    },
  },
};
</script>

<style lang="scss">
@import "../../environment";

.o-questionnaire-container {
  .o-cold-form {
    .line-segment {
      left: -27px !important;
      width: 32px !important;
    }
    &__subtheme {
      color: var(--c-secondary);
    }
    .o-question-container__answers {
      width: 100%;
      justify-content: space-around;
      max-width: 400px;
      @media #{$to-tablet} {
        flex-direction: row !important;
      }
      .input--star {
        position: relative;
        cursor: pointer;
        input[type="radio"] {
          width: $font-l;
          height: $font-l;
          margin: 0 0.5rem 0 0;
          &:checked + .checkmark {
            color: $c-primary !important;
          }
        }
        .checkmark {
          position: absolute;
          left: 0;
          width: $font-l;
          text-align: center;
          color: white;
          opacity: 1;
          -webkit-text-stroke: 1px $c-primary;
          text-stroke: 1px $c-primary;
        }
      }
      .answer-span {
        margin-left: 0 !important;
      }
    }

    &__values {
      width: 100%;
      display: flex;
      justify-content: space-between;
      max-width: 260px;
      font-weight: 100;
      font-size: 1.4rem;
      @media #{$to-tablet} {
        font-size: 1.1rem;
      }
    }
  }
}
</style>
