<template>
  <div class="o-infos__clap2 o-infos__clap2-popin">
    <div v-if="config && config.option">
      <div class="o-infos__item">
        <div class="o-infos__item-subheader">
          <div class="o-infos__item-subheader-logos">
            <img src="/img/logo-snpi.svg" alt="SNPI" class="snpi" />
            <span data-icon="chevron-right"></span>
            <img src="/img/logo-clap.legal-hd.svg" alt="SNPI" class="snpi" />
          </div>
          <div>
            <span class="o-clap-alert-ship__img-alert-new-system-new-red">NEW</span>
            <span style="font-size: 16px; font-weight: 600">Vos modèles d'imprimés évoluent !</span>
          </div>
          <p>
            Tout beaux, tout neufs et toujours certifiés par nos juristes, utilisez dès à présent nos versions interactives, en partenariat avec ClapLegal (Editions Préférence).
          </p>
          <p>
            Construisez le document qui vous convient et signez-le électroniquement sur une seule et même interface !
          </p>
           <router-link to="/e-signatures" class="button button--white" data-icon_after="arrow-to-left">Essayer</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

	export default {
		name: "clap-popin-home",
		computed: {
      config(){ return this.$config() },
      type(){ return this.$store.getters.type() },
    },
	}
</script>

<style lang="scss">
.o-infos__clap2-popin {
  max-width: 45rem;
  .o-infos__item-subheader {
    a {
      margin: auto;
    }
  }
}
</style>