<template>
	<div class="a-loader" :class="{'a-loader--blur':blur, 'a-loader--static':static, 'a-loader--tall':tall}">
		<div class="a-loader__text" v-if="text">{{text}}</div>
		<div class="a-loader__spinner">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'loader',
		props:['text', 'blur', 'static', 'tall']
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
	.a-loader {
		position: overlay(); margin: 0; z-index: 4; background: none!important;
		&--static{ position: relative; min-height: 10rem }
		&--blur{ backdrop-filter: blur(2px) }
		&--tall{ min-height: 40rem }
		.title &{ display: inline-block; vertical-align: middle; margin-left: 1rem }
		&__text{
			position: absolute; left: 0; top: 50%; width: 100%; text-align: center; margin-top: -1em; padding-top: 30px;
			&+.a-loader__spinner{ margin-bottom: -0.5rem }
		}
		&__spinner{
			$loader: $space;
			$margin: 0.3rem;

			margin: auto; position: absolute; width: $loader; height: $loader; bottom: 50%; margin-bottom: math.div(-$loader,2);
			left: 50%; margin-left: math.div(-$loader,2); display: inline-block; z-index: 99;

		div {
				box-sizing: border-box;
				display: block;
				position: absolute;
				width: $loader - $margin;
				height: $loader - $margin;
				margin: $margin;
				border: $margin solid $c-primary;
				border-radius: 50%;
				animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
				border-color: $c-primary transparent transparent transparent;

				&:nth-child(1) {
					animation-delay: -0.45s;
				}
				&:nth-child(2) {
					animation-delay: -0.3s;
				}
				&:nth-child(3) {
					animation-delay: -0.15s;
				}
			}
		}
	}
	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>