<template>
  <div class="o-infos block" :class="interface.visibility">
    <h2 class="title">{{ interface.title }}</h2>
    <div v-if="config && config.option">
      <div class="o-infos__item">
        <p class="subtitle">Vos imprimés évoluent !</p>
        <p>
          Utilisez à présent nos nouveaux modèles interactifs, en partenariat
          avec Clap Legal.
        </p>
        <p>
          Attention, les anciennes versions pdf sont destinées à disparaître et
          ne seront donc plus actualisées par nos juristes. Pour être sûrs
          d'utiliser nos nouveaux modèles certifiés par nos juristes, utilisez
          désormais :
        </p>
        <p>SNPI x Clap.legal</p>
      </div>
    </div>
    <router-link to="/e-signatures/e-signatures-archive" class="button o-infos__cta-archives">Voir mes archives</router-link>
  </div>
</template>

<script>
export default {
  name: "infos",
  props: ["data", "interface"],
  computed: {
    config() { return this.$config(); },
    user(){ return this.$user() },
  },
  methods:{
		openPopin(){
			this.$popin('clap-popin')
		}
  }
};
</script>

<style lang="scss">
@import "../../environment";
.o-infos {
  .subtitle + * {
    margin-top: 0.5rem;
  }

  &__item {
    & + & {
      margin-top: $space-m;
    }
    p {
      margin-bottom: 10px;
    }
  }

  .question-mark {
    &:after {
      content: "?";
      border: solid 1px $c-primary;
      font-size: 2.2rem;
      width: 2.8rem;
      border-radius: 50%;
      text-align: center;
      flex-shrink: 0;
      color: $c-primary;
    }
  }
  &__cta-archives {
    width: 60%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;
  }
}
</style>
