<template>
	<div class="a-message">
		<div class="title-med">{{ data.title ? data.title : 'Information' }}</div>
    <p v-if="data.text" v-html="data.text" class="a-message__text"></p>
    <router-link class="button" :to="data.link" v-if="data.link && data.cta">{{ data.cta }}</router-link>
		<a class="button" @click="reload" v-if="data.reload">Recharger</a>
		<a class="button" @click="close" v-if="data.close">{{ data.close === true ? 'Fermer' : data.close }}</a>
	</div>
</template>

<script>
	export default {
		name: "message",
		props:['data'],
		methods:{
			reload(){
				window.location.reload();
			},
			close(){
				this.$emit('close');
			}
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-message{
		* + *{ margin-top: $space }
    &__text{
      a{ text-decoration: underline; color: $c-primary }
    }
	}
</style>