<template>
	<div class="a-update-phone">
		<h2 class="title">Modifier le numéro de téléphone</h2>

		<form @submit.prevent="updatePhone" ref="form" id="caci-register_update-phone">
			<vue-form-generator tag="div" :schema="form" :model="model" :options="formOptions"></vue-form-generator>
			<div class="bottom-right">
				<submit class="button--secondary" text="Enregistrer" text-success="Enregistré" :loading="loading" :success="sent"></submit>
			</div>
		</form>
	</div>

</template>

<script>
import contactRepository from "@/repositories/contactRepository";
import userRepository from "@/repositories/userRepository";

export default {
	name: "update-phone",
	props:['data'],
	data(){
		return{
			model: {},
			loading: false,
			sent: false,
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
			form:{
				fields:[
					{
						type: "tel-custom",
						inputName: "phone",
						model: "phone",
						styleClasses: "fields-label",
						label: "Téléphone portable",
						onlyCountries: ['FR','GP','MQ','GF','YT','PM','BL','MF','WF','PF','NC','UK'],
						required: true
					}
				]
			}
		}
	},
	computed:{
		user(){ return this.$user() }
	},
	methods:{
		close(){
			this.$update()
			this.$emit('close')
		},
		updatePhone(){

			this.loading = true;

			let formData = new FormData( this.$refs.form );
			let contact = this.data.contact.addresses.find(address => {
				return address.company === null || !address.company
			})

			contactRepository.updateAddress(contact.id, formData).then(response => {

				this.sent = true;
				this.loading = false;

				userRepository.get();
				setTimeout(this.close,1500);

			}, e => {

				this.loading = false;
			})
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
	.a-update-phone{
		.title + *{ margin-top: $space }
	}
</style>