<template>
	<div class="a-updated">
		<p class="title-med">La formation a été mise a jour</p>
		<p class="primary">
			Votre progression a été sauvegardée
		</p>

		<div class="bottom-between">
			<button class="button" @click="reload">Recharger</button>
		</div>

	</div>
</template>

<script>

export default {
name: "updated",
	methods:{
		reload(){
			window.location.reload()
		}
	}
}
</script>

<style lang="scss">
@import '../../environment';
	.a-updated{
		text-align: center;

		.bottom-between{ margin-top: $space }
		p + p{ margin-top: $space-m  }

		button{ flex: 1 }
	}
</style>