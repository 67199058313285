<template>
  <div class="o-foad container" :class="{'o-foad--paused': paused}">

    <div class="o-foad__main">

      <div class="o-foad__scroll" v-on:scroll.passive="handleScroll" ref="scroll">

        <div class="o-foad__breadcrumb"><router-link to="/formations/formations-souscrites">Mes formations</router-link> > {{ formation.title }}</div>
        <div class="o-foad__title title-big">{{ formation.title }}</div>

        <div v-if="data">

          <div class="o-foad__content" v-if="selected && !completed" :class="'font-'+font">

            <h2 class="title-med">
              <span v-if="selected.layout === 'quiz'">Quiz - </span>{{selected.title}}
            </h2>

            <div class="o-foad__content-header" v-if="selected.layout !== 'quiz'">
              <div class="o-foad__reading icon-flex" data-icon="timer">
                {{ humanDuration(selected.reading_time) }} de lecture</div>

              <div class="o-foad__font">
                <button data-icon="zoom-out" class="o-foad__font-button o-foad__font-button--less button button--small icon-flex" @click="font = 0" :class="{'o-foad__font-button--selected':font === 0}" title="Utiliser une taille de police inférieure"></button>
                <button data-icon="font-normal" class="o-foad__font-button o-foad__font-button--normal button button--small icon-flex" @click="font = 1" :class="{'o-foad__font-button--selected':font === 1}" title="Utiliser une taille de police standard"></button>
                <button data-icon="zoom-in" class="o-foad__font-button o-foad__font-button--plus button button--small icon-flex" @click="font = 2" :class="{'o-foad__font-button--selected': font === 2}" title="Utiliser une taille de police supérieure"></button>
              </div>
            </div>

            <component :is="selected.layout" :data="selected" :participant="participant" class="o-foad__data" @updated="setMediaProgress" @handleFocus="handleFocus" @lostFocus="inactiveTab" :key="selected.id" @answer-checked="isQuizzEditing" />

            </div>

          <survey v-else-if="!participant.survey && !loading" :formationParticipant="participant" @sent="complete"></survey>

          <div v-else-if="participant.present" class="o-foad__completed">
            <img src="/img/results-quiz.svg" width="400" height="315"/>
            <h3 class="title-med">Votre formation est validée !</h3>
            <form @submit.prevent="download" id="foad_download">
              <submit :loading="downloading" text="Télécharger votre attestation"></submit>
            </form>
          </div>

          <loader static="1" v-else-if="loading" text="Merci de patienter"></loader>
        </div>
        <div v-else>
          <loader static="1" v-if="loading" text="Chargement en cours"></loader>
          <h3 class="title-med" v-else>Le contenu de la formation à distance n'est pas encore disponible</h3>
        </div>
      </div>

      <div class="o-foad__nav" v-if="(previous||next) && !participant.present && !completed && selected">
        <div v-if="previous" class="o-foad__nav-item o-foad__nav-item--previous" @click="selectSubchapter('previous')">
          <div data-icon="chevron-left" class="icon-flex">
            <strong v-if="previous.title">{{previous.title}}</strong>
            <strong v-else>Chapitre précédent</strong>
          </div>
        </div>
        <div class="o-foad__nav-item o-foad__nav-item--next" :class="{'inactive': !ended }">
          <a v-if="ended" @click="selectSubchapter(next?'next':'survey')" data-icon_after="chevron-right" class="icon-flex">
            <span v-if="next && next.title">{{next.title}}</span>
            <span v-else-if="next">Chapitre suivant</span>
            <span v-else>Terminer la formation</span>
          </a>
          <a v-else-if="!remaining_time">
            <p>
              <strong data-icon="lock" class="icon-flex"></strong>
              <span v-if="selected.layout === 'foad-video'">
                Vous devez voir la vidéo complète pour <span v-if="next">accéder au prochain chapitre</span><span v-else>terminer la formation</span>
              </span>
              <span v-else-if="selected.layout === 'foad-pdf'">
                Vous devez lire tout le pdf pour <span v-if="next">accéder au prochain chapitre</span><span v-else>terminer la formation</span>
              </span>
              <span v-else-if="selected.layout === 'quiz'">
                Vous devez valider le quiz pour <span v-if="next">accéder au prochain chapitre</span><span v-else>terminer la formation</span>
              </span>
            </p>
          </a>
          <a v-else>
            <p>
              <span>Vous pourrez <span v-if="next">accéder au chapitre suivant</span><span v-else>terminer la formation</span> dans</span><br>
              <strong data-icon="lock" class="icon-flex">{{ formatRemaining(remaining_time) }}</strong>
            </p>
          </a>
        </div>
      </div>
    </div>

    <div @click="sidebar=!sidebar" class="o-foad__toggle" :class="{'o-foad__toggle--expanded': sidebar}" v-if="data && !completed && show_sidebar">
      <span :data-icon="sidebar?'arrow-to-left':'arrow-to-right'" class="icon-flex"></span><span class="o-foad__toggle-text" >Table des matières</span>
    </div>

    <div class="o-foad__sidebar" v-if="data && !completed" :class="{'o-foad__sidebar--active':sidebar&&show_sidebar}">

      <div class="o-foad__sidebar-inner" ref="sidebar">

        <div class="title-big">Table des matières</div>

        <div v-if="chapters" class="o-foad__sidebar-infos">
          {{ chapters }} chapitres | Temps estimé de la formation : {{ humanDuration(estimated_duration) }}
        </div>

        <div v-if="data.introduction" class="o-foad__chapter">
          <div class="o-foad__chapter-title title-med">Introduction</div>

          <ul class="o-foad__chapters">
            <li v-for="(subchapter, subchapter_index) in data.introduction" :key="subchapter.id"  @click="selectSubchapter(false, -1, subchapter_index)">
              <subchapter :data="subchapter" :progress="participant.progress" :chapter="-1" :subchapter="subchapter_index"></subchapter>
            </li>
          </ul>
        </div>

        <div v-for="(chapter, chapter_index) in data.chapters" :key="chapter_index" class="o-foad__chapter" v-if="data.chapters">
          <div class="o-foad__chapter-title title-med" v-if="chapter.title">{{ chapter.title }}</div>
          <div class="o-foad__chapter-title title-med" v-else>Chapitre {{ chapter_index+1 }}</div>
          <ul v-if="chapter.subchapters" class="o-foad__chapters">
            <li v-for="(subchapter, subchapter_index) in chapter.subchapters" :key="subchapter.id"  @click="selectSubchapter(false, chapter_index, subchapter_index)">
              <subchapter :data="subchapter" :progress="participant.progress" :chapter="chapter_index" :subchapter="subchapter_index"></subchapter>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import formationCourseRepository from "@/repositories/formationCourseRepository";
import formationParticipantRepository from "@/repositories/formationParticipantRepository";

export default {
  name: "foad-content",
  data(){
    return {
			sidebar: window.innerWidth>=1024,
	    IdleTime: false,
      hashTime: false,
      data: false,
      terminate: false,
      downloading: false,
      formation: false,
      font: 1,
      idleTime: false,
      participant: false,
      loading: true,
      focused: true,
      editing: false,
    }
  },
  methods:{
    isQuizzEditing(data) {
      this.editing = data.editing;
    },
    handleScroll(e){
      let percent = e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight);
      let scroll = parseFloat(percent.toFixed(3))
      scroll = isNaN(scroll)?0:scroll;

      this.participant.progress.scroll = scroll;

      if( scroll*100 % 10 === 0 && scroll)
        this.updateProgress()
    },
    formatRemaining(remaining){
      if(remaining && remaining > 0)
        return new Date(remaining*1000).toISOString().substr(14, 5)
      else
        return '00:00'
    },
    handleFocus(state){

      if( state )
        window.removeEventListener('blur', this.inactiveTab)
      else if( !this.focused )
        window.addEventListener('blur', this.inactiveTab)

      this.focused = !state;
    },
    complete() {

      this.loading = true;
      this.participant.survey = true;

      formationParticipantRepository.terminate(this.participant.id).then(response=>{
        this.loading = false;
        this.participant.present = true;
      }, response=>{
        this.loading = false;
      });
    },
    download(){

      this.downloading = true;

      formationParticipantRepository.download(this.participant.id).then(response=>{
        this.downloading = false;
      }, response=>{
        this.downloading = false;
        });
    },
    pause(){
      this.$store.commit('pausedFoad', true)
      this.$popin('idle', false, false)
    },
    start(){
      clearInterval(this.timer);
      this.timer = setInterval(this.time, 1000)
    },
    setElapsedTime(){

      if( this.paused || this.participant.progress.chapter.current !== this.participant.progress.chapter.max || this.participant.progress.subchapter.current !== this.participant.progress.subchapter.max )
        return;

      this.participant.progress.timeElapsed++;

      if( this.participant.progress.timeElapsed % 30 === 0 && this.participant.progress.timeElapsed)
        this.updateProgress()
    },
    idleTimer() {
      if(!this.paused){
        clearTimeout(this.IdleTime);

        // idle after 15min
        this.IdleTime = setTimeout(this.pause, 1000*60*15)
      }
    },
    checkHash() {
	    if( !this.paused ){
		    formationCourseRepository.foadHash(this.$route.params.id).then(hash=>{

			    let updated = hash !== this.hash

			    if( updated ){
				    clearInterval(this.hashTime)

				    this.updateProgress()
				    this.$popin('updated', false, false)
			    }
		    })
	    }
    },
    inactiveTab(e){

      if( !this.paused && !this.completed && !this.hasRole(this.user,'formation_create') )
        this.pause()
    },
    updateProgress(){

      if( this.participant.progress.chapter.current > this.participant.progress.chapter.max )
        this.participant.progress.subchapter.max = 0
      else if(this.participant.progress.chapter.current === this.participant.progress.chapter.max )
        this.participant.progress.subchapter.max = Math.max(this.participant.progress.subchapter.current, this.participant.progress.subchapter.max)

      this.participant.progress.chapter.max = Math.max(this.participant.progress.chapter.current, this.participant.progress.chapter.max)

      if( !this.completed )
        formationCourseRepository.storeProgress(this.$route.params.id, this.participant.progress);
    },
    setMediaProgress(progress){
      //this.$refs.scroll.scrollTop = 0
      if( this.participant.progress.chapter.max === this.participant.progress.chapter.current && this.participant.progress.subchapter.max === this.participant.progress.subchapter.current ){

        this.participant.progress.media = Math.max(this.participant.progress.media, progress);

        if( progress*100 % 10 === 0 && progress)
          this.updateProgress()
      }
    },
    selectSubchapter(state, chapter, subchapter){

      if (this.editing === true) {
        this.$popin('quiz-validation', false, false)
        return;
      }


      this.terminate = false;

      if( state ){

        chapter = this.participant.progress.chapter.current;
        subchapter = this.participant.progress.subchapter.current;

        if( state === 'survey' ){

          // formationCourseRepository.getNoteTerminate(this.participant.id)

        formationCourseRepository.checkNote(this.participant.id).then(response => {
          if (response.note.globalNote >= 50) {
            this.terminate = true;
          } else {
            this.$popin('quizz-resume', response.note);
          }
        });
          
        }
        else if( state === 'previous' ){

          if( subchapter > 0 ) {

            subchapter = subchapter - 1;
          }
          else if( chapter > 0 ){
            chapter = chapter-1;
            subchapter = this.data.chapters[chapter].subchapters.length-1;
          }
          else{
            chapter = chapter-1;
            subchapter = this.data.introduction.length-1;
          }
        }
        else{

          if(chapter === -1){

            if( this.data.introduction[subchapter+1] ){
              subchapter = subchapter+1;
            }
            else{
              chapter = chapter+1;
              subchapter = 0;
            }
          }
          else{
            if( this.data.chapters[chapter].subchapters[subchapter+1] ){
              subchapter = subchapter+1;
            }
            else if( this.data.chapters[chapter+1] ){
              chapter = chapter+1;
              subchapter = 0;
            }
          }
        }
      }

      if( chapter < this.participant.progress.chapter.max || ( chapter === this.participant.progress.chapter.max && subchapter <= this.participant.progress.subchapter.max) || (state === 'next' && this.ended)){

				if( chapter !== this.participant.progress.chapter.current || subchapter !== this.participant.progress.subchapter.current ){

          this.participant.progress.chapter.current = chapter;
          this.participant.progress.subchapter.current = subchapter;
          this.participant.progress.scroll = 0;

          if( this.participant.progress.chapter.max < chapter || (this.participant.progress.chapter.max === chapter && this.participant.progress.subchapter.max < subchapter ) ){

            this.participant.progress.timeElapsed = 0;
            this.participant.progress.media = 0;
          }

          this.updateProgress();
        }
      }

      this.$refs.scroll.scrollTop = 0
    }
  },
  computed:{
    paused(){
      return this.$store.getters.pausedFoad()
    },
    user(){
      return this.$user()
    },
    show_sidebar(){
      return this.data && this.selected && this.selected.layout !== 'quiz'
    },
    ended(){

      if( this.hasRole(this.user,'formation_create') )
        return true;

      if( this.participant.progress.chapter.current < this.participant.progress.chapter.max || (this.participant.progress.chapter.current === this.participant.progress.chapter.max && this.participant.progress.subchapter.current < this.participant.progress.subchapter.max ) )
        return true;

      return ((this.selected && this.selected.layout === 'foad-text') || this.participant.progress.media === 1) && this.remaining_time === 0;
    },
    completed(){
      return this.participant.present || (this.ended && !this.next && this.terminate);
    },
    remaining_time(){

      if( !this.data || !this.selected || this.selected.layout === 'quiz' )
        return 0;

      return Math.max(0, this.selected.reading_time - this.participant.progress.timeElapsed)
    },
    chapters(){

      if( !this.data )
        return 0;

      return (this.data.introduction?1:0) + this.data.chapters.length;
    },
    estimated_duration(){
	    let duration = 0;

			if( !this.data )
        return duration;

      if( this.data.introduction ){

        this.data.introduction.forEach(chapter=>{
          duration += parseInt(chapter.reading_time);
        })
      }

      if( this.data.chapters ){
        this.data.chapters.forEach(chapters=>{
          chapters.subchapters.forEach(chapter=>{
            duration += parseInt(chapter.reading_time !== '' ? chapter.reading_time : 0);
          })
        })
      }

      return duration
    },
    selected(){

      if( !this.data )
        return false;

      if( this.participant.progress.chapter.current === -1 )
        return this.data.introduction[this.participant.progress.subchapter.current];
      else
        return this.data.chapters[this.participant.progress.chapter.current].subchapters[this.participant.progress.subchapter.current];
    },
    previous(){

      if( !this.data )
        return false;

      let chapter = this.participant.progress.chapter.current;
      let subchapter = this.participant.progress.subchapter.current;

      if(chapter === -1)
        return this.data.introduction[subchapter-1]
      else
        return this.data.chapters[chapter].subchapters[subchapter-1] ? this.data.chapters[chapter].subchapters[subchapter-1] : (this.data.chapters[chapter-1] ? this.data.chapters[chapter-1].subchapters[this.data.chapters[chapter-1].subchapters.length - 1] : (this.data.introduction ? this.data.introduction[this.data.introduction.length -1] : false))
    },
    next(){

      if( !this.data )
        return false;

      let chapter = this.participant.progress.chapter.current;
      let subchapter = this.participant.progress.subchapter.current;

      if(chapter === -1)
        return this.data.introduction[subchapter+1] ? this.data.introduction[subchapter+1] : (this.data.chapters ? this.data.chapters[0].subchapters[0]  : false)
      else
        return this.data.chapters[chapter].subchapters[subchapter+1] ? this.data.chapters[chapter].subchapters[subchapter+1] : (this.data.chapters[chapter+1] ? this.data.chapters[chapter+1].subchapters[0] : false)
    },
  },
  watch:{
    selected(item){
	    if(item && item.layout === 'quiz')
        this.sidebar = false;
    }
  },
  mounted(){
    formationCourseRepository.foad(this.$route.params.id).then(response=>{

      this.loading = false;
      this.hash = response.hash
      this.data = response.content
      this.formation = response.formation
      this.participant = response.participant;

      if( !this.data.introduction && this.participant.progress.chapter.current === -1){
        this.participant.progress.chapter.current = 0
        this.participant.progress.chapter.max = 0
      }
      else{
        this.participant.progress.chapter.current = Math.max(0, Math.min(this.participant.progress.chapter.current, this.data.chapters.length-1));
        this.participant.progress.chapter.max = Math.max(0, Math.min(this.participant.progress.chapter.max, this.data.chapters.length-1));

        this.participant.progress.subchapter.current = Math.max(0, Math.min(this.participant.progress.subchapter.current, this.data.chapters[this.participant.progress.chapter.current].subchapters.length-1));
        this.participant.progress.subchapter.max = Math.max(0, Math.min(this.participant.progress.subchapter.max, this.data.chapters[this.participant.progress.chapter.current].subchapters.length-1));
      }

      if( !this.participant.present && this.data )
        this.timer = setInterval(this.setElapsedTime, 1000);

			this.$nextTick(()=>{
				if( this.participant.progress.scroll )
					this.$refs.scroll.scrollTop = (this.$refs.scroll.scrollHeight - this.$refs.scroll.clientHeight)*this.participant.progress.scroll;
			})

	    this.hashTime = setInterval(this.checkHash, 10000);
    });

    this.idleTimer()
  },
  created() {
    document.addEventListener('mousemove',this.idleTimer)
    document.addEventListener('onkeypress',this.idleTimer)
    document.addEventListener('touchstart',this.idleTimer)
    window.addEventListener('blur', this.inactiveTab)
  },
  destroyed() {
    document.removeEventListener('mousemove',this.idleTimer)
    document.removeEventListener('onkeypress',this.idleTimer)
    document.removeEventListener('touchstart',this.idleTimer)
    window.removeEventListener('blur', this.inactiveTab)

    clearTimeout(this.IdleTime)
    clearInterval(this.timer)
	  clearInterval(this.hashTime)
  }
}
</script>

<style lang="scss">
@import '../../environment';
.o-foad{
  color: $c-primary;  text-align: left ; position: relative; height: calc(100vh - 8rem);
  overflow: hidden; justify-content: center;

	@media #{$from-small}{ display: flex }

  &--paused{ filter: blur(8px) }

  &__main{
	  background: white; flex: 1;  height: 100%; overflow: hidden; position:relative;
	  @media #{$from-small}{ max-width: 75% }
  }
  &__scroll{
	  padding: $space-l $space-l $space-xl+$space; height: 100%; overflow-y: auto; position:relative;

	  @media #{$to-tablet}{ padding-bottom:  $space-xl+$space-xl }
  }

  &__sidebar{
    width: 0; opacity: 0; background: $c-background-darker; overflow: auto; overflow-x: hidden; transition: allow(width opacity);
	  @media #{$to-tablet}{ position:fixed; top: 0; left: 0; bottom: 0 }
    &-inner{
	    padding: $space-xl $space $space;
	    @media #{$from-small}{ width: 27vw }
    }
    &-infos{
      color: $c-main;

      & + *{ margin-top: $space-m }
    }
    &--active{
	    width: 100vw; opacity: 1;
	    @media #{$from-small}{ width: 28vw }
    }
  }

  &__breadcrumb + *{ margin-top: $space-l }

  &__completed{
    text-align: center;
    img{ margin: auto; margin-bottom: $space }
  }

  &__toggle{
	  position: absolute; right: $space; top: $space; z-index: 5; cursor: pointer;

	  @media #{$to-tablet}{ display: flex; flex-direction: column  }
	  &-text{
		  display: inline-block;  white-space: nowrap;
		  @media #{$to-tablet}{ transform: rotate(90deg); transform-origin: bottom left; position: absolute }
	  }

	  @media #{$to-tablet}{
		  &--expanded &-text{ display: none }
	  }
	  @media #{$from-small}{
		  &--expanded{ display: flex; flex-direction: column}
		  &--expanded &-text{ transform: rotate(90deg); transform-origin: bottom left; position: absolute }
	  }

  }

  &__reading{
    background: $c-background; border-radius: $border-radius; color: $c-primary;
    font-size: $font-s; white-space: nowrap; padding: 0.5rem 1rem; display: inline-block }

  &__font{

    &-button{
      background: white; border-color: $c-border; color: $c-primary; font-size: $font-m;

      &:before{ margin: 0 }

      & + &{ margin: 0 }
      &--less{ border-top-right-radius: 0; border-bottom-right-radius: 0; border-right-color: transparent }
      &--normal{ border-radius: 0 }
      &--plus{ border-top-left-radius: 0; border-bottom-left-radius: 0; border-left-color: transparent }

      .no-touch &:hover,&--selected{ background: $c-border }
      &--selected{ pointer-events: none }
    }
  }

  &__content{
    .title-big + & { margin-top: 0 }
    &-header{ display: flex; justify-content: space-between; align-items: center }
    &.font-0{ font-size: smaller }
    &.font-2{ font-size: larger }
  }

  &__data{ margin-top: $space }

  &__nav{
    position: absolute; bottom: 0; left: 0; right: 0; padding: $space-m; background: white; border-top: solid 1px $c-border;  z-index: 999;

	  @media #{$from-small}{ display: flex; }

    &-item{
	    @media #{$from-small}{ width: calc(50% - #{$space-s}) }
	    @media #{$to-tablet}{ & + *{ margin-top: $space-s }}

      & > * {
        padding: $space-s $space-m; border: solid 2px $c-primary; border-radius: $border-radius; font-weight: bold; display: flex; align-items: center; justify-content: center;
        cursor: pointer; min-height: 6rem; height: 100%;
        span{ font-weight: normal }
        [data-icon]{ font-size: $font-l }

      }

	    &--previous{
		    @media #{$from-small}{ .icon-flex:before{ display: none } }
		    @media #{$to-tablet}{
			    & > *{ justify-content: flex-start }
			    .icon-flex:before{ margin-right: $space }
		    }
	    }

      &--next{
        margin-left: auto;

        & > * {
          background: $c-primary; color: white; justify-content: flex-end;
          &:after{ color: white; font-size: $font-s }
        }

        &.inactive{
          & > *{
            background: $c-background-darker; border-color: $c-background-darker; pointer-events: none; color: $c-primary; text-align: center; justify-content: center;
            p{ opacity: 0.7 }
          }
        }
      }
    }
  }

  &__chapter{

    & + &{ margin-top: $space-m }
    &-title{
	    font-size: $font-l;
      & + *{ margin-top: $space-s }
    }
  }

  &__chapters{
    border-radius: $border-radius; overflow: hidden;
    & > * + *{
      .m-subchapter:before{ content: ''; position: absolute; background: $c-border; height: 1px; left: $space-m; right: $space-m; top: 0 }
    }
  }
}
</style>
