<template>
  <div class="o-infos__clap2" :class="interface ? interface.visibility : ''">
    <h2 class="title" v-if="interface">{{ interface.title }}</h2>
    <div v-if="config && config.option">
      <div class="o-infos__item">
        <div class="o-infos__item-subheader">
          <div class="o-infos__item-subheader-logos">
            <img src="/img/logo-snpi.svg" alt="SNPI" class="snpi" />
            <span data-icon="chevron-right"></span>
            <img src="/img/logo-clap.legal-hd.svg" alt="SNPI" class="snpi" />
          </div>
          <div>
            <span class="o-clap-alert-ship__img-alert-new-system-new-red">NEW</span>
            <span style="font-size: 16px; font-weight: 600">Vos imprimés évoluent !</span>
          </div>
          <p>
            Utilisez à présent nos nouveaux modèles interactif, en partenariat
            avec Clap legal.
          </p>
          <a class="button button--white" data-icon_after="arrow-to-left" @click="openPopin()">Essayer</a>
        </div>
        <div class="o-infos__item-disclaimer">
          <img
            class="o-infos__item-disclaimer__warning"
            src="/img/warning.svg"
            alt="SHIP"
          />
          <p>
            <strong>Attention,</strong> les anciennes versions pdf sont destinées à disparaître
            et ne seront donc plus actualisées par nos juristes.
          </p>
          <p>
            Pour être sûrs d'utiliser nos nouveaux modèles certifiés par nos
            juristes, utilisez désormais Clap Legal
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "infos",
  props: ["data", "interface"],
  computed: {
    config() { return this.$config(); },
    user(){ return this.$user() },
  },
  methods:{
    tryClap(){
      if(this.user.clap_token.length>0){
        this.$router.push({ path: '/documents/documents-a-signer'});
      }
      else
        this.$router.push({ path: 'e-signatures'});
    },
			openPopin(){
				this.$popin('clap-popin')
			}
  }
};
</script>

<style lang="scss">
@import "../../environment";
.o-grid__content {
  .o-infos__clap2 {
    .o-infos__item {
      margin: -20px -30px -30px -30px;
    }
  }
}
.o-infos {
  &__clap2 {
    border-radius: 4px;
    color: white;
    @media #{$to-tablet} {
      width: 90%;
      margin: auto;
      margin-top: 3rem;
    }
  }

  &__item {
    & + & {
      margin-top: $space-m;
    }
    p {
      margin-bottom: 10px;
    }
    &-subtitle {
      font-weight: 700;
      margin-bottom: 1rem;
    }
    &-subheader {
      background-color: var(--c-primary);
      padding: 3rem;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      border-radius: 4px 4px 0 0;
      &-logos {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        img {
          background: white;
          border-radius: 10000rem;
          padding: 2rem 1.7rem;
          overflow: initial;
        }
        img:first-child {
          min-width: 50px;
          max-width: 70px;
        }
        span {
          font-size: 3rem;
          color: gray;
          opacity: 0.3;
        }
      }
      a {
        max-width: 110px;
      }
    }
    &-disclaimer {
      position: relative;
      padding: 3rem;
      font-size: $font-xs;
      background-color: var(--c-secondary);
      border-radius: 0 0 4px 4px;
      &__warning {
        position: absolute;
        left: -55px;
        bottom: -20px;
      }
    }
  }

  .question-mark {
    &:after {
      content: "?";
      border: solid 1px $c-primary;
      font-size: 2.2rem;
      width: 2.8rem;
      border-radius: 50%;
      text-align: center;
      flex-shrink: 0;
      color: $c-primary;
    }
  }
}
</style>
