<template>
	<div class="o-theme-slider" v-if="data && formations && formations.length > 0">
		<h2 class="title">{{ interface.title }}</h2>
		<swiper ref="mySwiper" :options="swiperOptions" class="o-theme-slider__slider snpi-slider no-padding">
			<swiper-slide v-for="(item, index) in formations" :key="index" v-if="item.id !== data.id">
				<router-link :to="{ name: 'formation', params: { id: item.id }}" tag="div" class="o-theme-slider__item">
					<div class="o-theme-slider__type">
						<span v-if="item.format === 'e-learning'">En ligne</span>
						<span v-else-if="item.format === 'instructor-led'">Présentiel</span>
						<span v-else-if="item.format === 'in-house'">En agence</span>
						<span v-else>Webinaire</span>
					</div>
					<h3 class="o-theme-slider__title">{{ item.formation.title }}</h3>
					<div class="o-theme-slider__link link">Voir la formation</div>
				</router-link>
			</swiper-slide>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
		</swiper>
		<loader class="action" v-if="loading"></loader>
	</div>
</template>

<script>
import formationCourseRepository from "@/repositories/formationCourseRepository";

export default {
	name: "theme-slider",
	props:['data', 'interface'],
	data() {
		return {
			formations: false,
			loading: true,
			swiperOptions: {
				slidesPerView: 1,
				spaceBetween: 10,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				breakpoints: {
					768: {
						slidesPerView: 2.2,
						spaceBetween: 20
					},
					1024: {
						slidesPerView: 3.2,
						spaceBetween: 20
					}
				}
			}
		}
	},
	watch:{
		data(){
			formationCourseRepository.list({theme:this.data.formation.theme}).then(formations => {
				this.formations = formations.items.filter(session => session.id !== parseInt(this.data.id))
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss">
	@import '../../environment';

	.o-theme-slider{

		&.block > .title{ margin-top: 0 }

		&__slider{
			.block & { background: none; box-shadow: none }
			.swiper-slide{ height: auto }
			.swiper-button-prev{ box-shadow: $selected-box-shadow }
		}

		&__item{
			color: $c-primary; background: white; padding: $space; cursor: pointer; border-radius: $border-radius; height: 100%; box-shadow: $subtle-box-shadow;

			& > * + *{ margin-top: $space-s }
		}

		&__title{ font-weight: bold; color: $c-text }
		&__link{ font-weight: normal; text-align: right; display: block  }

		@media #{$to-tablet}{ padding-bottom: $space }
	}

</style>