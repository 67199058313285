<template>
	<div :id="groupId + '-' + itemId" class="a-accordion-item" :class="{'is-active': active}">
		<dt class="a-accordion-item__title">
			<h4 @click="toggle" class="preventOther">
				<slot name="title">
					{{item.question}}
				</slot>
			</h4>

			<span class="a-accordion-item__trigger-icon"></span>
		</dt>
		<transition
				name="accordion-item"
				@enter="startTransition"
				@after-enter="endTransition"
				@before-leave="startTransition"
				@after-leave="endTransition">
			<dd v-if="active" class="a-accordion-item__details">
				<div v-html="item.answer" class="a-accordion-item__details-inner">
					<slot name="content">

					</slot>
				</div>
			</dd>
		</transition>
	</div>
</template>

<script>
export default {
	name: "accordion-item",
	props: ['item', 'multiple', 'groupId','itemId'],
	data(){
		return{
			active: false
		}
	},
	methods: {
		toggle(event) {
			if (this.multiple) this.active = !this.active
			else {
				this.$parent.$children.forEach((item, index) => {
					if (item.$el.id === event.currentTarget.parentElement.parentElement.id) item.active = !item.active
					else item.active = false
				})
			}
		},

		startTransition(el) {
			el.style.height = el.scrollHeight + 'px'
		},

		endTransition(el) {
			el.style.height = ''
		}
	}
}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
.a-accordion-item {

	&__details-inner {
		padding: $space-s $space-s $space-m;
	}

	&__title {
		position: relative; width: 100%; text-align: left; padding: $space-s; cursor: pointer;

		h4 {
			padding-right: 1.25rem; font-weight: bold; color: $c-primary
		}
	}

	&__trigger-icon {
		$size: 8px;
		display: block;
		position: absolute;
		top: 0; right: 1.25rem; bottom: 0;
		margin: auto;
		width: $size;
		height: $size;
		border-right: 2px solid $c-primary;
		border-bottom: 2px solid $c-primary;
		transform: translateY( math.div(-$size, 4)) rotate(45deg);
		transition: transform 0.2s ease;
		pointer-events: none;

		.is-active & {
			transform: translateY( math.div($size,4) ) rotate(225deg);
		}
	}

	&__details {
		overflow: hidden; color: $c-primary;
	}

}

.accordion-item-enter-active, .accordion-item-leave-active {
	will-change: height;
	transition: height 0.2s ease;
}
.accordion-item-enter, .accordion-item-leave-to {
	height: 0 !important;
}


</style>