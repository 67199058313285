<template>
	<div class="o-table">
		<div class="container">
			<h3 class="title-big" v-if="data.title">{{ data.title }}</h3>

			<div class="o-table__content">
				<div element="table_scroll">
					<table class="o-table__table">
						<thead class="o-table__thead">
						<tr>
							<th v-for="(item,index) in data.thead" :key="index">
								{{ item.title }}
							</th>
						</tr>
						</thead>
						<tbody>

						<tr class="o-table__line" v-for="(line,index) in data.lines" :key="index+'line'">
							<td v-for="(item,index) in line.cells" :key="index+'cell'">
								<div>{{ item.text }}</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "table-component",
		props:['data']
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-table{
		text-align: left; margin: $space-xl 0;
		@media #{$to-tablet}{ margin-right: $space-m; margin-left: $space-m }

		&__table{ width: 100%  }

		&__thead{ background: $c-primary; color: white; font-weight: bold;
			th{
				padding: $space-m $space-s;

				&:first-of-type{ border-top-left-radius: $border-radius; }
				&:last-of-type{ border-top-right-radius: $border-radius; }
			}
		}

		&__line{
			border: solid 1px $c-background-darker; border-top: none; background: white;
			td{
				padding: $space-m $space-s;
				& + td{ border-left: solid 1px $c-background-darker }
			}

			&:last-of-type{
				td:first-of-type{ border-bottom-left-radius: $border-radius }
				td:last-of-type{ border-bottom-right-radius: $border-radius }
			}
		}
		&__label{ background: $c-background  }
	}
</style>
