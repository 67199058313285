<template>
	<div>
		Veuillez patienter pendant la mise à jour de  votre compte
	</div>
</template>

<script>
	export default {
		name: "wait"
	}
</script>

<style scoped>

</style>