<template>
	<div class="c-tab" v-show="isActive">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "tab",
		props: {
			id: { required: true },
			name: { required: true },
			selected: { default: false},
			icon: { default: false },
			unread: { default: false }
		},
		data() {
			return {
				isActive: false
			};
		},
		mounted() {
			this.isActive = this.selected;
		}
	}
</script>

<style scoped>

</style>