<template>
	<div class="o-signatures-list">
		<div class="block">

			<h2 class="title o-signatures-list__title" v-if="interface.title">
				{{ interface.title }}
				<div class="field-select o-signatures-list__order">
					<div class="field-wrap">
						<select v-model="params.filter" @change="searchSignature">
							<option value="">Toutes les signatures</option>
							<option :value="value" v-for="(label, value) in status">Signatures {{ label }}</option>
						</select>
					</div>
				</div>
			</h2>
		</div>
		<div v-if="(!signatures || signatures.length < 1) && !loading" class="o-signatures-list__empty">
			<p class="title-big">Vous n'avez aucune signature {{ params.filter ? status[params.filter].replace('ées', 'ée') : '' }}.</p>
			<button class="button" @click="newSignature">Créer une nouvelle signature</button>
		</div>
		<div v-else class="block block--notitle" v-infinite-scroll.full="loadMore" ref="container">
			<signature v-for="(signature,index) in signatures" :key="signature.id" :data="signature" :id="signature.id" />
      <loader v-if="loading" class="o-signatures-list__loader"></loader>
    </div>
	</div>
</template>

<script>

	import signatureRepository from '@/repositories/signatureRepository';

	export default {
		name: "signatures-list",
		props:['data', 'interface'],
		data(){
			return{
				signatures: false,
				loading: false,
				status:{
					2 : 'en cours',
					3 : 'terminées',
					4 : 'échouées',
					5 : 'annulées',
					6 : 'expirées',
					'-1': 'archivées'
				},
        count: -1,
				params: {
					filter: '',
          limit : 10,
          offset: 0,
				}
			}
		},
		methods:{
			searchSignature(){
				this.query();
			},
      loadMore(){

        if( this.signatures.length >= this.count || !this.signatures.length )
          return false;

        this.query(this.params.offset + this.params.limit)

      },
			query(offset){

        if( this.loading === true )
          return;

        if( !offset ){

          this.signatures = [];
          this.count = -1;
        }

        this.params.offset = offset;
        this.loading = true;

				signatureRepository.get(this.params).then(response=>{
					this.loading = false;
					this.count = response.count;
					this.signatures = response.items;
				});
			},
			newSignature(){
				this.$popin('signature-add')
			},
			reset(){
				this.query(0);
			}
		},
		mounted(){

			this.$update(this.reset);

			this.reset();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-signatures-list{
		position:relative; min-height: 30rem;
		&__empty{
			background: $c-background-darker!important; text-align: center; padding: 5rem $space; border-radius: $border-radius; color: $c-primary;
			* + *{ margin-top: $space-m }
			.button{ margin-top: 5rem }
		}
		&__title{
			display: flex; align-items: center;
		}
		&__order{ margin-left: auto }
    &__loader.a-loader{ top: auto; bottom: -5.5rem; box-shadow: none }
  }

</style>