<template>
	<main>
		<eLearningTunnel type="webinarId" :id="$route.params.id"></eLearningTunnel>
	</main>
</template>

<script>

	export default {
		name: "Elearning"
	}
</script>