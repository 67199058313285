<template>
	<div class="radio-list">
		<label v-for="input in schema.values">
			<input
					class="form-control"
					type="radio"
					:value="input.value"
					:disabled="schema.disabled"
					:autocomplete="schema.autocomplete"
          :id="getFieldID(schema)"
          :name="getFieldName(schema)"
					:class="schema.fieldClasses"
					:required="schema.required" @click="selectionItem(input)" :checked="input.value === value" />
			<span>{{input.name}}</span>
		</label>
	</div>

</template>

<script>
	import { abstractField } from "vue-form-generator";

	export default {
		name: "field-radios-custom",
		mixins: [ abstractField ],
		methods:{
			selectionItem(item){
				this.value = item.value
			}
		}
	};
</script>

