<template>
	<div class="m-expert" :class="{'m-contact--inactive':!data.isActive}">
		<div class="m-expert__inner">
			<div class="m-expert__avatar">
				<a :href="'mailto:'+data.address.email" v-if="data.address.email"></a>
				<img :src="data.avatar" v-if="data.avatar">
				<span class="m-expert__initial" v-else>{{ data.firstname.substr(0,1) }}{{ data.lastname.substr(0,1) }}</span>
			</div>
			<div class="m-expert__info">
				<h2 class="m-expert__title">{{ data.firstname }} {{ data.lastname }}</h2>
				<div class="m-expert__phone" v-if="data.address.phone">
					<a :href="'tel:'+data.address.phone">{{ data.address.phone }}</a>
				</div>
				<div class="m-expert__email" v-if="data.address.email">
					<a :href="'mailto:'+data.address.email">{{ data.address.email }}</a>
				</div>
				<div class="m-expert__street" v-if="data.address.street">
					{{ data.address.street }}
				</div>
				<div class="m-expert__city" v-if="data.address.city">
					{{ data.address.zip }} {{ data.address.city }}
				</div>
				<div class="m-expert__logos" v-if="data.address.rev || data.address.trv">
					<img src="/img/logo_rev.png" alt="REV" v-if="data.address.rev" width="50" />
					<img src="/img/logo_trv.png" alt="TRV" v-if="data.address.trv" width="50" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "expert",
		props:['data']
	}
</script>

<style lang="scss">
@use "sass:math";
@import '../../environment';
	.m-expert{
		&__inner{
			display: flex; background: #fff; border-radius: 4px; height: 100%; overflow: hidden;
		}
		&__avatar{
			width: 40%; background: $c-primary; @include aspect-ratio(math.div(3,4));
			@media #{$to-tablet}{ width: 33% }
			a{ position: overlay(); z-index: 2 }
			img{ position: overlay(); z-index: 1; width: 100%; height: 100%; object-fit: cover; max-width: none }
		}
		&__initial{
			position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center;
			align-items: center; color: #fff; font-size: 7rem; opacity: 0.15;
		}
		&__title{ font-weight: bold; margin-bottom: $space-m }
		&__email, &__phone, &__city, &__street{ font-size: $font-xs; word-break: break-all; margin-bottom: 0.5rem }
		&__email{
			margin-bottom: 1rem;
			a:hover{ text-decoration: underline; }
		}
		&__info{
			padding: $space; width: 60%; color: $c-primary; text-align: left; justify-content: center; display: flex; flex-flow: column;
			@media #{$to-tablet}{ width: 67%; padding: $space-m; }
		}

		&__logos{
			margin-top: $space-s;
			img{
				display: inline-block;
				@media #{$to-tablet}{ width: 40px }
			}
			img+img{ margin-left: 1rem }
		}
	}
</style>
