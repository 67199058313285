import Vue from "vue";

export default {

	get(slug) {

		return new Promise((resolve, reject) => {

			Vue.http.get('page/'+slug).then(response => {

				resolve(response.body.response);

			}, reject)
		});
	}
};
